import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-dark.png"

//Import config
// import { facebook, google } from "../../config"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const { user } = useSelector(({ Login }) => Login);

  // const signIn = (res, type) => {
  //   const { socialLogin } = props
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     }
  //     socialLogin(postData, props.history, type)
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     }
  //     socialLogin(postData, props.history, type)
  //   }
  // }

  // //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google")
  // }

  // //handleTwitterLoginResponse
  // // const twitterResponse = e => {}

  // //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook")
  // }


  const renderAler = () => {
    if (props.error?.response?.data?.error?.message) return <Alert color="danger">{props.error?.response?.data?.error?.message}</Alert>
    else return <Alert color="danger">Veuillez vérifier vos données et réessayer</Alert>
  }
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    // console.log(user);
    if (user?._id && localStorage.getItem("authUser") !== null) {
      // console.log("CONNECTED !");
      window.location.href = "/dashboard"
    }
  }, [user])

  return (<React.Fragment>
    <MetaTags><title>Login | AppliBTP</title></MetaTags>
    {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
    <div className="account-pages pt-sm-5" style={{ backgroundColor: "#f8f8fb", minHeight: 900 }} >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="" style={{ backgroundColor: "#264b9a", height: 130 }}>
                {/* bg-primary bg-soft */}
                <Row>
                  <Col xs={7}>
                    <div className="text-primary" style={{ padding: "19px 0px 19px 19px" }}>
                      <h5 className="" style={{ color: "#fff", fontSize: 21 }} >Bienvenue !</h5>
                      <p style={{ color: "#fff", fontSize: 12 }}>Connecter Pour Continuer à AppliBTP.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div>
                  <Link to="/" className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title login-logo-wrap">
                        <img
                          src={logo}
                          alt=""
                        // height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {props.error && props.error !== "" ? renderAler() : null}

                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Adresse Email"
                        // value="admin@themesbrand.com"
                        // value="applibtp@gmail.com"
                        value=""
                        className="form-control"
                        placeholder="Email"
                        type="text"
                        // required
                        validate={{
                          email: {value : true , errorMessage :"Adresse e-mail invalide !"},
                          required: { value: true, errorMessage: 'Adresse e-mail obligatoire !' },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Mot de passe"
                        // value="aplibtp++"
                        value=""
                        // type="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Mot de passe"
                        validate={{
                          required: { value: true, errorMessage: 'Mot de passe obligatoire' },
                          minLength: { value: 7, errorMessage: 'Mot de passe doit contenire au moins 7 carachtère' },
                          maxLength: { value: 16, errorMessage: 'Mot de passe doit contenire au plus 16 carachtère' }
                        }}
                      />
                      <div onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}
                        style={{ marginTop: '5px', color: '#1e3c7b', cursor: 'pointer' }}>
                        <span>voir mot de passe</span>
                      </div>


                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        Connexion
                      </button>
                    </div>
                    <Col md="12">
                      <Link to="/forgot-password" style={{ textDecoration: "underline !important", textAlign: "center", display: "block", marginTop: 10 }}>Mot de passe oublié?</Link>
                    </Col>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <Link to="/mentions-legales" style={{ textTransform: "uppercase", textAlign: "center", display: "block", marginTop: 10 }}>Mentions légales</Link>
          </Col>

        


          {/* <Col md="12" style={{ textAlign: "center" }}>
              <a href="#" style={{textTransform: "uppercase"}}>Mentions Légales</a>
            </Col> */}
        </Row>
      </Container>
    </div>
  </React.Fragment>)
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}