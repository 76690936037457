import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
// import { withTranslation } from "react-i18next"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { getListOrder, getListReports, getListVacations } from "store/actions"
// import classNames from "classnames"

const SidebarContent = props => {
  // HOOKS
  const { user } = useSelector(({ Login }) => Login)
  
  const dispatch = useDispatch();
  const ref = useRef()

  const { menu_data } = props
  const [count_reports, setCountReports] = useState(0)
  const [count_vacations, setCountVacations] = useState(0)
  const [count_orders, setCountOrders] = useState(0)
  const [menu, setMenu] = useState([])
  // const [active, setActive] = useState(false)
  // const style = {}

  const initMenu = (path_name) => {
    // console.log('INIT MENU', path_name);
    try {
      new MetisMenu("#side-menu")
      const matchingMenuItem = findMenuByLocation(path_name)
      if (matchingMenuItem) activateParentDropdown(matchingMenuItem)
    } catch (error) { }
  }

  const findMenuByLocation = (path_name) => {
    try {
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a");

      for (let i = 0; i < items.length; ++i) {
        if (path_name === items[i].pathname) {
          return items[i];
        }
      }

      try {
        const parent_menu_path = "/" + path_name.split("/")[1]
        for (let i = 0; i < items.length; ++i) {
          if (parent_menu_path === items[i].pathname) {
            return items[i];
          }
        }
      } catch (error) { }

      return null
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    initMenu(props.location.pathname)
    // changeSubMenus(opened_parent_menu)
    // setActive(false)
    // setTimeout(() => {
    // initMenu(props.location.pathname)
    // changeSubMenus(opened_parent_menu)
    // }, 3000)



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu_data])


  useEffect(() => {
    setMenu(menu_data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, )


  useEffect(() => {
    ref.current.recalculate()
  })

  const getCounts = () => {
    dispatch(getListReports({
      offset: 0, limit: 1, filters: {
        isValid: false
      }
    }, (resp) => {
      setCountReports(resp?.counts || 0)
    }, (err) => { }))

    dispatch(getListVacations({
      offset: 0, limit: 1, filters: {
        status: "0"
      }
    }, (resp) => {
      setCountVacations(resp?.counts || 0)
    }, (err) => { }))
    dispatch(getListOrder({
      offset: 0, limit: 1, filters: {
        status: "0"
      }
    },(res)=>{
      setCountOrders(res?.counts || 0)
    },(e)=>{ }
    ))


  }

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    try {
      item?.classList.add("active")

      item?.classList.add("mm-collapsed")

      const parent = item.parentElement
      const parent2El = parent.childNodes[1]
      if (parent2El && parent2El.id !== "side-menu") {
        parent2El?.classList.add("mm-show")
      }

      if (parent) {
        parent?.classList.add("mm-active")
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add("mm-show") // ul tag

          const parent3 = parent2.parentElement // li tag
          if (parent3) {
            parent3?.classList.add("mm-active") // li
            parent3.childNodes[0]?.classList.add("mm-active") //a
            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4?.classList.add("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5?.classList.add("mm-show") // li
                parent5.childNodes[0]?.classList.add("mm-active") // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false
      }
      scrollElement(item);
      return false
    } catch (error) {
      return false
    }
  }

  const [opened_parent_menu, setOpenedParentMeu] = useState("")
  const toggleParentMenu = (name = "") => {
    setOpenedParentMeu(name)
  }
  useEffect(() => {

    changeSubMenus()
    
    if(user && user.userType !== "SUPER_ADMIN") getCounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened_parent_menu])

  const changeSubMenus = () => {
    try {
      const menu = document.getElementById("side-menu")?.querySelectorAll('[data-pathname]') || []

      for (const e of menu) {
        e?.classList.remove('mm-active')

        if (opened_parent_menu === e.getAttribute('data-pathname')) {

          e.getElementsByTagName('a')[0]?.classList.remove("mm-collapsed")
          e.getElementsByTagName('a')[0]?.setAttribute('aria-expanded', true)

          const sub_menu = e.getElementsByTagName('ul')[0]
          sub_menu?.classList.remove("mm-collapsing")
          sub_menu?.classList.add("mm-show")
          sub_menu?.setAttribute('style', "")

          const sub_menu_links = sub_menu.getElementsByTagName('a') || []

          for (const sub_link of sub_menu_links) {
            if (sub_link?.getAttribute("href") === props?.location?.pathname) sub_link?.parentNode?.classList.add('mm-active')
            else sub_link?.parentNode?.classList.remove('mm-active')
          }

        } else {
          e.getElementsByTagName('a')[0]?.classList.remove("mm-collapsed")
          e.getElementsByTagName('a')[0]?.setAttribute('aria-expanded', false)

          const sub_menu = e.getElementsByTagName('sub-menu')[0]
          sub_menu?.classList.add("mm-collapsing")
          sub_menu?.classList.remove("mm-show")

          const sub_menu_links = sub_menu?.getElementsByTagName('a') || []
          for (const sub_link of sub_menu_links) {
            sub_link?.parentNode?.classList.remove('mm-active')
          }
        }
      }
    } catch (error) {

    }
  }


  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
               menu_data.map((item, i) => (
               <li key={'nav-' + i} className="parent"
              
                data-pathname={item.has_arrow ? item.path : null}
              >
                <Link 
                 onClick={(e) => {
                  // setActive(!active)
                  item.active = !item.active
                  // console.log('active',item.active );
                  if (item.has_arrow) {
                    // console.log('target',e.target?.parentElement );
                    let target = e.target?.parentElement
                    // console.log('target classList', target.classList);
                    // console.log('test',
                    //   target.classList?.contains('has-arrow')
                    //   || target.classList?.contains('waves-effect')
                    // );
 
                     if(target.classList?.contains('hahttp://localhost:3000/staffs/vacationss-arrow')
                      || target.classList?.contains('waves-effect')
                     ){
                       target = target?.parentElement
                     }
                     target?.classList.add('waves-effect')

                    if (!item.active) {
                      const others = target.getElementsByClassName('sub-menu')
                    //  console.log('new target',target);
                    //  console.log('others',others);
                      target.setAttribute('aria-expanded', false)
                      for (const iterator of others) {
                        iterator?.classList.add('mm-collapsing')
                        iterator?.classList.remove("mm-active")
                        iterator?.classList.remove("mm-show")
                        iterator.setAttribute('aria-expanded', false)
                        iterator.style.height ='0px'
                      }
                    
                    } else {
                      const parentElements = target.getElementsByClassName('sub-menu')
                      target.setAttribute('aria-expanded', true)
                      for (const iterator of parentElements) {
                        // console.log('iterator',iterator);
                      iterator?.classList.remove('mm-collapsing')
                      iterator?.classList.add("mm-active")
                      iterator?.classList.add("mm-show")
                      iterator.style.height ='100%'
                      iterator.setAttribute('aria-expanded', true)
                    }
                    }
                  }
                }}
 
                to={item.path} className={
                  item.badge === "sum_staff_notifs" ?
                    `${!(count_reports + count_vacations ) ? "has-arrow waves-effect" : "waves-effect"} ` :
                    `${item.has_arrow ? "has-arrow waves-effect" : "waves-effect"} `
                }>
                  <i className={item.icon}></i>
                  <span>{item.name}</span>
                  {item.badge === "sum_staff_notifs" && (count_reports + count_vacations > 0) ? <span className="badge rounded-pill bg-warning float-end">{count_reports + count_vacations}</span> : null}
                  {item.badge === "count_orders" && count_orders ? <span
                  style={{
                    position :"absolute",
                    top :5,
                    right :5
                  }}
                   className="badge rounded-pill bg-warning float-end">{count_orders}</span> : null}
                </Link>
                {item.subs && <ul className="sub-menu">
                  {item.subs.map((item, j) => (<li className="child"
                    key={'sub-nav-' + i + "-" + j}
                  >
                    <Link to={item.path} style={item.style || {}}>
                      <i className={item.icon}></i>
                      <span>{item.name}</span>
                      {item.badge === "count_reports" && count_reports ? <span className="badge rounded-pill bg-warning float-end">{count_reports}</span> : null}
                      {item.badge === "count_vacations" && count_vacations ? <span className="badge rounded-pill bg-warning float-end">{count_vacations}</span> : null}
                      
                    </Link>
                  </li>))}
                </ul>}
              </li>))
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  // location: PropTypes.shape({pathname: PropTypes.string.isRequired}),
  location: PropTypes.object,
  t: PropTypes.any,

  menu_data: PropTypes.array.isRequired
}

const mapStatetoProps = state => {
  return {}
  // const { user } = state.Login
  // return { user, permissions }
}

export default withRouter(
  connect(mapStatetoProps, {

  })(SidebarContent)
)

// export default withRouter(withTranslation()(SidebarContent))
