import SupplySrv from "services/SupplySrv";

import {
  GET_SUPPLYS,
  GET_SUPPLYS_FAIL,
  GET_SUPPLYS_SUCCESS,

  GET_ONE_SUPPLY,
  GET_ONE_SUPPLY_SUCCESS,
  GET_ONE_SUPPLY_FAIL,

  GET_ONE_TRACEBILITY,
  GET_ONE_TRACEBILITY_SUCCESS,
  GET_ONE_TRACEBILITY_FAIL,

  CREATE_SUPPLY,
  CREATE_SUPPLY_SUCCESS,
  CREATE_SUPPLY_FAIL,

  PATCH_SUPPLY,
  PATCH_SUPPLY_SUCCESS,
  PATCH_SUPPLY_FAIL,
  
  PATCH_TRACEBILITY,
  PATCH_TRACEBILITY_SUCCESS,
  PATCH_TRACEBILITY_FAIL,

  DELETE_SUPPLY,
  DELETE_SUPPLY_SUCCESS,
  DELETE_SUPPLY_FAIL,

  DELETE_TRACEABILITY,
  DELETE_TRACEABILITY_SUCCESS,
  DELETE_TRACEABILITY_FAIL,
  
  IMPORT_SUPPLIES,
  IMPORT_SUPPLIES_SUCCESS,
  IMPORT_SUPPLIES_FAIL,

  GET_TRACABILITIES,
  GET_TRACABILITIES_SUCCESS,
  GET_TRACABILITIES_FAIL,

  PRINT_TRACEABILITY,
  PRINT_TRACEABILITY_SUCCESS,
  PRINT_TRACEABILITY_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListSupplies = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_SUPPLYS,
    });

    SupplySrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supplies: response.data.data.supplies,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_SUPPLYS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_SUPPLYS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_SUPPLY,
    });

    SupplySrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supply: response.data.data.supply,
          }

          dispatch({
            type: CREATE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_SUPPLY,
    });

    SupplySrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supply: response.data.data.supply
          }

          dispatch({
            type: GET_ONE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== GET TRACEABILITY LIST
export const getListTracabilities = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_TRACABILITIES,
    });

    SupplySrv.getTracabilities(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            traceabilities: response.data.data.traceabilities || [],
            counts: response.data.data.counts || 0,
          }

          dispatch({
            type: GET_TRACABILITIES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_TRACABILITIES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== GET ONE TRACEABILITY

export const getOneTraceabily = (payload, callback, callbackError) => {

    return (dispatch) => {
      dispatch({
        type: GET_ONE_TRACEBILITY,
      });
  
      SupplySrv.getOneTraceabily(payload)
        .then((response) => {
          if (response && response?.data?.success) {
            const resp = {
              traceability: response.data.data.traceability
            }
  
            dispatch({
              type: GET_ONE_TRACEBILITY_SUCCESS,
              payload: resp,
            });
  
            callback(resp);
          }
          else{
            if(callbackError) callbackError({ error: response?.data.error });
          }
        })
        .catch(e => {
          dispatch({
            type: GET_ONE_TRACEBILITY_FAIL,
            payload: { error: {} }
          });
          if(callbackError) callbackError(e?.response?.data?.error);
        });
    };
  };
// =============== PATCH TRACEABILITY
export const editTraceability = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_TRACEBILITY,
    });

    SupplySrv.patchTraceability(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supply: response.data.data.supply,
          }

          dispatch({
            type: PATCH_TRACEBILITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_TRACEBILITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== PATCH
export const editSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_SUPPLY,
    });

    SupplySrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supply: response.data.data.supply,
          }

          dispatch({
            type: PATCH_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== DELETE TRACEABILITY
export const deleteTraceability  = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_TRACEABILITY,
    });

    SupplySrv.deleteTraceability (payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_TRACEABILITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_TRACEABILITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_SUPPLY,
    });

    SupplySrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== IMPORT
export const importSupplies = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_SUPPLIES,
    });

    SupplySrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            supplies: response.data.data.supplies || [],
          }

          dispatch({
            type: IMPORT_SUPPLIES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_SUPPLIES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// =============== PRINT (Traceability)
export const printTraceability = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_TRACEABILITY,
    });

    SupplySrv.printTraceability(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: PRINT_TRACEABILITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_TRACEABILITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== PRINT (Traceability) EXCEL
export const printTraceabilityExcel = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_TRACEABILITY,
    });

    SupplySrv.printTraceabilityExcel(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: PRINT_TRACEABILITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_TRACEABILITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== PRINT EXPORT(Traceability) EXCEL
export const printExportExcel = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_TRACEABILITY,
    });

    SupplySrv.printExportExcel(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: PRINT_TRACEABILITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_TRACEABILITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
