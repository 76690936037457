import {
  GET_MACHINES,
  GET_MACHINES_FAIL,
  GET_MACHINES_SUCCESS,

  GET_ONE_MACHINE,
  GET_ONE_MACHINE_SUCCESS,
  GET_ONE_MACHINE_FAIL,

  CREATE_MACHINE,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_FAIL,

  PATCH_MACHINE,
  PATCH_MACHINE_SUCCESS,
  PATCH_MACHINE_FAIL,

  DELETE_MACHINE,
  DELETE_MACHINE_SUCCESS,
  DELETE_MACHINE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  machine: null,
  machines: [],
  error: null,

  loading_get_machines: false,
  loading_get_machine: false,

  loading_add_machine: false,
  loading_edit_machine: false,

  count_all_machines: 0,
}

const MachineState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MACHINES:
      return {
        ...state, loading_get_machines: true, error: null
      }
    case GET_MACHINES_SUCCESS:
      return {
        ...state,
        machines: action.payload.machines,
        loading_get_machines: false, error: null,
        count_all_machines: action.payload.counts || 0
      }
    case GET_MACHINES_FAIL:
      return {
        ...state,
        loading_get_machines: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MACHINE:
      return {
        ...state, machine: null, loading_get_machine: true, error: null
      }
    case GET_ONE_MACHINE_SUCCESS:
      return {
        ...state,
        machine: action.payload.machine,
        loading_get_machine: false, error: null
      }
    case GET_ONE_MACHINE_FAIL:
      return {
        ...state,
        loading_get_machine: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MACHINE:
      return {
        ...state, machine: null, loading_add_machine: true, error: null
      }
    case CREATE_MACHINE_SUCCESS:
      return {
        ...state,
        machine: action.payload.machine,
        loading_add_machine: false, error: null
      }
    case CREATE_MACHINE_FAIL:
      return {
        ...state,
        loading_add_machine: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MACHINE:
      return {
        ...state, loading_edit_machine: true, error: null
      }
    case PATCH_MACHINE_SUCCESS:
      return {
        ...state,
        machine: action.payload.machine,
        loading_edit_machine: false, error: null
      }
    case PATCH_MACHINE_FAIL:
      return {
        ...state,
        loading_edit_machine: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MACHINE:
      return {
        ...state, machine: null, loading_delete_machine: true, error: null
      }
    case DELETE_MACHINE_SUCCESS:
      return {
        ...state,
        machine: action.payload.machine,
        loading_delete_machine: false, error: null
      }
    case DELETE_MACHINE_FAIL:
      return {
        ...state,
        loading_delete_machine: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MachineState
