import LicenseSrv from "services/LicenseSrv";

import {
  GET_LICENSES,
  GET_LICENSES_FAIL,
  GET_LICENSES_SUCCESS,

  GET_ONE_LICENSE,
  GET_ONE_LICENSE_SUCCESS,
  GET_ONE_LICENSE_FAIL,

  CREATE_LICENSE,
  CREATE_LICENSE_SUCCESS,
  CREATE_LICENSE_FAIL,

  PATCH_LICENSE,
  PATCH_LICENSE_SUCCESS,
  PATCH_LICENSE_FAIL,

  DELETE_LICENSE,
  DELETE_LICENSE_SUCCESS,
  DELETE_LICENSE_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListLicenses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_LICENSES,
    });

    LicenseSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            licenses: response.data.data.drivingLicenses,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_LICENSES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_LICENSES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createLicense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_LICENSE,
    });

    LicenseSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            license: response.data.data.drivingLicense,
          }

          dispatch({
            type: CREATE_LICENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_LICENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneLicense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_LICENSE,
    });

    LicenseSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            license: response.data.data.drivingLicense
          }

          dispatch({
            type: GET_ONE_LICENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_LICENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editLicense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_LICENSE,
    });

    LicenseSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            license: response.data.data.drivingLicense,
          }

          dispatch({
            type: PATCH_LICENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_LICENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteLicense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_LICENSE,
    });

    LicenseSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_LICENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_LICENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};