import {
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_FAIL,
  GET_PUBLICATIONS_SUCCESS,

  GET_ONE_PUBLICATION,
  GET_ONE_PUBLICATION_SUCCESS,
  GET_ONE_PUBLICATION_FAIL,

  CREATE_PUBLICATION,
  CREATE_PUBLICATION_SUCCESS,
  CREATE_PUBLICATION_FAIL,

  PATCH_PUBLICATION,
  PATCH_PUBLICATION_SUCCESS,
  PATCH_PUBLICATION_FAIL,

  DELETE_PUBLICATION,
  DELETE_PUBLICATION_SUCCESS,
  DELETE_PUBLICATION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  publication: null,
  publications: [],
  error: null,

  loading_get_publications: false,
  loading_get_publication: false,

  loading_add_publication: false,
  loading_edit_publication: false,

  count_all_publications: 0,
}

const PublicationState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_PUBLICATIONS:
      return {
        ...state, loading_get_publications: true, error: null
      }
    case GET_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        publications: action.payload.publications,
        loading_get_publications: false, error: null,
        count_all_publications: action.payload.counts || 0
      }
    case GET_PUBLICATIONS_FAIL:
      return {
        ...state, publications: [],
        loading_get_publications: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_PUBLICATION:
      return {
        ...state, publication: null, loading_get_publication: true, error: null
      }
    case GET_ONE_PUBLICATION_SUCCESS:
      return {
        ...state,
        publication: action.payload.publication,
        loading_get_publication: false, error: null
      }
    case GET_ONE_PUBLICATION_FAIL:
      return {
        ...state,
        loading_get_publication: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_PUBLICATION:
      return {
        ...state, publication: null, loading_add_publication: true, error: null
      }
    case CREATE_PUBLICATION_SUCCESS:
      return {
        ...state,
        publication: action.payload.publication,
        loading_add_publication: false, error: null
      }
    case CREATE_PUBLICATION_FAIL:
      return {
        ...state,
        loading_add_publication: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_PUBLICATION:
      return {
        ...state, loading_edit_publication: true, error: null
      }
    case PATCH_PUBLICATION_SUCCESS:
      return {
        ...state,
        publication: action.payload.publication,
        loading_edit_publication: false, error: null
      }
    case PATCH_PUBLICATION_FAIL:
      return {
        ...state,
        loading_edit_publication: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_PUBLICATION:
      return {
        ...state, publication: null, loading_delete_publication: true, error: null
      }
    case DELETE_PUBLICATION_SUCCESS:
      return {
        ...state,
        publication: action.payload.publication,
        loading_delete_publication: false, error: null
      }
    case DELETE_PUBLICATION_FAIL:
      return {
        ...state,
        loading_delete_publication: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default PublicationState
