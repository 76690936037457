// import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import {
  Row, Col, Button, NavLink
} from "reactstrap"

// Redux

// actions
// import {
//   changeCrumb
// } from "../../store/actions"

import { MetaTags } from 'react-meta-tags'

import mobiles_img from "assets/images/mobiles.png"
import innovation_img from "assets/images/innovation.png"
import timer_img from "assets/images/timer.png"
// import wallet_img from "assets/images/wallet.png"
import services_img from "assets/images/img-services.png"
import { useSelector } from "react-redux"

const HomePage = props => {
  // useEffect(() => {
  //   window.location.href = "/login"
  // }, [])
  // const { user } = useSelector(({ Login }) => Login);

  // useEffect(() => {
  //   if (user?._id && localStorage.getItem("authUser") !== null) {
  //     // console.log("CONNECTED !");
  //     window.location.href = "/dashboard"
  //   }
  // }, [user])
  return (
    <React.Fragment>
      <MetaTags><title>AppliBTP</title></MetaTags>

      <div className="main-section">
        <div className="container">
          <div className="discover">
            <h1 className="title" >Logiciel Web <br /> et Application mobile</h1>
            <p className="short-desc">Faciliter et dématérialiser l'activité d'infrastructure réseau.</p>
            <NavLink href="/prices" to="/prices" style={{ width: 130 }} ><Button className="btn-discover" color="primary">Découvrir</Button></NavLink>
          </div>
        </div>
      </div>

      <div className="services-section">
        <div className="container">
          <Row>
            <Col md="6" style={{ textAlign: "center" }}>
              <img className="mobiles" src={services_img} alt="#" style={{ width: "90%" }} />
            </Col>
            <Col md="6" >
              <div className="separation-note mb-5">
                <span className="mark-line"></span> <span className="mark-note" >Nos services</span>
              </div>

              <Row>
                <Col md="11">
                  <p className="primed" style={{ fontSize: 15, fontWeight: 500 }}  >Gestion et Suivi</p>
                  <h3>Gestion Planning</h3>
                  <p className="grey">Le responsable de chantier aura une interface pour planifier chaque chantier.<br /> Cette interface lui permet de créer des interventions ou les prendre sur la liste des interventions générées.</p>
                  <p className="hilighted" style={{ cursor: "pointer" }}>Voir Exemple</p>
                </Col>
                <Col md="1">
                  <p className="selected-index" style={{ fontWeight: "bold" }}>1</p>
                  <p className="grey" style={{ fontWeight: "bold" }}>2</p>
                  <p className="grey" style={{ fontWeight: "bold" }}>3</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="points-section">
        <div className="separation-note mb-5">
          <span className="mark-line"></span> <span className="mark-note" >Pourquoi choisir <span style={{ fontWeight: "bold", color: "#264B9A" }}>Appli</span><span style={{ color: "#264B9A", fontWeight: "normal" }} >BTP</span></span>
        </div>

        <Row>
          <Col md="4">
            <img className="mobiles" src={innovation_img} alt="#" />
            <h4 className="mt-2 mb-3">Innovante</h4>
            <p className="grey mt-2">Une solution va vous permettre de contrôler tous les organes de votre entreprise et pour qu’ils fonctionnent correctement d’une manière automatique.</p>
          </Col>
          <Col md="4">
            <img className="mobiles" src={timer_img} alt="#" />
            <h4 className="mt-2 mb-3">Un service rapide et simple</h4>
            <p className="grey mt-2">AppliBTP vous permet de gérer vos serveurs, vos données, votre stockage, vos réseaux, du matériel comme des logiciels rapides et en toute simplicité.</p>
          </Col>
          <Col md="4">
            {/* <img className="mobiles" src={wallet_img} alt="#" /> */}
            <i className="mdi mdi-lock" style={{ fontSize: 46, color: "#e6e8ed" }} />
            <h4 className="mt-2 mb-3">Optimisation et sécurité</h4>
            <p className="grey mt-2">Une plateforme performante, une gestion optimale de votre infrastructure réseau en toutes sécurités</p>
          </Col>
        </Row>
      </div>

      <div className="quotation-section">
        <i className="mdi mdi-format-quote-close quotaion-icon" />
        <p style={{ fontSize: 20, fontWeight: "bold", color: "#062342" }} ><span style={{ color: "#264B9A" }}>Appli</span><span style={{ color: "#264B9A", fontWeight: "normal" }} >BTP</span> C'est la meilleur pour <br />votre chantier </p>
        <p className="grey mt-2">Le responsable de chantier aura une interface pour planifier chaque chantier. Cette<br /> interface lui permet de créer des interventions ou les prendre sur la liste des interventions<br /> générées.</p>

        <p className="mt-4"><strong>Paul Fréderic</strong> - <span className="grey">CEO de Projet</span> </p>
      </div>
      <div className="mobile-section">
        <Row>
          <Col md="6" style={{}}>
            <div style={{ float: "right", marginTop: 70 }}>
              <h3>Disponible Pour Play Store <br />et App Store</h3>
              <p className="grey">Télécharger notre application de App Store et <br /> Playstore pour Android et IOS</p>
            </div>
          </Col>
          <Col md="6">
            <img className="mobiles" src={mobiles_img} alt="#" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

HomePage.propTypes = {
}
export default HomePage