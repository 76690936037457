import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListActivitiesPage = React.lazy(() => import('./list-activities.page'));
const ListRolesPage = React.lazy(() => import('./list-roles.page'));
const ListLicensesPage = React.lazy(() => import('./list-licenses.page'));
const ListUnitsPage = React.lazy(() => import('./list-units.page'));
const ListProjectTypesPage = React.lazy(() => import('./list-project-types.page'));
const ListArticleTypesPage = React.lazy(() => import('./list-article-types.page'));
const ListMaterialTypesPage = React.lazy(() => import('./list-material-types.page'));
const ListStockTypesPage = React.lazy(() => import('./list-stock-types.page'));
const ListMachineTypesPage = React.lazy(() => import('./list-machine-types-page'));
const ListReparationTypesPage = React.lazy(() => import('./list-reparation-types-page'));
const ListAuthorizationsPage = React.lazy(() => import('./list-authorization.page'));
const ListCategoriesPage = React.lazy(() => import('./list-categories.page'));
const ListPermissionsPage = React.lazy(() => import("./list-permissions.page"));
const ListPublicationsPage = React.lazy(() => import("./list-publications-page"));
const ListMiddleSchoolPage = React.lazy(() => import('./list-middleSchool-page'))

const ListQuotePhasesPage = React.lazy(() => import('./list-quote-phases.page'))
const ListQuotesPage = React.lazy(() => import('pages/Quotes/list-quotes.page'))

const ListBonusPage = React.lazy(() => import('./list-bonus.page'))
const ConfigTraceabilityPage = React.lazy(() => import('./traceability.page'))

const ListClothesPage = React.lazy(() => import('./list-clothes.page'));

const Settings = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/activities`} />

      <Route path={`${match.url}/activities`}
        render={props => <ListActivitiesPage {...props} />}
      />

      <Route path={`${match.url}/roles`}
        render={props => <ListRolesPage {...props} />}
      />

      <Route path={`${match.url}/licenses`}
        render={props => <ListLicensesPage {...props} />}
      />

      <Route path={`${match.url}/units`}
        render={props => <ListUnitsPage {...props} />}
      />

      <Route path={`${match.url}/project-types`}
        render={props => <ListProjectTypesPage {...props} />}
      />

      <Route path={`${match.url}/article-types`}
        render={props => <ListArticleTypesPage {...props} />}
      />

      <Route path={`${match.url}/material-types`}
        render={props => <ListMaterialTypesPage {...props} />}
      />

      <Route path={`${match.url}/stock-types`}
        render={props => <ListStockTypesPage {...props} />}
      />

      <Route path={`${match.url}/machine-types`}
        render={props => <ListMachineTypesPage {...props} />}
      />

      <Route path={`${match.url}/reparation-types`}
        render={props => <ListReparationTypesPage {...props} />}
      />

      <Route path={`${match.url}/permissions`}
        render={props => <ListPermissionsPage {...props} />}
      />

      <Route path={`${match.url}/authorizations`}
        render={props => <ListAuthorizationsPage {...props} />}
      />

      <Route path={`${match.url}/categories`}
        render={props => <ListCategoriesPage {...props} />}
      />

      <Route path={`${match.url}/ouvrage`}
        render={props => <ListPublicationsPage {...props} />}
      />
      <Route path={`${match.url}/college`}
        render={props => <ListMiddleSchoolPage {...props} />}
      />
      <Route path={`${match.url}/quote-phases`}
        render={props => <ListQuotePhasesPage {...props} />}
      />
      <Route path={`${match.url}/quote-models`}
        render={props => <ListQuotesPage {...props} filter_by={{ isTemplate: true }} />}
      />
      <Route path={`${match.url}/bonus`}
        render={props => <ListBonusPage {...props} />}
      />

      <Route path={`${match.url}/traceability`}
        render={props => <ConfigTraceabilityPage {...props} />}
      />

      <Route path={`${match.url}/clothes`}
        render={props => <ListClothesPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/activities`} />
    </Switch>
  </Suspense>
);
export default Settings;