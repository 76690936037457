import {
  GET_MOVEMENTS,
  GET_MOVEMENTS_FAIL,
  GET_MOVEMENTS_SUCCESS,

  GET_ONE_MOVEMENT,
  GET_ONE_MOVEMENT_SUCCESS,
  GET_ONE_MOVEMENT_FAIL,

  CREATE_MOVEMENT,
  CREATE_MOVEMENT_SUCCESS,
  CREATE_MOVEMENT_FAIL,

  PATCH_MOVEMENT,
  PATCH_MOVEMENT_SUCCESS,
  PATCH_MOVEMENT_FAIL,

  DELETE_MOVEMENT,
  DELETE_MOVEMENT_SUCCESS,
  DELETE_MOVEMENT_FAIL,
  GET_EQUIPMENTS,
  GET_EQUIPMENTS_FAIL,
  GET_EQUIPMENTS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  movement: null,
  movements: [],
  equipments: [],
  error: null,

  loading_get_movements: false,
  loading_get_movement: false,

  loading_add_movement: false,
  loading_edit_movement: false,

  count_all_movements: 0,

  loading_get_equipments: false,
  count_all_equipments: 0,
}

const MovementState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MOVEMENTS:
      return {
        ...state, loading_get_movements: true, error: null
      }
    case GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        movements: action.payload.movements,
        loading_get_movements: false, error: null,
        count_all_movements: action.payload.counts || 0
      }
    case GET_MOVEMENTS_FAIL:
      return {
        ...state,
        loading_get_movements: false,
        error: action.payload?.error || {}
      }



    // =============== GET LIST EQUIPMENTS
    case GET_EQUIPMENTS:
      return {
        ...state, loading_get_equipments: true, error: null
      }
    case GET_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        equipments: action.payload.equipments,
        loading_get_equipments: false, error: null,
        count_all_equipments: action.payload.counts || 0
      }
    case GET_EQUIPMENTS_FAIL:
      return {
        ...state,
        loading_get_equipments: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MOVEMENT:
      return {
        ...state, movement: null, loading_get_movement: true, error: null
      }
    case GET_ONE_MOVEMENT_SUCCESS:
      return {
        ...state,
        movement: action.payload.movement,
        loading_get_movement: false, error: null
      }
    case GET_ONE_MOVEMENT_FAIL:
      return {
        ...state,
        loading_get_movement: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MOVEMENT:
      return {
        ...state, movement: null, loading_add_movement: true, error: null
      }
    case CREATE_MOVEMENT_SUCCESS:
      return {
        ...state,
        movement: action.payload.movement,
        loading_add_movement: false, error: null
      }
    case CREATE_MOVEMENT_FAIL:
      return {
        ...state,
        loading_add_movement: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MOVEMENT:
      return {
        ...state, loading_edit_movement: true, error: null
      }
    case PATCH_MOVEMENT_SUCCESS:
      return {
        ...state,
        movement: action.payload.movement,
        loading_edit_movement: false, error: null
      }
    case PATCH_MOVEMENT_FAIL:
      return {
        ...state,
        loading_edit_movement: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MOVEMENT:
      return {
        ...state, movement: null, loading_delete_movement: true, error: null
      }
    case DELETE_MOVEMENT_SUCCESS:
      return {
        ...state,
        movement: action.payload.movement,
        loading_delete_movement: false, error: null
      }
    case DELETE_MOVEMENT_FAIL:
      return {
        ...state,
        loading_delete_movement: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MovementState
