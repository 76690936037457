import { GET_COUNTS, GET_COUNTS_FAIL, GET_COUNTS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  counts: [],
  loadingCounts: false,
}
const CountState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_COUNTS:
      return {
        ...state,
        loadingCounts: true,
      }
    case GET_COUNTS_SUCCESS:
      return {
        ...state,
        counts: action.payload.counts,
        loadingCounts: false,
      }
    case GET_COUNTS_FAIL:
      return {
        ...state,
        loadingCounts: false,
      }
    default:
      return state
  }
}
export default CountState
