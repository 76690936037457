import {
  GET_PROJECT_TYPES,
  GET_PROJECT_TYPES_FAIL,
  GET_PROJECT_TYPES_SUCCESS,

  GET_ONE_PROJECT_TYPE,
  GET_ONE_PROJECT_TYPE_SUCCESS,
  GET_ONE_PROJECT_TYPE_FAIL,

  CREATE_PROJECT_TYPE,
  CREATE_PROJECT_TYPE_SUCCESS,
  CREATE_PROJECT_TYPE_FAIL,

  PATCH_PROJECT_TYPE,
  PATCH_PROJECT_TYPE_SUCCESS,
  PATCH_PROJECT_TYPE_FAIL,

  DELETE_PROJECT_TYPE,
  DELETE_PROJECT_TYPE_SUCCESS,
  DELETE_PROJECT_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  project_type: null,
  project_types: [],
  error: null,

  loading_get_project_types: false,
  loading_get_project_type: false,

  loading_add_project_type: false,
  loading_edit_project_type: false,

  count_all_project_types: 0,
}

const ProjectTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_PROJECT_TYPES:
      return {
        ...state, loading_get_project_types: true, error: null
      }
    case GET_PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        project_types: action.payload.project_types,
        loading_get_project_types: false, error: null,
        count_all_project_types: action.payload.counts || 0
      }
    case GET_PROJECT_TYPES_FAIL:
      return {
        ...state,
        loading_get_project_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_PROJECT_TYPE:
      return {
        ...state, project_type: null, loading_get_project_type: true, error: null
      }
    case GET_ONE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        project_type: action.payload.project_type,
        loading_get_project_type: false, error: null
      }
    case GET_ONE_PROJECT_TYPE_FAIL:
      return {
        ...state,
        loading_get_project_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_PROJECT_TYPE:
      return {
        ...state, project_type: null, loading_add_project_type: true, error: null
      }
    case CREATE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        project_type: action.payload.project_type,
        loading_add_project_type: false, error: null
      }
    case CREATE_PROJECT_TYPE_FAIL:
      return {
        ...state,
        loading_add_project_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_PROJECT_TYPE:
      return {
        ...state, loading_edit_project_type: true, error: null
      }
    case PATCH_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        project_type: action.payload.project_type,
        loading_edit_project_type: false, error: null
      }
    case PATCH_PROJECT_TYPE_FAIL:
      return {
        ...state,
        loading_edit_project_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_PROJECT_TYPE:
      return {
        ...state, project_type: null, loading_delete_project_type: true, error: null
      }
    case DELETE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        project_type: action.payload.project_type,
        loading_delete_project_type: false, error: null
      }
    case DELETE_PROJECT_TYPE_FAIL:
      return {
        ...state,
        loading_delete_project_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ProjectTypeState
