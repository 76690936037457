import {
  GET_TRAVEL_BONUSS,
  GET_TRAVEL_BONUSS_FAIL,
  GET_TRAVEL_BONUSS_SUCCESS,

  GET_ONE_TRAVEL_BONUS,
  GET_ONE_TRAVEL_BONUS_SUCCESS,
  GET_ONE_TRAVEL_BONUS_FAIL,

  CREATE_TRAVEL_BONUS,
  CREATE_TRAVEL_BONUS_SUCCESS,
  CREATE_TRAVEL_BONUS_FAIL,

  PATCH_TRAVEL_BONUS,
  PATCH_TRAVEL_BONUS_SUCCESS,
  PATCH_TRAVEL_BONUS_FAIL,

  DELETE_TRAVEL_BONUS,
  DELETE_TRAVEL_BONUS_SUCCESS,
  DELETE_TRAVEL_BONUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  travel_bonus: null,
  travel_bonuses: [],
  error: null,

  loading_get_travel_bonuses: false,
  loading_get_travel_bonus: false,

  loading_add_travel_bonus: false,
  loading_edit_travel_bonus: false,

  count_all_travel_bonuses: 0,
}

const TravelBonusState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_TRAVEL_BONUSS:
      return {
        ...state, loading_get_travel_bonuss: true, error: null
      }
    case GET_TRAVEL_BONUSS_SUCCESS:
      return {
        ...state,
        travel_bonuses: action.payload.travel_bonuses,
        loading_get_travel_bonuses: false, error: null,
        count_all_travel_bonuses: action.payload.counts || 0
      }
    case GET_TRAVEL_BONUSS_FAIL:
      return {
        ...state,
        loading_get_travel_bonuss: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_TRAVEL_BONUS:
      return {
        ...state, travel_bonus: null, loading_get_travel_bonus: true, error: null
      }
    case GET_ONE_TRAVEL_BONUS_SUCCESS:
      return {
        ...state,
        travel_bonus: action.payload.travel_bonus,
        loading_get_travel_bonus: false, error: null
      }
    case GET_ONE_TRAVEL_BONUS_FAIL:
      return {
        ...state,
        loading_get_travel_bonus: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_TRAVEL_BONUS:
      return {
        ...state, travel_bonus: null, loading_add_travel_bonus: true, error: null
      }
    case CREATE_TRAVEL_BONUS_SUCCESS:
      return {
        ...state,
        travel_bonus: action.payload.travel_bonus,
        loading_add_travel_bonus: false, error: null
      }
    case CREATE_TRAVEL_BONUS_FAIL:
      return {
        ...state,
        loading_add_travel_bonus: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_TRAVEL_BONUS:
      return {
        ...state, loading_edit_travel_bonus: true, error: null
      }
    case PATCH_TRAVEL_BONUS_SUCCESS:
      return {
        ...state,
        travel_bonus: action.payload.travel_bonus,
        loading_edit_travel_bonus: false, error: null
      }
    case PATCH_TRAVEL_BONUS_FAIL:
      return {
        ...state,
        loading_edit_travel_bonus: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_TRAVEL_BONUS:
      return {
        ...state, travel_bonus: null, loading_delete_travel_bonus: true, error: null
      }
    case DELETE_TRAVEL_BONUS_SUCCESS:
      return {
        ...state,
        travel_bonus: action.payload.travel_bonus,
        loading_delete_travel_bonus: false, error: null
      }
    case DELETE_TRAVEL_BONUS_FAIL:
      return {
        ...state,
        loading_delete_travel_bonus: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default TravelBonusState
