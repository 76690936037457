import {
  GET_WARKINGTIMES,
  GET_WARKINGTIMES_FAIL,
  GET_WARKINGTIMES_SUCCESS,

  GET_ONE_WARKINGTIME,
  GET_ONE_WARKINGTIME_SUCCESS,
  GET_ONE_WARKINGTIME_FAIL,


  GET_ONE_WARKINGTIME_By_Staff,
  GET_ONE_WARKINGTIME_By_Staff_SUCCESS,
  GET_ONE_WARKINGTIME_By_Staff_FAIL,

  CREATE_WARKINGTIME,
  CREATE_WARKINGTIME_SUCCESS,
  CREATE_WARKINGTIME_FAIL,

  PATCH_WARKINGTIME,
  PATCH_WARKINGTIME_SUCCESS,
  PATCH_WARKINGTIME_FAIL,

  DELETE_WARKINGTIME,
  DELETE_WARKINGTIME_SUCCESS,
  DELETE_WARKINGTIME_FAIL,


} from "./actionTypes"

const INIT_STATE = {
  workingtime: null,
  workingtimes: [],
  workingtimeByStaff: null,

  error: null,

  loading_get_workingtimes: false,
  loading_get_workingtime: false,
  loading_get_workingtime_by_Staff: false,

  loading_add_workingtime: false,
  loading_edit_workingtime: false,

  count_all_workingtimes: 0,
  count_all_workingtime_by_staff:0


}

const WorkingtimeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_WARKINGTIMES:
      return {
        ...state, loading_get_workingtimes: true, error: null
      }
    case GET_WARKINGTIMES_SUCCESS:
      return {
        ...state,
        workingtimes: action.payload.workingtimes,
        loading_get_workingtimes: false, error: null,
        count_all_workingtimes: action.payload.counts || 0
      }
    case GET_WARKINGTIMES_FAIL:
      return {
        ...state,
        loading_get_workingtimes: false,
        error: action.payload?.error || {}
      }






    // =============== GET ONE
    case GET_ONE_WARKINGTIME:
      return {
        ...state, workingtime: null, loading_get_workingtime: true, error: null
      }
    case GET_ONE_WARKINGTIME_SUCCESS:
      return {
        ...state,
        workingtime: action.payload.workingtime,
        loading_get_workingtime: false, error: null
      }
    case GET_ONE_WARKINGTIME_FAIL:
      return {
        ...state,
        loading_get_workingtime: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE BY STAFF
    case GET_ONE_WARKINGTIME_By_Staff:
      return {
        ...state, workingtimeByStaff: null, loading_get_workingtime_by_Staff: true, error: null
      }
    case GET_ONE_WARKINGTIME_By_Staff_SUCCESS:
      return {
        ...state,
        workingtimeByStaff: action.payload.workingtime,
        count_all_workingtime_by_staff: action.payload.counts || 0,
        loading_get_workingtime_by_Staff: false, error: null
      }
    case GET_ONE_WARKINGTIME_By_Staff_FAIL:
      return {
        ...state,
        loading_get_workingtime_by_Staff: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_WARKINGTIME:
      return {
        ...state, workingtime: null, loading_add_workingtime: true, error: null
      }
    case CREATE_WARKINGTIME_SUCCESS:
      return {
        ...state,
        workingtime: action.payload.workingtime,
        loading_add_workingtime: false, error: null
      }
    case CREATE_WARKINGTIME_FAIL:
      return {
        ...state,
        loading_add_workingtime: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_WARKINGTIME:
      return {
        ...state, loading_edit_workingtime: true, error: null
      }
    case PATCH_WARKINGTIME_SUCCESS:
      return {
        ...state,
        workingtime: action.payload.workingtime,
        loading_edit_workingtime: false, error: null
      }
    case PATCH_WARKINGTIME_FAIL:
      return {
        ...state,
        loading_edit_workingtime: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_WARKINGTIME:
      return {
        ...state, workingtime: null, loading_delete_workingtime: true, error: null
      }
    case DELETE_WARKINGTIME_SUCCESS:
      return {
        ...state,
        workingtime: action.payload.workingtime,
        loading_delete_workingtime: false, error: null
      }
    case DELETE_WARKINGTIME_FAIL:
      return {
        ...state,
        loading_delete_workingtime: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default WorkingtimeState
