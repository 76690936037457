import PropTypes from 'prop-types'
import React, { useState } from "react"

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  // ButtonDropdown,
} from "reactstrap"

const DropStatuses = props => {

  const { statuses, onStatusChanged } = props

  const [open_statuses, setOpenStatus] = useState(false)
  const [status, setStatus] = useState(props.default_status)


  return (
    <Dropdown data-testid="dropdown" className="drop-statuses white-toggle"
      isOpen={open_statuses}
      toggle={() => setOpenStatus(!open_statuses)}
    >
      <DropdownToggle className="btn btn-secondary" caret>
        <span data-testid="name" style={{ color: status.color }} >{status.name}</span>
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu data-testid="dropdown-menu">
        {statuses.map((item, i) => (
          <DropdownItem key={"status_" + i} style={{ color: item.color }} onClick={() => {
            setStatus(item);
            onStatusChanged({ name: item.value, value: item.value })
          }} >{item.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>)

}


DropStatuses.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    color: PropTypes.string,
  })).isRequired,


  default_status: PropTypes.object.isRequired,

  onStatusChanged: PropTypes.func.isRequired,

  // multi_select: PropTypes.bool
}

export default DropStatuses
