import {
  GET_CLIENTS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_SUCCESS,

  GET_ONE_CLIENT,
  GET_ONE_CLIENT_SUCCESS,
  GET_ONE_CLIENT_FAIL,

  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,

  PATCH_CLIENT,
  PATCH_CLIENT_SUCCESS,
  PATCH_CLIENT_FAIL,

  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  client: null,
  clients: [],
  error: null,

  loading_get_clients: false,
  loading_get_client: false,

  loading_add_client: false,
  loading_edit_client: false,

  count_all_clients: 0,
}

const ClientState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_CLIENTS:
      return {
        ...state, loading_get_clients: true, error: null,
        clients: []
      }
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.clients,
        loading_get_clients: false, error: null,
        count_all_clients: action.payload.counts || 0
      }
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        loading_get_clients: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_CLIENT:
      // console.log("GET_ONE_CLIENT");
      return {
        ...state, client: null, loading_get_client: true, error: null
      }
    case GET_ONE_CLIENT_SUCCESS:
      // console.log("GET_ONE_CLIENT_SUCCESS", action.payload.client);
      return {
        ...state,
        client: action.payload.client,
        loading_get_client: false, error: null
      }
    case GET_ONE_CLIENT_FAIL:
      // console.log("fail", action.payload);
      return {
        ...state,
        loading_get_client: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_CLIENT:
      return {
        ...state, client: null, loading_add_client: true, error: null
      }
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload.client,
        loading_add_client: false, error: null
      }
    case CREATE_CLIENT_FAIL:
      return {
        ...state,
        loading_add_client: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_CLIENT:
      return {
        ...state, loading_edit_client: true, error: null
      }
    case PATCH_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload.client,
        loading_edit_client: false, error: null
      }
    case PATCH_CLIENT_FAIL:
      return {
        ...state,
        loading_edit_client: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_CLIENT:
      return {
        ...state, client: null, loading_delete_client: true, error: null
      }
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload.client,
        loading_delete_client: false, error: null
      }
    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        loading_delete_client: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ClientState
