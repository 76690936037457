import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import CompanyState from "./company/reducer"
import ClientState from "./client/reducer"
import ProviderState from "./provider/reducer"
import StaffState from "./staff/reducer"
import ActivityState from "./settings/activity/reducer"
import ProjectState from "./project/reducer"
import ArticleState from "./article/reducer"
import StockState from "./stock/reducer"
import MaterialState from "./material/reducer"
import SupplyState from "./supply/reducer"
import MovementState from "./movement/reducer"
import MachineState from "./parking/reducer"
import DepenseState from "./depense/reducer"
import ReparationState from "./reparation/reducer"
import DocumentState from "./document/reducer"
import OrderState from "./order/reducer"
import ClearanceState from "./clearance/reducer"
import TimetableState from "./workingtime/reducer"
import QuoteState from "./quote/reducer"
import VacationState from "./vacation/reducer"
import ClockinginState from "./clockingin/reducer"
import TaskState from "./task/reducer"
import ReportState from "./report/reducer"

import InscriptionState from "./inscription/reducer"
import SubscriptionState from "./subscription/reducer"
import ModuleState from "./module/reducer"

// settings
import RoleState from "./settings/role/reducer"
import UnitState from "./settings/unit/reducer"
import LicenseState from "./settings/license/reducer"
import ArticleTypeState from "./settings/articleType/reducer"
import MaterialTypeState from "./settings/materialType/reducer"
import StockTypeState from "./settings/stockType/reducer"
import ProjectTypeState from "./settings/projectType/reducer"
import MachineTypeState from "./settings/MachineTypes/reducer"
import ReparationTypeState from "./settings/ReparationTypes/reducer"
import AuthorizationState from "./settings/authorization/reducer"
import CategorieState from "./settings/categories/reducer"
import PermissionState from "./permissions/reducer"
import PublicationState from "./settings/publication/reducer"
import MiddleSchoolState from "./settings/middleSchool/reducer"
import QuotePhaseState from "./settings/quotePhase/reducer"
import TravelBonusState from "./settings/bonusTravel/reducer"
import MealBonusState from "./settings/bonusMeal/reducer"
import ClotheState from "./settings/clothes/reducer"
import CountState from "./counts/reducer"

//E-commerce
// import ecommerce from "./e-commerce/reducer"

// //Calendar
// import calendar from "./calendar/reducer"

// //chat
// import chat from "./chat/reducer"

// //crypto
// import crypto from "./crypto/reducer"

// //invoices
// import invoices from "./invoices/reducer"

// //tasks
// import tasks from "./tasks/reducer"

// //contacts
// import contacts from "./contacts/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  CompanyState,
  ClientState,
  ProviderState,
  StaffState,
  ActivityState,
  RoleState,
  ClotheState,
  UnitState,
  LicenseState,
  ArticleTypeState,
  MaterialTypeState,
  QuotePhaseState,
  TravelBonusState,
  MealBonusState,
  StockTypeState,
  ProjectTypeState,
  ProjectState,
  ArticleState,
  StockState,
  MaterialState,
  SupplyState,
  MovementState,
  MachineTypeState,
  ReparationTypeState,
  MachineState,
  OrderState,
  ClearanceState,
  TimetableState,

  InscriptionState,
  SubscriptionState,
  ModuleState,

  PermissionState,
  QuoteState,
  VacationState,
  ClockinginState,

  TaskState,

  DepenseState,
  ReparationState,
  DocumentState,
  AuthorizationState,
  CategorieState,
  PublicationState,
  MiddleSchoolState,

  ReportState,
  CountState,
})

export default rootReducer
