/* TASKS */
export const GET_TASKS_PLANNING = "GET_TASKS_PLANNING"
export const GET_TASKS_PLANNING_SUCCESS = "GET_TASKS_PLANNING_SUCCESS"
export const GET_TASKS_PLANNING_FAIL = "GET_TASKS_PLANNING_FAIL"

export const GET_TASKS = "GET_TASKS"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_FAIL = "GET_TASKS_FAIL"

export const UPDATE_TASK_PLANNING = "UPDATE_TASK_PLANNING"
export const UPDATE_TASK_PLANNING_SUCCESS = "UPDATE_TASK_PLANNING_SUCCESS"
export const UPDATE_TASK_PLANNING_FAIL = "UPDATE_TASK_PLANNING_FAIL"

export const UPDATE_TASK_PLANNING_ADDRESS = "UPDATE_TASK_PLANNING_ADDRESS"
export const UPDATE_TASK_PLANNING_ADDRESS_SUCCESS = "UPDATE_TASK_PLANNING_ADDRESS_SUCCESS"
export const UPDATE_TASK_PLANNING_ADDRESS_FAIL = "UPDATE_TASK_PLANNING_ADDRESS_FAIL"

export const CUT_TASK = "CUT_TASK"
export const CUT_TASK_FAIL = "CUT_TASK_FAIL"
export const CUT_TASK_SUCCESS = "CUT_TASK_SUCCESS"

export const GET_ONE_TASK = "GET_ONE_TASK"
export const GET_ONE_TASK_FAIL = "GET_ONE_TASK_FAIL"
export const GET_ONE_TASK_SUCCESS = "GET_ONE_TASK_SUCCESS"
