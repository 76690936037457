// import PropTypes from 'prop-types'
import React, {
  useEffect,
} from "react"
// import { useDispatch } from "react-redux"

import {
  Container
} from "reactstrap"

import MetaTags from 'react-meta-tags';

import ListSubscriptionsGridCmp from "components/Modules/Subscription/ListSubscriptionsGridCmp";

const PricesPage = props => {
  // const dispatch = useDispatch();
  
  // const [message, setMessage] = useState("")

  useEffect(() => {

  }, [])

  // const checkData = () => {
  //   if (!company || company === "") return "Entreprise est obligatoire.";
  // }

  // const submit = () => {
  //   setMsgSuccess("");
  //   const check = checkData(); setMsgCheck(check)

  //   if (check === "") {
  //     setLoadingSubmit(true)
  //     dispatch(sendContactMsg({ data: { company, email, message } }, (resp) => {
  //       // console.log("=============++++> ", resp);
  //       setLoadingSubmit(false)
  //       setMsgSuccess("Votre message a été envoyé avec succès. Nous répondrons dès que possible")
  //       // history.push('/staffs/clockingin');
  //     }, (error) => {
  //       setLoadingSubmit(false)
  //       window.scrollTo(0, 0)
  //       setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
  //     }))
  //   }

  //   else window.scrollTo(0, 0)
  // }
  return (
    <React.Fragment>
      <MetaTags><title>AppliBTP</title></MetaTags>
      <div style={{ backgroundColor: "#f8f8fb" }}>
        <div className="question-section">
          <div className="container">
            <div className="question" style={{ width: 310, margin: "auto" }}>
              <h1 className="title">
                <span>Nos abonnements</span>
                <span className="sm-underline" style={{ width: 90, marginTop: 5, backgroundColor: "#07C8CC" }} />
              </h1>
            </div>
          </div>
        </div>

        <h1 className="mt-5 mb-5" style={{ textAlign: "center" }}>Choisissez <span style={{ color: "#07C8CC" }}>un plan</span> qui vous convient</h1>

        <Container>
          <ListSubscriptionsGridCmp />
        </Container>
      </div>
    </React.Fragment>
  )
}

PricesPage.propTypes = {
}
export default PricesPage