import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { NavLink, useHistory } from "react-router-dom";

import {
  Card, CardBody, Row, Col, //Button
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

// import SweetAlert from "react-bootstrap-sweetalert"
import {
  getActiveSubscription
} from 'store/actions'

import { isFunction, getCountPages } from 'helpers/utils';
import Pagination from 'components/Common/Pagination/Pagination';


const ListSubscriptionsGridCmp = props => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(({ Login }) => Login);


  const [selected_subscriptions, setSelectedSubscriptions] = useState([])

  const [current_page, setCurrentPage] = useState(1)

  const [count_all_subscriptions, setCountAll] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])

  const { default_selected_subscriptions
    //selection_multi
    //show_selection
  } = props


  useEffect(() => {
    getPage()
    setSubscriptions([])
    setCountAll(0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPage = (page = 1) => {
    // const offset = (page === 1 || page === 0) ? 0 : 10 * (page - 1);
    dispatch(getActiveSubscription({}, (resp) => {
      const _id = resp?.subscription?._id
      if (_id){
        if(user?.userType === "SUPER_ADMIN") history?.push("/subscriptions/edit/" + _id)
        else if(user?.userType === "ADMIN") history?.push("/subscriptions/preview/" + _id)
      } 
      // setSubscriptions(resp.subscriptions || [])
      // setCountAll(resp.counts || 0)
    }, (err) => { }))
  }

  useEffect(() => {
    if (isFunction(props.changeSelection)) props.changeSelection(selected_subscriptions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_subscriptions])

  useEffect(() => {
    setSelectedSubscriptions(default_selected_subscriptions ? [...default_selected_subscriptions] : [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_selected_subscriptions])



  // console.log("subscriptions", subscriptions);

  const renderSubsIcon = (index = 0) => {
    switch (index) {
      case 0: return (<i className="bx bx-walk h1 text-primary"></i>)
      case 1: return (<i className="bx bx-cycling h1 text-primary"></i>)
      case 2: return (<i className="bx bx-car h1 text-primary"></i>)

      default: return (<i className="bx bx-walk h1 text-primary"></i>)
    }
  }
  return (
    <React.Fragment>
      <Row className="mt-3">
        {subscriptions?.map((item, i) => <Col key={"subscription_" + i} md="4" className="mb-3">
          <Card className="plan-box" style={{ minHeight: 400 }}>
            <CardBody className="p-4" >
              <div className="d-flex">
                <div className="flex-grow-1">
                  {user?.userType === "SUPER_ADMIN" ?
                    <NavLink href={"/subscriptions/edit/" + item._id} to={"/subscriptions/edit/" + item._id}>
                      <i className="mdi mdi-pencil" style={{
                        position: "absolute", top: 3, right: 3, fontSize: 18, color: "#34c38f", cursor: "pointer", border: "1px solid", borderRadius: "50%", padding: 5
                      }} />
                    </NavLink>
                     : null
                  }
                  <h5>{item.name}</h5>
                  <p className="text-muted">{item.shortDesc}</p>
                </div>
                <div className="ms-3">
                  {renderSubsIcon(item.index)}
                </div>
              </div>
              <div className="py-4">
                <h2>
                  <sup><small>€</small></sup> <span className="_hilighted">{Number(item.priceByUser).toFixed(2)}</span>/ <span className="font-size-13">Par module/utilisateur</span>
                </h2>
              </div>
              <div className="text-center">
                {user?.userType === "ADMIN" ? <NavLink href={"/subscriptions/preview/" + item._id} to={"/subscriptions/preview/" + item._id}>
                  <button className="btn btn-success btn-md btn btn-success">S'ABONNER</button>
                </NavLink> : null}
              </div>
              <div className="plan-features mt-5">
                {!item.isFull ? <p><i className="bx bx-checkbox-square text-primary me-2"></i> Modules de base</p> : null}

                {!item.isFull && item.countAdditionModules ? <p>
                  <i className="bx bx-checkbox-square text-primary me-2"></i> {item.countAdditionModules} Modules
                  {item.countDefaultModules ? ` ( ${item.countDefaultModules} Modules optionnels)` : null}
                </p> : null}

                {item.isFull ? <p><i className="bx bx-checkbox-square text-primary me-2"></i> Tous les Modules</p> : null}


                {item.isFull ? null : <p><i className="bx bx-checkbox-square text-primary me-2"></i> {item.limitUsers} utilisateurs maximum </p>}

                <p><i className="bx bx-checkbox-square text-primary me-2"></i> {item.storageSize} Go de stockage par défaut</p>
              </div>
            </CardBody>
          </Card>
        </Col>)}
      </Row>

      <Pagination currentPage={current_page}
        totalPage={getCountPages(count_all_subscriptions, 10)}
        onChangePage={i => {
          setCurrentPage(i)
          getPage(i)
        }}
      />
    </React.Fragment>
  )
}
ListSubscriptionsGridCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_subscriptions: PropTypes.array
}

export default ListSubscriptionsGridCmp