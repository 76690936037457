import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListQuotesPage = React.lazy(() => import('./list-quotes.page'));
const EditQuotePage = React.lazy(() => import('./edit-quote.page'));
const AddQuotePage = React.lazy(() => import('./add-quote.page'));


const Quotes = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListQuotesPage {...props} />}
      />
      <Route path={`${match.url}/add`}
        render={props => <AddQuotePage {...props} isTemplate={false} />}
      />


      <Route path={`${match.url}/add-template`}
        render={props => <AddQuotePage {...props} isTemplate={true} />}
      />


      <Route path={`${match.url}/edit/:quoteId`}
        render={props => <EditQuotePage {...props} />}
      />

      <Route path={`${match.url}/preview/:quoteId`}
        render={props => <EditQuotePage {...props} />}
      />

      <Route path={`${match.url}/add-from-project/:selectedProject`}
        render={props => <AddQuotePage {...props} isTemplate={false} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Quotes;