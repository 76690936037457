import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,

  GET_ONE_CATEGORIE,
  GET_ONE_CATEGORIE_SUCCESS,
  GET_ONE_CATEGORIE_FAIL,

  CREATE_CATEGORIE,
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,

  PATCH_CATEGORIE,
  PATCH_CATEGORIE_SUCCESS,
  PATCH_CATEGORIE_FAIL,

  DELETE_CATEGORIE,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  categorie: null,
  categories: [],
  error: null,

  loading_get_categories: false,
  loading_get_categorie: false,

  loading_add_categorie: false,
  loading_edit_categorie: false,

  count_all_categories: 0,
}

const CategorieState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_CATEGORIES:
      return {
        ...state, loading_get_categories: true, error: null
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        loading_get_categories: false, error: null,
        count_all_categories: action.payload.counts || 0
      }
    case GET_CATEGORIES_FAIL:
      return {
        ...state, categories: [],
        loading_get_categories: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_CATEGORIE:
      return {
        ...state, categorie: null, loading_get_categorie: true, error: null
      }
    case GET_ONE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: action.payload.categorie,
        loading_get_categorie: false, error: null
      }
    case GET_ONE_CATEGORIE_FAIL:
      return {
        ...state,
        loading_get_categorie: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_CATEGORIE:
      return {
        ...state, categorie: null, loading_add_categorie: true, error: null
      }
    case CREATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: action.payload.categorie,
        loading_add_categorie: false, error: null
      }
    case CREATE_CATEGORIE_FAIL:
      return {
        ...state,
        loading_add_categorie: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_CATEGORIE:
      return {
        ...state, loading_edit_categorie: true, error: null
      }
    case PATCH_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: action.payload.categorie,
        loading_edit_categorie: false, error: null
      }
    case PATCH_CATEGORIE_FAIL:
      return {
        ...state,
        loading_edit_categorie: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_CATEGORIE:
      return {
        ...state, categorie: null, loading_delete_categorie: true, error: null
      }
    case DELETE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: action.payload.categorie,
        loading_delete_categorie: false, error: null
      }
    case DELETE_CATEGORIE_FAIL:
      return {
        ...state,
        loading_delete_categorie: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default CategorieState
