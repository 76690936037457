import StaffSrv from "services/StaffSrv";

import {
  GET_STAFFS,
  GET_STAFFS_FAIL,
  GET_STAFFS_SUCCESS,

  GET_ONE_STAFF,
  GET_ONE_STAFF_SUCCESS,
  GET_ONE_STAFF_FAIL,

  CREATE_STAFF,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,

  PATCH_STAFF,
  PATCH_STAFF_SUCCESS,
  PATCH_STAFF_FAIL,

  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,

  GET_STAFFS_AVAILABLE,
  GET_STAFFS_AVAILABLE_FAIL,
  GET_STAFFS_AVAILABLE_SUCCESS,


  GET_STAFF_VACATIONS,
  GET_STAFF_VACATIONS_FAIL,
  GET_STAFF_VACATIONS_SUCCESS,

  GET_PUBLIC_VACATIONS,
  GET_PUBLIC_VACATIONS_SUCCESS,
  GET_PUBLIC_VACATIONS_FAIL,

  PRINT_STAFF_EMPOWERMENT,
  PRINT_STAFF_EMPOWERMENT_SUCCESS,
  PRINT_STAFF_EMPOWERMENT_FAIL,

  PRINT_ANNUAL_CALENDAR,
  PRINT_ANNUAL_CALENDAR_SUCCESS,
  PRINT_ANNUAL_CALENDAR_FAIL,

  EDIT_STAFF_EMPOWERMENT,
  EDIT_STAFF_EMPOWERMENT_SUCCESS,
  EDIT_STAFF_EMPOWERMENT_FAIL,

  DELETE_STAFF_EMPOWERMENT,
  DELETE_STAFF_EMPOWERMENT_SUCCESS,
  DELETE_STAFF_EMPOWERMENT_FAIL,

  IMPORT_STAFFS,
  IMPORT_STAFFS_SUCCESS,
  IMPORT_STAFFS_FAIL,

  SEND_STAFF_NOTIF,
  SEND_STAFF_NOTIF_SUCCESS,
  SEND_STAFF_NOTIF_FAIL,

  SEND_EMAIL_NOTIF,
  SEND_EMAIL_NOTIF_SUCCESS,
  SEND_EMAIL_NOTIF_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListStaffs = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_STAFFS,
    });

    StaffSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staffs: response.data.data.staff,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_STAFFS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_STAFFS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createStaff = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_STAFF,
    });

    StaffSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staff: response.data.data.staff	,
          }

          dispatch({
            type: CREATE_STAFF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_STAFF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneStaff = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_STAFF,
    });

    StaffSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staff: response.data.data.staff
          }

          dispatch({
            type: GET_ONE_STAFF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_STAFF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== IMPORT
export const importStaffs = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_STAFFS,
    });

    StaffSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clients: response.data.data.staff || [],
          }

          dispatch({
            type: IMPORT_STAFFS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_STAFFS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editStaff = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_STAFF,
    });

    StaffSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staff: response.data.data.staff	,
          }

          dispatch({
            type: PATCH_STAFF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_STAFF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PRINT (HABILITATIONs)
export const printStaffEmpowerment = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_STAFF_EMPOWERMENT,
    });

    StaffSrv.printEmpowerment(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: PRINT_STAFF_EMPOWERMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_STAFF_EMPOWERMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PRINT ANNUAL CALENDAR
export const printAnnualCalendar = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_ANNUAL_CALENDAR,
    });

    StaffSrv.printAnnualCalendar(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: PRINT_ANNUAL_CALENDAR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_ANNUAL_CALENDAR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== EDIT EMPOWERMENT (HABILITATIONs)
export const editStaffEmpowerment = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDIT_STAFF_EMPOWERMENT,
    });

    StaffSrv.editEmpowerment(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data	,
          }

          dispatch({
            type: EDIT_STAFF_EMPOWERMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EDIT_STAFF_EMPOWERMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


export const deleteStaffEmpowerment = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_STAFF_EMPOWERMENT,
    });

    StaffSrv.deleteEmpowerment(payload)
      .then((response) => {
        
        if (response && response?.data?.success) {
          const resp = {
            data: response?.data?.data
          }

          dispatch({
            type: DELETE_STAFF_EMPOWERMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_STAFF_EMPOWERMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== DELETE
export const deleteStaff = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_STAFF,
    });

    StaffSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_STAFF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_STAFF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== SEND Notifs
export const sendNotif = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SEND_STAFF_NOTIF,
    });

    StaffSrv.sendNotif(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            // staff: response.data.data.staff	,
          }

          dispatch({
            type: SEND_STAFF_NOTIF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SEND_STAFF_NOTIF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== SEND EMAIL Notifs
export const sendEmailNotif = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SEND_EMAIL_NOTIF,
    });

    StaffSrv.sendEmailNotif(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            // staff: response.data.data.staff	,
          }

          dispatch({
            type: SEND_EMAIL_NOTIF_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SEND_EMAIL_NOTIF_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== GET LIST STAFF VACATIONS
export const getListStaffVacations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_STAFF_VACATIONS,
    });

    StaffSrv.getListStaffVacations(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacations: response.data.data.vacations || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_STAFF_VACATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_STAFF_VACATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET LIST PUBLIC VACATIONS
export const getListPublicVacations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PUBLIC_VACATIONS,
    });

    StaffSrv.getListPublicVacations(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            public_holidays: response.data.data.publicHolidays || []
          }

          dispatch({
            type: GET_PUBLIC_VACATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PUBLIC_VACATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};




// =============== GET LIST AVAILABLE
export const getListStaffsAvailable = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_STAFFS_AVAILABLE,
    });

    StaffSrv.getListStaffsAvailable(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staffs: response.data.data.staff,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_STAFFS_AVAILABLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_STAFFS_AVAILABLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};