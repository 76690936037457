/* CLOCIKNGINS */
export const GET_CLOCIKNGINS = "GET_CLOCIKNGINS"
export const GET_CLOCIKNGINS_SUCCESS = "GET_CLOCIKNGINS_SUCCESS"
export const GET_CLOCIKNGINS_FAIL = "GET_CLOCIKNGINS_FAIL"

export const GET_CLOCIKNGINS_GROUPED = "GET_CLOCKINGIN_TASKS"
export const GET_CLOCIKNGINS_GROUPED_SUCCESS = "GET_CLOCIKNGINS_GROUPED_SUCCESS"
export const GET_CLOCIKNGINS_GROUPED_FAIL = "GET_CLOCIKNGINS_GROUPED_FAIL"

export const GET_ONE_CLOCIKNGIN = "GET_ONE_CLOCIKNGIN"
export const GET_ONE_CLOCIKNGIN_SUCCESS = "GET_ONE_CLOCIKNGIN_SUCCESS"
export const GET_ONE_CLOCIKNGIN_FAIL = "GET_ONE_CLOCIKNGIN_FAIL"

export const CREATE_CLOCIKNGIN = "CREATE_CLOCIKNGIN"
export const CREATE_CLOCIKNGIN_SUCCESS = "CREATE_CLOCIKNGIN_SUCCESS"
export const CREATE_CLOCIKNGIN_FAIL = "CREATE_CLOCIKNGIN_FAIL"

export const PATCH_CLOCIKNGIN = "PATCH_CLOCIKNGIN"
export const PATCH_CLOCIKNGIN_SUCCESS = "PATCH_CLOCIKNGIN_SUCCESS"
export const PATCH_CLOCIKNGIN_FAIL = "PATCH_CLOCIKNGIN_FAIL"

export const DELETE_CLOCIKNGIN = "DELETE_CLOCIKNGIN"
export const DELETE_CLOCIKNGIN_SUCCESS = "DELETE_CLOCIKNGIN_SUCCESS"
export const DELETE_CLOCIKNGIN_FAIL = "DELETE_CLOCIKNGIN_FAIL"

export const GET_CLOCKINGIN_TASKS = "GET_CLOCKINGIN_TASKS"
export const GET_CLOCKINGIN_TASKS_SUCCESS = "GET_CLOCKINGIN_TASKS_SUCCESS"
export const GET_CLOCKINGIN_TASKS_FAIL = "GET_CLOCKINGIN_TASKS_FAIL"