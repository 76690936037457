import MiddleSchoolSrv from "services/MiddleSchoolSrv";

import {
  GET_MIDDLE_SCHOOLS,
  GET_MIDDLE_SCHOOLS_FAIL,
  GET_MIDDLE_SCHOOLS_SUCCESS,

  GET_ONE_MIDDLE_SCHOOL,
  GET_ONE_MIDDLE_SCHOOL_SUCCESS,
  GET_ONE_MIDDLE_SCHOOL_FAIL,

  CREATE_MIDDLE_SCHOOL,
  CREATE_MIDDLE_SCHOOL_SUCCESS,
  CREATE_MIDDLE_SCHOOL_FAIL,

  PATCH_MIDDLE_SCHOOL,
  PATCH_MIDDLE_SCHOOL_SUCCESS,
  PATCH_MIDDLE_SCHOOL_FAIL,

  DELETE_MIDDLE_SCHOOL,
  DELETE_MIDDLE_SCHOOL_SUCCESS,
  DELETE_MIDDLE_SCHOOL_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListMiddleSchools = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MIDDLE_SCHOOLS,
    });

    MiddleSchoolSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            middle_schools: response.data.data.colleges,
            counts: response.data.data.counts
          }
          
          dispatch({
            type: GET_MIDDLE_SCHOOLS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MIDDLE_SCHOOLS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createMiddleSchool = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_MIDDLE_SCHOOL,
    });

    MiddleSchoolSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            middle_school: response.data.data.colleges,
          }

          dispatch({
            type: CREATE_MIDDLE_SCHOOL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_MIDDLE_SCHOOL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneMiddleSchool = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_MIDDLE_SCHOOL,
    });

    MiddleSchoolSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            middle_school: response.data.data.colleges
          }

          dispatch({
            type: GET_ONE_MIDDLE_SCHOOL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_MIDDLE_SCHOOL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editMiddleSchool = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_MIDDLE_SCHOOL,
    });

    MiddleSchoolSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            middle_school: response.data.data.college,
          }

          dispatch({
            type: PATCH_MIDDLE_SCHOOL_SUCCESS,
            payload: resp,
          });
          
          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_MIDDLE_SCHOOL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteMiddleSchool = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_MIDDLE_SCHOOL,
    });

    MiddleSchoolSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_MIDDLE_SCHOOL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_MIDDLE_SCHOOL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};