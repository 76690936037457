/* WARKINGTIMES */
export const GET_WARKINGTIMES = "GET_WARKINGTIMES"
export const GET_WARKINGTIMES_SUCCESS = "GET_WARKINGTIMES_SUCCESS"
export const GET_WARKINGTIMES_FAIL = "GET_WARKINGTIMES_FAIL"

export const GET_ONE_WARKINGTIME = "GET_ONE_WARKINGTIME"
export const GET_ONE_WARKINGTIME_SUCCESS = "GET_ONE_WARKINGTIME_SUCCESS"
export const GET_ONE_WARKINGTIME_FAIL = "GET_ONE_WARKINGTIME_FAIL"

export const GET_ONE_WARKINGTIME_By_Staff = "GET_ONE_WARKINGTIME_By_Staff"
export const GET_ONE_WARKINGTIME_By_Staff_SUCCESS = "GET_ONE_WARKINGTIME_By_Staff_SUCCESS"
export const GET_ONE_WARKINGTIME_By_Staff_FAIL = "GET_ONE_WARKINGTIME_By_Staff_FAIL"


export const CREATE_WARKINGTIME = "CREATE_WARKINGTIME"
export const CREATE_WARKINGTIME_SUCCESS = "CREATE_WARKINGTIME_SUCCESS"
export const CREATE_WARKINGTIME_FAIL = "CREATE_WARKINGTIME_FAIL"

export const PATCH_WARKINGTIME = "PATCH_WARKINGTIME"
export const PATCH_WARKINGTIME_SUCCESS = "PATCH_WARKINGTIME_SUCCESS"
export const PATCH_WARKINGTIME_FAIL = "PATCH_WARKINGTIME_FAIL"

export const DELETE_WARKINGTIME = "DELETE_WARKINGTIME"
export const DELETE_WARKINGTIME_SUCCESS = "DELETE_WARKINGTIME_SUCCESS"
export const DELETE_WARKINGTIME_FAIL = "DELETE_WARKINGTIME_FAIL"


