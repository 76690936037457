import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom";

import {
  Row, Col
} from "reactstrap"
import { getTasksPlanning } from 'store/actions'



const TasksByDuration = props => {
  // HOOKS
  const dispatch = useDispatch();

  const { start_date, end_date } = props

  const [steps, setSteps] = useState([])
  const [tasks, setTasks] = useState([])
  const user = useSelector(state => state.Login.user);
  const memoizedUser = useMemo(() => user, [user]);
  useEffect(() => {
    dispatch(getTasksPlanning({
      filters: {
        startDate: start_date,
        endDate: end_date,
        isFinished: true
      }
    }, (resp) => {
      setSteps(resp?.steps || [])
      setTasks(resp?.tasks || [])
    }), (err) => {
      // console.log(err);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date])

  const renderTask = (task) => {
    return (<React.Fragment key={task._id} >
      <Col md="12">
        <div style={{
          borderBottom: "1px solid #ddd", marginTop: 15, paddingBottom: 10
        }}>
          <h4 key={task._id} className="primed mb-0">{task.title}</h4>

          <i className="mdi mdi-clock-outline grey" style={{ fontSize: 11 }} />
          <span className="grey" style={{ fontSize: 11 }}> {task.startTime} - {task.endTime} </span>
        </div>
      </Col>
    </React.Fragment >)
  }

  return (<React.Fragment>
    <Row className="mt-3">
      <Col md="9"><h5>Interventions du jour</h5></Col>
      <Col md="3" style={{ textAlign: "right", padding: 0 }}>
        {memoizedUser?.userType !== 'CLIENT' && <p><NavLink to="/plannings/tasks" className={"hilighted"}>
          Voir tout <i className="mdi mdi-chevron-right" />
        </NavLink></p>}
      </Col>
    </Row>
    <Row>
      {steps.map(step => {
        if (!Array.isArray(step?.tasks)) return null;

        step.tasks.map(task => {
          if (!task) return null;

          return renderTask(task)
        })

        return null;
      })}

      {tasks.map(task => {
        if (!task) return null;

        return renderTask(task)
      })}
    </Row>
  </React.Fragment >)
}
TasksByDuration.propTypes = {
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
}

export default TasksByDuration