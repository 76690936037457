// import PropTypes from 'prop-types'
import moment from "moment";
import React, {
  // useEffect 
} from "react"

import { NavLink } from "react-router-dom";

import {
  Row, Col
} from "reactstrap";

import logo_white from "assets/images/logo-light.png"

const Footer = props => {
  // useEffect(() => {
  //   window.location.href = "/login"
  // }, [])

  return (
    <React.Fragment>
      <div className="footer-wraper">
        <div className="container">
          <Row  >
            <Col md="6">
              <img className="logo-white" src={logo_white} alt="#" style={{ marginRight: 5 }} />
              <NavLink to="/mentions-legales" style={{ color: "#fff", fontWeight: 500, fontSize: 12, marginTop: 5 }}>Mentions légales</NavLink>
            </Col>
            <Col md="6">
              <div className="copyright">
                <span>Copyright Ⓒ {moment().format("YYYY")} AppliBTP All rights reserved</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

Footer.propTypes = {
}
export default Footer