/* DOCUMENTS */
export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL"

export const GET_ONE_DOCUMENT = "GET_ONE_DOCUMENT"
export const GET_ONE_DOCUMENT_SUCCESS = "GET_ONE_DOCUMENT_SUCCESS"
export const GET_ONE_DOCUMENT_FAIL = "GET_ONE_DOCUMENT_FAIL"

export const CREATE_DOCUMENT = "CREATE_DOCUMENT"
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS"
export const CREATE_DOCUMENT_FAIL = "CREATE_DOCUMENT_FAIL"

export const PATCH_DOCUMENT = "PATCH_DOCUMENT"
export const PATCH_DOCUMENT_SUCCESS = "PATCH_DOCUMENT_SUCCESS"
export const PATCH_DOCUMENT_FAIL = "PATCH_DOCUMENT_FAIL"

export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL"

