import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { getPermissions } from "store/actions"

const Sidebar = props => {
  const dispatch = useDispatch();

  const [menu_data, setMenuData] = useState([{
    path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: "Tableau de bord",
    models: ['ALL'],
    active: false
  }])

  const all_menu_data = [{
    path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: "Tableau de bord",
    models: ['ALL'],
    active: false

  }, {
    path: "/staffs",
    icon: "mdi mdi-account-group", name: "Ressources Humaines", badge: "sum_staff_notifs",
    // models: ['Staff'],
    has_arrow: true,
    active: false,
    subs: [{
      name: "Personnel",
      icon: "mdi mdi-account",
      path: "/staffs/list",
      models: ['Staff']
    },{
      name: "Pointages", badge: "count_reports",
      icon: "mdi mdi-checkbox-multiple-marked-outline",
      path: "/staffs/clockingin",
      models: ['Staff']
    },  {
      name: "Congés", badge: "count_vacations",
      icon: "mdi mdi-card-account-details-outline",
      path: "/staffs/vacations",
      models: ['Staff']
    }, 
      // ,{
      //   name: "Primes",
      //   icon: "mdi mdi-currency-eur",
      //   path: "/staffs/bonus",
      //   models: ['Staff']
      // }
    ]
  }, {
    path: "/clients", icon: "mdi mdi-account-group-outline", name: "Clients",
    models: ['Client'],
    active: false

  }, {
    path: "/providers", icon: "mdi mdi-share-variant", name: "Fournisseurs",
    models: ['Provider'],
    active: false

  }, {
    path: "/orders", icon: "mdi mdi-cart", name: "Commandes fournisseurs", badge: "count_orders",
    models: ['Order'],
    active: false

  }, {
    path: "/supplies", icon: "mdi mdi-family-tree", name: "Articles",
    models: ['Supply'],
    active: false

  }, {
    path: "/documents/list", icon: "mdi mdi-file-document-multiple", name: "Bibliothèque",
    models: ['Document'],
    active: false

  }, {
    path: "/articles/list", icon: "mdi mdi-package-variant-closed", name: "Ouvrages",
    models: ['Article'],
    active: false

  }, {
    path: "/materials/list", icon: "mdi mdi-tools", name: "Équipements",
    models: ['Material'],
    active: false

  }, {
    path: "/quotes/list", icon: "mdi mdi-file-document", name: "Devis",
    models: ['Quote'],
    active: false

  }, {
    path: "/stocks/list", icon: "mdi mdi-package-variant", name: "Entrepôts",
    models: ['Stock'],
    active: false

  }, {
    path: "/projects/list", icon: "mdi mdi-handshake", name: "Chantiers",
    models: ['Project'],
    active: false

  }, {
    path: "/plannings", icon: "mdi mdi-calendar", name: "Planning",
    has_arrow: true,
    active: false,
    subs: [{
      name: "Interventions",
      icon: "mdi mdi-chart-gantt",
      path: "/plannings/tasks",
      models: ['Task']
    }, {
      name: "Carte",
      icon: "mdi mdi-map-outline",
      path: "/plannings/map",
      models: ['Task']
    }, {
      name: "Véhicules",
      icon: "mdi mdi-truck",
      path: "/plannings/machines",
      models: ['Machine']
    }, {
      name: "Équipements",
      icon: "mdi mdi-tools",
      path: "/plannings/materials",
      models: ['Material']
    }]
  }, {
    path: "/parkings/list", icon: "mdi mdi-parking", name: "Parc Véhicule",
    models: ['Machine'],
    active: false

  },
  // {
  //   path: "/inscriptions/list", icon: "mdi mdi-credit-card-check-outline", name: "Inscriptions",
  //   models: ['Inscription']
  // }, 
  {
    path: "/settings", icon: "mdi mdi-cog", name: "Paramètres",
    // models: ['Permission', 'Activity', 'Role', 'DrivingLicense', 'Unit',
    //   'ProjectType',
    //   'ArticleType',
    //   'StockType',
    //   'QuotePhase',
    //   'RepairType',
    //   'College'
    // ],
    has_arrow: true,
    active: false,
    subs: [
      //   {
      //   name: "Permissions",
      //   icon: "mdi mdi-account-lock",
      //   path: "/settings/permissions",
      //   models: ['Permission']
      // }, 
      {
        name: "Activités",
        icon: "mdi mdi-hard-hat",
        path: "/settings/activities",
        models: ['Activity']
      }, {
        name: "Métiers",
        icon: "mdi mdi-account-hard-hat",
        path: "/settings/roles",
        models: ['Role']
      },
      {
        name: "Collèges de rattachement",
        style: { fontSize: 10 },
        icon: "mdi mdi-school",
        path: "/settings/college",
        models: ['College']
      },
      {
        name: "Vêtements EPI",
        icon: "mdi mdi-account-cowboy-hat",
        path: "/settings/clothes",
        models: ['PPEClothingType']
      },
      //  {
      //   name: "Autorisations",
      //   icon: "mdi mdi-lock-outline",
      //   path: "/settings/authorizations",
      //   models: ['Authorization']
      // },
      {
        name: "Permis",
        icon: "mdi mdi-car",
        path: "/settings/licenses",
        models: ['DrivingLicense']
      },
      {
        name: "Indemnités",
        icon: "mdi mdi-currency-eur",
        path: "/settings/bonus",
        models: ['TravelBonus', 'MealBonus']
      },
       {
        name: "Unités",
        icon: "mdi mdi-weight-kilogram",
        path: "/settings/units",
        models: ['Unit']
      }, {
        name: "Types Chantiers",
        icon: "mdi mdi-handshake",
        path: "/settings/project-types",
        models: ['ProjectType']
      }, {
        name: "Types Ouvrages",
        icon: "mdi mdi-package-variant-closed",
        path: "/settings/article-types",
        models: ['ArticleType']
      }, 
      {
        name: "Types Réparations",
        icon: "mdi mdi-cog",
        path: "/settings/reparation-types",
        models: ['RepairType']
      }, 
      {
        name: "Types Équipements",
        icon: "mdi mdi-tools",
        path: "/settings/material-types",
        models: ['MaterialType']
      }, {
        name: "Types Véhicules",
        icon: "mdi mdi-train-car",
        path: "/settings/machine-types",
        models: ['PROVIDER']
      }, {
        name: "Phases Devis",
        icon: "mdi mdi-file-document",
        path: "/settings/quote-phases",
        models: ['QuotePhase']
      }, 
      {
        name: "Types Entrepôts",
        icon: "mdi mdi-package-variant",
        path: "/settings/stock-types",
        models: ['StockType']
      }, {
        name: "Modèles Devis",
        icon: "mdi mdi-file-document-edit-outline",
        path: "/settings/quote-models",
        models: ['QuotePhase', 'Quote']
      },{
        name: "Traçabilités",
        icon: "mdi mdi-map-marker-path",
        path: "/settings/traceability",
        models: ['Traceability', 'CableType', 'AchievementCondition']
      }]
  }]


  /*
  <li>
    <Link to="/settings/categories">
      <i className="mdi mdi-cog"></i>
      <span>Sous catégories</span>
    </Link>
  </li> 
  <li>
    <Link to="/settings/ouvrage">
      <i className="mdi mdi-cog"></i>
      <span> Ouvrage</span>
    </Link>
  </li>
  */

  const { user, permissions } = props
  // console.log("====> permisions", permissions);

  useEffect(() => {
    if (user?.userType === "STAFF")
      dispatch(getPermissions({}, (resp) => { }, (error) => { }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    // console.log('RERENDER BY TYPE', user?.userType);
    switch (user?.userType) {
      case "ADMIN":
        setMenuData(all_menu_data)

        break;
      case "SUPER_ADMIN":
        setMenuData([{
          path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: "Tableau de bord"
        },
        {
          path: "/subscriptions", icon: "mdi mdi-credit-card-outline", name: "Configuration abonnement"
        },
        {
          path: "/subscribers", icon: "mdi mdi-card-account-details-outline", name: "Clients Abonnés"
        },
        {
          path: "/staffs/all", icon: "mdi mdi-account-multiple-outline", name: "Utilisateurs"
        },
        {
          path: "/user-subscriptions", icon: "mdi mdi-playlist-check", name: "Abonnements"
        }
          // , { path: "/modules", icon: "mdi mdi-package", name: "Modules" }
        ])
        break;


      case "STAFF":
        const new_menu_data = []
        // for (const permission of permissions) {
        //   if(permission.allowAll){

        //   }
        //   console.log(permission.model);
        // }
        for (const data of all_menu_data) {




          if (data.subs?.length) {
            const new_subs = []
            for (const sub of data.subs) {
              const sub_allowd = permissions.filter((permission) => {
                return (
                  sub.models?.includes("ALL") ||
                  (
                    permission && permission.allowAll
                    && permission.rules?.length >= 3 &&
                    sub.models?.includes(permission.model)
                  )
                )
              })

              if (sub_allowd?.length) new_subs.push(sub)
            }

            if (new_subs.length) new_menu_data.push({ ...data, subs: new_subs })
          }
          else {
            const allowed = permissions.filter((permission) => {
              return (
                data.models?.includes("ALL") ||
                (
                  permission && permission.allowAll
                  && permission.rules?.length &&
                  data.models?.includes(permission.model)
                )
              )
            })

            if (allowed?.length) new_menu_data.push(data)
          }
        }
        setMenuData(new_menu_data);
        break;



      case "PROVIDER":
        setMenuData(all_menu_data.filter((item) => {
          return (
            item && (
              item.models?.includes('ALL')
              // || item.path === "/providers"
              || item.path === "/orders"
            )
          )
        }))
        break;
      case "CLIENT":
        setMenuData(all_menu_data.filter((item) => {
          return (
            item && (
              item.models?.includes('ALL')
               || item.path === "/clients"
              // || item.path === "/orders"
              || item.path === "/projects/list"
              // || item.path === "/quotes/list"
            )
          )
        }))
        break;

      default: setMenuData([{
        path: "/dashboard", icon: "mdi mdi-chart-timeline-variant", name: "Tableau de bord"
      }])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, permissions])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}

          <SidebarContent menu_data={menu_data || []} />
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  const { user, permissions } = state.Login

  return {
    layout: state.Layout,
    user, permissions
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))