/* REPORTS */
export const GET_REPORTS = "GET_REPORTS"
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS"
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL"

export const GET_ONE_REPORT = "GET_ONE_REPORT"
export const GET_ONE_REPORT_SUCCESS = "GET_ONE_REPORT_SUCCESS"
export const GET_ONE_REPORT_FAIL = "GET_ONE_REPORT_FAIL"

export const CREATE_REPORT = "CREATE_REPORT"
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS"
export const CREATE_REPORT_FAIL = "CREATE_REPORT_FAIL"

export const PATCH_REPORT = "PATCH_REPORT"
export const PATCH_REPORT_SUCCESS = "PATCH_REPORT_SUCCESS"
export const PATCH_REPORT_FAIL = "PATCH_REPORT_FAIL"

export const DELETE_REPORT = "DELETE_REPORT"
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS"
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL"

export const PATCH_REPORT_CHECKING = "PATCH_REPORT_CHECKING"
export const PATCH_REPORT_CHECKING_SUCCESS = "PATCH_REPORT_CHECKING_SUCCESS"
export const PATCH_REPORT_CHECKING_FAIL = "PATCH_REPORT_CHECKING_FAIL"

export const CREATE_REPORT_CHECKING = "CREATE_REPORT_CHECKING"
export const CREATE_REPORT_CHECKING_SUCCESS = "CREATE_REPORT_CHECKING_SUCCESS"
export const CREATE_REPORT_CHECKING_FAIL = "CREATE_REPORT_CHECKING_FAIL"

export const DELETE_REPORT_CHECKING = "DELETE_REPORT_CHECKING_CHECKING"
export const DELETE_REPORT_CHECKING_SUCCESS = "DELETE_REPORT_CHECKING_SUCCESS"
export const DELETE_REPORT_CHECKING_FAIL = "DELETE_REPORT_CHECKING_FAIL"

export const DELETE_REPORT_PROJECT = "DELETE_REPORT_PROJECT_CHECKING"
export const DELETE_REPORT_PROJECT_SUCCESS = "DELETE_REPORT_PROJECT_SUCCESS"
export const DELETE_REPORT_PROJECT_FAIL = "DELETE_REPORT_PROJECT_FAIL"

export const CHECK_WORKING_STAFF_DATE = "CHECK_WORKING_STAFF_DATE"
export const CHECK_WORKING_STAFF_DATE_SUCCESS = "CHECK_WORKING_STAFF_DATE_SUCCESS"
export const CHECK_WORKING_STAFF_DATE_FAIL = "CHECK_WORKING_STAFF_DATE_FAIL"

export const GET_REPORTED_TASKS = "GET_REPORTED_TASKS_DATE"
export const GET_REPORTED_TASKS_SUCCESS = "GET_REPORTED_TASKS_SUCCESS"
export const GET_REPORTED_TASKS_FAIL = "GET_REPORTED_TASKS_FAIL"

export const CREATE_CHECKING_TASK = "CREATE_CHECKING_TASK"
export const CREATE_CHECKING_TASK_SUCCESS = "CREATE_CHECKING_TASK_SUCCESS"
export const CREATE_CHECKING_TASK_FAIL = "CREATE_CHECKING_TASK_FAIL"

export const PATCH_CHECKING_TASK = "PATCH_CHECKING_TASK"
export const PATCH_CHECKING_TASK_SUCCESS = "PATCH_CHECKING_TASK_SUCCESS"
export const PATCH_CHECKING_TASK_FAIL = "PATCH_CHECKING_TASK_FAIL"

export const DELETE_CHECKING_TASK = "DELETE_CHECKING_TASK"
export const DELETE_CHECKING_TASK_SUCCESS = "DELETE_CHECKING_TASK_SUCCESS"
export const DELETE_CHECKING_TASK_FAIL = "DELETE_CHECKING_TASK_FAIL"