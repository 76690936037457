export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"


export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS"
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS"
export const GET_USER_PERMISSIONS_FAIL = "GET_USER_PERMISSIONS_FAIL"

export const GET_CONNECTED_PROFILE = "GET_CONNECTED_PROFILE"
export const GET_CONNECTED_PROFILE_SUCCESS = "GET_CONNECTED_PROFILE_SUCCESS"
export const GET_CONNECTED_PROFILE_FAIL = "GET_CONNECTED_PROFILE_FAIL"

export const EDIT_CONNECTED_PROFILE = "EDIT_CONNECTED_PROFILE"
export const EDIT_CONNECTED_PROFILE_SUCCESS = "EDIT_CONNECTED_PROFILE_SUCCESS"
export const EDIT_CONNECTED_PROFILE_FAIL = "EDIT_CONNECTED_PROFILE_FAIL"

export const EDIT_PASSWORD = "EDIT_PASSWORD"
export const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS"
export const EDIT_PASSWORD_FAIL = "EDIT_PASSWORD_FAIL"

export const SEND_CONTACT_MSG = "SEND_CONTACT_MSG"
export const SEND_CONTACT_MSG_SUCCESS = "SEND_CONTACT_MSG_SUCCESS"
export const SEND_CONTACT_MSG_FAIL = "SEND_CONTACT_MSG_FAIL"

export const CHECK_SUBSCRIPTION = "CHECK_SUBSCRIPTION"
export const CHECK_SUBSCRIPTION_SUCCESS = "CHECK_SUBSCRIPTION_SUCCESS"
export const CHECK_SUBSCRIPTION_FAIL = "CHECK_SUBSCRIPTION_FAIL"