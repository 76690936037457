import {
  GET_UNITS,
  GET_UNITS_FAIL,
  GET_UNITS_SUCCESS,

  GET_ONE_UNIT,
  GET_ONE_UNIT_SUCCESS,
  GET_ONE_UNIT_FAIL,

  CREATE_UNIT,
  CREATE_UNIT_SUCCESS,
  CREATE_UNIT_FAIL,

  PATCH_UNIT,
  PATCH_UNIT_SUCCESS,
  PATCH_UNIT_FAIL,

  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  unit: null,
  units: [],
  error: null,

  loading_get_units: false,
  loading_get_unit: false,

  loading_add_unit: false,
  loading_edit_unit: false,

  count_all_units: 0,
}

const UnitState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_UNITS:
      return {
        ...state, loading_get_units: true, error: null
      }
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload.units,
        loading_get_units: false, error: null,
        count_all_units: action.payload.counts || 0
      }
    case GET_UNITS_FAIL:
      return {
        ...state,
        loading_get_units: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_UNIT:
      return {
        ...state, unit: null, loading_get_unit: true, error: null
      }
    case GET_ONE_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload.unit,
        loading_get_unit: false, error: null
      }
    case GET_ONE_UNIT_FAIL:
      return {
        ...state,
        loading_get_unit: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_UNIT:
      return {
        ...state, unit: null, loading_add_unit: true, error: null
      }
    case CREATE_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload.unit,
        loading_add_unit: false, error: null
      }
    case CREATE_UNIT_FAIL:
      return {
        ...state,
        loading_add_unit: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_UNIT:
      return {
        ...state, loading_edit_unit: true, error: null
      }
    case PATCH_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload.unit,
        loading_edit_unit: false, error: null
      }
    case PATCH_UNIT_FAIL:
      return {
        ...state,
        loading_edit_unit: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_UNIT:
      return {
        ...state, unit: null, loading_delete_unit: true, error: null
      }
    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload.unit,
        loading_delete_unit: false, error: null
      }
    case DELETE_UNIT_FAIL:
      return {
        ...state,
        loading_delete_unit: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default UnitState
