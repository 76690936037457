/* MATERIAL_TYPES */
export const GET_MATERIAL_TYPES = "GET_MATERIAL_TYPES"
export const GET_MATERIAL_TYPES_SUCCESS = "GET_MATERIAL_TYPES_SUCCESS"
export const GET_MATERIAL_TYPES_FAIL = "GET_MATERIAL_TYPES_FAIL"

export const GET_ONE_MATERIAL_TYPE = "GET_ONE_MATERIAL_TYPE"
export const GET_ONE_MATERIAL_TYPE_SUCCESS = "GET_ONE_MATERIAL_TYPE_SUCCESS"
export const GET_ONE_MATERIAL_TYPE_FAIL = "GET_ONE_MATERIAL_TYPE_FAIL"

export const CREATE_MATERIAL_TYPE = "CREATE_MATERIAL_TYPE"
export const CREATE_MATERIAL_TYPE_SUCCESS = "CREATE_MATERIAL_TYPE_SUCCESS"
export const CREATE_MATERIAL_TYPE_FAIL = "CREATE_MATERIAL_TYPE_FAIL"

export const PATCH_MATERIAL_TYPE = "PATCH_MATERIAL_TYPE"
export const PATCH_MATERIAL_TYPE_SUCCESS = "PATCH_MATERIAL_TYPE_SUCCESS"
export const PATCH_MATERIAL_TYPE_FAIL = "PATCH_MATERIAL_TYPE_FAIL"

export const DELETE_MATERIAL_TYPE = "DELETE_MATERIAL_TYPE"
export const DELETE_MATERIAL_TYPE_SUCCESS = "DELETE_MATERIAL_TYPE_SUCCESS"
export const DELETE_MATERIAL_TYPE_FAIL = "DELETE_MATERIAL_TYPE_FAIL"

export const GET_MATERIAL_MARKS = "GET_MATERIAL_MARKS"
export const GET_MATERIAL_MARKS_SUCCESS = "GET_MATERIAL_MARKS_SUCCESS"
export const GET_MATERIAL_MARKS_FAIL = "GET_MATERIAL_MARKS_FAIL"