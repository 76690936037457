import  CategorieSrv from "services/CategorieSrv";

import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,

  GET_ONE_CATEGORIE,
  GET_ONE_CATEGORIE_SUCCESS,
  GET_ONE_CATEGORIE_FAIL,

  CREATE_CATEGORIE,
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,

  PATCH_CATEGORIE,
  PATCH_CATEGORIE_SUCCESS,
  PATCH_CATEGORIE_FAIL,

  DELETE_CATEGORIE,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL,

} from "./actionTypes"

// =============== GET LIST
export const getListCategories = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_CATEGORIES,
    });

     CategorieSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
         
          const resp = {
            categories: response.data.data.categories,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_CATEGORIES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_CATEGORIES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createCategorie = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_CATEGORIE,
    });

     CategorieSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            categorie: response.data.data.category,
          }

          dispatch({
            type: CREATE_CATEGORIE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_CATEGORIE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneCategorie = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_CATEGORIE,
    });

     CategorieSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            categorie: response.data.data.categorie
          }

          dispatch({
            type: GET_ONE_CATEGORIE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_CATEGORIE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== PATCH
export const editCategorie = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_CATEGORIE,
    });

     CategorieSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            categorie: response.data.data.categorie,
          }

          dispatch({
            type: PATCH_CATEGORIE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_CATEGORIE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteCategorie = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_CATEGORIE,
    });

     CategorieSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_CATEGORIE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_CATEGORIE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
