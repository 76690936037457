/* TRAVEL_BONUSS */
export const GET_TRAVEL_BONUSS = "GET_TRAVEL_BONUSS"
export const GET_TRAVEL_BONUSS_SUCCESS = "GET_TRAVEL_BONUSS_SUCCESS"
export const GET_TRAVEL_BONUSS_FAIL = "GET_TRAVEL_BONUSS_FAIL"

export const GET_ONE_TRAVEL_BONUS = "GET_ONE_TRAVEL_BONUS"
export const GET_ONE_TRAVEL_BONUS_SUCCESS = "GET_ONE_TRAVEL_BONUS_SUCCESS"
export const GET_ONE_TRAVEL_BONUS_FAIL = "GET_ONE_TRAVEL_BONUS_FAIL"

export const CREATE_TRAVEL_BONUS = "CREATE_TRAVEL_BONUS"
export const CREATE_TRAVEL_BONUS_SUCCESS = "CREATE_TRAVEL_BONUS_SUCCESS"
export const CREATE_TRAVEL_BONUS_FAIL = "CREATE_TRAVEL_BONUS_FAIL"

export const PATCH_TRAVEL_BONUS = "PATCH_TRAVEL_BONUS"
export const PATCH_TRAVEL_BONUS_SUCCESS = "PATCH_TRAVEL_BONUS_SUCCESS"
export const PATCH_TRAVEL_BONUS_FAIL = "PATCH_TRAVEL_BONUS_FAIL"

export const DELETE_TRAVEL_BONUS = "DELETE_TRAVEL_BONUS"
export const DELETE_TRAVEL_BONUS_SUCCESS = "DELETE_TRAVEL_BONUS_SUCCESS"
export const DELETE_TRAVEL_BONUS_FAIL = "DELETE_TRAVEL_BONUS_FAIL"