// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const ReportSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    if (payload?.filters?.isValid === false) filters += "&isValid=false";

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.reports}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.reports}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.reports, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.reports}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.reports}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== CHECK WORKING
  checkWorkingStaff: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if ((payload.filters[key] || payload.filters[key] === false) && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.staffs}/is-working?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== REPORTED TASKS
  reportedTasks: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if ((payload.filters[key] || payload.filters[key] === false) && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.bonuses}/grouped?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },




  // =============== EDIT REPORT CHECKING TASK
  patchReportCheckingTask: (payload) => {
    return Promise.all([
      new Promise((resolve, reject) => {
        return axiosApi.patch(`${endpoints.checking_tasks}/${payload?.checking_id}`, payload.data)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      }),
      new Promise((resolve, reject) => {
        return axiosApi.patch(`${endpoints.tasks}/${payload?.task_id}`,
          { title: payload.data?.taskTitle }
        )
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
    ]).then(resp => {
      return resp
    })
      .catch(e => {
        return e
      })
  },


  // =============== CREATE REPORT CHECKING TASK
  addReportCheckingTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.reports}/${payload.report_id}/tasks`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE CHECKING TASK FROM REPORT
  deleteReportCheckingTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.reports}/${payload.report_id}/tasks/${payload.checking_task_id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== DELETE PROJECT FROM REPORT
  deleteReportProject: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.reports}/${payload.report_id}/projects/${payload.project_id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },










  // =============== CREATE CHECKING TASK
  addCheckingTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.checking_tasks}`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== EDIT CHECKING TASK
  patchCheckingTask: (payload) => {
    return Promise.all([
      new Promise((resolve, reject) => {
        return axiosApi.patch(`${endpoints.checking_tasks}/${payload?.checking_id}`, payload.data)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      }),
      new Promise((resolve, reject) => {
        return axiosApi.patch(`${endpoints.tasks}/${payload?.task_id}`,
          { title: payload.data?.taskTitle }
        )
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
    ]).then(resp => {
      return resp
    })
      .catch(e => {
        return e
      })
  },

  // =============== DELETE CHECKING TASK
  deleteCheckingTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.checking_tasks}/${payload._id}`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
}

export default ReportSrv