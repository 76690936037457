import QuoteSrv from "services/QuoteSrv";

import {
  GET_QUOTES,
  GET_QUOTES_FAIL,
  GET_QUOTES_SUCCESS,

  GET_ONE_QUOTE,
  GET_ONE_QUOTE_SUCCESS,
  GET_ONE_QUOTE_FAIL,

  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAIL,

  PATCH_QUOTE,
  PATCH_QUOTE_SUCCESS,
  PATCH_QUOTE_FAIL,

  DELETE_QUOTE,
  DELETE_QUOTE_SUCCESS,
  DELETE_QUOTE_FAIL,


  // STEPS
  GET_QUOTE_STEPS,
  GET_QUOTE_STEPS_FAIL,
  GET_QUOTE_STEPS_SUCCESS,

  CREATE_QUOTE_STEP,
  CREATE_QUOTE_STEP_SUCCESS,
  CREATE_QUOTE_STEP_FAIL,

  PATCH_QUOTE_STEP,
  PATCH_QUOTE_STEP_SUCCESS,
  PATCH_QUOTE_STEP_FAIL,

  DELETE_QUOTE_STEP,
  DELETE_QUOTE_STEP_SUCCESS,
  DELETE_QUOTE_STEP_FAIL,


  PRINT_QUOTE,
  PRINT_QUOTE_SUCCESS,
  PRINT_QUOTE_FAIL,


  // STEP TASKS
  GET_QUOTE_STEP_TASKS,
  GET_QUOTE_STEP_TASKS_FAIL,
  GET_QUOTE_STEP_TASKS_SUCCESS,

  CREATE_QUOTE_STEP_TASK,
  CREATE_QUOTE_STEP_TASK_SUCCESS,
  CREATE_QUOTE_STEP_TASK_FAIL,

  PATCH_QUOTE_STEP_TASK,
  PATCH_QUOTE_STEP_TASK_SUCCESS,
  PATCH_QUOTE_STEP_TASK_FAIL,

  DELETE_QUOTE_STEP_TASK,
  DELETE_QUOTE_STEP_TASK_SUCCESS,
  DELETE_QUOTE_STEP_TASK_FAIL,


  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,


  // PLANING
  GET_QUOTE_PLANING,
  GET_QUOTE_PLANING_SUCCESS,
  GET_QUOTE_PLANING_FAIL,

  VALIDATE_QUOTE,
  VALIDATE_QUOTE_SUCCESS,
  VALIDATE_QUOTE_FAIL,


  CLONE_QUOTE,
  CLONE_QUOTE_SUCCESS,
  CLONE_QUOTE_FAIL,
  
} from "./actionTypes"

// =============== GET LIST
export const getListQuotes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_QUOTES,
    });

    QuoteSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quotes: response.data.data.quotes,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_QUOTES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_QUOTES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_QUOTE,
    });

    QuoteSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote: response.data.data.quote,
          }

          dispatch({
            type: CREATE_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_QUOTE,
    });

    QuoteSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote: response.data.data.quote
          }

          dispatch({
            type: GET_ONE_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_QUOTE,
    });

    QuoteSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote: response.data.data.quote,
          }

          dispatch({
            type: PATCH_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== VALIDATION
export const validateQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: VALIDATE_QUOTE,
    });

    QuoteSrv.validateQuote(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote: response.data.data.quote,
          }

          dispatch({
            type: VALIDATE_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: VALIDATE_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== Clone
export const cloneQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CLONE_QUOTE,
    });

    QuoteSrv.cloneQuote(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote: response.data.data.quote,
          }

          dispatch({
            type: CLONE_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CLONE_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== DELETE
export const deleteQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_QUOTE,
    });

    QuoteSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== PRINT
export const printQuote = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_QUOTE,
    });

    QuoteSrv.printQuote(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: PRINT_QUOTE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_QUOTE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};







// =============== GET LIST QUOTE STEPS
export const getListQuoteSteps = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_QUOTE_STEPS,
    });

    QuoteSrv.getListSteps(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote_steps: response.data.data.quoteSteps || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_QUOTE_STEPS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_QUOTE_STEPS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE QUOTE STEP
export const createQuoteStep = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_QUOTE_STEP,
    });

    QuoteSrv.createStep(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            quote_step: response.data.data.quoteStep,
          }

          dispatch({
            type: CREATE_QUOTE_STEP_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_QUOTE_STEP_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== PATCH QUOTE STEP
export const editQuoteStep = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_QUOTE_STEP,
    });

    QuoteSrv.patchStep(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            step: response.data.data.quoteStep,
          }

          dispatch({
            type: PATCH_QUOTE_STEP_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_QUOTE_STEP_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE QUOTE STEP
export const deleteQuoteStep = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_QUOTE_STEP,
    });

    QuoteSrv.deleteStep(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_QUOTE_STEP_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_QUOTE_STEP_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};














// =============== GET LIST QUOTE STEP TASKS
export const getListQuoteStepTasks = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_QUOTE_STEP_TASKS,
    });

    QuoteSrv.getListStepTasks(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            tasks: response.data.data.tasks || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_QUOTE_STEP_TASKS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_QUOTE_STEP_TASKS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE QUOTE STEP TASK
export const createQuoteStepTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_QUOTE_STEP_TASK,
    });

    QuoteSrv.createStepTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            task: response.data.data.task,
          }

          dispatch({
            type: CREATE_QUOTE_STEP_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        console.log('error',e);
        dispatch({
          type: CREATE_QUOTE_STEP_TASK_FAIL,
          payload: { error: e }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// // =============== PATCH QUOTE STEP TASK
export const editQuoteStepTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_QUOTE_STEP_TASK,
    });

    QuoteSrv.patchStepTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            task: response.data.data.task,
          }

          dispatch({
            type: PATCH_QUOTE_STEP_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_QUOTE_STEP_TASK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE QUOTE STEP TASK
export const deleteQuoteStepTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_QUOTE_STEP_TASK,
    });

    QuoteSrv.deleteStepTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_QUOTE_STEP_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_QUOTE_STEP_TASK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};






// =============== GET LIST TASKS
export const getListTasks = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_TASKS,
    });

    QuoteSrv.getListTasks(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            tasks: response.data.data.tasks || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_TASKS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_TASKS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};









// GET QUOTE PLANNING
export const getQuotePlaning = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_QUOTE_PLANING,
    });

    QuoteSrv.getQuotePlaning(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            steps: response.data.data.plannigSteps || [],
            tasks: response.data.data.tasks || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_QUOTE_PLANING_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_QUOTE_PLANING_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};