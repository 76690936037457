import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListInscriptionsPage = React.lazy(() => import('./list-inscriptions.page'));
const PreviewInscriptionPage = React.lazy(() => import('./preview-inscription.page'));

const Inscriptions = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListInscriptionsPage {...props} />}
      />

      <Route path={`${match.url}/preview/:inscriptionId`}
        render={props => <PreviewInscriptionPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Inscriptions;