// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const QuoteSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    if (!payload?.filters?.isTemplate) filters += "&isTemplate=false"

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.quote, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.quote}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  validateQuote: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.quote}/validate/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  cloneQuote: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote}/clone/${payload?._id}?cloneWithPlanification=${payload?.data.cloneWithPlanification}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.quote}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  printQuote: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.quote}/print/${payload?.quote_id}`, payload.data)
        .then(resp => {
          resolve(resp);
        }).catch(e => reject(e))
    })
  },






  // =============== GET LIST QUOTE STEPS
  getListSteps: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote_steps}/quote/${payload?.quote_id || ""}?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE STEP
  createStep: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.quote_steps, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH STEP
  patchStep: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.quote_steps}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE STEP
  deleteStep: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.quote_steps}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },















  // =============== GET LIST QUOTE STEP TASKs
  getListStepTasks: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote_step_tasks}/${payload?.step_id || ""}?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE STEP TASK
  createStepTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.tasks, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH STEP TASK
  patchStepTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.tasks}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE STEP TASK
  deleteStepTask: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.tasks}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },




  // =============== GET QUOTE PLANNING
  getQuotePlaning: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.quote}/${payload?.quote_id}/planning${!payload?.newPercent && payload?.newPercent !== 0 ? "" : ("?newPercent=" + payload?.newPercent)}${!payload?.newValue && payload?.newValue !== 0 ? "" : ("?newValue=" + payload?.newValue)}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== GET LIST TASKS
  getListTasks: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] === false || (payload.filters[key] && payload.filters[key] !== ""))
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(payload.with_pagination ? `${endpoints.tasks}/${payload.offset || 0}/${payload.limit || 10}?${filters}` : `${endpoints.tasks}?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

}


export default QuoteSrv