import BonusTravelSrv from "services/BonusTravelSrv";

import {
  GET_TRAVEL_BONUSS,
  GET_TRAVEL_BONUSS_FAIL,
  GET_TRAVEL_BONUSS_SUCCESS,

  GET_ONE_TRAVEL_BONUS,
  GET_ONE_TRAVEL_BONUS_SUCCESS,
  GET_ONE_TRAVEL_BONUS_FAIL,

  CREATE_TRAVEL_BONUS,
  CREATE_TRAVEL_BONUS_SUCCESS,
  CREATE_TRAVEL_BONUS_FAIL,

  PATCH_TRAVEL_BONUS,
  PATCH_TRAVEL_BONUS_SUCCESS,
  PATCH_TRAVEL_BONUS_FAIL,

  DELETE_TRAVEL_BONUS,
  DELETE_TRAVEL_BONUS_SUCCESS,
  DELETE_TRAVEL_BONUS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListTravelBonuss = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_TRAVEL_BONUSS,
    });

    BonusTravelSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            travel_bonuses: response.data.data.travelBonuses || [],
            counts: response.data.data.counts
          }
          
          dispatch({
            type: GET_TRAVEL_BONUSS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_TRAVEL_BONUSS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createTravelBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_TRAVEL_BONUS,
    });

    BonusTravelSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            travel_bonus: response.data.data.travelBonuses,
          }

          dispatch({
            type: CREATE_TRAVEL_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_TRAVEL_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneTravelBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_TRAVEL_BONUS,
    });

    BonusTravelSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            travel_bonus: response.data.data.travelBonus
          }

          dispatch({
            type: GET_ONE_TRAVEL_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_TRAVEL_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editTravelBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_TRAVEL_BONUS,
    });

    BonusTravelSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            travel_bonus: response.data.data.travelBonus,
          }

          dispatch({
            type: PATCH_TRAVEL_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_TRAVEL_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteTravelBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_TRAVEL_BONUS,
    });

    BonusTravelSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_TRAVEL_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_TRAVEL_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};