/* COMPANY */
export const GET_COMPANY = "GET_COMPANY_PROJECT"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

export const CREATE_COMPANY = "CREATE_COMPANY"
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS"
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL"

export const PATCH_COMPANY = "PATCH_COMPANY"
export const PATCH_COMPANY_SUCCESS = "PATCH_COMPANY_SUCCESS"
export const PATCH_COMPANY_FAIL = "PATCH_COMPANY_FAIL"