/* STAFFS */
export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

export const GET_ONE_STAFF = "GET_ONE_STAFF"
export const GET_ONE_STAFF_SUCCESS = "GET_ONE_STAFF_SUCCESS"
export const GET_ONE_STAFF_FAIL = "GET_ONE_STAFF_FAIL"

export const CREATE_STAFF = "CREATE_STAFF"
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS"
export const CREATE_STAFF_FAIL = "CREATE_STAFF_FAIL"

export const PATCH_STAFF = "PATCH_STAFF"
export const PATCH_STAFF_SUCCESS = "PATCH_STAFF_SUCCESS"
export const PATCH_STAFF_FAIL = "PATCH_STAFF_FAIL"

export const DELETE_STAFF = "DELETE_STAFF"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL"

export const GET_STAFFS_AVAILABLE = "GET_STAFFS_AVAILABLE"
export const GET_STAFFS_AVAILABLE_SUCCESS = "GET_STAFFS_AVAILABLE_SUCCESS"
export const GET_STAFFS_AVAILABLE_FAIL = "GET_STAFFS_AVAILABLE_FAIL"

export const GET_STAFF_VACATIONS = "GET_STAFF_VACATIONS"
export const GET_STAFF_VACATIONS_SUCCESS = "GET_STAFF_VACATIONS_SUCCESS"
export const GET_STAFF_VACATIONS_FAIL = "GET_STAFF_VACATIONS_FAIL"

export const GET_PUBLIC_VACATIONS = "GET_PUBLIC_VACATIONS"
export const GET_PUBLIC_VACATIONS_SUCCESS = "GET_PUBLIC_VACATIONS_SUCCESS"
export const GET_PUBLIC_VACATIONS_FAIL = "GET_PUBLIC_VACATIONS_FAIL"

export const IMPORT_STAFFS = "IMPORT_STAFFS"
export const IMPORT_STAFFS_SUCCESS = "IMPORT_STAFFS_SUCCESS"
export const IMPORT_STAFFS_FAIL = "IMPORT_STAFFS_FAIL"

export const PRINT_STAFF_EMPOWERMENT = "PRINT_STAFF_EMPOWERMENT"
export const PRINT_STAFF_EMPOWERMENT_SUCCESS = "PRINT_STAFF_EMPOWERMENT_SUCCESS"
export const PRINT_STAFF_EMPOWERMENT_FAIL = "PRINT_STAFF_EMPOWERMENT_FAIL"

export const PRINT_ANNUAL_CALENDAR = "PRINT_ANNUAL_CALENDAR"
export const PRINT_ANNUAL_CALENDAR_SUCCESS = "PRINT_ANNUAL_CALENDAR_SUCCESS"
export const PRINT_ANNUAL_CALENDAR_FAIL = "PRINT_ANNUAL_CALENDAR_FAIL"

export const EDIT_STAFF_EMPOWERMENT = "EDIT_STAFF_EMPOWERMENT"
export const EDIT_STAFF_EMPOWERMENT_SUCCESS = "EDIT_STAFF_EMPOWERMENT_SUCCESS"
export const EDIT_STAFF_EMPOWERMENT_FAIL = "EDIT_STAFF_EMPOWERMENT_FAIL"

export const DELETE_STAFF_EMPOWERMENT = "DELETE_STAFF_EMPOWERMENT"
export const DELETE_STAFF_EMPOWERMENT_SUCCESS = "DELETE_STAFF_EMPOWERMENT_SUCCESS"
export const DELETE_STAFF_EMPOWERMENT_FAIL = "DELETE_STAFF_EMPOWERMENT_FAIL"

export const SEND_STAFF_NOTIF = "SEND_STAFF_NOTIF"
export const SEND_STAFF_NOTIF_SUCCESS = "SEND_STAFF_NOTIF_SUCCESS"
export const SEND_STAFF_NOTIF_FAIL = "SEND_STAFF_NOTIF_FAIL"

export const SEND_EMAIL_NOTIF = "SEND_EMAIL_NOTIF"
export const SEND_EMAIL_NOTIF_SUCCESS = "SEND_EMAIL_NOTIF_SUCCESS"
export const SEND_EMAIL_NOTIF_FAIL = "SEND_EMAIL_NOTIF_FAIL"