import { token_prefix, user_prefix } from "helpers/apiConfigs";
import { axiosGlobalConfigs } from "helpers/axiosConfig";

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,

  // GET_USER_PERMISSIONS,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAIL,

  GET_CONNECTED_PROFILE_SUCCESS
} from "./actionTypes"

let user = null;
let token = "";

try {
  user = JSON.parse(localStorage.getItem(user_prefix))
  token = localStorage.getItem(token_prefix)

  axiosGlobalConfigs(token)
} catch (error) { }




const initialState = {
  error: "",
  loading: false,
  user, token,
  permissions: [],
  // super_admin: false
}



const login = (state = initialState, action) => {
  // state = {
  //   ...initialState, permissions
  // }
  switch (action?.type) {
    case LOGIN_USER:
      state = {
        ...state, error: "",
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      axiosGlobalConfigs(action.payload?.token)

      state = {
        ...state,
        user: action.payload?.user,
        token: action.payload?.token,
        error: "",
        loading: false,
      }
      break
    case GET_USER_PERMISSIONS_SUCCESS:
      state = {
        ...state,
        permissions: action.payload?.permissions || [],
      }
      break
    case GET_USER_PERMISSIONS_FAIL:
      state = {
        ...state,
        permissions: [],
      }
      break


    case LOGOUT_USER:
      // localStorage.removeItem(user_prefix)
      // localStorage.removeItem(token_prefix)
      localStorage.clear();
      state = { ...state, user: null, token: "" }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break



    case GET_CONNECTED_PROFILE_SUCCESS:
      if (action.payload?.profile?.profile?.picture) {
        try {
          const new_user = { ...user, profile: action.payload?.profile?.profile }
          localStorage.setItem("authUser", JSON.stringify(new_user))
          state = {
            ...state,
            user: new_user,
          }
        } catch (error) {
          state = { ...state }
        }
      }

      else state = { ...state }
      break


    default:
      state = { ...state }
      break
  }
  return state
}

export default login
