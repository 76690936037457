import ArticleTypeSrv from "services/ArticleTypeSrv";

import {
  GET_ARTICLE_TYPES,
  GET_ARTICLE_TYPES_FAIL,
  GET_ARTICLE_TYPES_SUCCESS,

  GET_ONE_ARTICLE_TYPE,
  GET_ONE_ARTICLE_TYPE_SUCCESS,
  GET_ONE_ARTICLE_TYPE_FAIL,

  CREATE_ARTICLE_TYPE,
  CREATE_ARTICLE_TYPE_SUCCESS,
  CREATE_ARTICLE_TYPE_FAIL,

  PATCH_ARTICLE_TYPE,
  PATCH_ARTICLE_TYPE_SUCCESS,
  PATCH_ARTICLE_TYPE_FAIL,

  DELETE_ARTICLE_TYPE,
  DELETE_ARTICLE_TYPE_SUCCESS,
  DELETE_ARTICLE_TYPE_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListArticleTypes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_TYPES,
    });

    ArticleTypeSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_types: response.data.data.articleTypes,
            counts: response.data.data.counts
          }
          
          dispatch({
            type: GET_ARTICLE_TYPES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_TYPES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createArticleType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_TYPE,
    });

    ArticleTypeSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_type: response.data.data.articleType,
          }

          dispatch({
            type: CREATE_ARTICLE_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneArticleType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ARTICLE_TYPE,
    });

    ArticleTypeSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_type: response.data.data.articleType
          }

          dispatch({
            type: GET_ONE_ARTICLE_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ARTICLE_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editArticleType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE_TYPE,
    });

    ArticleTypeSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_type: response.data.data.articleType,
          }

          dispatch({
            type: PATCH_ARTICLE_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteArticleType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_TYPE,
    });

    ArticleTypeSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};