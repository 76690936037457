import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import SelectReact from 'react-select';

import { NavLink, useHistory } from "react-router-dom";


import {
  Label, Card, CardBody, CardHeader, Input, Row, Col,
  Button, UncontrolledAlert, Spinner
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

import {
  getConnectedProfile, editConnectedProfile, editPassword, getListActivities, editProvider, getOneProvider
} from 'store/actions'
import { endpoints } from 'helpers/apiConfigs';

import { isFunction, isValidEmail } from 'helpers/utils';

import PlacesAutocomplete, {
  geocodeByAddress, getLatLng, geocodeByPlaceId
} from 'react-places-autocomplete';


import GoogleMapReact from 'google-map-react';

import marker_red from "assets/images/marker-red.ico";
import AdresseSrv from 'services/AdresseSrv';


const EditProviderCmp = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    provider_id, back_path, is_profile, is_return_mdl, onChange, onCancel
  } = props

  // HOOKS
  const { provider, loading_edit_provider, loading_get_provider } = useSelector(({ ProviderState }) => ProviderState);


  const [user_profile, setUserProfile] = useState(null)
  const [loading_edit_profile, setLoadingEditProfile] = useState(false)
  const [loading_edit_pass, setLoadingEditPass] = useState(false)

  const [loading_get_profile, setLoadingGetProfile] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_pass, setConfirmPassword] = useState("")


  const [close_suggessions, setCloseSuggessions] = useState(false)
  const [close_suggessions_post, setCloseSuggessionsPost] = useState(false)

  const [msg_check, setMsgCheck] = useState("")


  const [fullName, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const [SIRET, setSIRET] = useState("")
  const [ref, setRef] = useState("")

  const [street, setStreet] = useState("")

  const [latadress, setLatAdress] = useState(null)
  const [lngadress, setLngAdress] = useState(null)

  const handleSelectAddress = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    try {
      setLatAdress(latLng.lat)
      setLngAdress(latLng.lng)
    } catch (error) { }

    setCloseSuggessions(true)


    try {
      const [place] = await geocodeByPlaceId(placeId);
      const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};
      if (!postalCode || postalCode === "") {
        await AdresseSrv.getReverse({
          filters: { lat: latLng?.lat, lon: latLng?.lng }
        }).then(res => {
          console.log({ postalCode, res })
          if (res?.features?.[0]?.properties?.postcode)
            setPostalCode(res?.features?.[0]?.properties?.postcode)
        })
          .catch(e => {
            console.log(e)
          })
      } else {
        setPostalCode(postalCode)
      }


      const { long_name: department = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
      setDepartment(department)

      const { long_name: region = '' } = place.address_components.find(c => c.types.includes("administrative_area_level_1")) || {};
      setRegion(region)

      const { long_name: city = '' } = place.address_components.find(c => c.types.includes("locality")) || {};
      setCity(city)
    } catch (error) { }
  };

  const [region, setRegion] = useState("")
  const [department, setDepartment] = useState("")


  const [city, setCity] = useState("")
  const [additionAddress, setAdditionAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")

  const [directorPhone, setDirectorPhone] = useState("")
  const [directorName, setDirectorName] = useState("");

  const [interlocutors, setInterlocutors] = useState([{
    fullName: "", email: "", phone: ""
  }]);
  const [commercials, setCommercials] = useState([{
    fullName: "", email: "", phone: ""
  }]);




  const center = { lat: 48.84, lng: 2.20 };

  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };


  const checkData = () => {
    if (fullName === "") {
      return "Le Raison Social est obligatoire.";
    }
    if (street === "") {
      return "L'adresse est obligatoire."
    }
    if (!email || !isValidEmail(email)) {
      return "L'e-mail est obligatoire."
    }
    if (phone?.length < 6) {
      return "Le télèphone est obligatoire."
    }


    return ""
  }

  const dropSteps = (index, type) => {
    switch (type) {
      case 'commercial':
        if (commercials.length > 1) {
          const newCommercials = [...commercials];
          newCommercials.splice(index, 1);
          setCommercials(newCommercials)
        }
        break;
      case 'interlocutor':
        if (interlocutors.length > 1) {
          const new_interlocutors = [...interlocutors]
          new_interlocutors.splice(index, 1);
          setInterlocutors(new_interlocutors)
        }
        break;
      default: break;
    }
  }


  useEffect(() => {
    if (!is_profile) {
      dispatch(getListActivities({
        offset: 0, limit: 500, filters: {
          sort: "name"
        }
      }, (resp) => { }, (err) => { }))
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (provider_id && provider_id !== "") {
      setMsgCheck("");

      if (is_profile) {
        setLoadingGetProfile(true)
        dispatch(getConnectedProfile({}, (resp) => {
          setUserProfile(resp?.profile)
          fillProviderData(resp?.profile)
          setLoadingGetProfile(false)
        }, (error) => {
          setLoadingGetProfile(false)
        }))
      }
      else {
        // if (!loading_get_provider && provider?._id !== provider_id)
        dispatch(getOneProvider({ _id: provider_id }, (resp) => {
          fillProviderData(resp.provider)
        }, (error) => { }))

        // else fillProviderData(provider)
      }
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider_id])

  const fillProviderData = (new_provider) => {
    if (new_provider) {
      setName(new_provider.profile?.fullName);
      setEmail(new_provider.email || "");
      setPhone(new_provider.profile?.phone || "");

      setSIRET(new_provider.SIRET || "");
      setRef(new_provider.ref || "");

      setStreet(new_provider.profile?.address?.street || "");
      setCity(new_provider.profile?.address?.city || "");
      setAdditionAddress(new_provider.profile?.address?.additionAddress);
      setPostalCode(new_provider.profile?.address?.postalCode || "");

      setDepartment(new_provider.profile?.address?.department)
      setRegion(new_provider.profile?.address?.region)

      setLatAdress(new_provider.profile?.address?.location?.coordinates[0] || null)
      setLngAdress(new_provider.profile?.address?.location?.coordinates[1] || null)

      setDirectorName(new_provider.director.directorName || "")
      setDirectorPhone(new_provider.director.directorPhone || "")

      setInterlocutors(new_provider.interlocutors || [])

      setCommercials(new_provider.commercials || [])


    }
  }





  const submitEdit = () => {
    const check = checkData(); setMsgCheck(check);

    if (check === "") {
      if (commercials) {
        for (let i = 0; i < commercials.length; i++) {
          const element = commercials[i];
          if (element && element.fullName === '' && element.phone === '' && element.email === '') {
            commercials.splice(i, 1);
            i--;
          }

        }
      }
      if (interlocutors) {
        for (let i = 0; i < interlocutors.length; i++) {
          const element = interlocutors[i];
          if (element && element.fullName === '' && element.phone === '' && element.email === '') {
            interlocutors.splice(i, 1);
            i--;
          }
        }
      }





      if (is_profile) {
        setLoadingEditProfile(true);

        dispatch(editConnectedProfile({
          // _id: user_profile?._id,
          user_type: "PROVIDER",
          data: {
            lat: latadress, lng: lngadress, ref: (ref && ref !== "") ? ref : null, additionAddress,
            SIRET, street, phone, directorName, directorPhone, city, department, region, postalCode, fullName,
            email: email.toLowerCase(), interlocutors, commercials
          }
        }, (resp) => {
          setLoadingEditProfile(false);
          if (back_path && back_path !== "") history.push(back_path);
        }, (error) => {
          setLoadingEditProfile(false);
          window.scrollTo(0, 0)
          setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
        }))
      }





      else dispatch(editProvider({
        _id: provider?._id,

        data: {
          lat: latadress, lng: lngadress, ref: (ref && ref !== "") ? ref : null,
          SIRET, street, phone, directorName, directorPhone, city, department, region, postalCode, fullName,
          email: email.toLowerCase(), interlocutors, commercials, additionAddress
        }
      },
        (resp) => {
          if (isFunction(onChange)) onChange(resp?.provider)
          if (back_path && back_path !== "") history.push(back_path);
        }, (error) => {
          window.scrollTo(0, 0)
          setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
        }))
    }

    else window.scrollTo(0, 0)
  }


  const submitChangePassword = () => {
    setLoadingEditPass(true);
    setMsgCheck('');

    dispatch(editPassword({
      data: { oldPassword, password, confirmPassword: confirm_pass }
    }, (resp) => {
      setLoadingEditPass(false);
      setPassword('')
      setOldPassword('')
      setConfirmPassword('')
      // if (back_path && back_path !== "") history.push(back_path);
    }, (error) => {
      setLoadingEditPass(false);
      window.scrollTo(0, 0)
      setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
    }))
  }


  if (is_profile) {
    if (provider_id !== user_profile?._id) return (<div className="page-content text-center">
      {loading_get_profile && <Spinner color="primary" />}
    </div>);
  }
  else {
    if (provider_id !== provider?._id) return (<div className="page-content text-center">
      {loading_get_provider && <Spinner color="primary" />}
    </div>);
  }


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
            <i className="mdi mdi-block-helper me-2"></i>{msg_check}
          </UncontrolledAlert>}


          {!is_profile ? <h4 className="hilighted" style={{ textTransform: "uppercase" }}>Fournisseur</h4> : null}

          <Row key="0" className="mt-4">
            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Informations Générales</h4>

            </Col>
            <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">Raison Social</Label>
                <Input value={fullName} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Raison Social" />
              </div>
            </Col>
            <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">N° SIRET</Label>
                <Input value={SIRET} onChange={(e) => setSIRET(e.target.value)} className="form-control" placeholder="N° SIRET" />
              </div>
            </Col>

            <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">E-mail</Label>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="E-mail" />
              </div>
            </Col>
            <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">Télèphone</Label>
                {/* <Input value={phone} onChange={(e) => setPhone(e.target.value)} type="number" className="form-control" placeholder="+33" /> */}
                <PhoneInput className="form-control"
                  country={'fr'}
                  value={phone}
                  onChange={phone_number => setPhone(phone_number)}
                />
              </div>
            </Col>
            {!is_profile ? <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">Référence <span className="grey">(optionnel)</span></Label>
                <Input value={ref} onChange={(e) => setRef(e.target.value)} className="form-control" placeholder="Référence" />
              </div>
            </Col> : null}






            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Adresse</h4>
              <hr />
            </Col>
            <Col md="8">
              <Row>
                {/* ADDRESS */}
                <Col md="8">
                  <div className="mb-3">
                    <Label className="frm-label">Adresse</Label>
                    {(!latadress || !lngadress) ?
                      <i className="mdi mdi-alert-circle-outline hilighted" style={{ fontSize: 17, marginLeft: 10 }} title="Adresse incomplète" /> : null
                    }
                    <PlacesAutocomplete searchOptions={{
                      componentRestrictions: { country: ['FR'] },
                      bounds: defaultBounds,
                      stricBounds: false,
                      fields: ["address_components", "geometry", "icon", "name"],

                      types: ['address'],
                    }}
                      value={street}
                      onChange={(rslt) => {
                        try {
                          setStreet(rslt)
                        } catch (error) { }

                        setCloseSuggessions(false);
                      }}
                      onSelect={(address, placeId, suggestion) => {
                        if (suggestion && suggestion.description) {
                          setStreet(suggestion.description)
                          handleSelectAddress(suggestion.description, suggestion.placeId)
                        }
                      }}

                      autocomplete="off"

                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ width: "100%" }}>
                          <input
                            {...getInputProps({
                              placeholder: 'Adress...',
                              className: 'form-control form-control',
                            })}
                          />
                          {!close_suggessions && <div className="autocomplete-dropdown-container" style={{}}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {

                              return (
                                <div key={"__ADRS_SUGG" + i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className: suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item', style: {}
                                  })}

                                  onClick={() => {
                                    if (suggestion && suggestion.description) {
                                      setStreet(suggestion.description)
                                      handleSelectAddress(suggestion.description, suggestion.placeId)
                                    }



                                    // console.log("suggestion", suggestion);

                                    // geocodeByAddress(suggestion.description)
                                    //   .then(results => getLatLng(results[0]))
                                    //   .then((result) => {
                                    //     try {
                                    //       setLatAdress(result.lat)
                                    //       setLngAdress(result.lng)
                                    //     } catch (error) { }

                                    //     setCloseSuggessions(true)
                                    //   })
                                  }}

                                >
                                  <span>{suggestion?.description || ""}</span>
                                </div>
                              );
                            })}
                          </div>}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Col>

                {/* ZIP CODE */}
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Code postal</Label>

                    <PlacesAutocomplete searchOptions={{
                      componentRestrictions: { country: ['FR'] },
                      types: ['geocode']
                    }}
                      value={postalCode}
                      onChange={(rslt) => {
                        try {
                          setPostalCode(rslt.split(" ")[0])
                        } catch (error) { }

                        setCloseSuggessionsPost(false);
                      }}
                      onSelect={(address, placeId, suggestion) => {
                        if (suggestion && suggestion.description) {
                          setStreet(suggestion.description)
                          handleSelectAddress(suggestion.description, suggestion.placeId)
                        }
                      }}

                      // inputProps={{
                      //   autocomplete: "off"
                      // }}
                      autocomplete="off"
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ width: "100%" }}>
                          <input
                            {...getInputProps({
                              placeholder: 'Code postal...',
                              className: 'form-control form-control'
                            })}
                          />
                          {!close_suggessions_post && <div className="autocomplete-dropdown-container" style={{}}>
                            {loading && <div>Chargement...</div>}
                            {suggestions.map((suggestion, i) => {

                              return (
                                <div key={"_ZIP_SUGG_" + i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className: suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item', style: {}
                                  })}

                                  onClick={() => {
                                    setPostalCode(suggestion?.terms[0]?.value)
                                    setStreet(suggestion?.description || "")

                                    geocodeByAddress(suggestion.description)
                                      .then(results => {

                                        try {
                                          const adrs_cmp = results[0].address_components
                                          // setPostalCode(adrs_cmp[0].long_name)
                                          setCity(adrs_cmp[1].long_name)
                                          setDepartment(adrs_cmp[2].long_name)
                                          setRegion(adrs_cmp[3].long_name)

                                        } catch (error) { }

                                        setCloseSuggessionsPost(true)
                                      })

                                      // .then(latLng => {})
                                      .catch(error => {

                                      });

                                    geocodeByAddress(suggestion?.description)
                                      .then(results => getLatLng(results[0]))
                                      .then((result) => {

                                        try {
                                          setLatAdress(result.lat)
                                          setLngAdress(result.lng)
                                        } catch (error) { }
                                      })
                                  }}
                                >
                                  <span>{suggestion?.terms[0]?.value + " " + (suggestion?.terms[1]?.value) || ""}</span>
                                </div>
                              );
                            })}
                          </div>}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                </Col>


                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Département</Label>
                    <Input value={department} onChange={(e) => setDepartment(e.target.value)} className="form-control" placeholder="Département" />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Région</Label>
                    <Input value={region} onChange={(e) => setRegion(e.target.value)} className="form-control" placeholder="Région" />
                  </div>
                </Col>


                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Ville</Label>
                    <Input value={city} onChange={(e) => setCity(e.target.value)} className="form-control" placeholder="Ville" />
                  </div>
                </Col>


                <Col md="12">
                  <div className="mb-3">
                    <Label className="frm-label">Complément d'adresse</Label>
                    <Input value={additionAddress} onChange={(e) => setAdditionAddress(e.target.value)} className="form-control" placeholder="Complément d'adresse" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="mb-3">
              {(latadress && lngadress) && <div style={{ height: '30vh', width: '100%' }}>

                <GoogleMapReact
                  bootstrapURLKeys={{ key: endpoints.gmap_key }}
                  // defaultCenter={{ lat:46.00, lng: 2.00 }}
                  center={{ lat: latadress, lng: lngadress }}
                  defaultZoom={11}
                  // onGoogleApiLoaded={({ map, maps }) => {
                  //   if (maps) renderMarkers(map, maps)
                  // }}
                  yesIWantToUseGoogleMapApiInternals={true}
                // onChange={(changes) => console.log("changes", changes)}
                >
                  <img src={marker_red} style={{
                    width: 44,
                    marginTop: -58,
                    marginLeft: -22
                  }}
                    lat={latadress} lng={lngadress}
                    alt="#"
                  />
                </GoogleMapReact>
              </div>}
            </Col>


            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Informations professionnelles</h4>
              <hr />
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="frm-label">Directeur</Label>
                <Input value={directorName}
                  onChange={(e) => setDirectorName(e.target.value)
                  } className="form-control" placeholder="Directeur" />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="frm-label">Télèphone</Label>
                <PhoneInput className="form-control"
                  value={directorPhone}
                  country={'fr'}
                  onChange={(phone_number) =>
                    setDirectorPhone(phone_number)
                  }

                />
              </div>
            </Col>
          </Row>

          <Col md="12" className="hr-divider mb-2 mt-2">
            <h4 className="divider-name">Interlocuteurs</h4>
            <hr />
          </Col>

          {/* INTERLOCUTORS */}


          {interlocutors.map((item, index) => item !== null &&
            <Row key={item[index]?._id}>
              <Col md="3" >
                <div className="mb-3">
                  <Label className="frm-label">Nom et Prénom</Label>
                  <Input value={item.fullName} onChange={(e) => {
                    const new_interlocutors = [...interlocutors];
                    // if (e.target.value !== "") 
                    new_interlocutors[index].fullName = e.target.value
                    setInterlocutors(new_interlocutors)
                  }}
                    className="form-control" placeholder="Nom et Prénom interlocuteur" />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">E-mail</Label>
                  <Input value={item.email}
                    onChange={(e) => {
                      const new_interlocutors = [...interlocutors];
                      // if (e.target.value !== "") 
                      new_interlocutors[index].email = e.target.value
                      setInterlocutors(new_interlocutors)
                    }}
                    className="form-control" placeholder="E-mail interlocuteur" />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">Télèphone</Label>
                  <PhoneInput className="form-control"
                    country={'fr'}
                    value={item.phone}
                    onChange={(phone_number) => {
                      const new_interlocutors = [...interlocutors];
                      // if (phone_number !== "") 
                      new_interlocutors[index].phone = phone_number
                      setInterlocutors(new_interlocutors)
                    }}

                  />
                </div>
              </Col>
              <Col md="1" className="my-4">
                <i style={{ fontSize: 25, cursor: 'pointer ', position: 'absolute' }}
                  onClick={(e) => dropSteps(index, 'interlocutor')}
                  className="mdi mdi-close-circle-outline dangered float-right" />
              </Col>
            </Row>
          )}
          <Col md="12">
            <span className='burgundy-red' style={{ cursor: 'pointer' }} id="click"
              onClick={() => setInterlocutors([...interlocutors, { fullName: "", email: "", phone: "" }])}>+ Ajouter interlocuteur</span>
          </Col>

          <Col md="12" className="hr-divider mb-2 mt-2">
            <h4 className="divider-name">Commerciaux du secteur</h4>
            <hr />
          </Col>

          {/* COMMECIAL */}
          {commercials && commercials.map((item, index) => item !== null &&
            <Row key={item[index]?._id}>
              <Col md="3">
                <div className="mb-3">
                  <Label className="frm-label">Nom et Prénom</Label>
                  <Input value={item.fullName}
                    onChange={(e) => {
                      const new_commercials = [...commercials];
                      //  if(e.target.value !== "") 
                      new_commercials[index].fullName = e.target.value
                      setCommercials(new_commercials)
                    }}
                    className="form-control" placeholder="Responsable Commercial" />
                </div>
              </Col>
              <Col md="4" >
                <div className="mb-3">
                  <Label className="frm-label">E-mail</Label>
                  <Input value={item.email}
                    onChange={(e) => {
                      const new_commercials = [...commercials];
                      // if (e.target.value !== "") 
                      new_commercials[index].email = e.target.value
                      setCommercials(new_commercials)
                    }}
                    className="form-control" placeholder="E-mail commercial" />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">Télèphone</Label>
                  <PhoneInput className="form-control"
                    country={'fr'}

                    value={item.phone}
                    onChange={(phone_number) => {
                      const new_commercials = [...commercials];
                      if (phone_number !== "") new_commercials[index].phone = phone_number
                      setCommercials(new_commercials)
                    }}

                  />
                </div>
              </Col>
              <Col md="1" key={index} className="my-4">
                <i style={{ fontSize: 25, cursor: 'pointer ', position: 'absolute' }}
                  onClick={(e) => dropSteps(index, 'commercial')}
                  className="mdi mdi-close-circle-outline dangered float-right" />
              </Col>

            </Row>

          )}
          <Col md="12">
            <span className='burgundy-red' style={{ cursor: 'pointer' }} id="click"
              onClick={() =>
                setCommercials([...commercials, { fullName: "", email: "", phone: "" }])}>+ Ajouter Commercial</span>
          </Col>

          <div className="mt-5">
            <Button onClick={() => {
              if (!loading_edit_provider) submitEdit()
            }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
              Enregistrer {(loading_edit_provider || loading_edit_profile) && <Spinner className="ms-2 small-spinner" color="light" />}
            </Button>
            {(!is_profile && !is_return_mdl) ? <NavLink to="/providers">
              <Button color="secondary" outline className="waves-effect" style={{ width: 127 }}>
                Annuler
              </Button>
            </NavLink> : null}


            {is_return_mdl ? <Button onClick={() => { if (isFunction(onCancel)) onCancel(); }} color="secondary" outline className="waves-effect" style={{ width: 127 }}>Annuler</Button> : null}
          </div>
        </CardBody>
      </Card>




      {is_profile ? <div>
        <hr />
        <Card>
          <CardHeader>
            <h3>Changer mot de passe</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Label>Ancien mot de passe</Label>
                <Input type="password" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
              </Col>
              <Col md="6">
                <Label>Nouveau mot de passe</Label>
                <Input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Col>
              <Col md="6" className="mt-3">
                <Label>Confirmation</Label>
                <Input type="password" className="form-control" value={confirm_pass} onChange={(e) => setConfirmPassword(e.target.value)} />
              </Col>

              <Col md="6" style={{ marginTop: 42 }}>
                <Button disabled={(confirm_pass !== password || !oldPassword || oldPassword === "")} onClick={() => {
                  if (!loading_edit_pass) submitChangePassword()
                }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
                  Changer {(loading_edit_pass) && <Spinner className="ms-2 small-spinner" color="light" />}
                </Button>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div> : null}

    </React.Fragment >
  )
}
EditProviderCmp.propTypes = {
  provider_id: PropTypes.string.isRequired,
  back_path: PropTypes.string,

  is_profile: PropTypes.bool,

  // FOR OPEN FROM MODAL
  is_return_mdl: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
}
export default EditProviderCmp