import ArticleSrv from "services/ArticleSrv";

import {
  GET_ARTICLES,
  GET_ARTICLES_FAIL,
  GET_ARTICLES_SUCCESS,

  GET_ONE_ARTICLE,
  GET_ONE_ARTICLE_SUCCESS,
  GET_ONE_ARTICLE_FAIL,

  CREATE_ARTICLE,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAIL,

  PATCH_ARTICLE,
  PATCH_ARTICLE_SUCCESS,
  PATCH_ARTICLE_FAIL,

  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,

  /* ********************** ARTICLE SUPPLYs ********************** */

  GET_ARTICLE_SUPPLIES,
  GET_ARTICLE_SUPPLIES_SUCCESS,
  GET_ARTICLE_SUPPLIES_FAIL,

  // GET_ONE_ARTICLE_SUPPLY,
  // GET_ONE_ARTICLE_SUPPLY_SUCCESS,
  // GET_ONE_ARTICLE_SUPPLY_FAIL,

  CREATE_ARTICLE_SUPPLY,
  CREATE_ARTICLE_SUPPLY_SUCCESS,
  CREATE_ARTICLE_SUPPLY_FAIL,

  PATCH_ARTICLE_SUPPLY,
  PATCH_ARTICLE_SUPPLY_SUCCESS,
  PATCH_ARTICLE_SUPPLY_FAIL,

  DELETE_ARTICLE_SUPPLY,
  DELETE_ARTICLE_SUPPLY_SUCCESS,
  DELETE_ARTICLE_SUPPLY_FAIL,


  /* ********************** ARTICLE MATERIALS ********************** */
  GET_ARTICLE_MATERIALS,
  GET_ARTICLE_MATERIALS_SUCCESS,
  GET_ARTICLE_MATERIALS_FAIL,

  // GET_ONE_ARTICLE_MATERIAL,
  // GET_ONE_ARTICLE_MATERIAL_SUCCESS,
  // GET_ONE_ARTICLE_MATERIAL_FAIL,

  CREATE_ARTICLE_MATERIAL,
  CREATE_ARTICLE_MATERIAL_SUCCESS,
  CREATE_ARTICLE_MATERIAL_FAIL,

  PATCH_ARTICLE_MATERIAL,
  PATCH_ARTICLE_MATERIAL_SUCCESS,
  PATCH_ARTICLE_MATERIAL_FAIL,

  DELETE_ARTICLE_MATERIAL,
  DELETE_ARTICLE_MATERIAL_SUCCESS,
  DELETE_ARTICLE_MATERIAL_FAIL,







  /* ********************** ARTICLE LABORS ********************** */
  GET_ARTICLE_LABORS,
  GET_ARTICLE_LABORS_SUCCESS,
  GET_ARTICLE_LABORS_FAIL,

  // GET_ONE_ARTICLE_LABOR,
  // GET_ONE_ARTICLE_LABOR_SUCCESS,
  // GET_ONE_ARTICLE_LABOR_FAIL,

  CREATE_ARTICLE_LABOR,
  CREATE_ARTICLE_LABOR_SUCCESS,
  CREATE_ARTICLE_LABOR_FAIL,

  PATCH_ARTICLE_LABOR,
  PATCH_ARTICLE_LABOR_SUCCESS,
  PATCH_ARTICLE_LABOR_FAIL,

  DELETE_ARTICLE_LABOR,
  DELETE_ARTICLE_LABOR_SUCCESS,
  DELETE_ARTICLE_LABOR_FAIL,






  /* ********************** ARTICLE ARTICLES ********************** */
  GET_ARTICLE_ARTICLES,
  GET_ARTICLE_ARTICLES_SUCCESS,
  GET_ARTICLE_ARTICLES_FAIL,

  // GET_ONE_ARTICLE_ARTICLE,
  // GET_ONE_ARTICLE_ARTICLE_SUCCESS,
  // GET_ONE_ARTICLE_ARTICLE_FAIL,

  CREATE_ARTICLE_ARTICLE,
  CREATE_ARTICLE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_ARTICLE_FAIL,

  PATCH_ARTICLE_ARTICLE,
  PATCH_ARTICLE_ARTICLE_SUCCESS,
  PATCH_ARTICLE_ARTICLE_FAIL,

  DELETE_ARTICLE_ARTICLE,
  DELETE_ARTICLE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ARTICLE_FAIL,


  GET_ARTICLE_PARENTS,
  GET_ARTICLE_PARENTS_SUCCESS,
  GET_ARTICLE_PARENTS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListArticles = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLES,
    });

    ArticleSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            articles: response.data.data.articles,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_ARTICLES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE,
    });

    ArticleSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article: response.data.data.article,
          }

          dispatch({
            type: CREATE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ARTICLE,
    });

    ArticleSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article: response.data.data.article
          }

          dispatch({
            type: GET_ONE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE,
    });

    ArticleSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article: response.data.data.article,
          }

          dispatch({
            type: PATCH_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE,
    });

    ArticleSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};




/* ********************** ARTICLE SUPPLYs ********************** */

// =============== GET ARTICLE SUPPLIES
export const getArticleSupplies = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_SUPPLIES,
    });

    ArticleSrv.getArticleSupplies(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_suplies: response.data.data.articleSupplies
          }

          dispatch({
            type: GET_ARTICLE_SUPPLIES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_SUPPLIES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE ARTICLE SUPPLY
export const createArticleSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_SUPPLY,
    });

    ArticleSrv.createArticleSupply(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_supply: response.data.data.articleSupply,
          }

          dispatch({
            type: CREATE_ARTICLE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ARTICLE SUPPLY
export const editArticleSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE_SUPPLY,
    });

    ArticleSrv.patchArticleSupply(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_supply: response.data.data.articleSupply,
          }

          dispatch({
            type: PATCH_ARTICLE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE ARTICLE SUPPLY
export const deleteArticleSupply = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_SUPPLY,
    });

    ArticleSrv.deleteArticleSupply(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_SUPPLY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_SUPPLY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};












/* ********************** ARTICLE MATERIALs ********************** */

// =============== GET ARTICLE MATERIALS
export const getArticleMaterials = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_MATERIALS,
    });

    ArticleSrv.getArticleMaterials(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_materials: response.data.data.articleMaterials || []
          }

          dispatch({
            type: GET_ARTICLE_MATERIALS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_MATERIALS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE ARTICLE MATERIAL
export const createArticleMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_MATERIAL,
    });

    ArticleSrv.createArticleMaterial(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_material: response.data.data.articleMaterial,
          }

          dispatch({
            type: CREATE_ARTICLE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ARTICLE MATERIAL
export const editArticleMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE_MATERIAL,
    });

    ArticleSrv.patchArticleMaterial(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_material: response.data.data.articleMaterial,
          }

          dispatch({
            type: PATCH_ARTICLE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE ARTICLE MATERIAL
export const deleteArticleMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_MATERIAL,
    });

    ArticleSrv.deleteArticleMaterial(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

















/* ********************** ARTICLE LABORs ********************** */

// =============== GET ARTICLE LABORS
export const getArticleLabors = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_LABORS,
    });

    ArticleSrv.getArticleLabors(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_labors: response.data.data.articleLabors || []
          }

          dispatch({
            type: GET_ARTICLE_LABORS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_LABORS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE ARTICLE LABOR
export const createArticleLabor = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_LABOR,
    });

    ArticleSrv.createArticleLabor(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_labor: response.data.data.articleLabor,
          }

          dispatch({
            type: CREATE_ARTICLE_LABOR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_LABOR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ARTICLE LABOR
export const editArticleLabor = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE_LABOR,
    });

    ArticleSrv.patchArticleLabor(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_labor: response.data.data.articleLabor,
          }

          dispatch({
            type: PATCH_ARTICLE_LABOR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_LABOR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE ARTICLE LABOR
export const deleteArticleLabor = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_LABOR,
    });

    ArticleSrv.deleteArticleLabor(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_LABOR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_LABOR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
























// =============== GET ARTICLE PARENTS
export const getArticleParents = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_PARENTS,
    });

    ArticleSrv.getArticleParents(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            articles: response.data.data.parents || []
          }

          dispatch({
            type: GET_ARTICLE_PARENTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_PARENTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};





/* ********************** ARTICLE ARTICLES ********************** */

// =============== GET ARTICLE ARTICLES
export const getArticleArticles = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ARTICLE_ARTICLES,
    });

    ArticleSrv.getArticleArticles(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_articles: response.data.data.articleArticles || []
          }

          dispatch({
            type: GET_ARTICLE_ARTICLES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ARTICLE_ARTICLES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE ARTICLE ARTICLE
export const createArticleArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_ARTICLE,
    });

    ArticleSrv.createArticleArticle(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_material: response.data.data.articleArticle,
          }

          dispatch({
            type: CREATE_ARTICLE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARTICLE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ARTICLE ARTICLE
export const editArticleArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARTICLE_ARTICLE,
    });

    ArticleSrv.patchArticleArticle(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            article_material: response.data.data.articleArticle,
          }

          dispatch({
            type: PATCH_ARTICLE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARTICLE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE ARTICLE ARTICLE
export const deleteArticleArticle = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_ARTICLE,
    });

    ArticleSrv.deleteArticleArticle(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARTICLE_ARTICLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARTICLE_ARTICLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};