import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListModulesPage = React.lazy(() => import('./list-modules.page'));
const EditModulePage = React.lazy(() => import('./edit-module.page'));

const Modules = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListModulesPage {...props} />}
      />

      <Route path={`${match.url}/edit/:moduleId`}
        render={props => <EditModulePage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Modules;