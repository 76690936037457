import {
  GET_STAFFS,
  GET_STAFFS_FAIL,
  GET_STAFFS_SUCCESS,

  GET_ONE_STAFF,
  GET_ONE_STAFF_SUCCESS,
  GET_ONE_STAFF_FAIL,

  CREATE_STAFF,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,

  PATCH_STAFF,
  PATCH_STAFF_SUCCESS,
  PATCH_STAFF_FAIL,

  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL
} from "./actionTypes"

const INIT_STATE = {
  staff: null,
  staffs: [],
  error: null,

  loading_get_staffs: false,
  loading_get_staff: false,

  loading_add_staff: false,
  loading_edit_staff: false,

  count_all_staffs: 0,
}

const StaffState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_STAFFS:
      return {
        ...state, loading_get_staffs: true, error: null
      }
    case GET_STAFFS_SUCCESS:
      return {
        ...state,
        staffs: action.payload.staffs,
        loading_get_staffs: false, error: null,
        count_all_staffs: action.payload.counts || 0
      }
    case GET_STAFFS_FAIL:
      return {
        ...state,
        loading_get_staffs: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_STAFF:
      return {
        ...state, staff: null, loading_get_staff: true, error: null
      }
    case GET_ONE_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload.staff,
        loading_get_staff: false, error: null
      }
    case GET_ONE_STAFF_FAIL:
      return {
        ...state,
        loading_get_staff: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_STAFF:
      return {
        ...state, staff: null, loading_add_staff: true, error: null
      }
    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload.staff,
        loading_add_staff: false, error: null
      }
    case CREATE_STAFF_FAIL:
      return {
        ...state,
        loading_add_staff: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_STAFF:
      return {
        ...state, loading_edit_staff: true, error: null
      }
    case PATCH_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload.staff,
        loading_edit_staff: false, error: null
      }
    case PATCH_STAFF_FAIL:
      return {
        ...state,
        loading_edit_staff: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_STAFF:
      return {
        ...state, staff: null, loading_delete_staff: true, error: null
      }
    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload.staff,
        loading_delete_staff: false, error: null
      }
    case DELETE_STAFF_FAIL:
      return {
        ...state,
        loading_delete_staff: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default StaffState
