
import React from "react"
import PropTypes from 'prop-types'
import { Input } from "reactstrap"
// import { NavLink, Router } from "react-router-dom";

const SearchInput = props => {
  return (<div className="search-input-wrap">
    <Input className="search-input"
      onChange={(e) => props.onChange ? props.onChange(e) : () => { }}
      placeholder={props.placeholder || "Recherche"}
    />


    {!props.hide_icon && <span
      onClick={(e) => props.onIconClick ? props.onIconClick(e) : () => { }}
      className="search-input-icon"
    >
      <i className={props.icon_name || "bx bx-search-alt"} ></i>
    </span>}
  </div>)
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,

  hide_icon: PropTypes.bool,
  icon_name: PropTypes.string,
  onIconClick: PropTypes.func
}

export default SearchInput
