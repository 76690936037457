import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { useSelector } from "react-redux"

const EditCompanyPage = React.lazy(() => import("./edit-company.page"))
const PreviewCompanyPage = React.lazy(() => import("./preview-company.page"))

const Companies = props => {
  const match = props.match
  const { user } = useSelector(({ Login }) => Login)

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/preview`} />

        {user?.userType === "ADMIN" && (
          <Route
            path={`${match.url}/edit`}
            render={props => <EditCompanyPage {...props} />}
          />
        )}

        <Route
          path={`${match.url}/preview`}
          render={props => <PreviewCompanyPage {...props} />}
        />

        <Redirect from={`${match.url}/`} to={`${match.url}/preview`} />
      </Switch>
    </Suspense>
  )
}

export default Companies