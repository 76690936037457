import {
  GET_CLOCIKNGINS,
  GET_CLOCIKNGINS_FAIL,
  GET_CLOCIKNGINS_SUCCESS,

  GET_ONE_CLOCIKNGIN,
  GET_ONE_CLOCIKNGIN_SUCCESS,
  GET_ONE_CLOCIKNGIN_FAIL,

  CREATE_CLOCIKNGIN,
  CREATE_CLOCIKNGIN_SUCCESS,
  CREATE_CLOCIKNGIN_FAIL,

  PATCH_CLOCIKNGIN,
  PATCH_CLOCIKNGIN_SUCCESS,
  PATCH_CLOCIKNGIN_FAIL,

  DELETE_CLOCIKNGIN,
  DELETE_CLOCIKNGIN_SUCCESS,
  DELETE_CLOCIKNGIN_FAIL
} from "./actionTypes"

const INIT_STATE = {
  clockingin: null,
  clockingins: [],
  error: null,

  loading_get_clockingins: false,
  loading_get_clockingin: false,

  loading_add_clockingin: false,
  loading_edit_clockingin: false,

  count_all_clockingins: 0,
}

const ClockinginState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_CLOCIKNGINS:
      return {
        ...state, loading_get_clockingins: true, error: null,
        clockingins: []
      }
    case GET_CLOCIKNGINS_SUCCESS:
      return {
        ...state,
        clockingins: action.payload.clockingins,
        loading_get_clockingins: false, error: null,
        count_all_clockingins: action.payload.counts || 0
      }
    case GET_CLOCIKNGINS_FAIL:
      return {
        ...state,
        loading_get_clockingins: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_CLOCIKNGIN:
      return {
        ...state, clockingin: null, loading_get_clockingin: true, error: null
      }
    case GET_ONE_CLOCIKNGIN_SUCCESS:
      return {
        ...state,
        clockingin: action.payload.clockingin,
        loading_get_clockingin: false, error: null
      }
    case GET_ONE_CLOCIKNGIN_FAIL:
      return {
        ...state,
        loading_get_clockingin: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_CLOCIKNGIN:
      return {
        ...state, clockingin: null, loading_add_clockingin: true, error: null
      }
    case CREATE_CLOCIKNGIN_SUCCESS:
      return {
        ...state,
        clockingin: action.payload.clockingin,
        loading_add_clockingin: false, error: null
      }
    case CREATE_CLOCIKNGIN_FAIL:
      return {
        ...state,
        loading_add_clockingin: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_CLOCIKNGIN:
      return {
        ...state, loading_edit_clockingin: true, error: null
      }
    case PATCH_CLOCIKNGIN_SUCCESS:
      return {
        ...state,
        clockingin: action.payload.clockingin,
        loading_edit_clockingin: false, error: null
      }
    case PATCH_CLOCIKNGIN_FAIL:
      return {
        ...state,
        loading_edit_clockingin: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_CLOCIKNGIN:
      return {
        ...state, clockingin: null, loading_delete_clockingin: true, error: null
      }
    case DELETE_CLOCIKNGIN_SUCCESS:
      return {
        ...state,
        clockingin: action.payload.clockingin,
        loading_delete_clockingin: false, error: null
      }
    case DELETE_CLOCIKNGIN_FAIL:
      return {
        ...state,
        loading_delete_clockingin: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ClockinginState
