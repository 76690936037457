import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom";


import {
  Label, Card, CardBody, CardHeader, Input, Row, Col,
  Button, UncontrolledAlert, Spinner
} from "reactstrap"

// Redux
import { useDispatch } from "react-redux"
// import { withRouter } from "react-router-dom"

import {
  getConnectedProfile, editConnectedProfile, editPassword
} from 'store/actions'
import { endpoints } from 'helpers/apiConfigs';

import { isValidEmail } from 'helpers/utils';
import SweetAlert from 'react-bootstrap-sweetalert';


const EditProfileCmp = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user_id, back_path
  } = props

  // HOOKS
  // const { user } = useSelector(({ Login }) => Login);


  const [user_profile, setUserProfile] = useState(null)
  const [loading_edit_profile, setLoadingEditProfile] = useState(false)
  const [loading_edit_pass, setLoadingEditPass] = useState(false)

  const [msg_check, setMsgCheck] = useState("")

  const [loading_get_profile, setLoadingGetProfile] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_pass, setConfirmPassword] = useState("")


  const [picture, setPicture] = useState(null)
  const [picture_link, setPictureLink] = useState("")

  const [firstName, setName] = useState("")
  const [lastName, setLastName] = useState("")

  const [email, setEmail] = useState("")
  
  const [showConfirmMdl, setShowConfirmMdl] = useState(false)
  const [showErrorMdl, setShowErrorMdl] = useState(false)




  useEffect(() => {
    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (user_id && user_id !== "") {
      setMsgCheck("");

      setLoadingGetProfile(true)
      dispatch(getConnectedProfile({}, (resp) => {
        setUserProfile(resp?.profile)
        fillUserData(resp?.profile)
        setLoadingGetProfile(false)
      }, (error) => {
        setLoadingGetProfile(false)
      }))
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])

  const fillUserData = (new_user) => {
    if (new_user) {
      setName(new_user.profile?.firstName);
      setLastName(new_user.profile?.lastName)

      setEmail(new_user.email)


      if (new_user.profile?.picture) setPictureLink(new_user.profile?.picture.path)
    }
  }



  const checkData = () => {
    if (firstName === "" || lastName === "") {
      return "Le nom est obligatoire.";
    }
    if (!email || !isValidEmail(email)) {
      return "L'e-mail est obligatoire."
    }


    return ""
  }

  const submitEdit = () => {
    const check = checkData(); setMsgCheck(check)

    if (check === "") {
      setLoadingEditProfile(true);

      const frm = new FormData();
      if (picture) frm.append('picture', picture);
      if (firstName) frm.append('firstName', firstName);
      if (lastName) frm.append('lastName', lastName);
      if (email) frm.append('email', email.toLowerCase());

      dispatch(editConnectedProfile({
        // _id: user_profile?._id,
        user_type: "ADMIN",
        data: frm
      }, (resp) => {
        setLoadingEditProfile(false);
        if (back_path && back_path !== "") history.push(back_path);
      }, (error) => {
        setLoadingEditProfile(false);
        window.scrollTo(0, 0)
        setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
      }))
    }

    else window.scrollTo(0, 0)
  }

  const submitChangePassword = () => {
    setLoadingEditPass(true);
    setMsgCheck('');

    dispatch(editPassword({
      data: { oldPassword, password, confirmPassword: confirm_pass }
    }, (resp) => {
      setLoadingEditPass(false);
      setPassword('')
      setOldPassword('')
      setConfirmPassword('')
      setShowConfirmMdl(true)
      // if (back_path && back_path !== "") history.push(back_path);
    }, (error) => {
      setLoadingEditPass(false);
      window.scrollTo(0, 0)
      setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
      setShowErrorMdl(true)
    }))
  }

  const onChangePicture = (e, key = "picture") => {
    if (e.target.files && e.target.files[0]) {
      switch (key) {
        case "picture":
          setPictureLink(URL.createObjectURL(e.target.files[0]))
          setPicture(e.target.files[0])
          break;
        default: break;
      }
    }
  };

  const renderImage = (img, img_url) => {
    if (img && img_url && img_url !== "") return (<img className="img" src={img_url} alt={"#"} />)
    if (!img && img_url && img_url !== "") {
      return (<img className="img" src={`${endpoints.root}/${user_profile?.profile.picture.path}`} alt={""} />)
    }

    return null
  }

  if (user_id !== user_profile?._id) return (<div className="page-content text-center">
    {loading_get_profile && <Spinner color="primary" />}
  </div>);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
            <i className="mdi mdi-block-helper me-2"></i>{msg_check}
          </UncontrolledAlert>}

          <Row>
            <Col md="12" className="hr-divider mb-4">
              <h4 className="divider-name">Informations Générales</h4>
              <hr />
            </Col>
            {/* UPLOAD IMAGE */}
            <Col md="3">
              <div className="attach-wrapper">
                <Label className="attach-label" htmlFor="logo-file">

                  {picture_link === "" ? <div className="center_both">
                    <i className="mdi mdi-camera icon-cam"></i>
                    <span className="attach-holder">Image </span>
                  </div> :
                    renderImage(picture, picture_link)}


                </Label>



                <input onChange={onChangePicture} id="logo-file" type="file" accept="image/*" />
              </div>
            </Col>

            <Col md="9">
              <Row>
                <Col md="6"><div className="mb-3">
                  <Label className="frm-label">Nom</Label>
                  <Input defaultValue={firstName} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Nom" />
                </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">Prénom</Label>
                    <Input defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} className="form-control" placeholder="Prénom" />
                  </div>
                </Col>



                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">E-mail</Label>
                    <Input defaultValue={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="E-mail de personel" />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="mt-5" style={{ textAlign: "right" }}>
            <Button onClick={() => {
              if (!loading_edit_profile) submitEdit()
            }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
              Enregistrer {(loading_edit_profile) && <Spinner className="ms-2 small-spinner" color="light" />}
            </Button>
          </div>
        </CardBody>
      </Card>


      <div>
        <hr />
        <Card>
          <CardHeader>
            <h3>Changer mot de passe</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Label>Ancien mot de passe</Label>
                <Input type="password" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
              </Col>
              <Col md="6">
                <Label>Nouveau mot de passe</Label>
                <Input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Col>
              <Col md="6" className="mt-3">
                <Label>Confirmation</Label>
                <Input type="password" className="form-control" value={confirm_pass} onChange={(e) => setConfirmPassword(e.target.value)} />
              </Col>

              <Col md="6" style={{ marginTop: 42 }}>
                <Button disabled={(confirm_pass !== password || !oldPassword || oldPassword === "" || password==="")} onClick={() => {
                  if (!loading_edit_pass) submitChangePassword()
                }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
                  Changer {(loading_edit_pass) && <Spinner className="ms-2 small-spinner" color="light" />}
                </Button>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>
      {showConfirmMdl ? (
        <SweetAlert
        style={{ height: "auto" }}
          success
          title={"Modifié"}
          onConfirm={() => {
            console.log("confirm")
            setShowConfirmMdl(false)
          }}
        >
          {"Mot de passe changé avec succes !"}
        </SweetAlert>
      ) : null}

      {showErrorMdl ? (
        <SweetAlert
        style={{ height: "auto" }}
          danger
          
          title={"Erreur"}
          onConfirm={() => {
            console.log("error")
            setShowErrorMdl(false)
          }}
        >
          {"Erreur lors de la modification du mot de passe  !"}
        </SweetAlert>
      ) : null}

    </React.Fragment >
  )
}
EditProfileCmp.propTypes = {
  user_id: PropTypes.string.isRequired,
  back_path: PropTypes.string,

  is_profile: PropTypes.bool,
}
export default EditProfileCmp