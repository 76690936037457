import ClotheSrv from "services/ClotheSrv";

import {
  GET_ClOTHES,
  GET_ClOTHES_FAIL,
  GET_ClOTHES_SUCCESS,

  GET_ONE_ClOTHE,
  GET_ONE_ClOTHE_SUCCESS,
  GET_ONE_ClOTHE_FAIL,

  CREATE_ClOTHE,
  CREATE_ClOTHE_SUCCESS,
  CREATE_ClOTHE_FAIL,

  PATCH_ClOTHE,
  PATCH_ClOTHE_SUCCESS,
  PATCH_ClOTHE_FAIL,

  DELETE_ClOTHE,
  DELETE_ClOTHE_SUCCESS,
  DELETE_ClOTHE_FAIL,


  GET_STAFF_ClOTHES,
  GET_STAFF_ClOTHES_FAIL,
  GET_STAFF_ClOTHES_SUCCESS,
  
  CREATE_STAFF_ClOTHE,
  CREATE_STAFF_ClOTHE_SUCCESS,
  CREATE_STAFF_ClOTHE_FAIL,

  PATCH_STAFF_ClOTHE,
  PATCH_STAFF_ClOTHE_SUCCESS,
  PATCH_STAFF_ClOTHE_FAIL,

  DELETE_STAFF_ClOTHE,
  DELETE_STAFF_ClOTHE_SUCCESS,
  DELETE_STAFF_ClOTHE_FAIL,
} from "./actionTypes"

// =============== GET LIST
export const getListClothes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ClOTHES,
    });

    ClotheSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothes: response.data.data.clothingTypes,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_ClOTHES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ClOTHES_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ClOTHE,
    });

    ClotheSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothe: response.data.data.clothingType,
          }

          dispatch({
            type: CREATE_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ClOTHE,
    });

    ClotheSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothe: response.data.data.clothingType
          }

          dispatch({
            type: GET_ONE_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ClOTHE,
    });

    ClotheSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothe: response.data.data.clothingType,
          }

          dispatch({
            type: PATCH_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ClOTHE,
    });

    ClotheSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};







// ************************************** STAFF CLOTHES *********************************************


// =============== GET STAFF CLOTHES
export const getListStaffClothes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_STAFF_ClOTHES,
    });

    ClotheSrv.getStaffClothes(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            staff_clothes: response.data.data.clothingsStaff,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_STAFF_ClOTHES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_STAFF_ClOTHES_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createStaffClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_STAFF_ClOTHE,
    });

    ClotheSrv.addStaffClothe(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothe: response.data.data.clothingType,
          }

          dispatch({
            type: CREATE_STAFF_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_STAFF_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editStaffClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_STAFF_ClOTHE,
    });

    ClotheSrv.patchStaffClothe(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clothe: response.data.data.clothingType,
          }

          dispatch({
            type: PATCH_STAFF_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_STAFF_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteStaffClothe = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_STAFF_ClOTHE,
    });

    ClotheSrv.removeStaffClothe(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_STAFF_ClOTHE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_STAFF_ClOTHE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};