import {
  GET_REPORTS,
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,

  GET_ONE_REPORT,
  GET_ONE_REPORT_SUCCESS,
  GET_ONE_REPORT_FAIL,

  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAIL,

  PATCH_REPORT,
  PATCH_REPORT_SUCCESS,
  PATCH_REPORT_FAIL,

  DELETE_REPORT,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  report: null,
  reports: [],
  error: null,

  loading_get_reports: false,
  loading_get_report: false,

  loading_add_report: false,
  loading_edit_report: false,

  count_all_reports: 0,
}

const ReportState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_REPORTS:
      return {
        ...state, loading_get_reports: true, error: null
      }
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports,
        loading_get_reports: false, error: null,
        count_all_reports: action.payload.counts || 0
      }
    case GET_REPORTS_FAIL:
      return {
        ...state, reports: [],
        loading_get_reports: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_REPORT:
      return {
        ...state, report: null, loading_get_report: true, error: null
      }
    case GET_ONE_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        loading_get_report: false, error: null
      }
    case GET_ONE_REPORT_FAIL:
      return {
        ...state,
        loading_get_report: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_REPORT:
      return {
        ...state, report: null, loading_add_report: true, error: null
      }
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        loading_add_report: false, error: null
      }
    case CREATE_REPORT_FAIL:
      return {
        ...state,
        loading_add_report: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_REPORT:
      return {
        ...state, loading_edit_report: true, error: null
      }
    case PATCH_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        loading_edit_report: false, error: null
      }
    case PATCH_REPORT_FAIL:
      return {
        ...state,
        loading_edit_report: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_REPORT:
      return {
        ...state, report: null, loading_delete_report: true, error: null
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        loading_delete_report: false, error: null
      }
    case DELETE_REPORT_FAIL:
      return {
        ...state,
        loading_delete_report: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ReportState
