import {
  GET_SUPPLYS,
  GET_SUPPLYS_FAIL,
  GET_SUPPLYS_SUCCESS,

  GET_ONE_SUPPLY,
  GET_ONE_SUPPLY_SUCCESS,
  GET_ONE_SUPPLY_FAIL,

  CREATE_SUPPLY,
  CREATE_SUPPLY_SUCCESS,
  CREATE_SUPPLY_FAIL,

  PATCH_SUPPLY,
  PATCH_SUPPLY_SUCCESS,
  PATCH_SUPPLY_FAIL,

  DELETE_SUPPLY,
  DELETE_SUPPLY_SUCCESS,
  DELETE_SUPPLY_FAIL,

  DELETE_TRACEABILITY,
  DELETE_TRACEABILITY_SUCCESS,
  DELETE_TRACEABILITY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  supply: null,
  supplies: [],
  error: null,

  loading_get_supplies: false,
  loading_get_supply: false,

  loading_add_supply: false,
  loading_edit_supply: false,

  count_all_supplies: 0,
}

const SupplyState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_SUPPLYS:
      return {
        ...state, loading_get_supplies: true, error: null
      }
    case GET_SUPPLYS_SUCCESS:
      return {
        ...state,
        supplies: action.payload.supplies,
        loading_get_supplies: false, error: null,
        count_all_supplies: action.payload.counts || 0
      }
    case GET_SUPPLYS_FAIL:
      return {
        ...state,
        loading_get_supplies: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_SUPPLY:
      return {
        ...state, supply: null, loading_get_supply: true, error: null
      }
    case GET_ONE_SUPPLY_SUCCESS:
      return {
        ...state,
        supply: action.payload.supply,
        loading_get_supply: false, error: null
      }
    case GET_ONE_SUPPLY_FAIL:
      return {
        ...state,
        loading_get_supply: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_SUPPLY:
      return {
        ...state, supply: null, loading_add_supply: true, error: null
      }
    case CREATE_SUPPLY_SUCCESS:
      return {
        ...state,
        supply: action.payload.supply,
        loading_add_supply: false, error: null
      }
    case CREATE_SUPPLY_FAIL:
      return {
        ...state,
        loading_add_supply: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_SUPPLY:
      return {
        ...state, loading_edit_supply: true, error: null
      }
    case PATCH_SUPPLY_SUCCESS:
      return {
        ...state,
        supply: action.payload.supply,
        loading_edit_supply: false, error: null
      }
    case PATCH_SUPPLY_FAIL:
      return {
        ...state,
        loading_edit_supply: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_SUPPLY:
      return {
        ...state, supply: null, loading_delete_supply: true, error: null
      }
    case DELETE_SUPPLY_SUCCESS:
      return {
        ...state,
        supply: action.payload.supply,
        loading_delete_supply: false, error: null
      }
    case DELETE_SUPPLY_FAIL:
      return {
        ...state,
        loading_delete_supply: false,
        error: action.payload?.error || {}
      }

    // =============== DELETE TRACEABILITY
    case DELETE_TRACEABILITY:
      return {
        ...state, traceability: null, loading_delete_traceability: true, error: null
      }
    case DELETE_TRACEABILITY_SUCCESS:
      return {
        ...state,
        traceability: action.payload.traceability,
        loading_delete_traceability: false, error: null
      }
    case DELETE_TRACEABILITY_FAIL:
      return {
        ...state,
        loading_delete_traceability: false,
        error: action.payload?.error || {}
      }


    default:
      return state
  }
}

export default SupplyState
