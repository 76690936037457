import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { NavLink } from "react-router-dom"

import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"

//ACTIONS
import { getCounts } from "store/actions"

// COMPONENTS
import DashCardCmp from "../DashCardCmp"
import ListSubscribersCmp from "components/Modules/Subscribers/ListSubscribersCmp"
import ListSubscriptionsGridCmp from "components/Modules/Subscription/ListSubscriptionsGridCmp"
import ListInscriptionsGridCmp from "components/Modules/Inscription/ListInscriptionsGridCmp"
import UserSubscriptionsPage from "pages/UserSubscriptions/list-user-subscription"

const SuperAdminDashCmp = props => {
  // HOOKS
  const dispatch = useDispatch()

  const { counts, loadingCounts } = useSelector(state => state.CountState)
  useEffect(() => {
    dispatch(
      getCounts(
        {
          countBody: [
            {
              model: "Company",
            },
            {
              model: "User",
            },
            {
              model: "User",
              filter: {
                userType: "CLIENT",
              },
            },
            {
              model: "User",
              filter: {
                userType: "PROVIDER",
              },
            },
            {
              model: "User",
              filter: {
                userType: "STAFF",
              },
            },
            {
              model: "Subscription",
            },
            {
              model: "UserSubscription",
            },
            {
              model: "STAFF",
            },
          ],
        },
        () => null,
        () => null
      )
    )
  }, [])

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md="6">
          <DashCardCmp
            name="Utilisateurs"
            icon="mdi mdi-account-group-outline"
            value={counts[1]?.count + " Utilisateurs"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name="Compagnies"
            icon="mdi mdi-briefcase"
            value={counts[0]?.count + " Compagnies"}
            addition="+3 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name="Clients"
            icon="mdi mdi-account-multiple"
            value={counts[2]?.count + " Clients"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="3">
          <DashCardCmp
            name="Abonnements"
            icon="mdi mdi-credit-card-multiple"
            value={counts[5]?.count + " Abonnements"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name="Abonnés"
            icon="mdi mdi-account-multiple"
            value={counts[6]?.count + " Abonnés"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name="Fournisseurs"
            icon="mdi mdi-account-multiple"
            value={counts[3]?.count+ " Fournisseurs"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
        <Col md="3">
          <DashCardCmp
            name="Personnel"
            icon="mdi mdi-account-multiple"
            value={counts[4]?.count + " Personnel"}
            addition="+2 ce mois"
            loading={loadingCounts}
          />
        </Col>
      </Row>

      
      {/* <Col md="12">
      <h4>Abonnés</h4>
      <ListSubscribersCmp />
      </Col> */}

      <Col md="12">
        <h4>Abonnements</h4>
        <hr />
        <UserSubscriptionsPage />
      </Col>
    </React.Fragment>
  )
}
SuperAdminDashCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_modules: PropTypes.array,
}

export default SuperAdminDashCmp
