/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

export const GET_ONE_PROJECT = "GET_ONE_PROJECT"
export const GET_ONE_PROJECT_SUCCESS = "GET_ONE_PROJECT_SUCCESS"
export const GET_ONE_PROJECT_FAIL = "GET_ONE_PROJECT_FAIL"

export const CREATE_PROJECT = "CREATE_PROJECT"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL"

export const PATCH_PROJECT = "PATCH_PROJECT"
export const PATCH_PROJECT_SUCCESS = "PATCH_PROJECT_SUCCESS"
export const PATCH_PROJECT_FAIL = "PATCH_PROJECT_FAIL"

export const GET_PROJECT_PLANING = "GET_PROJECT_PLANING"
export const GET_PROJECT_PLANING_SUCCESS = "GET_PROJECT_PLANING_SUCCESS"
export const GET_PROJECT_PLANING_FAIL = "GET_PROJECT_PLANING_FAIL"

export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"


export const GET_PROJECT_USERS = "GET_PROJECT_USERS"
export const GET_PROJECT_USERS_SUCCESS = "GET_PROJECT_USERS_SUCCESS"
export const GET_PROJECT_USERS_FAIL = "GET_PROJECT_USERS_FAIL"


// INCOMES
export const GET_PROJECT_INCOMES = "GET_PROJECT_INCOMES"
export const GET_PROJECT_INCOMES_SUCCESS = "GET_PROJECT_INCOMES_SUCCESS"
export const GET_PROJECT_INCOMES_FAIL = "GET_PROJECT_INCOMES_FAIL"

export const CREATE_INCOME = "CREATE_INCOME"
export const CREATE_INCOME_SUCCESS = "CREATE_INCOME_SUCCESS"
export const CREATE_INCOME_FAIL = "CREATE_INCOME_FAIL"

export const PATCH_INCOME = "PATCH_INCOME"
export const PATCH_INCOME_SUCCESS = "PATCH_INCOME_SUCCESS"
export const PATCH_INCOME_FAIL = "PATCH_INCOME_FAIL"

export const DELETE_INCOME = "DELETE_INCOME"
export const DELETE_INCOME_SUCCESS = "DELETE_INCOME_SUCCESS"
export const DELETE_INCOME_FAIL = "DELETE_INCOME_FAIL"

// ADDITION EXPENSES
export const CREATE_ADDITION_EXPENSE = "CREATE_ADDITION_EXPENSE"
export const CREATE_ADDITION_EXPENSE_SUCCESS = "CREATE_ADDITION_EXPENSE_SUCCESS"
export const CREATE_ADDITION_EXPENSE_FAIL = "CREATE_ADDITION_EXPENSE_FAIL"

export const GET_PROJECT_ADDITION_EXPENSES = "GET_PROJECT_ADDITION_EXPENSES"
export const GET_PROJECT_ADDITION_EXPENSES_SUCCESS = "GET_PROJECT_ADDITION_EXPENSES_SUCCESS"
export const GET_PROJECT_ADDITION_EXPENSES_FAIL = "GET_PROJECT_ADDITION_EXPENSES_FAIL"

export const PATCH_ADDITION_EXPENSE = "PATCH_ADDITION_EXPENSE"
export const PATCH_ADDITION_EXPENSE_SUCCESS = "PATCH_ADDITION_EXPENSE_SUCCESS"
export const PATCH_ADDITION_EXPENSE_FAIL = "PATCH_ADDITION_EXPENSE_FAIL"

export const DELETE_ADDITION_EXPENSE = "DELETE_ADDITION_EXPENSE"
export const DELETE_ADDITION_EXPENSE_SUCCESS = "DELETE_ADDITION_EXPENSE_SUCCESS"
export const DELETE_ADDITION_EXPENSE_FAIL = "DELETE_ADDITION_EXPENSE_FAIL"

// EXPENSES
export const GET_PROJECT_EXPENSES = "GET_PROJECT_EXPENSES"
export const GET_PROJECT_EXPENSES_SUCCESS = "GET_PROJECT_EXPENSES_SUCCESS"
export const GET_PROJECT_EXPENSES_FAIL = "GET_PROJECT_EXPENSES_FAIL"


// PHASES
export const GET_PROJECT_PHASES = "GET_PROJECT_PHASES"
export const GET_PROJECT_PHASES_SUCCESS = "GET_PROJECT_PHASES_SUCCESS"
export const GET_PROJECT_PHASES_FAIL = "GET_PROJECT_PHASES_FAIL"



// ARCHIVES
export const GET_PROJECT_ARCHIVES = "GET_PROJECT_ARCHIVES"
export const GET_PROJECT_ARCHIVES_SUCCESS = "GET_PROJECT_ARCHIVES_SUCCESS"
export const GET_PROJECT_ARCHIVES_FAIL = "GET_PROJECT_ARCHIVES_FAIL"

export const CREATE_ARCHIVE = "CREATE_ARCHIVE"
export const CREATE_ARCHIVE_SUCCESS = "CREATE_ARCHIVE_SUCCESS"
export const CREATE_ARCHIVE_FAIL = "CREATE_ARCHIVE_FAIL"

export const PATCH_ARCHIVE = "PATCH_ARCHIVE"
export const PATCH_ARCHIVE_SUCCESS = "PATCH_ARCHIVE_SUCCESS"
export const PATCH_ARCHIVE_FAIL = "PATCH_ARCHIVE_FAIL"

export const DELETE_ARCHIVE = "DELETE_ARCHIVE"
export const DELETE_ARCHIVE_SUCCESS = "DELETE_ARCHIVE_SUCCESS"
export const DELETE_ARCHIVE_FAIL = "DELETE_ARCHIVE_FAIL"