/* ARTICLES */
export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS"
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL"

export const GET_ONE_ARTICLE = "GET_ONE_ARTICLE"
export const GET_ONE_ARTICLE_SUCCESS = "GET_ONE_ARTICLE_SUCCESS"
export const GET_ONE_ARTICLE_FAIL = "GET_ONE_ARTICLE_FAIL"

export const CREATE_ARTICLE = "CREATE_ARTICLE"
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS"
export const CREATE_ARTICLE_FAIL = "CREATE_ARTICLE_FAIL"

export const PATCH_ARTICLE = "PATCH_ARTICLE"
export const PATCH_ARTICLE_SUCCESS = "PATCH_ARTICLE_SUCCESS"
export const PATCH_ARTICLE_FAIL = "PATCH_ARTICLE_FAIL"

export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS"
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL"

/* ********************** ARTICLE SUPPLYs ********************** */

export const GET_ARTICLE_SUPPLIES = "GET_ARTICLE_SUPPLIES"
export const GET_ARTICLE_SUPPLIES_SUCCESS = "GET_ARTICLE_SUPPLIES_SUCCESS"
export const GET_ARTICLE_SUPPLIES_FAIL = "GET_ARTICLE_SUPPLIES_FAIL"

export const GET_ONE_ARTICLE_SUPPLY = "GET_ONE_ARTICLE_SUPPLY"
export const GET_ONE_ARTICLE_SUPPLY_SUCCESS = "GET_ONE_ARTICLE_SUPPLY_SUCCESS"
export const GET_ONE_ARTICLE_SUPPLY_FAIL = "GET_ONE_ARTICLE_SUPPLY_FAIL"

export const CREATE_ARTICLE_SUPPLY = "CREATE_ARTICLE_SUPPLY"
export const CREATE_ARTICLE_SUPPLY_SUCCESS = "CREATE_ARTICLE_SUPPLY_SUCCESS"
export const CREATE_ARTICLE_SUPPLY_FAIL = "CREATE_ARTICLE_SUPPLY_FAIL"

export const PATCH_ARTICLE_SUPPLY = "PATCH_ARTICLE_SUPPLY"
export const PATCH_ARTICLE_SUPPLY_SUCCESS = "PATCH_ARTICLE_SUPPLY_SUCCESS"
export const PATCH_ARTICLE_SUPPLY_FAIL = "PATCH_ARTICLE_SUPPLY_FAIL"

export const DELETE_ARTICLE_SUPPLY = "DELETE_ARTICLE_SUPPLY"
export const DELETE_ARTICLE_SUPPLY_SUCCESS = "DELETE_ARTICLE_SUPPLY_SUCCESS"
export const DELETE_ARTICLE_SUPPLY_FAIL = "DELETE_ARTICLE_SUPPLY_FAIL"


/* ********************** ARTICLE MATERIALs ********************** */

export const GET_ARTICLE_MATERIALS = "GET_ARTICLE_MATERIALS"
export const GET_ARTICLE_MATERIALS_SUCCESS = "GET_ARTICLE_MATERIALS_SUCCESS"
export const GET_ARTICLE_MATERIALS_FAIL = "GET_ARTICLE_MATERIALS_FAIL"

export const GET_ONE_ARTICLE_MATERIAL = "GET_ONE_ARTICLE_MATERIAL"
export const GET_ONE_ARTICLE_MATERIAL_SUCCESS = "GET_ONE_ARTICLE_MATERIAL_SUCCESS"
export const GET_ONE_ARTICLE_MATERIAL_FAIL = "GET_ONE_ARTICLE_MATERIAL_FAIL"

export const CREATE_ARTICLE_MATERIAL = "CREATE_ARTICLE_MATERIAL"
export const CREATE_ARTICLE_MATERIAL_SUCCESS = "CREATE_ARTICLE_MATERIAL_SUCCESS"
export const CREATE_ARTICLE_MATERIAL_FAIL = "CREATE_ARTICLE_MATERIAL_FAIL"

export const PATCH_ARTICLE_MATERIAL = "PATCH_ARTICLE_MATERIAL"
export const PATCH_ARTICLE_MATERIAL_SUCCESS = "PATCH_ARTICLE_MATERIAL_SUCCESS"
export const PATCH_ARTICLE_MATERIAL_FAIL = "PATCH_ARTICLE_MATERIAL_FAIL"

export const DELETE_ARTICLE_MATERIAL = "DELETE_ARTICLE_MATERIAL"
export const DELETE_ARTICLE_MATERIAL_SUCCESS = "DELETE_ARTICLE_MATERIAL_SUCCESS"
export const DELETE_ARTICLE_MATERIAL_FAIL = "DELETE_ARTICLE_MATERIAL_FAIL"








/* ********************** ARTICLE LABORs ********************** */

export const GET_ARTICLE_LABORS = "GET_ARTICLE_LABORS"
export const GET_ARTICLE_LABORS_SUCCESS = "GET_ARTICLE_LABORS_SUCCESS"
export const GET_ARTICLE_LABORS_FAIL = "GET_ARTICLE_LABORS_FAIL"

export const GET_ONE_ARTICLE_LABOR = "GET_ONE_ARTICLE_LABOR"
export const GET_ONE_ARTICLE_LABOR_SUCCESS = "GET_ONE_ARTICLE_LABOR_SUCCESS"
export const GET_ONE_ARTICLE_LABOR_FAIL = "GET_ONE_ARTICLE_LABOR_FAIL"

export const CREATE_ARTICLE_LABOR = "CREATE_ARTICLE_LABOR"
export const CREATE_ARTICLE_LABOR_SUCCESS = "CREATE_ARTICLE_LABOR_SUCCESS"
export const CREATE_ARTICLE_LABOR_FAIL = "CREATE_ARTICLE_LABOR_FAIL"

export const PATCH_ARTICLE_LABOR = "PATCH_ARTICLE_LABOR"
export const PATCH_ARTICLE_LABOR_SUCCESS = "PATCH_ARTICLE_LABOR_SUCCESS"
export const PATCH_ARTICLE_LABOR_FAIL = "PATCH_ARTICLE_LABOR_FAIL"

export const DELETE_ARTICLE_LABOR = "DELETE_ARTICLE_LABOR"
export const DELETE_ARTICLE_LABOR_SUCCESS = "DELETE_ARTICLE_LABOR_SUCCESS"
export const DELETE_ARTICLE_LABOR_FAIL = "DELETE_ARTICLE_LABOR_FAIL"









/* ********************** ARTICLE ARTICLEs ********************** */

export const GET_ARTICLE_ARTICLES = "GET_ARTICLE_ARTICLES"
export const GET_ARTICLE_ARTICLES_SUCCESS = "GET_ARTICLE_ARTICLES_SUCCESS"
export const GET_ARTICLE_ARTICLES_FAIL = "GET_ARTICLE_ARTICLES_FAIL"

export const GET_ONE_ARTICLE_ARTICLE = "GET_ONE_ARTICLE_ARTICLE"
export const GET_ONE_ARTICLE_ARTICLE_SUCCESS = "GET_ONE_ARTICLE_ARTICLE_SUCCESS"
export const GET_ONE_ARTICLE_ARTICLE_FAIL = "GET_ONE_ARTICLE_ARTICLE_FAIL"

export const CREATE_ARTICLE_ARTICLE = "CREATE_ARTICLE_ARTICLE"
export const CREATE_ARTICLE_ARTICLE_SUCCESS = "CREATE_ARTICLE_ARTICLE_SUCCESS"
export const CREATE_ARTICLE_ARTICLE_FAIL = "CREATE_ARTICLE_ARTICLE_FAIL"

export const PATCH_ARTICLE_ARTICLE = "PATCH_ARTICLE_ARTICLE"
export const PATCH_ARTICLE_ARTICLE_SUCCESS = "PATCH_ARTICLE_ARTICLE_SUCCESS"
export const PATCH_ARTICLE_ARTICLE_FAIL = "PATCH_ARTICLE_ARTICLE_FAIL"

export const DELETE_ARTICLE_ARTICLE = "DELETE_ARTICLE_ARTICLE"
export const DELETE_ARTICLE_ARTICLE_SUCCESS = "DELETE_ARTICLE_ARTICLE_SUCCESS"
export const DELETE_ARTICLE_ARTICLE_FAIL = "DELETE_ARTICLE_ARTICLE_FAIL"


export const GET_ARTICLE_PARENTS = "GET_ARTICLE_PARENTS"
export const GET_ARTICLE_PARENTS_SUCCESS = "GET_ARTICLE_PARENTS_SUCCESS"
export const GET_ARTICLE_PARENTS_FAIL = "GET_ARTICLE_PARENTS_FAIL"