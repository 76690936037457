import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListSubscriptionsPage = React.lazy(() => import('./list-subscriptions.page'));
const PreviewSubscriptionPage = React.lazy(() => import('./preview-subscription.page'));
const EditSubscriptionPage = React.lazy(() => import('./edit-subscription.page'));
const EditUserSubscriptionPage= React.lazy(() => import('./edit-user-subscription.page'));
const PreviewSubSubscriptionPage= React.lazy(() => import('./preview-sub-subscription'));

const Subscriptions = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListSubscriptionsPage {...props} />}
      />

      <Route path={`${match.url}/preview/:subscriptionId`}
        render={props => <PreviewSubscriptionPage {...props} />}
      />

     <Route path={`${match.url}/preview-sub/:subscriptionId`}
        render={props => <PreviewSubSubscriptionPage {...props} />}
      />
      

      <Route path={`${match.url}/edit/:subscriptionId`}
        render={props => <EditSubscriptionPage {...props} />}
      />
      <Route path={`${match.url}/user/edit/:subscriptionId`}
        render={props => <EditUserSubscriptionPage {...props} />}
      />
      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Subscriptions;