/* MOVEMENTS */
export const GET_MOVEMENTS = "GET_MOVEMENTS"
export const GET_MOVEMENTS_SUCCESS = "GET_MOVEMENTS_SUCCESS"
export const GET_MOVEMENTS_FAIL = "GET_MOVEMENTS_FAIL"

export const GET_ONE_MOVEMENT = "GET_ONE_MOVEMENT"
export const GET_ONE_MOVEMENT_SUCCESS = "GET_ONE_MOVEMENT_SUCCESS"
export const GET_ONE_MOVEMENT_FAIL = "GET_ONE_MOVEMENT_FAIL"

export const CREATE_MOVEMENT = "CREATE_MOVEMENT"
export const CREATE_MOVEMENT_SUCCESS = "CREATE_MOVEMENT_SUCCESS"
export const CREATE_MOVEMENT_FAIL = "CREATE_MOVEMENT_FAIL"

export const PATCH_MOVEMENT = "PATCH_MOVEMENT"
export const PATCH_MOVEMENT_SUCCESS = "PATCH_MOVEMENT_SUCCESS"
export const PATCH_MOVEMENT_FAIL = "PATCH_MOVEMENT_FAIL"

export const DELETE_MOVEMENT = "DELETE_MOVEMENT"
export const DELETE_MOVEMENT_SUCCESS = "DELETE_MOVEMENT_SUCCESS"
export const DELETE_MOVEMENT_FAIL = "DELETE_MOVEMENT_FAIL"


export const GET_EQUIPMENTS = "GET_EQUIPMENTS"
export const GET_EQUIPMENTS_SUCCESS = "GET_EQUIPMENTS_SUCCESS"
export const GET_EQUIPMENTS_FAIL = "GET_EQUIPMENTS_FAIL"

export const RESTORE_MOVEMENT = "RESTORE_MOVEMENT"
export const RESTORE_MOVEMENT_SUCCESS = "RESTORE_MOVEMENT_SUCCESS"
export const RESTORE_MOVEMENT_FAIL = "RESTORE_MOVEMENT_FAIL"