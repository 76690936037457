import TaskSrv from "services/TaskSrv"
import {
  GET_TASKS_PLANNING,
  GET_TASKS_PLANNING_SUCCESS,
  GET_TASKS_PLANNING_FAIL,
  UPDATE_TASK_PLANNING,
  UPDATE_TASK_PLANNING_SUCCESS,
  UPDATE_TASK_PLANNING_FAIL,
  GET_TASKS_SUCCESS,
  GET_TASKS,
  GET_TASKS_FAIL,
  UPDATE_TASK_PLANNING_ADDRESS,
  UPDATE_TASK_PLANNING_ADDRESS_SUCCESS,
  UPDATE_TASK_PLANNING_ADDRESS_FAIL,
  CUT_TASK,
  CUT_TASK_SUCCESS,
  CUT_TASK_FAIL,
  GET_ONE_TASK,
  GET_ONE_TASK_FAIL,
  GET_ONE_TASK_SUCCESS,


} from "./actionTypes"

export const getTasksPlanning = (payload, callback, callbackError) => {
  // console.log("****", { payload, callback, callbackError });

  return dispatch => {
    dispatch({
      type: GET_TASKS_PLANNING,
    })

    TaskSrv.getTasksPlanning(payload)
      .then(response => {
        if (response && response?.data?.success) {
          const resp = {
            tasks: response.data.data.tasks || [],
            steps: response.data.data.steps || [],
            projects: response.data.data.projects || [],
            staff: response.data.data.staff || [],
            counts: response.data.data.counts,
          }

          dispatch({
            type: GET_TASKS_PLANNING_SUCCESS,
            payload: resp,
          })

          callback(resp)
        } else {
          if (callbackError) callbackError({ error: response?.data.error })
        }
      })
      .catch(e => {
        dispatch({
          type: GET_TASKS_PLANNING_FAIL,
          payload: { error: {} },
        })
        if (callbackError) callbackError(e?.response?.data?.error)
      })
  }
}

export const getTasks = (payload, callback, callbackError) => {
  // console.log("****", { payload, callback, callbackError });

  return dispatch => {
    dispatch({
      type: GET_TASKS,
    })

    TaskSrv.getTasks(payload)
      .then(response => {
        dispatch({
          type: GET_TASKS_SUCCESS,
          payload: response,
        })

        callback(response)
      })
      .catch(e => {
        dispatch({
          type: GET_TASKS_FAIL,
          payload: { error: {} },
        })
        if (callbackError) callbackError(e?.response?.data?.error)
      })
  }
}

export const updateTasksPlanning = (payload, callback, callbackError) => {
  // console.log("****", { payload, callback, callbackError });
  return dispatch => {
    dispatch({
      type: UPDATE_TASK_PLANNING,
    })

    TaskSrv.updateTasksPlanning(payload)
      .then(response => {
        dispatch({
          type: UPDATE_TASK_PLANNING_SUCCESS,
          payload: response,
        })
        callback(response)
      })
      .catch(e => {
        dispatch({
          type: UPDATE_TASK_PLANNING_FAIL,
          payload: { error: {} },
        })
        if (callbackError) callbackError(e?.response?.data?.error)
      })
  }
}

export const cutTask = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: CUT_TASK,
    });

    TaskSrv.cutTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            task: response.data.data.task,
          }
          dispatch({
            type: CUT_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        console.log('resp =============++>', e);

        dispatch({
          type: CUT_TASK_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
}

export const getOneTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_TASK,
    });

    TaskSrv.getOneTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            task: response.data.data.task || []
          }

          dispatch({
            type: GET_ONE_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_TASK_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



export const updateTasksPlanningAddress = (payload, callback, callbackError) => {
  // console.log("****", { payload, callback, callbackError });
  return dispatch => {
    dispatch({
      type: UPDATE_TASK_PLANNING_ADDRESS,
    })

    TaskSrv.updateTasksPlanningAddress(payload)
      .then(response => {
        dispatch({
          type: UPDATE_TASK_PLANNING_ADDRESS_SUCCESS,
          payload: response,
        })
        callback(response)
      })
      .catch(e => {
        dispatch({
          type: UPDATE_TASK_PLANNING_ADDRESS_FAIL,
          payload: { error: {} },
        })
        if (callbackError) callbackError(e?.response?.data?.error)
      })
  }
}

export const getTaskDate = async (payload) => {
  return TaskSrv.getDates(payload)
    .then(response => response?.data?.data?.task)
    .catch(e => {
      console.log(e)
    })
}

export const getTaskDateRanges = (payload, callback, callbackError) => {
  return dispatch => {
    TaskSrv.getDateRanges(payload)
      .then(response => {
        callback(response)
      })
      .catch(e => {
        if (callbackError) callbackError(e?.response)
      })
  }
}