import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,

  GET_ONE_PERMISSION,
  GET_ONE_PERMISSION_SUCCESS,
  GET_ONE_PERMISSION_FAIL,

  CREATE_PERMISSION,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,

  PATCH_PERMISSION,
  PATCH_PERMISSION_SUCCESS,
  PATCH_PERMISSION_FAIL,

  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  permission: null,
  permissions: [],
  error: null,

  loading_get_permissions: false,
  loading_get_permission: false,

  loading_add_permission: false,
  loading_edit_permission: false,

  count_all_permissions: 0,
}

const PermissionState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_PERMISSIONS:
      return {
        ...state, loading_get_permissions: true, error: null
      }
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.permissions,
        loading_get_permissions: false, error: null,
        count_all_permissions: action.payload.counts || 0
      }
    case GET_PERMISSIONS_FAIL:
      return {
        ...state, permissions: [],
        loading_get_permissions: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_PERMISSION:
      return {
        ...state, permission: null, loading_get_permission: true, error: null
      }
    case GET_ONE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload.permission,
        loading_get_permission: false, error: null
      }
    case GET_ONE_PERMISSION_FAIL:
      return {
        ...state,
        loading_get_permission: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_PERMISSION:
      return {
        ...state, permission: null, loading_add_permission: true, error: null
      }
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload.permission,
        loading_add_permission: false, error: null
      }
    case CREATE_PERMISSION_FAIL:
      return {
        ...state,
        loading_add_permission: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_PERMISSION:
      return {
        ...state, loading_edit_permission: true, error: null
      }
    case PATCH_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload.permission,
        loading_edit_permission: false, error: null
      }
    case PATCH_PERMISSION_FAIL:
      return {
        ...state,
        loading_edit_permission: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_PERMISSION:
      return {
        ...state, permission: null, loading_delete_permission: true, error: null
      }
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload.permission,
        loading_delete_permission: false, error: null
      }
    case DELETE_PERMISSION_FAIL:
      return {
        ...state,
        loading_delete_permission: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default PermissionState
