import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListSuppliesPage = React.lazy(() => import('./list-supplies.page'));
const AddSupplyPage = React.lazy(() => import('./add-supply.page'));
const PreviewSupplyPage = React.lazy(() => import('./preview-supply.page'));
const EditSupplyPage = React.lazy(() => import('./edit-supply.page'));

const Resources = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListSuppliesPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddSupplyPage {...props} />}
      />


      <Route path={`${match.url}/preview/:supplyId`}
        render={props => <PreviewSupplyPage {...props} />}
      />

      <Route path={`${match.url}/edit/:supplyId`}
        render={props => <EditSupplyPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Resources;