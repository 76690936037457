import {
    GET_DEPENSES,
    GET_DEPENSES_FAIL,
    GET_DEPENSES_SUCCESS,
  
    GET_ONE_DEPENSE,
    GET_ONE_DEPENSE_SUCCESS,
    GET_ONE_DEPENSE_FAIL,
  
    CREATE_DEPENSE,
    CREATE_DEPENSE_SUCCESS,
    CREATE_DEPENSE_FAIL,
  
    PATCH_DEPENSE,
    PATCH_DEPENSE_SUCCESS,
    PATCH_DEPENSE_FAIL,
  
    DELETE_DEPENSE,
    DELETE_DEPENSE_SUCCESS,
    DELETE_DEPENSE_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    depense: null,
    depenses: [],
    error: null,
  
    loading_get_depenses: false,
    loading_get_depense: false,
  
    loading_add_depense: false,
    loading_edit_depense: false,
  
    count_all_depenses: 0,
  }
  
  const DepenseState = (state = INIT_STATE, action) => {
    switch (action.type) {
      // =============== GET LIST
      case GET_DEPENSES:
        return {
          ...state, loading_get_depenses: true, error: null
    
        }
      case GET_DEPENSES_SUCCESS:
        return {
          ...state,
          depenses: action.payload.depenses,
          loading_get_depenses: false, error: null,
          count_all_depenses: action.payload.counts || 0

        }
      case GET_DEPENSES_FAIL:
        return {
          ...state, depenses: [],
          loading_get_depenses: false,
          error: action.payload?.error || {}
        }
  
  
      // =============== GET ONE
      case GET_ONE_DEPENSE:
        return {
          ...state, depense: null, loading_get_depense: true, error: null
        }
      case GET_ONE_DEPENSE_SUCCESS:
        return {
          ...state,
          depense: action.payload.depense,
          loading_get_depense: false, error: null
        }
      case GET_ONE_DEPENSE_FAIL:
        return {
          ...state,
          loading_get_depense: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== CREATE
      case CREATE_DEPENSE:
        return {
          ...state, depense: null, loading_add_depense: true, error: null
        }
      case CREATE_DEPENSE_SUCCESS:
        return {
          ...state,
          depense: action.payload.depense,
          loading_add_depense: false, error: null
        }
      case CREATE_DEPENSE_FAIL:
        return {
          ...state,
          loading_add_depense: false,
          error: action.payload?.error || {}
        }
  
  
  
  
      // =============== PATCH
      case PATCH_DEPENSE:
        return {
          ...state, loading_edit_depense: true, error: null
        }
      case PATCH_DEPENSE_SUCCESS:
        return {
          ...state,
          depense: action.payload.depense,
          loading_edit_depense: false, error: null
        }
      case PATCH_DEPENSE_FAIL:
        return {
          ...state,
          loading_edit_depense: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== DELETE
      case DELETE_DEPENSE:
        return {
          ...state, depense: null, loading_delete_depense: true, error: null
        }
      case DELETE_DEPENSE_SUCCESS:
        return {
          ...state,
          depense: action.payload.depense,
          loading_delete_depense: false, error: null
        }
      case DELETE_DEPENSE_FAIL:
        return {
          ...state,
          loading_delete_depense: false,
          error: action.payload?.error || {}
        }
  
  
  
  
  
      default:
        return state
    }
  }
  
  export default DepenseState
  