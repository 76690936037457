/* ARTICLE_TYPES */
export const GET_ARTICLE_TYPES = "GET_ARTICLE_TYPES"
export const GET_ARTICLE_TYPES_SUCCESS = "GET_ARTICLE_TYPES_SUCCESS"
export const GET_ARTICLE_TYPES_FAIL = "GET_ARTICLE_TYPES_FAIL"

export const GET_ONE_ARTICLE_TYPE = "GET_ONE_ARTICLE_TYPE"
export const GET_ONE_ARTICLE_TYPE_SUCCESS = "GET_ONE_ARTICLE_TYPE_SUCCESS"
export const GET_ONE_ARTICLE_TYPE_FAIL = "GET_ONE_ARTICLE_TYPE_FAIL"

export const CREATE_ARTICLE_TYPE = "CREATE_ARTICLE_TYPE"
export const CREATE_ARTICLE_TYPE_SUCCESS = "CREATE_ARTICLE_TYPE_SUCCESS"
export const CREATE_ARTICLE_TYPE_FAIL = "CREATE_ARTICLE_TYPE_FAIL"

export const PATCH_ARTICLE_TYPE = "PATCH_ARTICLE_TYPE"
export const PATCH_ARTICLE_TYPE_SUCCESS = "PATCH_ARTICLE_TYPE_SUCCESS"
export const PATCH_ARTICLE_TYPE_FAIL = "PATCH_ARTICLE_TYPE_FAIL"

export const DELETE_ARTICLE_TYPE = "DELETE_ARTICLE_TYPE"
export const DELETE_ARTICLE_TYPE_SUCCESS = "DELETE_ARTICLE_TYPE_SUCCESS"
export const DELETE_ARTICLE_TYPE_FAIL = "DELETE_ARTICLE_TYPE_FAIL"