/* SUBSCRIPTIONS */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL"

export const GET_ONE_SUBSCRIPTION = "GET_ONE_SUBSCRIPTION"
export const GET_ONE_SUBSCRIPTION_SUCCESS = "GET_ONE_SUBSCRIPTION_SUCCESS"
export const GET_ONE_SUBSCRIPTION_FAIL = "GET_ONE_SUBSCRIPTION_FAIL"

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION"
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS"
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL"

export const PATCH_SUBSCRIPTION = "PATCH_SUBSCRIPTION"
export const PATCH_SUBSCRIPTION_SUCCESS = "PATCH_SUBSCRIPTION_SUCCESS"
export const PATCH_SUBSCRIPTION_FAIL = "PATCH_SUBSCRIPTION_FAIL"

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS"
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL"

export const GET_ACTIVE_SUBSCRIPTION = "GET_ACTIVE_SUBSCRIPTION"
export const GET_ACTIVE_SUBSCRIPTION_SUCCESS = "GET_ACTIVE_SUBSCRIPTION_SUCCESS"
export const GET_ACTIVE_SUBSCRIPTION_FAIL = "GET_ACTIVE_SUBSCRIPTION_FAIL"