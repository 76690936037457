import ActivitySrv from "services/ActivitySrv";
import {
  GET_ACTIVITYS,
  GET_ACTIVITYS_FAIL,
  GET_ACTIVITYS_SUCCESS,

  GET_ONE_ACTIVITY,
  GET_ONE_ACTIVITY_SUCCESS,
  GET_ONE_ACTIVITY_FAIL,

  CREATE_ACTIVITY,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,

  PATCH_ACTIVITY,
  PATCH_ACTIVITY_SUCCESS,
  PATCH_ACTIVITY_FAIL,

  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListActivities = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ACTIVITYS,
    });

    ActivitySrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            activities: response.data.data.activities,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_ACTIVITYS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ACTIVITYS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createActivity = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ACTIVITY,
    });

    ActivitySrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            activity: response.data.data.activity,
          }

          dispatch({
            type: CREATE_ACTIVITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ACTIVITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneActivity = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ACTIVITY,
    });

    ActivitySrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            activity: response.data.data.activity
          }

          dispatch({
            type: GET_ONE_ACTIVITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ACTIVITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editActivity = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ACTIVITY,
    });

    ActivitySrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            activity: response.data.data.activity,
          }

          dispatch({
            type: PATCH_ACTIVITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ACTIVITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteActivity = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ACTIVITY,
    });

    ActivitySrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ACTIVITY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ACTIVITY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};