import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListStocksPage = React.lazy(() => import('./list-stocks.page'));
const AddStockPage = React.lazy(() => import('./add-stock.page'));
const EditStockPage = React.lazy(() => import('./edit-stock.page'));
const PreviewStockPage = React.lazy(() => import('./preview-stock.page'));

const Stocks = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListStocksPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddStockPage {...props} />}
      />

      <Route path={`${match.url}/edit/:stockId`}
        render={props => <EditStockPage {...props} />}
      />


      <Route path={`${match.url}/preview/:stockId`}
        render={props => <PreviewStockPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Stocks;