/* BONUSS */
export const GET_BONUSS = "GET_BONUSS"
export const GET_BONUSS_SUCCESS = "GET_BONUSS_SUCCESS"
export const GET_BONUSS_FAIL = "GET_BONUSS_FAIL"

export const GET_ONE_BONUS = "GET_ONE_BONUS"
export const GET_ONE_BONUS_SUCCESS = "GET_ONE_BONUS_SUCCESS"
export const GET_ONE_BONUS_FAIL = "GET_ONE_BONUS_FAIL"

export const CREATE_BONUS = "CREATE_BONUS"
export const CREATE_BONUS_SUCCESS = "CREATE_BONUS_SUCCESS"
export const CREATE_BONUS_FAIL = "CREATE_BONUS_FAIL"

export const PATCH_BONUS = "PATCH_BONUS"
export const PATCH_BONUS_SUCCESS = "PATCH_BONUS_SUCCESS"
export const PATCH_BONUS_FAIL = "PATCH_BONUS_FAIL"

export const DELETE_BONUS = "DELETE_BONUS"
export const DELETE_BONUS_SUCCESS = "DELETE_BONUS_SUCCESS"
export const DELETE_BONUS_FAIL = "DELETE_BONUS_FAIL"

export const PRINT_BONUS = "PRINT_BONUS"
export const PRINT_BONUS_SUCCESS = "PRINT_BONUS_SUCCESS"
export const PRINT_BONUS_FAIL = "PRINT_BONUS_FAIL"

export const IMPORT_BONUSS = "IMPORT_BONUSS"
export const IMPORT_BONUSS_SUCCESS = "IMPORT_BONUSS_SUCCESS"
export const IMPORT_BONUSS_FAIL = "IMPORT_BONUSS_FAIL"