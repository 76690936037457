/* REPARATIONS */
export const GET_REPARATIONS = "GET_REPARATIONS"
export const GET_REPARATIONS_SUCCESS = "GET_REPARATIONS_SUCCESS"
export const GET_REPARATIONS_FAIL = "GET_REPARATIONS_FAIL"

export const GET_ONE_REPARATION = "GET_ONE_REPARATION"
export const GET_ONE_REPARATION_SUCCESS = "GET_ONE_REPARATION_SUCCESS"
export const GET_ONE_REPARATION_FAIL = "GET_ONE_REPARATION_FAIL"

export const CREATE_REPARATION = "CREATE_REPARATION"
export const CREATE_REPARATION_SUCCESS = "CREATE_REPARATION_SUCCESS"
export const CREATE_REPARATION_FAIL = "CREATE_REPARATION_FAIL"

export const PATCH_REPARATION = "PATCH_REPARATION"
export const PATCH_REPARATION_SUCCESS = "PATCH_REPARATION_SUCCESS"
export const PATCH_REPARATION_FAIL = "PATCH_REPARATION_FAIL"

export const DELETE_REPARATION = "DELETE_REPARATION"
export const DELETE_REPARATION_SUCCESS = "DELETE_REPARATION_SUCCESS"
export const DELETE_REPARATION_FAIL = "DELETE_REPARATION_FAIL"

export const GET_MACHIN_RESPONSABLES = "GET_MACHIN_RESPONSABLES"
export const GET_MACHIN_RESPONSABLES_SUCCESS = "GET_MACHIN_RESPONSABLES_SUCCESS"
export const GET_MACHIN_RESPONSABLES_FAIL = "GET_MACHIN_RESPONSABLES_FAIL"

