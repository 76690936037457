import {
  GET_MATERIAL_TYPES,
  GET_MATERIAL_TYPES_FAIL,
  GET_MATERIAL_TYPES_SUCCESS,

  GET_ONE_MATERIAL_TYPE,
  GET_ONE_MATERIAL_TYPE_SUCCESS,
  GET_ONE_MATERIAL_TYPE_FAIL,

  CREATE_MATERIAL_TYPE,
  CREATE_MATERIAL_TYPE_SUCCESS,
  CREATE_MATERIAL_TYPE_FAIL,

  PATCH_MATERIAL_TYPE,
  PATCH_MATERIAL_TYPE_SUCCESS,
  PATCH_MATERIAL_TYPE_FAIL,

  DELETE_MATERIAL_TYPE,
  DELETE_MATERIAL_TYPE_SUCCESS,
  DELETE_MATERIAL_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  material_type: null,
  material_types: [],
  error: null,

  loading_get_material_types: false,
  loading_get_material_type: false,

  loading_add_material_type: false,
  loading_edit_material_type: false,

  count_all_material_types: 0,
}

const MaterialTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MATERIAL_TYPES:
      return {
        ...state, loading_get_material_types: true, error: null
      }
    case GET_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        material_types: action.payload.material_types,
        loading_get_material_types: false, error: null,
        count_all_material_types: action.payload.counts || 0
      }
    case GET_MATERIAL_TYPES_FAIL:
      return {
        ...state,
        loading_get_material_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MATERIAL_TYPE:
      return {
        ...state, material_type: null, loading_get_material_type: true, error: null
      }
    case GET_ONE_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        material_type: action.payload.material_type,
        loading_get_material_type: false, error: null
      }
    case GET_ONE_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        loading_get_material_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MATERIAL_TYPE:
      return {
        ...state, material_type: null, loading_add_material_type: true, error: null
      }
    case CREATE_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        material_type: action.payload.material_type,
        loading_add_material_type: false, error: null
      }
    case CREATE_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        loading_add_material_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MATERIAL_TYPE:
      return {
        ...state, loading_edit_material_type: true, error: null
      }
    case PATCH_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        material_type: action.payload.material_type,
        loading_edit_material_type: false, error: null
      }
    case PATCH_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        loading_edit_material_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MATERIAL_TYPE:
      return {
        ...state, material_type: null, loading_delete_material_type: true, error: null
      }
    case DELETE_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        material_type: action.payload.material_type,
        loading_delete_material_type: false, error: null
      }
    case DELETE_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        loading_delete_material_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MaterialTypeState
