import VacationSrv from "services/VacationSrv";

import {
  GET_VACATIONS,
  GET_VACATIONS_FAIL,
  GET_VACATIONS_SUCCESS,

  GET_ONE_VACATION,
  GET_ONE_VACATION_SUCCESS,
  GET_ONE_VACATION_FAIL,

  CREATE_VACATION,
  CREATE_VACATION_SUCCESS,
  CREATE_VACATION_FAIL,

  PATCH_VACATION,
  PATCH_VACATION_SUCCESS,
  PATCH_VACATION_FAIL,

  DELETE_VACATION,
  DELETE_VACATION_SUCCESS,
  DELETE_VACATION_FAIL,

  IMPORT_VACATIONS,
  IMPORT_VACATIONS_SUCCESS,
  IMPORT_VACATIONS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListVacations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_VACATIONS,
    });

    VacationSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacations: response.data.data.vacations,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_VACATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_VACATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createVacation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_VACATION,
    });

    VacationSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacation: response.data.data.vacation,
          }

          dispatch({
            type: CREATE_VACATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_VACATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneVacation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_VACATION,
    });

    VacationSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacation: response.data.data.vacation	,
          }


          dispatch({
            type: GET_ONE_VACATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_VACATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editVacation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_VACATION,
    });

    VacationSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacation: response.data.data.vacation,
          }

          dispatch({
            type: PATCH_VACATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_VACATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteVacation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_VACATION,
    });

    VacationSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_VACATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_VACATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== IMPORT
export const importVacations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_VACATIONS,
    });

    VacationSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            vacations: response.data.data.vacations || [],
          }

          dispatch({
            type: IMPORT_VACATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_VACATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};