import {
    GET_DOCUMENTS,
    GET_DOCUMENTS_FAIL,
    GET_DOCUMENTS_SUCCESS,
  
    GET_ONE_DOCUMENT,
    GET_ONE_DOCUMENT_SUCCESS,
    GET_ONE_DOCUMENT_FAIL,
  
    CREATE_DOCUMENT,
    CREATE_DOCUMENT_SUCCESS,
    CREATE_DOCUMENT_FAIL,
  
    PATCH_DOCUMENT,
    PATCH_DOCUMENT_SUCCESS,
    PATCH_DOCUMENT_FAIL,
  
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    document: null,
    documents: [],
    error: null,
  
    loading_get_documents: false,
    loading_get_document: false,
  
    loading_add_document: false,
    loading_edit_document: false,
  
    count_all_documents: 0,
  }
  
  const DocumentState = (state = INIT_STATE, action) => {
    switch (action.type) {
      // =============== GET LIST
      case GET_DOCUMENTS:
        return {
          ...state, loading_get_documents: true, error: null
        }
      case GET_DOCUMENTS_SUCCESS:
        return {
          ...state,
          documents: action.payload.documents,
          loading_get_documents: false, error: null,
          count_all_documents: action.payload.counts || 0
        }
      case GET_DOCUMENTS_FAIL:
        return {
          ...state, documents: [],
          loading_get_documents: false,
          error: action.payload?.error || {}
        }
  
  
      // =============== GET ONE
      case GET_ONE_DOCUMENT:
        return {
          ...state, document: null, loading_get_document: true, error: null
        }
      case GET_ONE_DOCUMENT_SUCCESS:
        return {
          ...state,
          document: action.payload.document,
          loading_get_document: false, error: null
        }
      case GET_ONE_DOCUMENT_FAIL:
        return {
          ...state,
          loading_get_document: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== CREATE
      case CREATE_DOCUMENT:
        return {
          ...state, document: null, loading_add_document: true, error: null
        }
      case CREATE_DOCUMENT_SUCCESS:
        return {
          ...state,
          document: action.payload.document,
          loading_add_document: false, error: null
        }
      case CREATE_DOCUMENT_FAIL:
        return {
          ...state,
          loading_add_document: false,
          error: action.payload?.error || {}
        }
  
  
  
  
      // =============== PATCH
      case PATCH_DOCUMENT:
        return {
          ...state, loading_edit_document: true, error: null
        }
      case PATCH_DOCUMENT_SUCCESS:
        return {
          ...state,
          document: action.payload.document,
          loading_edit_document: false, error: null
        }
      case PATCH_DOCUMENT_FAIL:
        return {
          ...state,
          loading_edit_document: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== DELETE
      case DELETE_DOCUMENT:
        return {
          ...state, document: null, loading_delete_document: true, error: null
        }
      case DELETE_DOCUMENT_SUCCESS:
        return {
          ...state,
          document: action.payload.document,
          loading_delete_document: false, error: null
        }
      case DELETE_DOCUMENT_FAIL:
        return {
          ...state,
          loading_delete_document: false,
          error: action.payload?.error || {}
        }
  
  
  
  
  
      default:
        return state
    }
  }
  
  export default DocumentState
  