import {
  GET_PROVIDERS,
  GET_PROVIDERS_FAIL,
  GET_PROVIDERS_SUCCESS,

  GET_ONE_PROVIDER,
  GET_ONE_PROVIDER_SUCCESS,
  GET_ONE_PROVIDER_FAIL,

  CREATE_PROVIDER,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_FAIL,

  PATCH_PROVIDER,
  PATCH_PROVIDER_SUCCESS,
  PATCH_PROVIDER_FAIL,

  DELETE_PROVIDER,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAIL
} from "./actionTypes"

const INIT_STATE = {
  provider: null,
  providers: [],
  error: null,

  loading_get_providers: false,
  loading_get_provider: false,

  loading_add_provider: false,
  loading_edit_provider: false,

  count_all_providers: 0,
}

const ProviderState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_PROVIDERS:
      return {
        ...state, loading_get_providers: true, error: null
      }
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload.providers,
        loading_get_providers: false, error: null,
        count_all_providers: action.payload.counts || 0
      }
    case GET_PROVIDERS_FAIL:
      return {
        ...state,
        loading_get_providers: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_PROVIDER:
      return {
        ...state, provider: null, loading_get_provider: true, error: null
      }
    case GET_ONE_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload.provider,
        loading_get_provider: false, error: null
      }
    case GET_ONE_PROVIDER_FAIL:
      return {
        ...state,
        loading_get_provider: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_PROVIDER:
      return {
        ...state, provider: null, loading_add_provider: true, error: null
      }
    case CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload.provider,
        loading_add_provider: false, error: null
      }
    case CREATE_PROVIDER_FAIL:
      return {
        ...state,
        loading_add_provider: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_PROVIDER:
      return {
        ...state, loading_edit_provider: true, error: null
      }
    case PATCH_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload.provider,
        loading_edit_provider: false, error: null
      }
    case PATCH_PROVIDER_FAIL:
      return {
        ...state,
        loading_edit_provider: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_PROVIDER:
      return {
        ...state, provider: null, loading_delete_provider: true, error: null
      }
    case DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload.provider,
        loading_delete_provider: false, error: null
      }
    case DELETE_PROVIDER_FAIL:
      return {
        ...state,
        loading_delete_provider: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ProviderState
