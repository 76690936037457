import OrderSrv from "services/OrderSrv";

import {
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,


  GET_ONE_ORDER,
  GET_ONE_ORDER_SUCCESS,
  GET_ONE_ORDER_FAIL,

  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,

  PATCH_ORDER,
  PATCH_ORDER_SUCCESS,
  PATCH_ORDER_FAIL,

  PRINT_ORDER,
  PRINT_ORDER_SUCCESS,
  PRINT_ORDER_FAIL,

  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ORDERS,
    });

    OrderSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            orders: response.data.data.orders,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_ORDERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ORDERS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ORDER,
    });

    OrderSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            order: response.data.data.order,
          }

          dispatch({
            type: CREATE_ORDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ORDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ORDER,
    });

    OrderSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            order: response.data.data.order
          }

          dispatch({
            type: GET_ONE_ORDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ORDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ORDER,
    });

    OrderSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            order: response.data.data.order,
          }

          dispatch({
            type: PATCH_ORDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ORDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== PRINT
export const printOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_ORDER,
    });

    OrderSrv.printOrder(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: PRINT_ORDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_ORDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteOrder = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ORDER,
    });

    OrderSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ORDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ORDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};