import  PublicationSrv from "services/PublicationSrv";

import {
  GET_PUBLICATIONS,
  GET_PUBLICATIONS_FAIL,
  GET_PUBLICATIONS_SUCCESS,

  GET_ONE_PUBLICATION,
  GET_ONE_PUBLICATION_SUCCESS,
  GET_ONE_PUBLICATION_FAIL,

  CREATE_PUBLICATION,
  CREATE_PUBLICATION_SUCCESS,
  CREATE_PUBLICATION_FAIL,

  PATCH_PUBLICATION,
  PATCH_PUBLICATION_SUCCESS,
  PATCH_PUBLICATION_FAIL,

  DELETE_PUBLICATION,
  DELETE_PUBLICATION_SUCCESS,
  DELETE_PUBLICATION_FAIL,

} from "./actionTypes"

// =============== GET LIST
export const getListPublications = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PUBLICATIONS,
    });

     PublicationSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
         
          const resp = {
            publications: response.data.data.publications,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PUBLICATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PUBLICATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createPublication = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_PUBLICATION,
    });

     PublicationSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            publication: response.data.data.category,
          }

          dispatch({
            type: CREATE_PUBLICATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_PUBLICATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOnePublication = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_PUBLICATION,
    });

     PublicationSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            publication: response.data.data.publication
          }

          dispatch({
            type: GET_ONE_PUBLICATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_PUBLICATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== PATCH
export const editPublication = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_PUBLICATION,
    });

     PublicationSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            publication: response.data.data.publication,
          }

          dispatch({
            type: PATCH_PUBLICATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_PUBLICATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deletePublication = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PUBLICATION,
    });

     PublicationSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PUBLICATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PUBLICATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
