import InscriptionSrv from "services/InscriptionSrv";

import {
  GET_INSCRIPTIONS,
  GET_INSCRIPTIONS_FAIL,
  GET_INSCRIPTIONS_SUCCESS,

  GET_ONE_INSCRIPTION,
  GET_ONE_INSCRIPTION_SUCCESS,
  GET_ONE_INSCRIPTION_FAIL,

  CREATE_INSCRIPTION,
  CREATE_INSCRIPTION_SUCCESS,
  CREATE_INSCRIPTION_FAIL,

  PATCH_INSCRIPTION,
  PATCH_INSCRIPTION_SUCCESS,
  PATCH_INSCRIPTION_FAIL,

  DELETE_INSCRIPTION,
  DELETE_INSCRIPTION_SUCCESS,
  DELETE_INSCRIPTION_FAIL,
} from "./actionTypes"

// =============== GET LIST
export const getListInscriptions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_INSCRIPTIONS,
    });

    InscriptionSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            inscriptions: response.data.data.userSubscriptions,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_INSCRIPTIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_INSCRIPTIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createInscription = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_INSCRIPTION,
    });

    InscriptionSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            inscription: response.data.data.userSubscription,
          }

          dispatch({
            type: CREATE_INSCRIPTION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_INSCRIPTION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneInscription = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_INSCRIPTION,
    });

    InscriptionSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            inscription: response.data.data.userSubscription
          }

          dispatch({
            type: GET_ONE_INSCRIPTION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_INSCRIPTION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editInscription = (payload, callback, callbackError) => {
console.log({payload});
  return (dispatch) => {
    dispatch({
      type: PATCH_INSCRIPTION,
    });

    InscriptionSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            inscription: response.data.data.inscription,
          }

          dispatch({
            type: PATCH_INSCRIPTION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_INSCRIPTION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteInscription = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_INSCRIPTION,
    });

    InscriptionSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_INSCRIPTION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_INSCRIPTION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};