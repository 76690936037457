// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const PaymentSrv = {

  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.payments, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  createPayment: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.payments}/bank/subscription`, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  getChargeList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }
    return new Promise((resolve, reject) => {
      console.log('payload?.next_page',payload?.next_page);
      return axiosApi.get(`${endpoints.payments}/bank/subscription/${payload?.id}?${filters}`) 
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  getBankTransfertList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }
    return new Promise((resolve, reject) => {
      console.log('payload?.next_page',payload?.next_page);
      return axiosApi.get(`${endpoints.payments}/bank/subscription/${payload?.id}?${filters}`) 
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  

  deletePayment: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.payments}/bank/subscription/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


}

export default PaymentSrv