/* INSCRIPTIONS */
export const GET_INSCRIPTIONS = "GET_INSCRIPTIONS"
export const GET_INSCRIPTIONS_SUCCESS = "GET_INSCRIPTIONS_SUCCESS"
export const GET_INSCRIPTIONS_FAIL = "GET_INSCRIPTIONS_FAIL"

export const GET_ONE_INSCRIPTION = "GET_ONE_INSCRIPTION"
export const GET_ONE_INSCRIPTION_SUCCESS = "GET_ONE_INSCRIPTION_SUCCESS"
export const GET_ONE_INSCRIPTION_FAIL = "GET_ONE_INSCRIPTION_FAIL"

export const CREATE_INSCRIPTION = "CREATE_INSCRIPTION"
export const CREATE_INSCRIPTION_SUCCESS = "CREATE_INSCRIPTION_SUCCESS"
export const CREATE_INSCRIPTION_FAIL = "CREATE_INSCRIPTION_FAIL"

export const PATCH_INSCRIPTION = "PATCH_INSCRIPTION"
export const PATCH_INSCRIPTION_SUCCESS = "PATCH_INSCRIPTION_SUCCESS"
export const PATCH_INSCRIPTION_FAIL = "PATCH_INSCRIPTION_FAIL"

export const DELETE_INSCRIPTION = "DELETE_INSCRIPTION"
export const DELETE_INSCRIPTION_SUCCESS = "DELETE_INSCRIPTION_SUCCESS"
export const DELETE_INSCRIPTION_FAIL = "DELETE_INSCRIPTION_FAIL"