// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const SupplySrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.supplies}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.supplies}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  

  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.supplies, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.supplies}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== IMPORT
  import: (payload) => {
    return new Promise((resolve, reject) => {

      return axiosApi.post(`${endpoints.supplies}/import`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.supplies}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
    // =============== DELETE
    deleteTraceability: (payload) => {
      return new Promise((resolve, reject) => {
        return axiosApi.delete(`${endpoints.traceabilities}/${payload?._id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
    },
  


  getTracabilities: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.traceabilities}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  getOneTraceabily: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.traceabilities}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  patchTraceability: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.traceabilities}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  printTraceability: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.traceabilities}/print`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  printTraceabilityExcel: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.traceabilities}/excel`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  printExportExcel: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.traceabilities}/list`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  
}
export default SupplySrv