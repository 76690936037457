import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListParkingsPage = React.lazy(() => import('./list-parkings.page'));
const AddParkingPage = React.lazy(() => import('./add-parking.page'));
const EditParkingPage = React.lazy(() => import('./edit-parking.page'));
const PreviewParkingPage = React.lazy(() => import('./preview-parking.page'));

const Parkings = ({ match,props }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListParkingsPage {...props} />}
      />


      <Route path={`${match.url}/add`}
        render={props => <AddParkingPage {...props} />}
      />

      <Route path={`${match.url}/edit/:parkingId`}
        render={props => <EditParkingPage {...props} />}
      />


      <Route path={`${match.url}/preview/:parkingId`}
        render={props => <PreviewParkingPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Parkings;