import {
  GET_LICENSES,
  GET_LICENSES_FAIL,
  GET_LICENSES_SUCCESS,

  GET_ONE_LICENSE,
  GET_ONE_LICENSE_SUCCESS,
  GET_ONE_LICENSE_FAIL,

  CREATE_LICENSE,
  CREATE_LICENSE_SUCCESS,
  CREATE_LICENSE_FAIL,

  PATCH_LICENSE,
  PATCH_LICENSE_SUCCESS,
  PATCH_LICENSE_FAIL,

  DELETE_LICENSE,
  DELETE_LICENSE_SUCCESS,
  DELETE_LICENSE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  license: null,
  licenses: [],
  error: null,

  loading_get_licenses: false,
  loading_get_license: false,

  loading_add_license: false,
  loading_edit_license: false,

  count_all_licenses: 0,
}

const LicenseState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_LICENSES:
      return {
        ...state, loading_get_licenses: true, error: null
      }
    case GET_LICENSES_SUCCESS:
      return {
        ...state,
        licenses: action.payload.licenses,
        loading_get_licenses: false, error: null,
        count_all_licenses: action.payload.counts || 0
      }
    case GET_LICENSES_FAIL:
      return {
        ...state,
        loading_get_licenses: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_LICENSE:
      return {
        ...state, license: null, loading_get_license: true, error: null
      }
    case GET_ONE_LICENSE_SUCCESS:
      return {
        ...state,
        license: action.payload.license,
        loading_get_license: false, error: null
      }
    case GET_ONE_LICENSE_FAIL:
      return {
        ...state,
        loading_get_license: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_LICENSE:
      return {
        ...state, license: null, loading_add_license: true, error: null
      }
    case CREATE_LICENSE_SUCCESS:
      return {
        ...state,
        license: action.payload.license,
        loading_add_license: false, error: null
      }
    case CREATE_LICENSE_FAIL:
      return {
        ...state,
        loading_add_license: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_LICENSE:
      return {
        ...state, loading_edit_license: true, error: null
      }
    case PATCH_LICENSE_SUCCESS:
      return {
        ...state,
        license: action.payload.license,
        loading_edit_license: false, error: null
      }
    case PATCH_LICENSE_FAIL:
      return {
        ...state,
        loading_edit_license: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_LICENSE:
      return {
        ...state, license: null, loading_delete_license: true, error: null
      }
    case DELETE_LICENSE_SUCCESS:
      return {
        ...state,
        license: action.payload.license,
        loading_delete_license: false, error: null
      }
    case DELETE_LICENSE_FAIL:
      return {
        ...state,
        loading_delete_license: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default LicenseState
