import {
  GET_CLEARANCES,
  GET_CLEARANCES_FAIL,
  GET_CLEARANCES_SUCCESS,

  GET_ONE_CLEARANCE,
  GET_ONE_CLEARANCE_SUCCESS,
  GET_ONE_CLEARANCE_FAIL,

  CREATE_CLEARANCE,
  CREATE_CLEARANCE_SUCCESS,
  CREATE_CLEARANCE_FAIL,

  PATCH_CLEARANCE,
  PATCH_CLEARANCE_SUCCESS,
  PATCH_CLEARANCE_FAIL,

  DELETE_CLEARANCE,
  DELETE_CLEARANCE_SUCCESS,
  DELETE_CLEARANCE_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  clearance: null,
  clearances: [],
 
  error: null,

  loading_get_clearances: false,
  loading_get_clearance: false,

  loading_add_clearance: false,
  loading_edit_clearance: false,
  count_all_clearances: 0,


}


const ClearanceState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_CLEARANCES:
      return {
        ...state, loading_get_clearances: true, error: null
      }
    case GET_CLEARANCES_SUCCESS:
      return {
        ...state,
        clearances: action.payload.clearances,
        loading_get_clearances: false, error: null,
        count_all_clearances: action.payload.counts || 0
      }
    case GET_CLEARANCES_FAIL:
      return {
        ...state,
        loading_get_clearances: false,
        error: action.payload?.error || {}
      }



   


    // =============== GET ONE
    case GET_ONE_CLEARANCE:
      return {
        ...state, clearance: null, loading_get_clearance: true, error: null
      }
    case GET_ONE_CLEARANCE_SUCCESS:
      return {
        ...state,
        clearance: action.payload.clearance,
        loading_get_clearance: false, error: null
      }
    case GET_ONE_CLEARANCE_FAIL:
      return {
        ...state,
        loading_get_clearance: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_CLEARANCE:
      return {
        ...state, clearance: null, loading_add_clearance: true, error: null
      }
    case CREATE_CLEARANCE_SUCCESS:
      return {
        ...state,
        clearance: action.payload.clearance,
        loading_add_clearance: false, error: null
      }
    case CREATE_CLEARANCE_FAIL:
      return {
        ...state,
        loading_add_clearance: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_CLEARANCE:
      return {
        ...state, loading_edit_clearance: true, error: null
      }
    case PATCH_CLEARANCE_SUCCESS:
      return {
        ...state,
        clearance: action.payload.clearance,
        loading_edit_clearance: false, error: null
      }
    case PATCH_CLEARANCE_FAIL:
      return {
        ...state,
        loading_edit_clearance: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_CLEARANCE:
      return {
        ...state, clearance: null, loading_delete_clearance: true, error: null
      }
    case DELETE_CLEARANCE_SUCCESS:
      return {
        ...state,
        clearance: action.payload.clearance,
        loading_delete_clearance: false, error: null
      }
    case DELETE_CLEARANCE_FAIL:
      return {
        ...state,
        loading_delete_clearance: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ClearanceState

