/* DEPENSES */
export const GET_DEPENSES = "GET_DEPENSES"
export const GET_DEPENSES_SUCCESS = "GET_DEPENSES_SUCCESS"
export const GET_DEPENSES_FAIL = "GET_DEPENSES_FAIL"

export const GET_ONE_DEPENSE = "GET_ONE_DEPENSE"
export const GET_ONE_DEPENSE_SUCCESS = "GET_ONE_DEPENSE_SUCCESS"
export const GET_ONE_DEPENSE_FAIL = "GET_ONE_DEPENSE_FAIL"

export const CREATE_DEPENSE = "CREATE_DEPENSE"
export const CREATE_DEPENSE_SUCCESS = "CREATE_DEPENSE_SUCCESS"
export const CREATE_DEPENSE_FAIL = "CREATE_DEPENSE_FAIL"

export const PATCH_DEPENSE = "PATCH_DEPENSE"
export const PATCH_DEPENSE_SUCCESS = "PATCH_DEPENSE_SUCCESS"
export const PATCH_DEPENSE_FAIL = "PATCH_DEPENSE_FAIL"

export const DELETE_DEPENSE = "DELETE_DEPENSE"
export const DELETE_DEPENSE_SUCCESS = "DELETE_DEPENSE_SUCCESS"
export const DELETE_DEPENSE_FAIL = "DELETE_DEPENSE_FAIL"

