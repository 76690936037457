import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListArticlesPage = React.lazy(() => import('./list-articles.page'));
const AddArticlePage = React.lazy(() => import('./add-article.page'));
const EditArticlePage = React.lazy(() => import('./edit-article.page'));
const PreviewArticlePage = React.lazy(() => import('./edit-article.page'));

const Articles = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListArticlesPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddArticlePage {...props} />}
      />

      <Route path={`${match.url}/edit/:articleId`}
        render={props => <EditArticlePage {...props} />}
      />


      <Route path={`${match.url}/preview/:articleId`}
        render={props => <PreviewArticlePage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Articles;