/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_ONE_CATEGORIE = "GET_ONE_CATEGORIE"
export const GET_ONE_CATEGORIE_SUCCESS = "GET_ONE_CATEGORIE_SUCCESS"
export const GET_ONE_CATEGORIE_FAIL = "GET_ONE_CATEGORIE_FAIL"

export const CREATE_CATEGORIE = "CREATE_CATEGORIE"
export const CREATE_CATEGORIE_SUCCESS = "CREATE_CATEGORIE_SUCCESS"
export const CREATE_CATEGORIE_FAIL = "CREATE_CATEGORIE_FAIL"

export const PATCH_CATEGORIE = "PATCH_CATEGORIE"
export const PATCH_CATEGORIE_SUCCESS = "PATCH_CATEGORIE_SUCCESS"
export const PATCH_CATEGORIE_FAIL = "PATCH_CATEGORIE_FAIL"

export const DELETE_CATEGORIE = "DELETE_CATEGORIE"
export const DELETE_CATEGORIE_SUCCESS = "DELETE_CATEGORIE_SUCCESS"
export const DELETE_CATEGORIE_FAIL = "DELETE_CATEGORIE_FAIL"

export const GET_CATEGORIE_SUPPLIES = "GET_CATEGORIE_SUPPLIES"
export const GET_CATEGORIE_SUPPLIES_SUCCESS = "GET_CATEGORIE_SUPPLIES_SUCCESS"
export const GET_CATEGORIE_SUPPLIES_FAIL = "GET_CATEGORIE_SUPPLIES_FAIL"
