import BonusSrv from "services/BonusSrv";

import {
  GET_BONUSS,
  GET_BONUSS_FAIL,
  GET_BONUSS_SUCCESS,

  GET_ONE_BONUS,
  GET_ONE_BONUS_SUCCESS,
  GET_ONE_BONUS_FAIL,

  CREATE_BONUS,
  CREATE_BONUS_SUCCESS,
  CREATE_BONUS_FAIL,

  PATCH_BONUS,
  PATCH_BONUS_SUCCESS,
  PATCH_BONUS_FAIL,

  DELETE_BONUS,
  DELETE_BONUS_SUCCESS,
  DELETE_BONUS_FAIL,

  IMPORT_BONUSS,
  IMPORT_BONUSS_SUCCESS,
  IMPORT_BONUSS_FAIL,

  PRINT_BONUS,
  PRINT_BONUS_SUCCESS,
  PRINT_BONUS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListBonuses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_BONUSS,
    });

    BonusSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            bonuses: response.data.data.bonuses,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_BONUSS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_BONUSS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_BONUS,
    });

    BonusSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            bonus: response.data.data.bonuse,
          }

          dispatch({
            type: CREATE_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneBonuse = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_BONUS,
    });

    BonusSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            bonus: response.data.data.bonus	,
          }


          dispatch({
            type: GET_ONE_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_BONUS,
    });

    BonusSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            bonus: response.data.data.bonus	,
          }

          dispatch({
            type: PATCH_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteBonuse = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_BONUS,
    });

    BonusSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== PRINT
export const printBonus = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PRINT_BONUS,
    });

    BonusSrv.printBonus(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: PRINT_BONUS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PRINT_BONUS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== IMPORT
export const importBonuses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_BONUSS,
    });

    BonusSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            bonuses: response.data.data.bonuses || [],
          }

          dispatch({
            type: IMPORT_BONUSS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_BONUSS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};