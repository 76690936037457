/* QUOTE_PHASES */
export const GET_QUOTE_PHASES = "GET_QUOTE_PHASES"
export const GET_QUOTE_PHASES_SUCCESS = "GET_QUOTE_PHASES_SUCCESS"
export const GET_QUOTE_PHASES_FAIL = "GET_QUOTE_PHASES_FAIL"

export const GET_ONE_QUOTE_PHASE = "GET_ONE_QUOTE_PHASE"
export const GET_ONE_QUOTE_PHASE_SUCCESS = "GET_ONE_QUOTE_PHASE_SUCCESS"
export const GET_ONE_QUOTE_PHASE_FAIL = "GET_ONE_QUOTE_PHASE_FAIL"

export const CREATE_QUOTE_PHASE = "CREATE_QUOTE_PHASE"
export const CREATE_QUOTE_PHASE_SUCCESS = "CREATE_QUOTE_PHASE_SUCCESS"
export const CREATE_QUOTE_PHASE_FAIL = "CREATE_QUOTE_PHASE_FAIL"

export const PATCH_QUOTE_PHASE = "PATCH_QUOTE_PHASE"
export const PATCH_QUOTE_PHASE_SUCCESS = "PATCH_QUOTE_PHASE_SUCCESS"
export const PATCH_QUOTE_PHASE_FAIL = "PATCH_QUOTE_PHASE_FAIL"

export const DELETE_QUOTE_PHASE = "DELETE_QUOTE_PHASE"
export const DELETE_QUOTE_PHASE_SUCCESS = "DELETE_QUOTE_PHASE_SUCCESS"
export const DELETE_QUOTE_PHASE_FAIL = "DELETE_QUOTE_PHASE_FAIL"