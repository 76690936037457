import PropTypes from 'prop-types'
import React, { useState } from "react"

import {
  Table
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import classNames from "classnames"
import { isFunction } from 'helpers/utils'
// actions
// import {
//   changeCrumb
// } from "../../store/actions"
import ReactTooltip from 'react-tooltip'


const ListingTable = props => {
  const { headers, children, changeSortBy, table_style, tbody_style, container_style } = props
  const [sort_asc, setSortAsc] = useState(true)
  const [sort_by, setSortBy] = useState("")

  return (
    <React.Fragment>
      <div className="table-responsive" style={container_style ? container_style : {}} >
        <Table className="table mb-0 appli-table" style={table_style || {}}>
          <thead>
            
            <tr>
              {headers.map((heading, i) => <th key={"heading_" + i}

                onClick={() => {
                  if (heading.sorting && isFunction(changeSortBy)) changeSortBy(
                    { name: heading.sort_by, value: (sort_asc ? "" : "-") + heading.sort_by }
                  )

                  setSortAsc(!sort_asc)
                  setSortBy(heading.sort_by || "")
                }}


                className={(heading.class || "") + " " + classNames({
                  "heading_sort": (heading.sorting && sort_by === heading.sort_by),
                })}

                style={heading.style || {}}
              >
                <span style={heading.sorting ? { cursor: "pointer" } : {}} >{heading.name}</span>

                {(heading.sorting && sort_by === heading.sort_by) && <span className="tbl-sort-icon" >
                  <i className={sort_asc ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}></i>
                </span>}
                {heading.detailsSubscription  && heading?.name === 'Statut'?
                
              <>  
                <span className="tbl-sort-icon" style={{paddingLeft: '0.25rem'}} 
                onMouseEnter={() => ReactTooltip.rebuild()} data-tip={`
                 <a style="font-size:10px;text-transform: capitalize;">Crée : Abonnement créé et en attente de confirmation de l'administrateur. </a>  </br>
                 <a  style="font-size:10px;text-transform: capitalize;">Validé :  Abonnement vérifié par l'administrateur et en cours d'utilisation. </a> </br>
                 <a  style="font-size:10px;text-transform: capitalize;">Expiré :  Abonnement expiré, Vous n'avez pas le droit d'accéder à vos données. </a> </br>
                 <a  style="font-size:10px;text-transform: capitalize;">Annulé :  Abonnement Annulé.  </a> </br>
                 <a  style="font-size:10px;text-transform: capitalize;">Renouvelé :  Abonnement Renouvelé automatiquement. </br>
                 <a  style="font-size:8px;text-transform: lowercase;">   - Pour désactiver le renouvellement automatique, veuillez contacter l'administrateur </a> </a> </br>
                 <a  style="font-size:10px;text-transform: capitalize;">Payé: Abonnement payé et en cours. </a>
                `}  data-for={'112233'}
                 >
                  <ReactTooltip 
                  id={'112233'}
                // backgroundColor="#fff" textColor="#333" borderColor="#444"
                arrowColor="#264b9a" type="info" multiline   html={true} 
                backgroundColor="white" textColor="#6d7995" border={true} borderColor="#6d7995" 
                place = {"bottom"}
              />
                  <i className="mdi mdi-information-outline"></i>
                </span> </>: null}
                
              </th>)}
            </tr>
          </thead>
          <tbody style={tbody_style || {}}>
            {children}
          </tbody>
        </Table>
      </div>

    </React.Fragment >
  )
}
ListingTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    width: PropTypes.number,
    class: PropTypes.string,
  })).isRequired,

  changeSortBy: PropTypes.func,
  table_style: PropTypes.object,
  tbody_style: PropTypes.object,
  container_style: PropTypes.object
}

const mapStatetoProps = state => {
  // const {  } = state.Profile
  return {}
}

export default withRouter(
  connect(mapStatetoProps, {

  })(ListingTable)
)
