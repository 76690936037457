import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListProjectsPage = React.lazy(() => import('./list-projects.page'));
const AddProjectPage = React.lazy(() => import('./add-project.page'));
const EditProjectPage = React.lazy(() => import('./edit-project.page'));
const PreviewProjectPage = React.lazy(() => import('./preview-project.page'));

const Projects = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListProjectsPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddProjectPage {...props} />}
      />

      <Route path={`${match.url}/edit/:projectId`}
        render={props => <EditProjectPage {...props} />}
      />


      <Route path={`${match.url}/preview/:projectId`}
        render={props => <PreviewProjectPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Projects;