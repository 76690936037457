// import PropTypes from 'prop-types'
import React, {
  // useEffect 
} from "react"
import {
  Row, Col
} from "reactstrap"

// Redux

// actions
// import {
//   changeCrumb
// } from "../../store/actions"

import { MetaTags } from 'react-meta-tags'

import img_members from "assets/images/img-members.png"
import img_clients from "assets/images/img-clients.png"
import img_projects from "assets/images/img-projects.png"

const AboutUsPage = props => {
  // useEffect(() => {
  //   window.location.href = "/login"
  // }, [])

  return (
    <React.Fragment>
      <MetaTags><title>AppliBTP</title></MetaTags>

      <div className="question-section">
        <div className="container">
          <div className="question" style={{ width: 330, margin: "auto" }}>
            <h1 className="title">
              <span>Qui Sommes-Nous?</span>
              <span className="sm-underline" style={{ width: 85, marginTop: 5 }} />
            </h1>
          </div>
        </div>
      </div>

      <div className="explication-section pt-5 pb-5">
        <div className="container">
          <h4 className="title">Nous sommes le <span className="hilighted">leader</span> dans <br />la Construction</h4>
          <p className="grey">Le responsable de chantier aura une interface pour planifier chaque chantier. Cette interface lui permet de créer des interventions ou les prendre sur la liste des interventions générées. Le responsable de chantier aura une interface pour planifier chaque chantier. Cette interface lui permet de créer des interventions ou les prendre sur la liste des interventions générées.</p>
        </div>
      </div>

      <div className="points-section">
        <Row>
          <Col md="3"></Col>
          <Col md="2">
            <img className="mobiles" src={img_projects} alt="#" />
            <p className="mt-2" style={{ color: "#ccc", fontSize: 30, fontWeight: 500 }}>168</p>
            <h4 className="mt-2 mb-3">Chantiers</h4>
          </Col>
          <Col md="2">
            <img className="mobiles" src={img_clients} alt="#" />
            <p className="mt-2" style={{ color: "#ccc", fontSize: 30, fontWeight: 500 }}>93</p>
            <h4 className="mt-2 mb-3">Clients</h4>
          </Col>
          <Col md="2">
            <img className="mobiles" src={img_members} alt="#" />
            <p className="mt-2" style={{ color: "#ccc", fontSize: 30, fontWeight: 500 }}>1536</p>
            <h4 className="mt-2 mb-3">Membres</h4>
          </Col>
          <Col md="3"></Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

AboutUsPage.propTypes = {
}
export default AboutUsPage