import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,

  GET_ONE_PROJECT,
  GET_ONE_PROJECT_SUCCESS,
  GET_ONE_PROJECT_FAIL,

  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,

  PATCH_PROJECT,
  PATCH_PROJECT_SUCCESS,
  PATCH_PROJECT_FAIL,

  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  project: null,
  projects: [],
  error: null,

  loading_get_projects: false,
  loading_get_project: false,

  loading_add_project: false,
  loading_edit_project: false,

  count_all_projects: 0,
}

const ProjectState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_PROJECTS:
      return {
        ...state, loading_get_projects: true, error: null
      }
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
        loading_get_projects: false, error: null,
        count_all_projects: action.payload.counts || 0
      }
    case GET_PROJECTS_FAIL:
      return {
        ...state,
        loading_get_projects: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_PROJECT:
      return {
        ...state, project: null, loading_get_project: true, error: null
      }
    case GET_ONE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        loading_get_project: false, error: null
      }
    case GET_ONE_PROJECT_FAIL:
      return {
        ...state,
        loading_get_project: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_PROJECT:
      return {
        ...state, project: null, loading_add_project: true, error: null
      }
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        loading_add_project: false, error: null
      }
    case CREATE_PROJECT_FAIL:
      return {
        ...state,
        loading_add_project: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_PROJECT:
      return {
        ...state, loading_edit_project: true, error: null
      }
    case PATCH_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        loading_edit_project: false, error: null
      }
    case PATCH_PROJECT_FAIL:
      return {
        ...state,
        loading_edit_project: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_PROJECT:
      return {
        ...state, project: null, loading_delete_project: true, error: null
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload.project,
        loading_delete_project: false, error: null
      }
    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        loading_delete_project: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ProjectState
