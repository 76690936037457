import React from "react"
import { Redirect } from "react-router-dom"


// Profile
import UserProfile from "../pages/Authentication/user-profile"



// Authentication related pages
// import AppliHome from "pages/WebSite/Home/AppliHome"
import HomePage from "../pages/WebSite/Home/home-page"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Terms from "../pages/WebSite/Terms/terms"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Clients from "../pages/Clients/index"
import Providers from "../pages/Providers/index"
import Projects from "../pages/Projects/index"
import Staffs from "../pages/Staffs/index"
import Supplies from "../pages/Supplies/index"
import Articles from "../pages/Articles/index"
import Materials from "../pages/Materials/index"
import Quotes from "../pages/Quotes/index"
import Stocks from "../pages/Stocks/index"
import Planings from "pages/Planings"
import Parkings from "pages/Parkings"
import Settings from "pages/Settings"
import Orders from "pages/Orders"
import Clearances from "pages/Clearances"
import Documents from 'pages/Documents'
import Company from 'pages/Company'

import Modules from "pages/Modules/index"
import Inscriptions from "pages/Inscriptions/index"
import Subscriptions from "pages/Subscriptions/index"
import Subscribers from "pages/Subscribers/index"
import AboutUsPage from "pages/WebSite/AboutUs/about-us-page"
import ContactUsPage from "pages/WebSite/ContactUs/contact-us-page"
import PricesPage from "pages/WebSite/Prices/prices-page"
import UserSubscriptionsPage from "pages/UserSubscriptions/index"

const userRoutes = [
  { path: "/dashboard", component: Dashboard, models: ['ALL'] },
  { path: '/profile', component: UserProfile, exact: true, models: ['ALL'] },
  { path: '/clients', component: Clients, exact: false, models: ['Client'] },
  { path: '/providers', component: Providers, exact: false, models: ['Provider'] },
  { path: '/projects', component: Projects, exact: false, models: ['Project'] },
  { path: '/staffs', component: Staffs, exact: false, models: ['Staff'] },
  { path: '/supplies', component: Supplies, exact: false, models: ['Supply'] },
  { path: '/articles', component: Articles, exact: false, models: ['Article'] },
  { path: '/materials', component: Materials, exact: false, models: ['Material'] },
  { path: '/quotes', component: Quotes, exact: false, models: ['Quote'] },
  { path: '/stocks', component: Stocks, exact: false, models: ['Stock'] },
  { path: '/plannings', component: Planings, exact: false, models: ['Quote', ''] },
  { path: '/parkings', component: Parkings, exact: false, models: ['Machine'] },
  { path: '/settings', component: Settings, exact: false, models: ['ALL'] },
  { path: '/orders', component: Orders, exact: false, models: ['Order'] },
  { path: '/clearances', component: Clearances, exact: false, models: ['Clearance'] },
  { path: '/documents', component: Documents, exact: false, models: ['Document'] },
  { path: '/company', component: Company, exact: false, models: ['Company'] },
  { path: '/inscriptions', component: Inscriptions, exact: false, models: ['Inscription'] },
  { path: '/subscriptions', component: Subscriptions, exact: false, models: ['Subscription'] },
  { path: '/subscribers', component: Subscribers, exact: false, models: ['Subscriber'] },
  { path: '/modules', component: Modules, exact: false, models: ['Module'] },
  { path: '/user-subscriptions', component: UserSubscriptionsPage, exact: false, models: ['Subscriber'] },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" />, models: ['ALL'] },
]

const authRoutes = [
  { path: "/", component: HomePage, exact: true },
  { path: "/about-us", component: AboutUsPage, exact: true },
  { path: "/contact-us", component: ContactUsPage, exact: true },
  { path: "/prices", component: PricesPage, exact: true },
  // { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/logout", component: Logout, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/forgot-password", component: ForgetPwd, exact: true },
  { path: "/register", component: Register, exact: true },

  { path: "/mentions-legales", component: Terms, exact: true },
]

export { userRoutes, authRoutes }
