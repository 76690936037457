import ProviderSrv from "services/ProviderSrv";

import {
  GET_PROVIDERS,
  GET_PROVIDERS_FAIL,
  GET_PROVIDERS_SUCCESS,

  GET_ONE_PROVIDER,
  GET_ONE_PROVIDER_SUCCESS,
  GET_ONE_PROVIDER_FAIL,

  CREATE_PROVIDER,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_FAIL,

  PATCH_PROVIDER,
  PATCH_PROVIDER_SUCCESS,
  PATCH_PROVIDER_FAIL,

  DELETE_PROVIDER,
  DELETE_PROVIDER_SUCCESS,
  DELETE_PROVIDER_FAIL,

  IMPORT_PROVIDERS,
  IMPORT_PROVIDERS_SUCCESS,
  IMPORT_PROVIDERS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListProviders = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROVIDERS,
    });

    ProviderSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            providers: response.data.data.providers,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PROVIDERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROVIDERS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createProvider = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_PROVIDER,
    });

    ProviderSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            provider: response.data.data.provider,
          }

          dispatch({
            type: CREATE_PROVIDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_PROVIDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneProvider = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_PROVIDER,
    });

    ProviderSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            provider: response.data.data.provider
          }

          dispatch({
            type: GET_ONE_PROVIDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_PROVIDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editProvider = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_PROVIDER,
    });

    ProviderSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            provider: response.data.data.provider,
          }

          dispatch({
            type: PATCH_PROVIDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_PROVIDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteProvider = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PROVIDER,
    });

    ProviderSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PROVIDER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PROVIDER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== IMPORT
export const importProviders = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_PROVIDERS,
    });

    ProviderSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            providers: response.data.data.providers || [],
          }

          dispatch({
            type: IMPORT_PROVIDERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_PROVIDERS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};