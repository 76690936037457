/* PROJECT_TYPES */
export const GET_PROJECT_TYPES = "GET_PROJECT_TYPES"
export const GET_PROJECT_TYPES_SUCCESS = "GET_PROJECT_TYPES_SUCCESS"
export const GET_PROJECT_TYPES_FAIL = "GET_PROJECT_TYPES_FAIL"

export const GET_ONE_PROJECT_TYPE = "GET_ONE_PROJECT_TYPE"
export const GET_ONE_PROJECT_TYPE_SUCCESS = "GET_ONE_PROJECT_TYPE_SUCCESS"
export const GET_ONE_PROJECT_TYPE_FAIL = "GET_ONE_PROJECT_TYPE_FAIL"

export const CREATE_PROJECT_TYPE = "CREATE_PROJECT_TYPE"
export const CREATE_PROJECT_TYPE_SUCCESS = "CREATE_PROJECT_TYPE_SUCCESS"
export const CREATE_PROJECT_TYPE_FAIL = "CREATE_PROJECT_TYPE_FAIL"

export const PATCH_PROJECT_TYPE = "PATCH_PROJECT_TYPE"
export const PATCH_PROJECT_TYPE_SUCCESS = "PATCH_PROJECT_TYPE_SUCCESS"
export const PATCH_PROJECT_TYPE_FAIL = "PATCH_PROJECT_TYPE_FAIL"

export const DELETE_PROJECT_TYPE = "DELETE_PROJECT_TYPE"
export const DELETE_PROJECT_TYPE_SUCCESS = "DELETE_PROJECT_TYPE_SUCCESS"
export const DELETE_PROJECT_TYPE_FAIL = "DELETE_PROJECT_TYPE_FAIL"