import UserSrv from "services/UserSrv";
import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

export const registerUser = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: REGISTER_USER,
    });

    UserSrv.register(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          // const new_token = `Bearer ${response.data?.data?.token}`
          // localStorage.setItem("authUser", JSON.stringify(response.data?.data?.user))
          // localStorage.setItem("authToken", new_token)

          const resp = {
            // user: response.data?.data?.user,
            // token: new_token,
          }

          dispatch({
            type: REGISTER_USER_SUCCESSFUL,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: REGISTER_USER_FAILED,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// export const registerUserSuccessful = user => {
//   return {
//     type: REGISTER_USER_SUCCESSFUL,
//     payload: user,
//   }
// }

// export const registerUserFailed = user => {
//   return {
//     type: REGISTER_USER_FAILED,
//     payload: user,
//   }
// }
