import {
  GET_REPARATION_TYPES,
  GET_REPARATION_TYPES_FAIL,
  GET_REPARATION_TYPES_SUCCESS,

  GET_ONE_REPARATION_TYPE,
  GET_ONE_REPARATION_TYPE_SUCCESS,
  GET_ONE_REPARATION_TYPE_FAIL,

  CREATE_REPARATION_TYPE,
  CREATE_REPARATION_TYPE_SUCCESS,
  CREATE_REPARATION_TYPE_FAIL,

  PATCH_REPARATION_TYPE,
  PATCH_REPARATION_TYPE_SUCCESS,
  PATCH_REPARATION_TYPE_FAIL,

  DELETE_REPARATION_TYPE,
  DELETE_REPARATION_TYPE_SUCCESS,
  DELETE_REPARATION_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  reparation_type: null,
  reparation_types: [],
  error: null,

  loading_get_reparation_types: false,
  loading_get_reparation_type: false,

  loading_add_reparation_type: false,
  loading_edit_reparation_type: false,

  count_all_reparation_types: 0,
}

const ReparationTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_REPARATION_TYPES:
      return {
        ...state, loading_get_reparation_types: true, error: null
      }
    case GET_REPARATION_TYPES_SUCCESS:
      return {
        ...state,
        reparation_types: action.payload.reparation_types,
        loading_get_reparation_types: false, error: null,
        count_all_reparation_types: action.payload.counts || 0
      }
    case GET_REPARATION_TYPES_FAIL:
      return {
        ...state,
        loading_get_reparation_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_REPARATION_TYPE:
      return {
        ...state, reparation_type: null, loading_get_reparation_type: true, error: null
      }
    case GET_ONE_REPARATION_TYPE_SUCCESS:
      return {
        ...state,
        reparation_type: action.payload.reparation_type,
        loading_get_reparation_type: false, error: null
      }
    case GET_ONE_REPARATION_TYPE_FAIL:
      return {
        ...state,
        loading_get_reparation_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_REPARATION_TYPE:
      return {
        ...state, reparation_type: null, loading_add_reparation_type: true, error: null
      }
    case CREATE_REPARATION_TYPE_SUCCESS:
      return {
        ...state,
        reparation_type: action.payload.reparation_type,
        loading_add_reparation_type: false, error: null
      }
    case CREATE_REPARATION_TYPE_FAIL:
      return {
        ...state,
        loading_add_reparation_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_REPARATION_TYPE:
      return {
        ...state, loading_edit_reparation_type: true, error: null
      }
    case PATCH_REPARATION_TYPE_SUCCESS:
      return {
        ...state,
        reparation_type: action.payload.reparation_type,
        loading_edit_reparation_type: false, error: null
      }
    case PATCH_REPARATION_TYPE_FAIL:
      return {
        ...state,
        loading_edit_reparation_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_REPARATION_TYPE:
      return {
        ...state, reparation_type: null, loading_delete_reparation_type: true, error: null
      }
    case DELETE_REPARATION_TYPE_SUCCESS:
      return {
        ...state,
        reparation_type: action.payload.reparation_type,
        loading_delete_reparation_type: false, error: null
      }
    case DELETE_REPARATION_TYPE_FAIL:
      return {
        ...state,
        loading_delete_reparation_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ReparationTypeState
