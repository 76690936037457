/* STOCKS */
export const GET_STOCKS = "GET_STOCKS"
export const GET_STOCKS_SUCCESS = "GET_STOCKS_SUCCESS"
export const GET_STOCKS_FAIL = "GET_STOCKS_FAIL"

export const GET_ONE_STOCK = "GET_ONE_STOCK"
export const GET_ONE_STOCK_SUCCESS = "GET_ONE_STOCK_SUCCESS"
export const GET_ONE_STOCK_FAIL = "GET_ONE_STOCK_FAIL"

export const CREATE_STOCK = "CREATE_STOCK"
export const CREATE_STOCK_SUCCESS = "CREATE_STOCK_SUCCESS"
export const CREATE_STOCK_FAIL = "CREATE_STOCK_FAIL"

export const PATCH_STOCK = "PATCH_STOCK"
export const PATCH_STOCK_SUCCESS = "PATCH_STOCK_SUCCESS"
export const PATCH_STOCK_FAIL = "PATCH_STOCK_FAIL"

export const DELETE_STOCK = "DELETE_STOCK"
export const DELETE_STOCK_SUCCESS = "DELETE_STOCK_SUCCESS"
export const DELETE_STOCK_FAIL = "DELETE_STOCK_FAIL"