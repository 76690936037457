import {
  GET_QUOTE_PHASES,
  GET_QUOTE_PHASES_FAIL,
  GET_QUOTE_PHASES_SUCCESS,

  GET_ONE_QUOTE_PHASE,
  GET_ONE_QUOTE_PHASE_SUCCESS,
  GET_ONE_QUOTE_PHASE_FAIL,

  CREATE_QUOTE_PHASE,
  CREATE_QUOTE_PHASE_SUCCESS,
  CREATE_QUOTE_PHASE_FAIL,

  PATCH_QUOTE_PHASE,
  PATCH_QUOTE_PHASE_SUCCESS,
  PATCH_QUOTE_PHASE_FAIL,

  DELETE_QUOTE_PHASE,
  DELETE_QUOTE_PHASE_SUCCESS,
  DELETE_QUOTE_PHASE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  quote_phase: null,
  quote_phases: [],
  error: null,

  loading_get_quote_phases: false,
  loading_get_quote_phase: false,

  loading_add_quote_phase: false,
  loading_edit_quote_phase: false,

  count_all_quote_phases: 0,
}

const QuotePhaseState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_QUOTE_PHASES:
      return {
        ...state, loading_get_quote_phases: true, error: null
      }
    case GET_QUOTE_PHASES_SUCCESS:
      return {
        ...state,
        quote_phases: action.payload.quote_phases,
        loading_get_quote_phases: false, error: null,
        count_all_quote_phases: action.payload.counts || 0
      }
    case GET_QUOTE_PHASES_FAIL:
      return {
        ...state,
        loading_get_quote_phases: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_QUOTE_PHASE:
      return {
        ...state, quote_phase: null, loading_get_quote_phase: true, error: null
      }
    case GET_ONE_QUOTE_PHASE_SUCCESS:
      return {
        ...state,
        quote_phase: action.payload.quote_phase,
        loading_get_quote_phase: false, error: null
      }
    case GET_ONE_QUOTE_PHASE_FAIL:
      return {
        ...state,
        loading_get_quote_phase: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_QUOTE_PHASE:
      return {
        ...state, quote_phase: null, loading_add_quote_phase: true, error: null
      }
    case CREATE_QUOTE_PHASE_SUCCESS:
      return {
        ...state,
        quote_phase: action.payload.quote_phase,
        loading_add_quote_phase: false, error: null
      }
    case CREATE_QUOTE_PHASE_FAIL:
      return {
        ...state,
        loading_add_quote_phase: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_QUOTE_PHASE:
      return {
        ...state, loading_edit_quote_phase: true, error: null
      }
    case PATCH_QUOTE_PHASE_SUCCESS:
      return {
        ...state,
        quote_phase: action.payload.quote_phase,
        loading_edit_quote_phase: false, error: null
      }
    case PATCH_QUOTE_PHASE_FAIL:
      return {
        ...state,
        loading_edit_quote_phase: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_QUOTE_PHASE:
      return {
        ...state, quote_phase: null, loading_delete_quote_phase: true, error: null
      }
    case DELETE_QUOTE_PHASE_SUCCESS:
      return {
        ...state,
        quote_phase: action.payload.quote_phase,
        loading_delete_quote_phase: false, error: null
      }
    case DELETE_QUOTE_PHASE_FAIL:
      return {
        ...state,
        loading_delete_quote_phase: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default QuotePhaseState
