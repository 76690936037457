import DepenseSrv from "services/DepenseSrv";

import {
  GET_DEPENSES,
  GET_DEPENSES_FAIL,
  GET_DEPENSES_SUCCESS,

  GET_ONE_DEPENSE,
  GET_ONE_DEPENSE_SUCCESS,
  GET_ONE_DEPENSE_FAIL,

  CREATE_DEPENSE,
  CREATE_DEPENSE_SUCCESS,
  CREATE_DEPENSE_FAIL,

  PATCH_DEPENSE,
  PATCH_DEPENSE_SUCCESS,
  PATCH_DEPENSE_FAIL,

  DELETE_DEPENSE,
  DELETE_DEPENSE_SUCCESS,
  DELETE_DEPENSE_FAIL,

} from "./actionTypes"

// =============== GET LIST
export const getListDepenses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_DEPENSES,
    });

    DepenseSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            depenses: response?.data?.data.expenses,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_DEPENSES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
     
        dispatch({
          type: GET_DEPENSES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createDepense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_DEPENSE,
    });

    DepenseSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            depense: response.data.data.expense
          }

          dispatch({
            type: CREATE_DEPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_DEPENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneDepense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_DEPENSE,
    });

    DepenseSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            depense: response.data.data.expenses
          }

          dispatch({
            type: GET_ONE_DEPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_DEPENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editDepense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_DEPENSE,
    });

    DepenseSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            depense: response.data.data.expense,
          }

          dispatch({
            type: PATCH_DEPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_DEPENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteDepense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_DEPENSE,
    });

    DepenseSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_DEPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_DEPENSE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};