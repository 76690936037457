import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListProvidersPage = React.lazy(() => import('./list-providers.page'));
const AddProviderPage = React.lazy(() => import('./add-provider.page'));
const EditProviderPage = React.lazy(() => import('./edit-provider.page'));
const PreviewProviderPage = React.lazy(() => import('./preview-provider.page'));

const Providers = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListProvidersPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddProviderPage {...props} />}
      />

      <Route path={`${match.url}/edit/:providerId`}
        render={props => <EditProviderPage {...props} />}
      />


      <Route path={`${match.url}/preview/:providerId`}
        render={props => <PreviewProviderPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Providers;