import {
  GET_MIDDLE_SCHOOLS,
  GET_MIDDLE_SCHOOLS_FAIL,
  GET_MIDDLE_SCHOOLS_SUCCESS,

  GET_ONE_MIDDLE_SCHOOL,
  GET_ONE_MIDDLE_SCHOOL_SUCCESS,
  GET_ONE_MIDDLE_SCHOOL_FAIL,

  CREATE_MIDDLE_SCHOOL,
  CREATE_MIDDLE_SCHOOL_SUCCESS,
  CREATE_MIDDLE_SCHOOL_FAIL,

  PATCH_MIDDLE_SCHOOL,
  PATCH_MIDDLE_SCHOOL_SUCCESS,
  PATCH_MIDDLE_SCHOOL_FAIL,

  DELETE_MIDDLE_SCHOOL,
  DELETE_MIDDLE_SCHOOL_SUCCESS,
  DELETE_MIDDLE_SCHOOL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  middle_school: null,
  middle_schools: [],
  error: null,

  loading_get_middle_schools: false,
  loading_get_middle_school: false,

  loading_add_middle_school: false,
  loading_edit_middle_school: false,

  count_all_middle_school: 0,
}

const MiddleSCHOOLState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MIDDLE_SCHOOLS:
      return {
        ...state, loading_get_middle_schools: true, error: null
      }
    case GET_MIDDLE_SCHOOLS_SUCCESS:
      return {
        ...state,
        middle_schools: action.payload.middle_schools,
        loading_get_middle_schools: false, error: null,
        count_all_middle_schools: action.payload.counts || 0
      }
    case GET_MIDDLE_SCHOOLS_FAIL:
      return {
        ...state,
        loading_get_middle_schools: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MIDDLE_SCHOOL:
      return {
        ...state, middle_school: null, loading_get_middle_school: true, error: null
      }
    case GET_ONE_MIDDLE_SCHOOL_SUCCESS:
      return {
        ...state,
        middle_school: action.payload.middle_school,
        loading_get_middle_school: false, error: null
      }
    case GET_ONE_MIDDLE_SCHOOL_FAIL:
      return {
        ...state,
        loading_get_MIDDLE_SCHOOL: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MIDDLE_SCHOOL:
      return {
        ...state, middle_school: null, loading_add_middle_school: true, error: null
      }
    case CREATE_MIDDLE_SCHOOL_SUCCESS:
      return {
        ...state,
        middle_school: action.payload.middle_school,
        loading_add_middle_school: false, error: null
      }
    case CREATE_MIDDLE_SCHOOL_FAIL:
      return {
        ...state,
        loading_add_middle_school: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MIDDLE_SCHOOL:
      return {
        ...state, loading_edit_middle_school: true, error: null
      }
    case PATCH_MIDDLE_SCHOOL_SUCCESS:
      return {
        ...state,
        middle_school: action.payload.middle_school,
        loading_edit_middle_school: false, error: null
      }
    case PATCH_MIDDLE_SCHOOL_FAIL:
      return {
        ...state,
        loading_edit_middle_school: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MIDDLE_SCHOOL:
      return {
        ...state, middle_school: null, loading_delete_middle_school: true, error: null
      }
    case DELETE_MIDDLE_SCHOOL_SUCCESS:
      return {
        ...state,
        middle_school: action.payload.middle_school,
        loading_deletemiddle_school: false, error: null
      }
    case DELETE_MIDDLE_SCHOOL_FAIL:
      return {
        ...state,
        loading_delete_middle_school: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MiddleSCHOOLState
