export const isFunction = (functionToCheck) => (functionToCheck && {}.toString.call(functionToCheck) === '[object Function]')

export const getCountPages = (count_all, page_size = 10) => {
  if (count_all === 0) return 1;

  let pages = Math.floor(count_all / page_size);
  if ((count_all % page_size) > 0) pages += 1;

  return pages;
}

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const model_translaion = {
  "Activity": {
    "fr": "Activités",
    "en": "Activities",
    "subs": []
  },
  "Article": {
    "fr": "Ouvrages",
    "en": "Articles",
    "subs": []
  },
  "ArticleMaterial": {
    "fr": "Matériels d'ouvrage",
    "en": "Materials Ouvrage",
    "subs": []
  },
  "ArticleSupply": {
    "fr": "Articles d'ouvrage",
    "en": "Article Supplies",
    "subs": []
  },
  "ArticleLabor": {
    "fr": "Mains d'oeuvre d'ouvrage",
    "en": "Article Labors",
    "subs": []
  },
  "ArticleType": {
    "fr": "Type d'ouvrage",
    "en": "Article Type",
    "subs": []
  },
  "MaterialType": {
    "fr": "Type de matériel",
    "en": "Material Type",
    "subs": []
  },
  "MaterialCategory": {
    "fr": "Catégorie de matériel",
    "en": "Material Category",
    "subs": []
  },
  "College": {
    "fr": "Collège de rattachement",
    "en": "College",
    "subs": []
  },
  "Client": {
    "fr": "Clients",
    "en": "Clients",
    "subs": []
  },
  "DrivingLicense": {
    "fr": "Permis",
    "en": "Driving License",
    "subs": []
  },
  "Expense": {
    "fr": "Dépenses",
    "en": "Expenses",
    "subs": []
  },
  "Repaire": {
    "fr": "Réparations",
    "en": "Repaires",
    "subs": []
  },
  "MachineType": {
    "fr": "Type machine",
    "en": "Machine type",
    "subs": []
  },
  "TravelBonus": {
    "fr": "Indemnité",
    "en": "Bonuses",
    "subs": []
  },
  "parcType": {
    "fr": "Type parc",
    "en": "Parc type",
    "subs": []
  },
  "Material": {
    "fr": "Matériels",
    "en": "Materials",
    "subs": []
  },
  "Movement": {
    "fr": "Mouvements",
    "en": "Movements",
    "subs": []
  },
  "Order": {
    "fr": "Commandes",
    "en": "Orders",
    "subs": []
  },
  "Permission": {
    "fr": "Permissions",
    "en": "Permissions",
    "subs": []
  },
  "Project": {
    "fr": "Chantiers",
    "en": "Projects",
    "subs": []
  },
  "ProjectType": {
    "fr": "Type projet",
    "en": "Project Type",
    "subs": []
  },
  "Provider": {
    "fr": "Fournisseurs",
    "en": "Providers",
    "subs": []
  },
  "RepairType": {
    "fr": "Type réparation",
    "en": "Repair Type",
    "subs": []
  },
  "Role": {
    "fr": "Métiers",
    "en": "Jobs",
    "subs": []
  },
  "Staff": {
    "fr": "Personnel",
    "en": "Staff",
    "subs": []
  },
  "Stock": {
    "fr": "Stock",
    "en": "Stock",
    "subs": []
  },
  "StockType": {
    "fr": "Type de stock",
    "en": "Stock Type",
    "subs": []
  },
  "Supply": {
    "fr": "Articles",
    "en": "Supplies",
    "subs": []
  },
  "Unit": {
    "fr": "Unités",
    "en": "Units",
    "subs": []
  },
  "User": {
    "fr": "Utilisateurs",
    "en": "Users",
    "subs": []
  },
  "Vacation": {
    "fr": "Congés",
    "en": "Vacations",
    "subs": []
  },
  "WorkingTimes": {
    "fr": "Horaires de travail",
    "en": "Working hours ",
    "subs": []
  },
  "Publication": {
    "fr": "Ouvrages",
    "en": "Publications",
    "subs": []
  },
  "Authorization": {
    "fr": "Autorisation",
    "en": "Authorization",
    "subs": []
  },
  "Category": {
    "fr": "Sous categories",
    "en": "Category",
    "subs": []
  },
  "Empowerment": {
    "fr": "Habilitations",
    "en": "Empowerment",
    "subs": []
  },
  "ClockingIn": {
    "fr": "Pointage",
    "en": "ClockingIn",
    "subs": []
  },
  "QuotePhase": {
    "fr": "Phases devis",
    "en": "Quote Phase",
    "subs": []
  },
  "Task": {
    "fr": "Interventions",
    "en": "Tasks",
    "subs": []
  },
  "QuoteStep": {
    "fr": "Etapes Devis",
    "en": "Quote Steps",
    "subs": []
  },
  "Quote": {
    "fr": "Devis",
    "en": "Quote",
    "subs": []
  },
  "Machine": {
    "fr": "Parc",
    "en": "Parc",
    "subs": [
      {
        "Expense": {
          "fr": "Dépenses",
          "en": "Expenses"
        },
        "Repaire": {
          "fr": "Réparations",
          "en": "Repaires"
        }
      }
    ]
  },
  "Document": {
    "fr": "Documents",
    "en": "Documents",
    "subs": [
      {
        "Machine": {
          "fr": "Parc",
          "en": "Parc"
        },
        "User": {
          "fr": "Utilisateurs",
          "en": "Users"
        }
      }
    ]
  },
  "Bonuse": {
    "fr": "Primes",
    "en": "Bonus",
    "subs": []
  }
}
export const _modelName = (model_name = "") => {
  switch (model_name) {
    case "Activity": return "Activités";
    case "Article": return "Ouvrages";
    case "ArticleMaterial": return "Matériaux d'ouvrage";
    case "ArticleSupply": return "Articles d'ouvrages";
    case "ArticleType": return "Type d'ouvrage";
    case "Client": return "Clients";
    case "Document": return "Documents";
    case "DrivingLicense": return "Permis";
    case "Expense": return "Dépenses";
    case "Machine": return "Parc";
    case "MachineType": return "Type véhicule";
    case "Material": return "Matériaux";
    case "Movement": return "Mouvements";
    case "Order": return "Commandes";
    case "Permission": return "Permissions";
    case "Project": return "Projets";
    case "ProjectType": return "Type projet";
    case "Provider": return "Fournisseurs";
    case "RepairType": return "Type réparation";
    case "Repaire": return "Réparations";
    case "Role": return "Métiers";
    case "Staff": return "Personnel";
    case "Stock": return "Stock";
    case "StockType": return "StockType";
    case "Supply": return "Articles";
    case "Unit": return "Unités";
    case "User": return "Utilisateurs";
    case "Vacation": return "Congés";

    default: return model_name;
  }
}

export const modelName = (model_name = "") => {
  return model_translaion[model_name]?.fr || ""
}

export const order_statuses = [
  { value: 0, label: "Brouillon", color: "#FF8800" },
  { value: 1, label: "En cours", color: "#6D7995" },
  { value: 2, label: "Expédié", color: "#00a20c" },
  { value: 6, label: "Livraison partielle", color: "#00a20c" },
  { value: 3, label: "Livré", color: "#264b9a" },
  { value: 4, label: "Annuler", color: "#f44336" },
  { value: 5, label: "Annuler par fournisseur", color: "#f44336" }
];

export const orderStatusesByUserType = (user_type, is_responsable = false) => {
  // console.log("user_type, is_responsable", user_type, is_responsable);
  switch (user_type) {
    case "PROVIDER":
      return [
        order_statuses[2],
        {
          ...order_statuses.filter(obj => {
            return obj.value === 5
          })[0], label: "Annuler"
        }
      ];
    case "STAFF":
      // console.log("====> is_responsable", is_responsable);
      if (is_responsable) return [
        order_statuses.filter(obj => {
          return obj.value === 0
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 1
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 6
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 3
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 4
        })[0],
      ];

      return [
        order_statuses.filter(obj => {
          return obj.value === 0
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 3
        })[0],
        order_statuses.filter(obj => {
          return obj.value === 6
        })[0],
      ];
    case "ADMIN":
      return order_statuses

    default: return []
  }
}



















export const unusedFillPlanByProject = (resp) => {
  let plan = []
  const data = {};

  try {
    // let indx = 0
    if (resp?.steps?.length) for (const step of resp.steps) {
      if (step) {
        const key = typeof step.project === "string" ? step.project : step.project?._id

        if (Array.isArray(data[key]?.steps)) data[key].steps.push(step)
        else data[key] = { steps: [step], tasks: [] }

        data[key]['project_name'] = step?.project?.name || ""
      }
    }




    // indx = 0
    if (resp?.tasks?.length) for (const task of resp.tasks) {
      if (task) {
        const key = typeof task.project === "string" ? task.project : task.project?._id

        if (Array.isArray(data[key]?.tasks)) data[key].tasks.push(task)
        else {
          // PROJECT NOT FOUND (has no steps)
          if (!data[key]) data[key] = {
            steps: [], tasks: [task]
          }

          // PROJECT EXIST (has steps)
          else {
            if (Array.isArray(data[key]?.tasks)) data[key].tasks.push(task)
            else data[key]["tasks"] = [task]
          }
        }

        data[key]['project_name'] = task?.project?.name || ""
      }
    }




    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const project_plan = [] //fillPlan(data[key], key)

        if (project_plan?.length) {
          // if (data[key].project_name === "Chantier PR-211119-1") console.log("======> project_plan : ", key,  data[key].project_name, project_plan);

          plan.push({
            is_project: true,

            // id: "project_" + 
            id: key,
            text: data[key].project_name || key
          })

          for (let i = 0; i < project_plan.length; i++) {
            //6197d80ee8adf3002724e284
            // console.log(project_plan[i]["parent"]);

            // if (i === 0) {
            //   // console.log("->", project_plan[i].parent === key);

            //   plan.push({
            //     ...project_plan[i],
            //     // text: project_plan[i].parent
            //     parent: key
            //   });

            //   // plan.push(project_plan[i]);
            // }
            // else 
            plan.push(project_plan[i]);
          }
        }


        // plan.concat(fillPlan(data[key], key))

        // plan = [...plan, ...fillPlan(data[key], key)]

        // plan.push(...fillPlan(data[key], key) || [])
      }
    }

  } catch (error) {
    console.log("error group by project", error);
  }

  return plan
}

export const unusedFillPlanByStaff = (resp) => {
  let plan = []
  const data = {};

  try {
    if (resp?.steps?.length) for (const step of resp.steps) {
      if (step?.tasks?.length) for (const task of step?.tasks) {
        // console.log("task.team", task?.team);
        if (Array.isArray(task.team)) for (const item of task.team) {
          if (typeof item?.staff === "object") {
            const key = item?.staff?._id
            if (Array.isArray(data[key]?.tasks)) data[key].tasks.push(task)
            else data[key] = { steps: [], tasks: [task] }

            data[key]['staff_name'] = (item?.staff?.profile) ? item?.staff?.profile?.firstName + " " + item?.staff?.profile?.lastName : ""
          }
        }
      }
    }


    if (resp?.tasks?.length) for (const task of resp.tasks) {
      if (task) {
        // console.log("=> task.team", task.team);
        if (Array.isArray(task.team)) for (const item of task.team) {
          if (typeof item?.staff === "object") {
            const key = item?.staff?._id
            if (Array.isArray(data[key]?.tasks)) data[key].tasks.push(task)
            else data[key] = { steps: [], tasks: [task] }

            data[key]['staff_name'] = (item?.staff?.profile) ? item?.staff?.profile?.firstName + " " + item?.staff?.profile?.lastName : ""
          }
        }
      }
    }


    // console.log(data);
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const staff_plan = [] // fillPlan(data[key], key)

        if (staff_plan?.length) {
          plan.push({
            is_staff: true,

            id: key,
            text: data[key].staff_name || key,
            project_name: "",
            // spliting: `<i class="mdi mdi-eye" style="cursor: pointer" />`
          })
          for (let i = 0; i < staff_plan.length; i++) {
            // let exist_id = false
            let count_exist = 0
            for (const plan_task of plan) {
              if (plan_task?.id === staff_plan[i]?.id) {
                // exist_id = true;
                count_exist += 1;
                // break;
              }
            }

            // if (!exist_id) plan.push(staff_plan[i]);

            if (count_exist === 0) plan.push({
              ...staff_plan[i], project_name: staff_plan[i]?.project ? staff_plan[i]?.project.name : "",
              // spliting: ""
            });
            else {
              // console.log("count_exist", data[key].staff_name, count_exist);
              // plan.push({
              //   ...staff_plan[i],
              //   id: `${staff_plan[i].id}-${count_exist + 1}`,
              //   project_name: staff_plan[i]?.project ? staff_plan[i]?.project.name : ""
              // });
            }
          }
        }

        // console.log("staff_plan", plan);
        // break;
      }
    }

    // console.log(plan);

  } catch (error) {
    console.log("error group by staff", error);
  }

  return plan
  // return []
}