import ReparationSrv from "services/ReparationSrv";

import {
  GET_REPARATIONS,
  GET_REPARATIONS_FAIL,
  GET_REPARATIONS_SUCCESS,

  GET_ONE_REPARATION,
  GET_ONE_REPARATION_SUCCESS,
  GET_ONE_REPARATION_FAIL,

  CREATE_REPARATION,
  CREATE_REPARATION_SUCCESS,
  CREATE_REPARATION_FAIL,

  PATCH_REPARATION,
  PATCH_REPARATION_SUCCESS,
  PATCH_REPARATION_FAIL,

  DELETE_REPARATION,
  DELETE_REPARATION_SUCCESS,
  DELETE_REPARATION_FAIL,

  GET_MACHIN_RESPONSABLES,
  GET_MACHIN_RESPONSABLES_SUCCESS,
  GET_MACHIN_RESPONSABLES_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListReparations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_REPARATIONS,
    });

    ReparationSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparations: response.data.data.repaires,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_REPARATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_REPARATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createReparation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_REPARATION,
    });

    ReparationSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation: response.data.data.repaireMachine
          }

          dispatch({
            type: CREATE_REPARATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_REPARATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneReparation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_REPARATION,
    });

    ReparationSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation: response.data.data.repaires
          }

          dispatch({
            type: GET_ONE_REPARATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_REPARATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editReparation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_REPARATION,
    });

    ReparationSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation: response.data.data.repaires,
          }

          dispatch({
            type: PATCH_REPARATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_REPARATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== LIST RESPONSABLES
export const getListResponsables = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MACHIN_RESPONSABLES,
    });

    ReparationSrv.getListResponsables(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data?.machineHistory || [],
            counts: response.data.data?.counts || 0
          }

          dispatch({
            type: GET_MACHIN_RESPONSABLES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MACHIN_RESPONSABLES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== DELETE
export const deleteReparation = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_REPARATION,
    });

    ReparationSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_REPARATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_REPARATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};