import ReparationTypesSrv from "services/ReparationTypesSrv";

import {
  GET_REPARATION_TYPES,
  GET_REPARATION_TYPES_FAIL,
  GET_REPARATION_TYPES_SUCCESS,

  GET_ONE_REPARATION_TYPE,
  GET_ONE_REPARATION_TYPE_SUCCESS,
  GET_ONE_REPARATION_TYPE_FAIL,

  CREATE_REPARATION_TYPE,
  CREATE_REPARATION_TYPE_SUCCESS,
  CREATE_REPARATION_TYPE_FAIL,

  PATCH_REPARATION_TYPE,
  PATCH_REPARATION_TYPE_SUCCESS,
  PATCH_REPARATION_TYPE_FAIL,

  DELETE_REPARATION_TYPE,
  DELETE_REPARATION_TYPE_SUCCESS,
  DELETE_REPARATION_TYPE_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListReparationTypes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_REPARATION_TYPES,
    });

    ReparationTypesSrv.getListReparationType(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation_types: response.data.data.repairTypes,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_REPARATION_TYPES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_REPARATION_TYPES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createReparationType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_REPARATION_TYPE,
    });

    ReparationTypesSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation_type: response.data.data.reparationType,
          }

          dispatch({
            type: CREATE_REPARATION_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_REPARATION_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneReparationType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_REPARATION_TYPE,
    });

    ReparationTypesSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation_type: response.data.data.reparationType
          }

          dispatch({
            type: GET_ONE_REPARATION_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_REPARATION_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editReparationType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_REPARATION_TYPE,
    });

    ReparationTypesSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reparation_type: response.data.data.reparationType,
          }

          dispatch({
            type: PATCH_REPARATION_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_REPARATION_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteReparationType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_REPARATION_TYPE,
    });

    ReparationTypesSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_REPARATION_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_REPARATION_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};