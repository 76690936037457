/* ACTIVITYS */
export const GET_ACTIVITYS = "GET_ACTIVITYS"
export const GET_ACTIVITYS_SUCCESS = "GET_ACTIVITYS_SUCCESS"
export const GET_ACTIVITYS_FAIL = "GET_ACTIVITYS_FAIL"

export const GET_ONE_ACTIVITY = "GET_ONE_ACTIVITY"
export const GET_ONE_ACTIVITY_SUCCESS = "GET_ONE_ACTIVITY_SUCCESS"
export const GET_ONE_ACTIVITY_FAIL = "GET_ONE_ACTIVITY_FAIL"

export const CREATE_ACTIVITY = "CREATE_ACTIVITY"
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS"
export const CREATE_ACTIVITY_FAIL = "CREATE_ACTIVITY_FAIL"

export const PATCH_ACTIVITY = "PATCH_ACTIVITY"
export const PATCH_ACTIVITY_SUCCESS = "PATCH_ACTIVITY_SUCCESS"
export const PATCH_ACTIVITY_FAIL = "PATCH_ACTIVITY_FAIL"

export const DELETE_ACTIVITY = "DELETE_ACTIVITY"
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL"