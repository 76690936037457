import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListSubscribersPage = React.lazy(() => import('./list-subscribers.page'));

const Subscribers = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListSubscribersPage {...props} />}
      />

      {/* <Route path={`${match.url}/add`}
        render={props => <AddClientPage {...props} />}
      />

      <Route path={`${match.url}/edit/:clientId`}
        render={props => <EditClientPage {...props} />}
      />
 */}

      <Route path={`${match.url}/preview/:subscriberId`}
        render={props => <ListSubscribersPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Subscribers;