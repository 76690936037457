import {
  GET_REPARATIONS,
  GET_REPARATIONS_FAIL,
  GET_REPARATIONS_SUCCESS,

  GET_ONE_REPARATION,
  GET_ONE_REPARATION_SUCCESS,
  GET_ONE_REPARATION_FAIL,

  CREATE_REPARATION,
  CREATE_REPARATION_SUCCESS,
  CREATE_REPARATION_FAIL,

  PATCH_REPARATION,
  PATCH_REPARATION_SUCCESS,
  PATCH_REPARATION_FAIL,

  DELETE_REPARATION,
  DELETE_REPARATION_SUCCESS,
  DELETE_REPARATION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  reparation: null,
  reparations: [],
  error: null,

  loading_get_reparations: false,
  loading_get_reparation: false,

  loading_add_reparation: false,
  loading_edit_reparation: false,

  count_all_reparations: 0,
}

const ReparationState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_REPARATIONS:
      return {
        ...state, loading_get_reparations: true, error: null
      }
    case GET_REPARATIONS_SUCCESS:
      return {
        ...state,
        reparations: action.payload.reparations,
        loading_get_reparations: false, error: null,
        count_all_reparations: action.payload.counts || 0
      }
    case GET_REPARATIONS_FAIL:
      return {
        ...state, reparations: [],
        loading_get_reparations: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_REPARATION:
      return {
        ...state, reparation: null, loading_get_reparation: true, error: null
      }
    case GET_ONE_REPARATION_SUCCESS:
      return {
        ...state,
        reparation: action.payload.reparation,
        loading_get_reparation: false, error: null
      }
    case GET_ONE_REPARATION_FAIL:
      return {
        ...state,
        loading_get_reparation: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_REPARATION:
      return {
        ...state, reparation: null, loading_add_reparation: true, error: null
      }
    case CREATE_REPARATION_SUCCESS:
      return {
        ...state,
        reparation: action.payload.repaireMachine,
        loading_add_reparation: false, error: null
      }
    case CREATE_REPARATION_FAIL:
      return {
        ...state,
        loading_add_reparation: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_REPARATION:
      return {
        ...state, loading_edit_reparation: true, error: null
      }
    case PATCH_REPARATION_SUCCESS:
      return {
        ...state,
        reparation: action.payload.reparation,
        loading_edit_reparation: false, error: null
      }
    case PATCH_REPARATION_FAIL:
      return {
        ...state,
        loading_edit_reparation: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_REPARATION:
      return {
        ...state, reparation: null, loading_delete_reparation: true, error: null
      }
    case DELETE_REPARATION_SUCCESS:
      return {
        ...state,
        reparation: action.payload.reparation,
        loading_delete_reparation: false, error: null
      }
    case DELETE_REPARATION_FAIL:
      return {
        ...state,
        loading_delete_reparation: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ReparationState
