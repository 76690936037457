import {
  GET_MACHINE_TYPES,
  GET_MACHINE_TYPES_FAIL,
  GET_MACHINE_TYPES_SUCCESS,

  GET_ONE_MACHINE_TYPE,
  GET_ONE_MACHINE_TYPE_SUCCESS,
  GET_ONE_MACHINE_TYPE_FAIL,

  CREATE_MACHINE_TYPE,
  CREATE_MACHINE_TYPE_SUCCESS,
  CREATE_MACHINE_TYPE_FAIL,

  PATCH_MACHINE_TYPE,
  PATCH_MACHINE_TYPE_SUCCESS,
  PATCH_MACHINE_TYPE_FAIL,

  DELETE_MACHINE_TYPE,
  DELETE_MACHINE_TYPE_SUCCESS,
  DELETE_MACHINE_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  machine_type: null,
  machine_types: [],
  error: null,

  loading_get_machine_types: false,
  loading_get_machine_type: false,

  loading_add_machine_type: false,
  loading_edit_machine_type: false,

  count_all_machine_types: 0,
}

const MachineTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MACHINE_TYPES:
      return {
        ...state, loading_get_machine_types: true, error: null
      }
    case GET_MACHINE_TYPES_SUCCESS:
      return {
        ...state,
        machine_types: action.payload.machine_types,
        loading_get_machine_types: false, error: null,
        count_all_machine_types: action.payload.counts || 0
      }
    case GET_MACHINE_TYPES_FAIL:
      return {
        ...state,
        loading_get_machine_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MACHINE_TYPE:
      return {
        ...state, machine_type: null, loading_get_machine_type: true, error: null
      }
    case GET_ONE_MACHINE_TYPE_SUCCESS:
      return {
        ...state,
        machine_type: action.payload.machine_type,
        loading_get_machine_type: false, error: null
      }
    case GET_ONE_MACHINE_TYPE_FAIL:
      return {
        ...state,
        loading_get_machine_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MACHINE_TYPE:
      return {
        ...state, machine_type: null, loading_add_machine_type: true, error: null
      }
    case CREATE_MACHINE_TYPE_SUCCESS:
      return {
        ...state,
        machine_type: action.payload.machine_type,
        loading_add_machine_type: false, error: null
      }
    case CREATE_MACHINE_TYPE_FAIL:
      return {
        ...state,
        loading_add_machine_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MACHINE_TYPE:
      return {
        ...state, loading_edit_machine_type: true, error: null
      }
    case PATCH_MACHINE_TYPE_SUCCESS:
      return {
        ...state,
        machine_type: action.payload.machine_type,
        loading_edit_machine_type: false, error: null
      }
    case PATCH_MACHINE_TYPE_FAIL:
      return {
        ...state,
        loading_edit_machine_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MACHINE_TYPE:
      return {
        ...state, machine_type: null, loading_delete_machine_type: true, error: null
      }
    case DELETE_MACHINE_TYPE_SUCCESS:
      return {
        ...state,
        machine_type: action.payload.machine_type,
        loading_delete_machine_type: false, error: null
      }
    case DELETE_MACHINE_TYPE_FAIL:
      return {
        ...state,
        loading_delete_machine_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MachineTypeState
