import {
  GET_ARTICLE_TYPES,
  GET_ARTICLE_TYPES_FAIL,
  GET_ARTICLE_TYPES_SUCCESS,

  GET_ONE_ARTICLE_TYPE,
  GET_ONE_ARTICLE_TYPE_SUCCESS,
  GET_ONE_ARTICLE_TYPE_FAIL,

  CREATE_ARTICLE_TYPE,
  CREATE_ARTICLE_TYPE_SUCCESS,
  CREATE_ARTICLE_TYPE_FAIL,

  PATCH_ARTICLE_TYPE,
  PATCH_ARTICLE_TYPE_SUCCESS,
  PATCH_ARTICLE_TYPE_FAIL,

  DELETE_ARTICLE_TYPE,
  DELETE_ARTICLE_TYPE_SUCCESS,
  DELETE_ARTICLE_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  article_type: null,
  article_types: [],
  error: null,

  loading_get_article_types: false,
  loading_get_article_type: false,

  loading_add_article_type: false,
  loading_edit_article_type: false,

  count_all_article_types: 0,
}

const ArticleTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_ARTICLE_TYPES:
      return {
        ...state, loading_get_article_types: true, error: null
      }
    case GET_ARTICLE_TYPES_SUCCESS:
      return {
        ...state,
        article_types: action.payload.article_types,
        loading_get_article_types: false, error: null,
        count_all_article_types: action.payload.counts || 0
      }
    case GET_ARTICLE_TYPES_FAIL:
      return {
        ...state,
        loading_get_article_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_ARTICLE_TYPE:
      return {
        ...state, article_type: null, loading_get_article_type: true, error: null
      }
    case GET_ONE_ARTICLE_TYPE_SUCCESS:
      return {
        ...state,
        article_type: action.payload.article_type,
        loading_get_article_type: false, error: null
      }
    case GET_ONE_ARTICLE_TYPE_FAIL:
      return {
        ...state,
        loading_get_article_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_ARTICLE_TYPE:
      return {
        ...state, article_type: null, loading_add_article_type: true, error: null
      }
    case CREATE_ARTICLE_TYPE_SUCCESS:
      return {
        ...state,
        article_type: action.payload.article_type,
        loading_add_article_type: false, error: null
      }
    case CREATE_ARTICLE_TYPE_FAIL:
      return {
        ...state,
        loading_add_article_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_ARTICLE_TYPE:
      return {
        ...state, loading_edit_article_type: true, error: null
      }
    case PATCH_ARTICLE_TYPE_SUCCESS:
      return {
        ...state,
        article_type: action.payload.article_type,
        loading_edit_article_type: false, error: null
      }
    case PATCH_ARTICLE_TYPE_FAIL:
      return {
        ...state,
        loading_edit_article_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_ARTICLE_TYPE:
      return {
        ...state, article_type: null, loading_delete_article_type: true, error: null
      }
    case DELETE_ARTICLE_TYPE_SUCCESS:
      return {
        ...state,
        article_type: action.payload.article_type,
        loading_delete_article_type: false, error: null
      }
    case DELETE_ARTICLE_TYPE_FAIL:
      return {
        ...state,
        loading_delete_article_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ArticleTypeState
