import CompanySrv from "services/CompanySrv";

import {
  CREATE_COMPANY,
  CREATE_COMPANY_FAIL,
  CREATE_COMPANY_SUCCESS,

  GET_COMPANY,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,

  PATCH_COMPANY,
  PATCH_COMPANY_SUCCESS,
  PATCH_COMPANY_FAIL,
} from "./actionTypes"


// =============== GET ONE
export const getCompany = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: GET_COMPANY,
    });
    
    CompanySrv.getCompany(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            company: response.data.data.company
          }

          dispatch({
            type: GET_COMPANY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_COMPANY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createCompany = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_COMPANY,
    });

    CompanySrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            company: response.data.data.company,
          }

          dispatch({
            type: CREATE_COMPANY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_COMPANY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editCompany = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_COMPANY,
    });

    CompanySrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            company: response.data.data.company,
          }

          dispatch({
            type: PATCH_COMPANY_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_COMPANY_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};