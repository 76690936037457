/* QUOTES */
export const GET_QUOTES = "GET_QUOTES"
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS"
export const GET_QUOTES_FAIL = "GET_QUOTES_FAIL"

export const GET_ONE_QUOTE = "GET_ONE_QUOTE"
export const GET_ONE_QUOTE_SUCCESS = "GET_ONE_QUOTE_SUCCESS"
export const GET_ONE_QUOTE_FAIL = "GET_ONE_QUOTE_FAIL"

export const CREATE_QUOTE = "CREATE_QUOTE"
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS"
export const CREATE_QUOTE_FAIL = "CREATE_QUOTE_FAIL"

export const PATCH_QUOTE = "PATCH_QUOTE"
export const PATCH_QUOTE_SUCCESS = "PATCH_QUOTE_SUCCESS"
export const PATCH_QUOTE_FAIL = "PATCH_QUOTE_FAIL"

export const VALIDATE_QUOTE = "VALIDATE_QUOTE"
export const VALIDATE_QUOTE_SUCCESS = "VALIDATE_QUOTE_SUCCESS"
export const VALIDATE_QUOTE_FAIL = "VALIDATE_QUOTE_FAIL"

export const CLONE_QUOTE = "CLONE_QUOTE"
export const CLONE_QUOTE_SUCCESS = "CLONE_QUOTE_SUCCESS"
export const CLONE_QUOTE_FAIL = "CLONE_QUOTE_FAIL"

export const DELETE_QUOTE = "DELETE_QUOTE"
export const DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS"
export const DELETE_QUOTE_FAIL = "DELETE_QUOTE_FAIL"

export const PRINT_QUOTE = "PRINT_QUOTE"
export const PRINT_QUOTE_SUCCESS = "PRINT_QUOTE_SUCCESS"
export const PRINT_QUOTE_FAIL = "PRINT_QUOTE_FAIL"


// QUOTE STEPS
export const GET_QUOTE_STEPS = "GET_QUOTE_STEPS"
export const GET_QUOTE_STEPS_SUCCESS = "GET_QUOTE_STEPS_SUCCESS"
export const GET_QUOTE_STEPS_FAIL = "GET_QUOTE_STEPS_FAIL"

export const CREATE_QUOTE_STEP = "CREATE_QUOTE_STEP"
export const CREATE_QUOTE_STEP_SUCCESS = "CREATE_QUOTE_STEP_SUCCESS"
export const CREATE_QUOTE_STEP_FAIL = "CREATE_QUOTE_STEP_FAIL"

export const PATCH_QUOTE_STEP = "PATCH_QUOTE_STEP"
export const PATCH_QUOTE_STEP_SUCCESS = "PATCH_QUOTE_STEP_SUCCESS"
export const PATCH_QUOTE_STEP_FAIL = "PATCH_QUOTE_STEP_FAIL"

export const DELETE_QUOTE_STEP = "DELETE_QUOTE_STEP"
export const DELETE_QUOTE_STEP_SUCCESS = "DELETE_QUOTE_STEP_SUCCESS"
export const DELETE_QUOTE_STEP_FAIL = "DELETE_QUOTE_STEP_FAIL"



// QUOTE STEP TASKS
export const GET_QUOTE_STEP_TASKS = "GET_QUOTE_STEP_TASKS"
export const GET_QUOTE_STEP_TASKS_SUCCESS = "GET_QUOTE_STEP_TASKS_SUCCESS"
export const GET_QUOTE_STEP_TASKS_FAIL = "GET_QUOTE_STEP_TASKS_FAIL"

export const CREATE_QUOTE_STEP_TASK = "CREATE_QUOTE_STEP_TASK"
export const CREATE_QUOTE_STEP_TASK_SUCCESS = "CREATE_QUOTE_STEP_TASK_SUCCESS"
export const CREATE_QUOTE_STEP_TASK_FAIL = "CREATE_QUOTE_STEP_TASK_FAIL"

export const PATCH_QUOTE_STEP_TASK = "PATCH_QUOTE_STEP_TASK"
export const PATCH_QUOTE_STEP_TASK_SUCCESS = "PATCH_QUOTE_STEP_TASK_SUCCESS"
export const PATCH_QUOTE_STEP_TASK_FAIL = "PATCH_QUOTE_STEP_TASK_FAIL"

export const DELETE_QUOTE_STEP_TASK = "DELETE_QUOTE_STEP_TASK"
export const DELETE_QUOTE_STEP_TASK_SUCCESS = "DELETE_QUOTE_STEP_TASK_SUCCESS"
export const DELETE_QUOTE_STEP_TASK_FAIL = "DELETE_QUOTE_STEP_TASK_FAIL"


export const GET_TASKS = "GET_TASKS"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_FAIL = "GET_TASKS_FAIL"


export const GET_QUOTE_PLANING = "GET_QUOTE_PLANING"
export const GET_QUOTE_PLANING_SUCCESS = "GET_QUOTE_PLANING_SUCCESS"
export const GET_QUOTE_PLANING_FAIL = "GET_QUOTE_PLANING_FAIL"