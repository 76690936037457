import  AuthorizationSrv from "services/AuthorizationSrv";

import {
  GET_AUTHORIZATIONS,
  GET_AUTHORIZATIONS_FAIL,
  GET_AUTHORIZATIONS_SUCCESS,

  GET_ONE_AUTHORIZATION,
  GET_ONE_AUTHORIZATION_SUCCESS,
  GET_ONE_AUTHORIZATION_FAIL,


  CREATE_AUTHORIZATION,
 CREATE_AUTHORIZATION_SUCCESS,
CREATE_AUTHORIZATION_FAIL ,

  PATCH_AUTHORIZATION,
  PATCH_AUTHORIZATION_SUCCESS,
  PATCH_AUTHORIZATION_FAIL,

  DELETE_AUTHORIZATION,
  DELETE_AUTHORIZATION_SUCCESS,
  DELETE_AUTHORIZATION_FAIL,

  GET_EMPOWERMENTS,
  GET_EMPOWERMENTS_SUCCESS,
  GET_EMPOWERMENTS_FAIL

} from "./actionTypes"

// =============== GET LIST
export const getListAuthorizations = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_AUTHORIZATIONS,
    });

     AuthorizationSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
         
          const resp = {
            authorizations: response.data.data.authorizations,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_AUTHORIZATIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        console.log(e);
        dispatch({
          type: GET_AUTHORIZATIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createAuthorization = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_AUTHORIZATION,
    });

     AuthorizationSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            authorization: response.data.data.authorization,
          }

          dispatch({
            type: CREATE_AUTHORIZATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type:CREATE_AUTHORIZATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneAuthorization = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_AUTHORIZATION,
    });

     AuthorizationSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            authorization: response.data.data.authorization
          }

          dispatch({
            type: GET_ONE_AUTHORIZATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_AUTHORIZATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== PATCH
export const editAuthorization = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_AUTHORIZATION,
    });

     AuthorizationSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            authorization: response.data.data.authorization,
          }

          dispatch({
            type: PATCH_AUTHORIZATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_AUTHORIZATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== GET EMPOWERMENTS
export const getEmpowerments = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_EMPOWERMENTS,
    });

     AuthorizationSrv.getEmpowerments(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            empowerments: response.data.data.empowerments || [],
          }

          dispatch({
            type: GET_EMPOWERMENTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_EMPOWERMENTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteAuthorization = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_AUTHORIZATION,
    });

     AuthorizationSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_AUTHORIZATION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_AUTHORIZATION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
