/* VACATIONS */
export const GET_VACATIONS = "GET_VACATIONS"
export const GET_VACATIONS_SUCCESS = "GET_VACATIONS_SUCCESS"
export const GET_VACATIONS_FAIL = "GET_VACATIONS_FAIL"

export const GET_ONE_VACATION = "GET_ONE_VACATION"
export const GET_ONE_VACATION_SUCCESS = "GET_ONE_VACATION_SUCCESS"
export const GET_ONE_VACATION_FAIL = "GET_ONE_VACATION_FAIL"

export const CREATE_VACATION = "CREATE_VACATION"
export const CREATE_VACATION_SUCCESS = "CREATE_VACATION_SUCCESS"
export const CREATE_VACATION_FAIL = "CREATE_VACATION_FAIL"

export const PATCH_VACATION = "PATCH_VACATION"
export const PATCH_VACATION_SUCCESS = "PATCH_VACATION_SUCCESS"
export const PATCH_VACATION_FAIL = "PATCH_VACATION_FAIL"

export const DELETE_VACATION = "DELETE_VACATION"
export const DELETE_VACATION_SUCCESS = "DELETE_VACATION_SUCCESS"
export const DELETE_VACATION_FAIL = "DELETE_VACATION_FAIL"

export const IMPORT_VACATIONS = "IMPORT_VACATIONS"
export const IMPORT_VACATIONS_SUCCESS = "IMPORT_VACATIONS_SUCCESS"
export const IMPORT_VACATIONS_FAIL = "IMPORT_VACATIONS_FAIL"