// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const ProjectSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },




  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.projects, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.projects}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== GET PROJECT PLANING
  getProjectPlaning: (payload) => {
    return new Promise((resolve, reject) => {
      let filters = ""
      if (payload.filters) {
        for (const key in payload.filters) {
          if (Object.hasOwnProperty.call(payload.filters, key)) {
            if (payload.filters[key] && payload.filters[key] !== "")
              filters += `&${key}=${payload.filters[key]}`
            else if (payload.filters[key] === false) filters += `&${key}=false`
          }
        }
      }

      // return axiosApi.get(`${endpoints.projects}/planning/${payload?.project_id}`)
      return axiosApi.get(`${endpoints.projects}/cloned-planning/${payload?.project_id}?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.projects}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },






  // =============== GET PROJECT INCOMES
  getProjectIncomes: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/incomes/${payload?.offset}/${payload?.limit}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== CREATE INCOME
  createIncome: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.projects + "/incomes", payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH INCOME
  patchIncome: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.projects}/incomes/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE INCOME
  deleteProjectIncome: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.projects}/incomes/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },









  // =============== GET ADDITION EXPENSES
  getAdditionExpenses: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/expenses/${payload?.offset}/${payload?.limit}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== CREATE ADDITION EXPENSES
  createAdditionExpense: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.projects + "/expenses", payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH ADDITION EXPENSES
  patchAdditionExpense: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.projects}/expenses/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ADDITION EXPENSES
  deleteAdditionExpense: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.projects}/expenses/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },











  // =============== GET PROJECT ARCHIVES
  getProjectArchives: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/archives/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== CREATE ARCHIVE
  createArchive: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.projects + "/archives", payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH ARCHIVE
  patchArchive: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.projects}/archives/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ARCHIVE
  deleteProjectArchive: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.projects}/archives/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== GET PROJECT PHASES
  getProjectPhases: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/${payload.project_id}/phases?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET PROJECT EXPENSES
  getProjectExpenses: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/${payload.project_id}/expenses/${payload?.offset}/${payload?.limit }/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },





  // =============== GET PROJECT USERS
  getProjectUsers: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.projects}/${payload?.project_id}/allowed-users?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
}

export default ProjectSrv