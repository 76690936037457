import PropTypes from 'prop-types'
import React, { useState } from "react"

import {
  Row, Col, Input, Modal, ModalBody, ModalHeader, ModalFooter, Button, Label, UncontrolledAlert
} from "reactstrap"


// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

// actions
import {
  createDepense
} from "../../../store/actions"
// import usePrevious from 'helpers/usePrevious'
import PaymentSrv from "services/paymentService"

const AddBankTransfertMdl = props => {
  // HOOKS
  const dispatch = useDispatch();
  const { loading_add_depense } = useSelector(({ DepenseState }) => DepenseState)

  const { is_open, size, toggleOpen, accept, subscription } = props

  // const [task, setTask] = useState("")
  const [ref, setRef] = useState("")
  const [date, setDate] = useState("")
  const [price, setPrice] = useState(0)
  // const [file, setFile] = useState(null)
  
  const [msg_check, setMsgCheck] = useState("")

  const checkData = () => {
    if (price === "") {
      return "Montant est obligatoire.";
    }
    else if (date === "") {
      return "Date est obligatoire.";
    }

    return ""
  }

  const submitAdd = () => {

    const check = checkData(); setMsgCheck(check)
 

    if (check === "") {
      // const frm = new FormData();
      // frm.append('ref', ref);
      // frm.append('date', date);
      // frm.append('price', price);
      // if(file) frm.append('file', file);
      // frm.append('machine', machine_id);
      const data = {
        ref,
        date,
        price,
        subscription
      }
      // dispatch(createDepense({

      //   data
      //   // { task, date, ref, totalCost, machine: machine_id }
      // }, (resp) => {

      //   setRef("");
      //   setDate("");
      //   setPrice(0);
      //   // setFile(null);
      //   setMsgCheck("")



      //   accept()

      // }, (error) => {

      //   setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
      // }))
      PaymentSrv.createPayment(data)
        .then((response) => {
          if (response && response?.data?.success) {
            setRef("");
            setDate("");
            setPrice(0);
            // setFile(null);
            setMsgCheck("")
            accept()

            // setPayments(response.data?.data?.payments || [])

            // setCountAllPayments(response.data?.data?.count)

            //  if(response.data?.data?.payments?.has_more)
            //  setNextPage(response.data?.data?.payments?.next_page || null)

          }

        })
        .catch(e => {
        });

    }
  }

  return (
    <Modal isOpen={is_open} size={size || "md"} toggle={toggleOpen} >
      <ModalHeader>Ajouter paiement par virement</ModalHeader>



      <ModalBody>
        {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
          <i className="mdi mdi-block-helper me-2"></i>{msg_check}
        </UncontrolledAlert>}

        <Row>
         

          <Col md="6">
            <div className="mb-3">
              <Label reparationclassName="frm-label">Date</Label>
              <Input type="date"
                onChange={(e) => setDate(e.target.value)}
                className="form-control" placeholder="00.00" />
            </div>
          </Col>
          <Col md="6">
            <div className="mb-3">
              <Label reparationclassName="frm-label">Référence de virement</Label>
              <Input type="string"
                onChange={(e) => setRef(e.target.value)}
                className="form-control" placeholder="référence" />
            </div>
          </Col>

          <Col md="6">
            <div className="mb-3">
              <Label reparationclassName="frm-label">Montant</Label>
              <Input onChange={(e) => setPrice(e.target.value)}
                placeholder="total" type="number" min="0"

              />
            </div>
          </Col>

          {/* <Col md="6">
            <div className="mb-3">
              <Label reparationclassName="frm-label">Pièce jointe</Label>
              <Input type="file" onChange={(e) => {
                try {
                  setFile(e.target.files[0])
                } catch (error) {}
              }}/>
            </div>
          </Col> */}

        </Row>
      </ModalBody>




      <ModalFooter>
        <Button disabled={(loading_add_depense)} onClick={() => {
          submitAdd()
        }} color="success" className="btn btn-success waves-effect waves-light success-frm" >
          {loading_add_depense ? "..." : "Sauvegarder"}
        </Button>


        <Button onClick={() => {
          toggleOpen();
          // setTask("");
          setRef("");
          setDate("");
          setPrice(0);
          setMsgCheck("")

        }} color="secondary" outline className="waves-effect" style={{ width: 127 }}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  )
}
AddBankTransfertMdl.propTypes = {
  is_open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  toggleOpen: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,

  subscription: PropTypes.string.isRequired,
}

export default AddBankTransfertMdl