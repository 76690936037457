import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import SelectReact from 'react-select';

import { NavLink, useHistory } from "react-router-dom";


import {
  Label, Card, CardBody, CardHeader, Input, Row, Col,
  Button, UncontrolledAlert, Spinner
} from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

import {
  getConnectedProfile, editConnectedProfile, editPassword, getListActivities, editClient, getOneClient, deleteClientInterlocutor
} from 'store/actions'
import { endpoints } from 'helpers/apiConfigs';

import { isFunction, isValidEmail } from 'helpers/utils';

import PlacesAutocomplete, {
  geocodeByAddress, getLatLng, geocodeByPlaceId
} from 'react-places-autocomplete';


import GoogleMapReact from 'google-map-react';

import marker_red from "assets/images/marker-red.ico";
import SweetAlert from "react-bootstrap-sweetalert"
import AdresseSrv from 'services/AdresseSrv';

const EditClientCmp = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    client_id, back_path, is_profile, is_return_mdl, onChange, onCancel
  } = props

  // HOOKS
  const { client, loading_get_client } = useSelector(({ ClientState }) => ClientState);
  const [loading_edit_client, setLoadingEditClient] = useState(false)

  // const [loading_get_client, setLoadingGetClient] = useState(false)
  // const [client, setClient] = useState(null)

  // console.log("client", client);

  const [user_profile, setUserProfile] = useState(null)
  const [loading_edit_profile, setLoadingEditProfile] = useState(false)
  const [loading_edit_pass, setLoadingEditPass] = useState(false)

  const [loading_get_profile, setLoadingGetProfile] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_pass, setConfirmPassword] = useState("")


  const [picture, setPicture] = useState(null)
  const [picture_link, setPictureLink] = useState("")

  const [msg_check, setMsgCheck] = useState("")

  const [fullName, setName] = useState("")
  const [SIRET, setSIRET] = useState("")
  // const [lastName, setLastName] = useState("")

  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [postalCode, setPostalCode] = useState("")


  const [city, setCity] = useState("")
  const [additionAddress, setAdditionAddress] = useState("")

  const [latadress, setLatAdress] = useState(0)
  const [lngadress, setLngAdress] = useState(0)

  const [department, setDepartment] = useState("")
  const [region, setRegion] = useState("")
  const [ref, setRef] = useState("")

  const handleSelectAddress = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    try {
      setLatAdress(latLng.lat)
      setLngAdress(latLng.lng)
    } catch (error) { }

    setCloseSuggessions(true)


    try {
      const [place] = await geocodeByPlaceId(placeId);
      const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};      
      if (postalCode === "") {
        await AdresseSrv.getReverse({
          filters: { lat: latLng.lat, lon: latLng.lng }
        }).then(res => {
          if (res?.features?.[0]?.properties?.postcode)
            setPostalCode(res?.features?.[0]?.properties?.postcode)
        })
          .catch(e => {
            console.log(e)
          })
      } else {
        setPostalCode(postalCode)
      }

      const { long_name: department = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
      setDepartment(department)

      const { long_name: region = '' } = place.address_components.find(c => c.types.includes("administrative_area_level_1")) || {};
      setRegion(region)

      const { long_name: city = '' } = place.address_components.find(c => c.types.includes("locality")) || {};
      setCity(city)
    } catch (error) { }
  };
  // const [interlocutor, setInterlocutor] = useState("")
  const [street, setStreet] = useState("")
  const [director, setDirector] = useState("")
  // const [activity_type, setActivityType] = useState("")

  // const [activity, setActivity] = useState("")
  const [activities, setActivities] = useState([])
  const [activities_options, setActivitiesOptions] = useState([])

  const [interlocutors, setInterlocutors] = useState([{
    fullName: "", firstName: "", lastName: "", job: "", email: "", phone: ""
  }]);

  const [selected_interlocutor, setSelectedInterlocutor] = useState(null);
  const [show_alert_delete_interlocutor, setShowAlertDeleteInterlocutor] = useState(false);

  // const [loading_adrs_cmp, setAdrsCmp] = useState(false)
  // const [loading_post_cmp, setPostCmp] = useState(false)

  const [close_suggessions, setCloseSuggessions] = useState(false)
  const [close_suggessions_post, setCloseSuggessionsPost] = useState(false)

  const center = { lat: 48.84, lng: 2.20 };
  // Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  useEffect(() => {
    if (!is_profile) {
      dispatch(getListActivities({
        offset: 0, limit: 500, filters: {
          sort: "name"
        }
      }, (resp) => {
        const activities_list = []

        if (resp?.activities)
          for (let i = 0; i < resp.activities.length; i++) {
            activities_list.push({
              value: resp.activities[i]._id,
              label: resp.activities[i].name
            })
          }
        setActivitiesOptions(activities_list)
      }, (err) => { }))
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (client_id && client_id !== "") {
      setMsgCheck("");

      if (is_profile) {
        setLoadingGetProfile(true)
        dispatch(getConnectedProfile({}, (resp) => {
          setUserProfile(resp?.profile)
          fillClientData(resp?.profile)
          setLoadingGetProfile(false)
        }, (error) => {
          setLoadingGetProfile(false)
        }))
      }
      else {
        // // console.log("NOT profile", { loading_get_client, "client?._id": client?._id, client_id });
        // if (!loading_get_client) //&& client?._id !== client_id)
        dispatch(getOneClient({ _id: client_id }, (resp) => {
          fillClientData(resp.client)
        }, (error) => { }))

        // else {
        //   fillClientData(client)
        //   // // setLoadingGetClient(true)
        //   // dispatch(getOneClient({ _id: client_id }, (resp) => {
        //   //   // setClient(resp.client || null)
        //   //   // setLoadingGetClient(false)
        //   //   // console.log("resp", resp.client);
        //   //   try {
        //   //     if (resp.client.interlocutors) {
        //   //       setInterlocutors(resp.client.interlocutors || [])
        //   //     }
        //   //     if (resp.client.activities) {
        //   //       setInterlocutors(resp.client.activities || [])
        //   //     }
        //   //   } catch (error) { }


        //   //   // console.log("resp", resp.client);
        //   // }, (error) => {
        //   //   // setLoadingGetClient(false)
        //   // }))
        // }
      }
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id])

  const fillClientData = (new_client) => {
    // console.log("fillClientData", new_client);
    if (new_client) {
      setName(new_client.profile?.fullName);
      setSIRET(new_client.SIRET);
      setStreet(new_client.profile?.address?.fullAddress)
      setEmail(new_client.email)
      setPhone(new_client.profile?.phone)
      setRef(new_client.ref)
      // setInterlocutor(new_client.interlocutor)
      setDirector(new_client.director)

      // setActivity(new_client.activity?._id)
      if (Array.isArray(new_client.activities)) {
        const acts = [];
        for (const act of new_client.activities) {
          if (act?._id) acts.push(act._id)
        }

        setActivities(acts)
      }


      setPostalCode(new_client.profile?.address?.postalCode)
      setCity(new_client.profile?.address?.city);
      setAdditionAddress(new_client.profile?.address?.additionAddress);

      if (new_client.profile?.picture) setPictureLink(new_client.profile?.picture.path || "")

      try {
        const new_interlocutors = []
        for (let indx = 0; indx < new_client.interlocutors.length; indx++) {
          if (new_client.interlocutors[indx]?._id) new_interlocutors.push(new_client.interlocutors[indx])
        }

        setInterlocutors(new_interlocutors || [])
      } catch (error) { }


      setDepartment(new_client.profile?.address?.department)
      setRegion(new_client.profile?.address?.region);
      // setLatAdress(-94.48297) //31.653381
      // setLngAdress(38.98854) //1.758986


      try {
        setLatAdress(new_client.profile?.address.location.coordinates?.[0] || 0)
      } catch (error) { }
      try {
        setLngAdress(new_client.profile?.address.location.coordinates?.[1] || 0)
      } catch (error) { }
    }
  }



  const checkData = () => {
    if (fullName === "") {
      return "Le raison social est obligatoire.";
    }
    if (!email || !isValidEmail(email)) {
      return "L'e-mail est obligatoire."
    }
    if (phone?.length < 6) {
      return "Le télèphone est obligatoire."
    }
    if (street === "") {
      return "L'adresse est obligatoire."
    }
    // if (!activity || activity === "") {
    //   return "L'activité est obligatoire."
    // }

    return ""
  }


  const submitEdit = () => {
    const check = checkData(); setMsgCheck(check);


    if (interlocutors) {
      for (let i = 0; i < interlocutors.length; i++) {
        const element = interlocutors[i];
        if (element.email && element.email !== "" && !isValidEmail(element.email)) {
          setMsgCheck("Vérifier l'email de l'interlocuteur.");
          window.scrollTo(0, 0)
          return;
        }

        if (element && element.firstName === '' && element.lastName === '' && element.job === '' && element.phone === '' && element.email === '') {
          interlocutors.splice(i, 1);
          i--;
        }
      }
    }
    var formData = new FormData();

    if (check === "") {
      //EDIT CLIENT
      formData.append('type', "CLIENT");

      if (fullName) formData.append('fullName', fullName);
      if (SIRET) formData.append('SIRET', SIRET);
      if (street) formData.append('fullAddress', street);
      if (phone) formData.append('phone', phone);
      if (email) formData.append('email', email.toLowerCase());
      if (ref) formData.append('ref', ref);
      // if (activity) formData.append('activity', activity);
      if (activities) for (const act_id of activities) {
        if (act_id && act_id !== "undefined") formData.append('activities[]', act_id);
      }

      formData.append('interlocutors', JSON.stringify(interlocutors));
      // if (interlocutor) formData.append('interlocutor', interlocutor);
      // Object.keys(interlocutors).forEach(key => formData.append('interlocutors', JSON.stringify(interlocutors[key])));

      if (director) formData.append('director', director);
      if (picture) formData.append('picture', picture);
      if (city) formData.append('city', city);
      if (region) formData.append('region', region);
      if (postalCode) formData.append('postalCode', postalCode);
      if (department) formData.append('department', department);

      if (latadress) formData.append('lat', latadress);
      if (lngadress) formData.append('lng', lngadress);

      if (additionAddress) formData.append('additionAddress', additionAddress);

      if (is_profile) {
        setLoadingEditProfile(true);

        dispatch(editConnectedProfile({
          // _id: user_profile?._id,
          user_type: "CLIENT",
          data: formData
        }, (resp) => {
          setLoadingEditProfile(false);
          if (back_path && back_path !== "") history.push(back_path);
        }, (error) => {
          setLoadingEditProfile(false);
          window.scrollTo(0, 0)
          setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
        }))
      }





      else {
        setLoadingEditClient(true)

        dispatch(editClient({
          _id: client?._id, data: formData
        }, (resp) => {
          setLoadingEditClient(false)

          if (isFunction(onChange)) onChange(resp?.client)
          if (back_path && back_path !== "") history.push(back_path);
        }, (error) => {
          setLoadingEditClient(false)

          console.log("error", error);
          window.scrollTo(0, 0)
          setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
        }))
      }
    }

    else window.scrollTo(0, 0)
  }

  const rmInterlocutor = (item, index) => {
    if (item?._id) {
      setSelectedInterlocutor(item)
      setShowAlertDeleteInterlocutor(true)
    }
    else {
      const newInterlocutors = [...interlocutors];
      newInterlocutors.splice(index, 1);
      setInterlocutors(newInterlocutors);
    }
  }

  const submitChangePassword = () => {
    setLoadingEditPass(true);
    setMsgCheck('');

    dispatch(editPassword({
      data: { oldPassword, password, confirmPassword: confirm_pass }
    }, (resp) => {
      setLoadingEditPass(false);
      setPassword('')
      setOldPassword('')
      setConfirmPassword('')
      // if (back_path && back_path !== "") history.push(back_path);
    }, (error) => {
      setLoadingEditPass(false);
      window.scrollTo(0, 0)
      setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
    }))
  }

  const onChangePicture = e => {
    if (e.target.files && e.target.files[0]) {

      setPicture(e.target.files[0])
    } else {
      setPicture(picture_link)
    }
    if (e.target.files && e.target.files[0]) {
      setPictureLink(URL.createObjectURL(e.target.files[0]))
    }

  };

  const renderImage = (img, img_url) => {
    if (img && img_url && img_url !== "") return (<img className="img" src={img_url} alt={client?.name || ""} />)
    if (!img && img_url && img_url !== "") {
      if (is_profile) return (<img className="img" src={`${endpoints.root}/${user_profile?.profile.picture.path}`} alt={""} />)
      else return (<img className="img" src={`${endpoints.root}/${client?.profile.picture.path}`} alt={client?.name || ""} />)
    }
    //  return (<img className="img" src={`${endpoints.root}/${client?.profile?.picture.path}`} alt={client?.name || ""} />)
    if (img === null) (<img className="img" src={`${endpoints.root}/${img_url}`} alt={client?.name || ""} />)
  }

  if (is_profile) {
    if (client_id !== user_profile?._id) return (<div className="page-content text-center">
      {loading_get_profile && <Spinner color="primary" />}
    </div>);
  }
  else {
    if (client_id !== client?._id) return (<div className="page-content text-center">
      {loading_get_client && <Spinner color="primary" />}
    </div>);
  }


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
            <i className="mdi mdi-block-helper me-2"></i>{msg_check}
          </UncontrolledAlert>}



          <Row>
            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Informations Générales</h4>
              <hr />

            </Col>
            {/* UPLOAD IMAGE */}
            <Col md="3">
              <div className="attach-wrapper">
                <Label className="attach-label" htmlFor="logo-file">

                  {picture_link === "" ? <div className="center_both">
                    <i className="mdi mdi-camera icon-cam"></i>
                    <span className="attach-holder">Image </span>
                  </div> :
                    renderImage(picture, picture_link)}


                </Label>



                <input onChange={onChangePicture} id="logo-file" type="file" accept="image/*" />
              </div>
            </Col>

            <Col md="9">
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">Raison Social</Label>
                    <Input value={fullName} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Raison Social" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">N° SIRET</Label>
                    <Input value={SIRET} onChange={(e) => setSIRET(e.target.value)} className="form-control" placeholder="N° SIRET" />
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">E-mail</Label>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="E-mail de Client" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">Télèphone</Label>
                    <PhoneInput className="form-control"
                      country={'fr'}
                      value={phone}
                      onChange={phone_number => setPhone(phone_number)}
                    />   </div>
                </Col>

              </Row>
            </Col>
            <Col md="4">
              <div className="mb-3 mt-3">
                <Label className="frm-label">Référence <span style={{ color: "#777" }}>( optionnel )</span> </Label>
                <Input className="form-control" onChange={(e) => setRef(e.target.value)} value={ref} placeholder="Référence" />
              </div>
            </Col>
            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Informations professionnelles</h4>
              <hr />
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="frm-label">Directeur</Label>
                <Input value={director} onChange={(e) => setDirector(e.target.value)} className="form-control" placeholder="Nom de Directeur" />
              </div>
            </Col>
            <Col md="6">
              {!is_profile ? <div className="mb-3">
                <Label className="frm-label">Type d'activité</Label>
                <SelectReact
                  closeMenuOnSelect={false}
                  onChange={(acts) => {
                    const new_activities = []
                    if (acts)
                      for (const act of acts) {
                        new_activities.push(act?.value)
                      }

                    setActivities(new_activities || [])
                  }}
                  placeholder="Sélectioner"
                  value={activities_options?.filter(option => activities.includes(option.value))}
                  isMulti
                  options={activities_options}

                />
              </div> : null}
            </Col>
            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Adresse</h4>
              <hr />
            </Col>

            <Col md="8">
              <Row>
                {/* ADDRESS */}
                <Col md="8">
                  <div className="mb-3">
                    <Label className="frm-label">Adresse</Label>
                    {
                      !latadress || !latadress ?
                        <i className="mdi mdi-alert-circle-outline hilighted" style={{ fontSize: 18, marginLeft: 5 }} title="Adresse incomplète" />
                        : null
                    }
                    <PlacesAutocomplete searchOptions={{
                      componentRestrictions: { country: ['FR'] },
                      bounds: defaultBounds,
                      stricBounds: false,
                      fields: ["address_components", "geometry", "icon", "name"],

                      types: ['address'],
                    }}
                      value={street}
                      onChange={(rslt) => {
                        try {
                          setStreet(rslt)
                        } catch (error) { }

                        setCloseSuggessions(false);
                      }}
                      onSelect={(address, placeId, suggestion) => {
                            if (suggestion && suggestion.description) {
                              setStreet(suggestion.description)
                              handleSelectAddress(suggestion.description, suggestion.placeId)
                            }
                          }}

                      autocomplete="off"

                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ width: "100%" }}>
                          <input
                            {...getInputProps({
                              placeholder: 'Adress...',
                              className: 'form-control form-control',
                            })}
                          />
                          {!close_suggessions && <div className="autocomplete-dropdown-container" style={{}}>
                            {loading && <div>Chargement...</div>}
                            {suggestions.map((suggestion, i) => {

                              return (
                                <div key={"ADRS_SUGG_start_indx_" + i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className: suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item', style: {}
                                  })}

                                  onClick={() => {
                                    if (suggestion && suggestion.description) {
                                      setStreet(suggestion.description)
                                      handleSelectAddress(suggestion.description, suggestion.placeId)
                                    }

                                  }}

                                >
                                  <span>{suggestion?.description || ""}</span>
                                </div>
                              );
                            })}
                          </div>}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Col>

                {/* ZIP CODE */}
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Code postal</Label>

                    <PlacesAutocomplete searchOptions={{
                      componentRestrictions: { country: ['FR'] },
                      types: ['geocode']
                    }}
                      value={postalCode}
                      onChange={(rslt) => {
                        try {
                          setPostalCode(rslt.split(" ")[0])
                        } catch (error) { }

                        setCloseSuggessionsPost(false);
                      }}
                      onSelect={(address, placeId, suggestion) => {
                            if (suggestion && suggestion.description) {
                              setStreet(suggestion.description)
                              handleSelectAddress(suggestion.description, suggestion.placeId)
                            }
                          }}

                      autocomplete="off"
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{ width: "100%" }}>
                          <input
                            {...getInputProps({
                              placeholder: 'Code postal...',
                              className: 'form-control form-control'
                            })}
                          />
                          {!close_suggessions_post && <div className="autocomplete-dropdown-container" style={{}}>
                            {loading && <div>Chargement...</div>}
                            {suggestions.map((suggestion, i) => {

                              return (
                                <div key={"ZIP_SUGG_start_indx_" + i}
                                  {...getSuggestionItemProps(suggestion, {
                                    className: suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item', style: {}
                                  })}

                                  onClick={() => {
                                    setPostalCode(suggestion?.terms[0]?.value)
                                    setStreet(suggestion?.description || "")

                                    geocodeByAddress(suggestion.description)
                                      .then(results => {

                                        try {
                                          const adrs_cmp = results[0].address_components

                                          setCity(adrs_cmp[1].long_name)
                                          setDepartment(adrs_cmp[2].long_name)
                                          setRegion(adrs_cmp[3].long_name)

                                        } catch (error) { }

                                        setCloseSuggessionsPost(true)
                                      })


                                      .catch(error => {

                                      });

                                    geocodeByAddress(suggestion?.description)
                                      .then(results => getLatLng(results[0]))
                                      .then((result) => {

                                        try {
                                          setLatAdress(result.lat)
                                          setLngAdress(result.lng)
                                        } catch (error) { }
                                      })
                                  }}
                                >
                                  <span>{suggestion?.terms[0]?.value + " " + (suggestion?.terms[1]?.value) || ""}</span>
                                </div>
                              );
                            })}
                          </div>}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                </Col>


                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Département</Label>
                    <Input value={department} onChange={(e) => setDepartment(e.target.value)} className="form-control" placeholder="Département" />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Région</Label>
                    <Input value={region} onChange={(e) => setRegion(e.target.value)} className="form-control" placeholder="Région" />
                  </div>
                </Col>


                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Ville</Label>
                    <Input value={city} onChange={(e) => setCity(e.target.value)} className="form-control" placeholder="Ville" />
                  </div>
                </Col>

                <Col md="12">
                  <div className="mb-3">
                    <Label className="frm-label">Complément d'adresse</Label>
                    <Input value={additionAddress} onChange={(e) => setAdditionAddress(e.target.value)} className="form-control" placeholder="Complément d'adresse" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="4" className="mb-3">
              {(latadress && lngadress) ? <div style={{ height: '30vh', width: '100%' }}>

                <GoogleMapReact
                  bootstrapURLKeys={{ key: endpoints.gmap_key }}
                  center={{ lat: latadress, lng: lngadress }}
                  defaultZoom={11}

                  yesIWantToUseGoogleMapApiInternals={true}

                >
                  <img src={marker_red} style={{
                    width: 44,
                    marginTop: -58,
                    marginLeft: -22
                  }}
                    lat={latadress} lng={lngadress}
                    alt="#"
                  />
                </GoogleMapReact>
              </div> : null}
            </Col>


            <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Interlocuteurs</h4>
              <hr />
            </Col>

            {/* INTERLOCUTORS */}


            {interlocutors.map((item, index) => item !== null &&
              <Row key={item[index]?._id + "_" + index}>
                <Col md="2" >
                  <div className="mb-3">
                    <Label className="frm-label">Nom</Label>
                    <Input value={item.firstName}
                      onChange={(e) => {
                        const new_interlocutors = [...interlocutors];
                        // if (e.target.value !== "") 
                        new_interlocutors[index].firstName = e.target.value
                        setInterlocutors(new_interlocutors)
                      }}
                      className="form-control" placeholder="Nom interlocuteur" />
                  </div>
                </Col>
                <Col md="2" >
                  <div className="mb-3">
                    <Label className="frm-label">Prénom</Label>
                    <Input value={item.lastName}
                      onChange={(e) => {
                        const new_interlocutors = [...interlocutors];
                        // if (e.target.value !== "") 
                        new_interlocutors[index].lastName = e.target.value
                        setInterlocutors(new_interlocutors)
                      }}
                      className="form-control" placeholder="Prénom interlocuteur" />
                  </div>
                </Col>
                <Col md="3" >
                  <div className="mb-3">
                    <Label className="frm-label">E-mail</Label>
                    <Input value={item.email}
                      onChange={(e) => {
                        const new_interlocutors = [...interlocutors];
                        // if (e.target.value !== "") 
                        new_interlocutors[index].email = e.target.value
                        setInterlocutors(new_interlocutors)
                      }}
                      className="form-control" placeholder="E-mail interlocuteur" />
                  </div>
                </Col>

                <Col md="2" >
                  <div className="mb-3">
                    <Label className="frm-label">Métier</Label>
                    <Input value={item.job}
                      onChange={(e) => {
                        const new_interlocutors = [...interlocutors];
                        // if (e.target.value !== "") 
                        new_interlocutors[index].job = e.target.value
                        setInterlocutors(new_interlocutors)
                      }}
                      className="form-control" placeholder="Métier interlocuteur" />
                  </div>
                </Col>

                <Col md="2" className="p-0" >
                  <div className="mb-3">
                    <Label className="frm-label">Télèphone</Label>
                    <PhoneInput className="form-control"
                      country={'fr'}
                      value={item.phone}
                      onChange={(phone_number) => {
                        const new_interlocutors = [...interlocutors];
                        // if (phone_number !== "") 
                        new_interlocutors[index].phone = phone_number
                        setInterlocutors(new_interlocutors)
                      }}

                    />
                  </div>
                </Col>
                <Col md="1" className="my-4">
                  <div onClick={() => rmInterlocutor(item, index)}>
                    <i style={interlocutors.length === 1 ? { cursor: "default", color: "#ddd", fontSize: 25, position: 'absolute' } :
                      { fontSize: 25, position: 'absolute', color: "#C95978" }} className="mdi mdi-close-circle-outline  float-right" />
                  </div>
                </Col>
              </Row>
            )}
            <Col md="12">
              <span className='burgundy-red' style={{ cursor: 'pointer' }} id="click"
                onClick={() => setInterlocutors([...interlocutors, { firstName: "", lastName: "", job: "", email: "", phone: "" }])}>+ Ajouter interlocuteur</span>
            </Col>


          </Row>


          <div className="mt-5">
            <Button onClick={() => {
              if (!loading_edit_client) submitEdit()
            }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
              Enregistrer {(loading_edit_client || loading_edit_profile) && <Spinner className="ms-2 small-spinner" color="light" />}
            </Button>
            {(!is_profile && !is_return_mdl) ? <NavLink to="/clients">
              <Button color="secondary" outline className="waves-effect" style={{ width: 127 }}>
                Annuler
              </Button>
            </NavLink> : null}

            {is_return_mdl ? <Button onClick={() => { if (isFunction(onCancel)) onCancel(); }} color="secondary" outline className="waves-effect" style={{ width: 127 }}>Annuler</Button> : null}
          </div>
        </CardBody>
      </Card>


      {is_profile ? <div>
        <hr />
        <Card>
          <CardHeader>
            <h3>Changer mot de passe</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Label>Ancien mot de passe</Label>
                <Input type="password" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
              </Col>
              <Col md="6">
                <Label>Nouveau mot de passe</Label>
                <Input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Col>
              <Col md="6" className="mt-3">
                <Label>Confirmation</Label>
                <Input type="password" className="form-control" value={confirm_pass} onChange={(e) => setConfirmPassword(e.target.value)} />
              </Col>

              <Col md="6" style={{ marginTop: 42 }}>
                <Button disabled={(confirm_pass !== password || !oldPassword || oldPassword === "")} onClick={() => {
                  if (!loading_edit_pass) submitChangePassword()
                }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
                  Changer {(loading_edit_pass) && <Spinner className="ms-2 small-spinner" color="light" />}
                </Button>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div> : null}



      {show_alert_delete_interlocutor && selected_interlocutor && <SweetAlert
        style={{ height: "auto" }}
        title="Êtes-vous sûr?"
        warning
        showCancel
        confirmButtonText="Oui, supprimez-le!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setShowAlertDeleteInterlocutor(false)
          deleteClientInterlocutor({ _id: selected_interlocutor?._id })
          dispatch(deleteClientInterlocutor({ _id: selected_interlocutor?._id }, (resp) => {
            try {
              const new_interlocutors = [...interlocutors]
              for (let i = 0; i < new_interlocutors.length; i++) {
                if (new_interlocutors[i]?._id === selected_interlocutor?._id) {
                  new_interlocutors.splice(i, 1);
                  break;
                }
              }

              setInterlocutors(new_interlocutors)
            } catch (error) {

            }
          }, (error) => {
            setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
          }))
        }}
        onCancel={() => setShowAlertDeleteInterlocutor(false)}
      >
        Vous ne pourrez pas revenir en arrière!
      </SweetAlert>}

    </React.Fragment >
  )
}
EditClientCmp.propTypes = {
  client_id: PropTypes.string.isRequired,
  back_path: PropTypes.string,

  is_profile: PropTypes.bool,

  // FOR OPEN FROM MODAL
  is_return_mdl: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
}
export default EditClientCmp