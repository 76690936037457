// import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
// useSelector

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { getPermissions } from 'store/actions'

// import app from "./helpers/firebase"
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'


// Bugsnag.notify(new Error('Test error'))

// const messaging = firebase.messaging();

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  // function getLayout() {
  //   let layoutCls = VerticalLayout
  //   switch (props.layout.layoutType) {
  //     case "horizontal":
  //       layoutCls = HorizontalLayout
  //       break
  //     default:
  //       layoutCls = VerticalLayout
  //       break
  //   }
  //   return layoutCls
  // }

  const {
    // permissions, 
    user
  } = props // useSelector(({ LoginState }) => LoginState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.userType === "STAFF")
      dispatch(getPermissions({}, (resp) => { }, (error) => { }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    // app
    // const messaging = firebase.messaging();
  }, [])

  // const Layout = getLayout()
  const renderRouting = () => {
    return <Router>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact={(route.exact)}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={VerticalLayout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact={(route.exact)}
          />
        ))}
      </Switch>
    </Router>
  }

  // const renderContainer = () => {
  //   try {
  //     if (process.env.REACT_APP_BUGSNAG_KEY) {
  //       Bugsnag.start({
  //         apiKey: process.env.REACT_APP_BUGSNAG_KEY || "REACT_APP_BUGSNAG_KEY",
  //         plugins: [new BugsnagPluginReact()],

  //         onError: function (event) {
  //           // console.log("ERRRRRRRR", event);
  //           return renderRouting()
  //         }
  //       })

  //       const ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React)

  //       return <ErrorBoundary>
  //         {renderRouting()}
  //       </ErrorBoundary>
  //     }

  //     return renderRouting()
  //   } catch (error) {
  //     console.log("---------------->", error);
  //     return renderRouting()
  //   }
  // }

  return (<React.Fragment>
      {/* {renderContainer()} */}
      {renderRouting()}
    </React.Fragment>
  )
}

App.propTypes = {
  // layout: PropTypes.any
}

const mapStateToProps = state => {
  const { user, permissions } = state.Login

  return {
    // layout: state.Layout,
    user, permissions
  }
}

export default connect(mapStateToProps, null)(App)