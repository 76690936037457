// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const ArticleSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if ((payload.filters[key] || payload.filters[key] === false) && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.articles, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.articles}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.articles}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },





  /* ********************** ARTICLE SUPPLY ********************** */

  getArticleSupplies: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload.article_id}/supplies?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE ARTCLE SUPPLY
  createArticleSupply: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.articles + "/supplies", payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH ARTCLE SUPPLY
  patchArticleSupply: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.articles}/supplies/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ARTCLE SUPPLY
  deleteArticleSupply: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.articles}/supplies/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },











  /* ********************** ARTICLE MATERIAL ********************** */

  getArticleMaterials: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload.article_id}/materials?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE ARTCLE MATERIAL
  createArticleMaterial: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.articles + "/materials", payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH ARTCLE MATERIAL
  patchArticleMaterial: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.articles}/materials/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ARTCLE MATERIAL
  deleteArticleMaterial: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.articles}/materials/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },








  /* ********************** ARTICLE LABOR ********************** */

  getArticleLabors: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload.article_id}/labors?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE ARTCLE LABOR
  createArticleLabor: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.articles + "/labors", payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH ARTCLE LABOR
  patchArticleLabor: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.articles}/labors/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ARTCLE LABOR
  deleteArticleLabor: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.articles}/labors/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



















  getArticleParents: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload.article_id}/parents?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  /* ********************** ARTICLE ARTICLE ********************** */

  getArticleArticles: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.articles}/${payload.article_id}/articles?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE ARTCLE ARTICLE
  createArticleArticle: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.articles + "/articles", payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== PATCH ARTCLE ARTICLE
  patchArticleArticle: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.articles}/articles/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE ARTCLE ARTICLE
  deleteArticleArticle: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.articles}/articles/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

}

export default ArticleSrv