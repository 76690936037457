import { endpoints } from "helpers/apiConfigs"
import axiosApi from "../helpers/axiosConfig"

const TaskSrv = {
  // =============== GET LIST
  getTasksPlanning: payload => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
          else if (payload.filters[key] === false) filters += `&${key}=false`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi
        .get(`${endpoints.tasks}/planning?${filters}`)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },
    // =============== GET One
    getOneTask: payload => {
           return new Promise((resolve, reject) => {
        return axiosApi.get(`${endpoints.tasks}/${payload?._id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => reject(e))
      })
    },
  

  // =============== GET LIST TASKS
  getTasks: payload => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
          else if (payload.filters[key] === false) filters += `&${key}=false`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi
        .get(`${endpoints.tasks}?${filters}`)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => reject(e))
    })
  },

  // =============== UPDATE TASK
  updateTasksPlanning: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .patch(`${endpoints.tasks}/${payload?.id}`, payload?.data)
        .then(resp => {
          console.log({ resp })
          resolve(resp)
        })
        .catch(e => {
          console.log({ e })
          reject(e)
        })
    })
  },

  // =============== UPDATE TASK ADDRESS
  updateTasksPlanningAddress: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .patch(`${endpoints.tasks}/address/${payload?.id}`, payload?.data)
        .then(resp => {
          console.log({ resp })
          resolve(resp)
        })
        .catch(e => {
          console.log({ e })
          reject(e)
        })
    })
  },
  cutTask: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .patch(`${endpoints.tasks}/cut-task/${payload?.id}`, payload?.data)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => {
          console.log({ e })
          reject(e)
        })
    })
  },
  getDates: payload => {
    return new Promise((resolve, reject) => {
      return axiosApi
        .post(`${endpoints.tasks}/dates`, payload)
        .then(resp => {
          resolve(resp)
        })
        .catch(e => {
          console.log({ e })
          reject(e)
        })
    })
  },
  getDateRanges: payload => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
          else if (payload.filters[key] === false) filters += `&${key}=false`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi
        .get(`${endpoints.taskDateRanges}?${filters}`)
        .then(resp => {
          resolve(resp?.data?.data)
        })
        .catch(e => reject(e))
    })
  },
}

export default TaskSrv
