// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const UserSrv = {

  register: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.root}/signup`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  reset: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.root}/password/reset`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  forget: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.root}/password/forgot`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  checkSubscription: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.root}/subscription/check`, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== GET PERMISSTIONS
  getPermissions: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.permissions}/me`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  
  
  // =============== GET MY PROFILE
  getConnectedProfile: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.users}/me`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },



  // =============== EDIT MY PROFILE
  editConnectedProfile: (payload) => {
    let link = endpoints.root
    switch (payload?.user_type) {
      case "STAFF": link = endpoints.staffs+"/me"; break;
      case "CLIENT": link = endpoints.clients+"/me"; break;
      case "PROVIDER": link = endpoints.providers+"/me"; break;
      case "SUPER_ADMIN": 
      case "ADMIN": link = endpoints.users+"/me"; break

      default: break;
    }
    
    return new Promise((resolve, reject) => {
      return axiosApi.patch(link, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
    
  },



  // =============== CHANGE USER PASS
  editPassword: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(endpoints.users+"/change-password", payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
    
  },


  // =============== SEND CONTACT MSG
  sendContactMsg: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.contact, payload?.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
    
  },
  
}

export default UserSrv