import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListMaterialsPage = React.lazy(() => import('./list-materials.page'));
const AddMaterialPage = React.lazy(() => import('./add-material.page'));
const EditMaterialPage = React.lazy(() => import('./edit-material.page'));
const PreviewMaterialPage = React.lazy(() => import('./preview-material.page'));

const Materials = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListMaterialsPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddMaterialPage {...props} />}
      />

      <Route path={`${match.url}/edit/:materialId`}
        render={props => <EditMaterialPage {...props} />}
      />


      <Route path={`${match.url}/preview/:materialId`}
        render={props => <PreviewMaterialPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Materials;