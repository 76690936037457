/* STOCK_TYPES */
export const GET_STOCK_TYPES = "GET_STOCK_TYPES"
export const GET_STOCK_TYPES_SUCCESS = "GET_STOCK_TYPES_SUCCESS"
export const GET_STOCK_TYPES_FAIL = "GET_STOCK_TYPES_FAIL"

export const GET_ONE_STOCK_TYPE = "GET_ONE_STOCK_TYPE"
export const GET_ONE_STOCK_TYPE_SUCCESS = "GET_ONE_STOCK_TYPE_SUCCESS"
export const GET_ONE_STOCK_TYPE_FAIL = "GET_ONE_STOCK_TYPE_FAIL"

export const CREATE_STOCK_TYPE = "CREATE_STOCK_TYPE"
export const CREATE_STOCK_TYPE_SUCCESS = "CREATE_STOCK_TYPE_SUCCESS"
export const CREATE_STOCK_TYPE_FAIL = "CREATE_STOCK_TYPE_FAIL"

export const PATCH_STOCK_TYPE = "PATCH_STOCK_TYPE"
export const PATCH_STOCK_TYPE_SUCCESS = "PATCH_STOCK_TYPE_SUCCESS"
export const PATCH_STOCK_TYPE_FAIL = "PATCH_STOCK_TYPE_FAIL"

export const DELETE_STOCK_TYPE = "DELETE_STOCK_TYPE"
export const DELETE_STOCK_TYPE_SUCCESS = "DELETE_STOCK_TYPE_SUCCESS"
export const DELETE_STOCK_TYPE_FAIL = "DELETE_STOCK_TYPE_FAIL"