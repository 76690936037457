import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import SelectReact from 'react-select';

import { NavLink, useHistory } from "react-router-dom";

import classnames from "classnames"


import {
  Label, Card, CardBody, CardHeader, Input, Row, Col,
  Button, UncontrolledAlert, Spinner, Nav, NavItem, TabContent, TabPane,
} from "reactstrap"
import { NavLink as NavLinkTab } from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

import {
  editStaff, getListRoles, getOneStaff,
  getListLicenses, getListStaffs, getListMiddleSchools, getConnectedProfile, editConnectedProfile, editPassword
} from 'store/actions'
import { endpoints } from 'helpers/apiConfigs';

import { isFunction, isValidEmail } from 'helpers/utils';

import PlacesAutocomplete, {
  geocodeByAddress, getLatLng, geocodeByPlaceId
} from 'react-places-autocomplete';


import GoogleMapReact from 'google-map-react';
import moment from 'moment';

import marker_red from "assets/images/marker-red.ico";
import Select from 'react-select';
import AdresseSrv from 'services/AdresseSrv';

const EditStaffCmp = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    staff_id, back_path, is_profile, is_return_mdl, onCancel, onChange
  } = props

  // HOOKS
  const { roles } = useSelector(({ RoleState }) => RoleState);
  const { staff, staffs, loading_edit_staff, loading_get_staff } = useSelector(({ StaffState }) => StaffState);
  const { licenses } = useSelector(({ LicenseState }) => LicenseState);
  const { middle_schools } = useSelector(({ MiddleSchoolState }) => MiddleSchoolState);



  const [user_profile, setUserProfile] = useState(null)
  const [loading_edit_profile, setLoadingEditProfile] = useState(false)
  const [loading_edit_pass, setLoadingEditPass] = useState(false)

  const [msg_check, setMsgCheck] = useState("")
  const [active_tab, setActiveTab] = useState("1")

  const [loading_get_profile, setLoadingGetProfile] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_pass, setConfirmPassword] = useState("")


  const [picture, setPicture] = useState(null)
  const [picture_link, setPictureLink] = useState("")


  const [ref, setRef] = useState('')
  const [firstName, setName] = useState("")
  const [lastName, setLastName] = useState("")

  const [email, setEmail] = useState("")
  const [personalNumber, setPersonalNumber] = useState("")
  const [birthDate, setBirthDate] = useState("")
  const [professionalNumber, setProfessionalNumber] = useState("")
  const [personalEmail, setPersonalEmail] = useState("")

  const [region, setRegion] = useState("")
  const [fullAddress, setAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [latadress, setLatAdress] = useState(0) //useState(46.00)
  const [lngadress, setLngAdress] = useState(0)
  const [department, setDepartment] = useState("")
  const [additionAddress, setAdditionAddress] = useState("")

  const [fullAddressHome, setFullAddressHome] = useState("")
  const [cityHome, setCityHome] = useState("")
  const [regionHome, setRegionHome] = useState("")
  const [latHome, setLatHome] = useState(0)
  const [lngHome, setLngHome] = useState(0)
  const [departmentHome, setDepartmentHome] = useState("")
  const [postalCodeHome, setPostalCodeHome] = useState("")
  const [additionAddressHome, setAdditionAddressHome] = useState("")


  const [licences, setLicences] = useState([])


  const [contacts, setContacts] = useState([{ contactFullName: "", contactFullPhone: "" }]);

  const [roles_options, setRolesOptions] = useState([])
  const [staff_options, setStaffOptions] = useState([])

  useEffect(() => {
    if (roles && roles?.length) {
      setRolesOptions(roles?.map(v => (
        {
          label: v?.name,
          value: v?._id
        }
      )))
    }
  }, [roles])

  useEffect(() => {
    if (staffs && staffs?.length) {
      setStaffOptions(staffs?.map(v => (
        {
          label: (v?.profile?.firstName || '') + " " + (v?.profile?.lastName || ''),
          value: v?._id
        }
      )))
    }
  }, [staffs])

  const handleSelectAddress = async (address, placeId, is_attach = false) => {

    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    try {
      if (is_attach) {
        setLatHome(latLng.lat)
        setLngHome(latLng.lng)
      } else {
        setLatAdress(latLng.lat)
        setLngAdress(latLng.lng)
      }
    } catch (error) { }

    setCloseSuggessions(true)
    try {
      const [place] = await geocodeByPlaceId(placeId);
      const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};
      if (postalCode === "") {
        await AdresseSrv.getReverse({
          filters: { lat: latLng?.lat, lon: latLng?.lng }
        }).then(res => {
          if (res?.features?.[0]?.properties?.postcode)
            if (is_attach) setPostalCodeHome(res?.features?.[0]?.properties?.postcode)
            else setPostalCode(res?.features?.[0]?.properties?.postcode)
        })
          .catch(e => {
            console.log(e)
          })
      } else {
        if (is_attach) setPostalCodeHome(postalCode)
        else setPostalCode(postalCode)
      }




      const { long_name: department = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};

      if (is_attach) setDepartmentHome(department)
      else setDepartment(department)

      const { long_name: region = '' } = place.address_components.find(c => c.types.includes("administrative_area_level_1")) || {};

      if (is_attach) setRegionHome(region)
      else setRegion(region)

      const { long_name: city = '' } = place.address_components.find(c => c.types.includes("locality")) || {};
      if (is_attach) setCityHome(city)
      else setCity(city)
    } catch (error) { }
  }


  const [role, setRole] = useState([])
  const [middleSchool, setMiddleSchool] = useState("")
  const [responsable, setResponsable] = useState([])
  // const [permis, setPermis] = useState([])

  const [medicalDate, setMedicalDate] = useState("")
  const [medicalLimitDate, setMedicaLimitDate] = useState("")
  const [medicalDocument, setMedicalDocument] = useState(null);

  const [frontLicensePicture, setFrontLicensePicture] = useState(null);
  const [frontLicensePicture_link, setFrontLicensePictureLink] = useState("");
  const [backLicensePicture, setBackLicensePicture] = useState(null);
  const [backLicensePictureLink, setBackLicensePictureLink] = useState("");

  const [close_suggessions, setCloseSuggessions] = useState(false)
  const [close_suggessions_post, setCloseSuggessionsPost] = useState(false)
  const [licences_list, setLicensesList] = useState([])
  const center = { lat: 48.84, lng: 2.20 };
  // Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };


  useEffect(() => {
    if (!is_profile) {
      dispatch(getListRoles({
        offset: 0, limit: 500, filters: {
          sort: "name"
        }
      }, (resp) => { }, (err) => { }))


      dispatch(getListLicenses({
        offset: 0, limit: 500, filters: {
          sort: "name"
        }
      }, (resp) => {
        let licences_list = []

        if (licenses)
          for (let i = 0; i < licenses.length; i++) {
            licences_list.push({
              value: licenses[i]._id,
              label: licenses[i].name
            })
            setLicensesList(licences_list)
          }
      }, (err) => { }))

      dispatch(getListMiddleSchools({
        offset: 0, limit: 500, filters: {
          sort: "name"
        }
      }, (resp) => { }, (err) => { }))

      dispatch(getListStaffs({
        offset: 0, limit: 500, filters: {
          sort: "firstName"
        }
      }, (resp) => { }, (err) => { }))
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (staff_id && staff_id !== "") {
      setMsgCheck("");

      if (is_profile) {
        setLoadingGetProfile(true)
        dispatch(getConnectedProfile({}, (resp) => {
          setUserProfile(resp?.profile)
          fillStaffData(resp?.profile)
          setLoadingGetProfile(false)
        }, (error) => {
          setLoadingGetProfile(false)
        }))
      }
      else {
        // console.log("===> staff", staff, loading_get_staff && staff?._id !== staff_id);
        //  || middleSchool !== staff?.college

        // if (!loading_get_staff && (staff?._id !== staff_id))
        dispatch(getOneStaff({ _id: staff_id }, (resp) => {
          fillStaffData(resp.staff)
        }, (error) => { }))
        // else fillStaffData(staff)
      }
    }

    setOldPassword('')
    setPassword('')
    setConfirmPassword('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff_id])

  const fillStaffData = (new_staff) => {
    if (new_staff) {
      setRef(new_staff.ref)
      setName(new_staff.profile?.firstName);
      setLastName(new_staff.profile?.lastName)

      setEmail(new_staff.email)
      setPersonalNumber(new_staff.personalNumber)
      setProfessionalNumber(new_staff.professionalNumber)
      setPersonalEmail(new_staff.personalEmail || "")

      setBirthDate(new_staff.profile?.birthDate)

      setPostalCode(new_staff.profile?.address?.postalCode)
      setCity(new_staff.profile?.address?.city);
      setAddress(new_staff.profile?.address?.fullAddress)
      setDepartment(new_staff.profile?.address?.department)
      setRegion(new_staff.profile?.address?.region);
      setAdditionAddress(new_staff.profile?.address?.additionAddress);

      if (new_staff.profile?.address?.location?.coordinates) {
        setLatAdress(new_staff.profile?.address?.location?.coordinates?.[0]);
        setLngAdress(new_staff.profile?.address?.location?.coordinates?.[1]);
      }


      setPostalCodeHome(new_staff.attachmentAddress?.postalCode)
      setCityHome(new_staff.attachmentAddress?.city);
      setFullAddressHome(new_staff.attachmentAddress?.fullAddress)
      setDepartmentHome(new_staff.attachmentAddress?.department)
      setRegionHome(new_staff.attachmentAddress?.region);
      setAdditionAddressHome(new_staff.attachmentAddress?.additionAddress);

      if (new_staff.attachmentAddress?.location?.coordinates) {
        setLatHome(new_staff.attachmentAddress?.location?.coordinates?.[0]);
        setLngHome(new_staff.attachmentAddress?.location?.coordinates?.[1]);
      }


      if (new_staff.profile?.picture) setPictureLink(new_staff.profile?.picture.path)
      if (new_staff?.roles && new_staff?.roles?.length) {
        setRole(new_staff.roles?.map(v => v?._id))
      }
      if (new_staff?.responsable && new_staff?.responsable?.length) {
        setResponsable(new_staff.responsable?.map(v => v?._id))
      }
      if (new_staff.college) {
        if (typeof new_staff.college === "string") setMiddleSchool(new_staff.college)
        else setMiddleSchool(new_staff.college._id)
      }
      // if (new_staff.responsable) setResponsable(new_staff.responsable?._id)

      if (Array.isArray(new_staff.licences)) {
        const new_licenses = [];
        for (const lc of new_staff.licences) {
          if (lc && lc.licence) new_licenses.push({
            ...lc,
            licenceGettingDate: moment(lc.licenceGettingDate).format("YYYY-MM-DD"),
            licenseControlDate: moment(lc.licenseControlDate).format("YYYY-MM-DD"),
            licenseLimitDate: moment(lc.licenseLimitDate).format("YYYY-MM-DD"),

            value: lc.licence?._id,
            label: lc.licence?.name,
          });
        }

        setLicences(new_licenses)
      }

      if (new_staff.frontLicensePicture) setFrontLicensePictureLink(new_staff.frontLicensePicture.path)
      if (new_staff.backLicensePicture) setBackLicensePictureLink(new_staff.backLicensePicture.path);


      setMedicalDate(new_staff.medicalDate)
      setMedicaLimitDate(new_staff.medicalLimitDate)
      setMedicalDocument(new_staff.medicalDocument)


      if (new_staff.contacts) setContacts(new_staff.contacts)
    }
  }



  const checkData = () => {
    if (firstName === "" || lastName === "") {
      return "Le nom est obligatoire.";
    }
    if (!email || !isValidEmail(email)) {
      return "L'e-mail Professionnel est obligatoire."
    }

    if (personalNumber?.length < 6) {
      return "Le télèphone Personnel est obligatoire."
    }

    if (!role || !role?.length) {
      return "Le métier est obligatoire."
    }

    if (!professionalNumber || professionalNumber === "") {
      return "Télèphone Professionnel est obligatoire."
    }

    if (!middleSchool || middleSchool === "") {
      return "Collège de rattachement est obligatoire."
    }

    if (fullAddress === "") return "L'adresse est obligatoire."
    if (!latadress || !lngadress) return "Vérifier les coordonnées de l'adresse."

    if (fullAddressHome === "") return "L'adresse rattachement est obligatoire."
    if (!latHome || !lngHome) return "Vérifier les coordonnées de l'adresse rattachement."

    if (licences) {
      for (let i = 0; i < licences?.length; i++) {
        const element = licences[i];
        if (!element ||
          !element.licenceGettingDate || element.licenceGettingDate === '' || element.licenceGettingDate === "Invalid date" ||
          !element.licenseLimitDate || element.licenseLimitDate === '' || element.licenseLimitDate === "Invalid date" ||
          !element.licenseControlDate || element.licenseControlDate === '' || element.licenseControlDate === "Invalid date"
        ) {
          return "Date permis sont obligatoires."
        }
      }
    }

    return ""
  }

  const dropSteps = (index) => {

    const newContacts = [...contacts];
    if (contacts.length > 1)
      newContacts.splice(index, 1);
    setContacts(newContacts)
  }


  const changeLicenceAttr = (indx, key, value) => {
    try {
      const new_licences = [...licences]
      new_licences[indx][key] = value
      setLicences(new_licences)
    } catch (error) { }
  }

  const submitEdit = () => {
    const check = checkData(); setMsgCheck(check)
    if (contacts) {
      for (let i = 0; i < contacts?.length; i++) {
        const element = contacts[i];
        if (element && element.fullName === '' && element.phone === '' && element.email === '') {
          contacts.splice(i, 1);
          i--;
        }

      }
    }

    const formData = new FormData();
    if (check === "") {
      if (picture) formData.append('picture', picture);
      if (ref) formData.append('ref', ref)
      if (firstName) formData.append('firstName', firstName);
      if (lastName) formData.append('lastName', lastName);
      if (email) formData.append('email', email.toLowerCase());

      if (professionalNumber) formData.append('professionalNumber', professionalNumber);
      if (personalEmail) formData.append('personalEmail', personalEmail);

      if (personalNumber) formData.append('personalNumber', personalNumber);

      if (birthDate) formData.append('birthDate', birthDate);

      if (fullAddress) formData.append('fullAddress', fullAddress);
      if (city) formData.append('city', city);
      if (region) formData.append('region', region);
      if (postalCode) formData.append('postalCode', postalCode);
      if (department) formData.append('department', department);
      if (latadress) formData.append('lat', latadress);
      if (lngadress) formData.append('lng', lngadress);
      if (additionAddress) formData.append('additionAddress', additionAddress);



      if (fullAddressHome) formData.append('fullAddressHome', fullAddressHome);
      if (cityHome) formData.append('cityHome', cityHome);
      if (regionHome) formData.append('regionHome', regionHome);
      if (postalCodeHome) formData.append('postalCodeHome', postalCodeHome);
      if (departmentHome) formData.append('departmentHome', departmentHome);
      if (latHome) formData.append('latHome', latHome);
      if (lngHome) formData.append('lngHome', lngHome);
      if (additionAddressHome) formData.append('additionAddressHome', additionAddressHome);



      if (medicalDocument && !medicalDocument.owner) formData.append('medicalDocument', medicalDocument);
      if (medicalDate) formData.append('medicalDate', medicalDate);
      if (medicalLimitDate) formData.append('medicalLimitDate', medicalLimitDate);

      if (role && role?.length) formData.append('roles[]', JSON.stringify(role));
      if (responsable && responsable?.length) formData.append('responsable[]', JSON.stringify(responsable));

      // if (responsable) formData.append('responsable', responsable)

      if (middleSchool) formData.append('college', middleSchool)

      if (frontLicensePicture) formData.append('frontLicensePicture', frontLicensePicture);
      if (backLicensePicture) formData.append('backLicensePicture', backLicensePicture);

      try {
        formData.append('licences', JSON.stringify(licences));
      } catch (error) { }

      if (contacts) formData.append('contacts', JSON.stringify(contacts));

      if (is_profile) {
        setLoadingEditProfile(true);

        dispatch(editConnectedProfile({
          // _id: user_profile?._id,
          user_type: "STAFF",
          data: formData
        }, (resp) => {
          setLoadingEditProfile(false);
          if (back_path && back_path !== "") history.push(back_path);
        }, (error) => {
          setLoadingEditProfile(false);
          window.scrollTo(0, 0)
          setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
        }))
      }





      else dispatch(editStaff({
        _id: staff?._id,
        data: formData
      }, (resp) => {
        if (isFunction(onChange)) onChange(resp?.staff)
        if (back_path && back_path !== "") history.push(back_path);

        // else history.push(`/staffs/edit/${staff_id}`);
      }, (error) => {
        window.scrollTo(0, 0)
        setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
      }))
    }

    else window.scrollTo(0, 0)
  }

  const submitChangePassword = () => {
    setLoadingEditPass(true);
    setMsgCheck('');

    dispatch(editPassword({
      data: { oldPassword, password, confirmPassword: confirm_pass }
    }, (resp) => {
      setLoadingEditPass(false);
      setPassword('')
      setOldPassword('')
      setConfirmPassword('')
      // if (back_path && back_path !== "") history.push(back_path);
    }, (error) => {
      setLoadingEditPass(false);
      window.scrollTo(0, 0)
      setMsgCheck(error?.message || "Veuillez vérifier vos données et réessayer")
    }))
  }

  const onChangePicture = (e, key = "picture") => {
    if (e.target.files && e.target.files[0]) {
      switch (key) {
        case "picture":
          setPictureLink(URL.createObjectURL(e.target.files[0]))
          setPicture(e.target.files[0])
          break;
        case "front":
          setFrontLicensePicture(e.target.files[0])
          setFrontLicensePictureLink(URL.createObjectURL(e.target.files[0]))
          break;
        case "back":
          setBackLicensePicture(e.target.files[0])
          setBackLicensePictureLink(URL.createObjectURL(e.target.files[0]))
          break;
        case "medical_doc":
          setMedicalDocument(e.target.files[0])
          break;
        default: break;
      }
    }
  };

  const renderImage = (img, img_url) => {
    if (img && img_url && img_url !== "") return (<img className="img" src={img_url} alt={staff?.name || ""} />)
    if (!img && img_url && img_url !== "") {
      if (is_profile) return (<img className="img" src={`${endpoints.root}/${user_profile?.profile.picture.path}`} alt={""} />)
      else return (<img className="img" src={`${endpoints.root}/${staff?.profile.picture.path}`} alt={staff?.name || ""} />)
    }


    return null
  }

  const renderPic = (img, img_url, pic = "") => {
    if (img && img_url && img_url !== "") return (<img className="img" src={img_url} alt={staff?.name || ""} />)
    if (!img && img_url && img_url !== "") return (<img className="img" src={`${endpoints.root}/${staff[pic]?.path}`} alt={pic} />)

    return null
  }

  if (is_profile) {
    if (staff_id !== user_profile?._id) return (<div className="page-content text-center">
      {loading_get_profile && <Spinner color="primary" />}
    </div>);
  }
  else {
    if (staff_id !== staff?._id) return (<div className="page-content text-center">
      {loading_get_staff && <Spinner color="primary" />}
    </div>);
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {msg_check !== "" && <UncontrolledAlert color="danger" className="hide-alert-close" role="alert" >
            <i className="mdi mdi-block-helper me-2"></i>{msg_check}
          </UncontrolledAlert>}

          <Row>
            <Col md="12" className="hr-divider mb-4">
              <h4 className="divider-name">Informations Générales</h4>
              <hr />
            </Col>
            {/* UPLOAD IMAGE */}
            <Col md="3">
              <div className="attach-wrapper">
                <Label className="attach-label" htmlFor="logo-file">

                  {picture_link === "" ? <div className="center_both">
                    <i className="mdi mdi-camera icon-cam"></i>
                    <span className="attach-holder">Image </span>
                  </div> :
                    renderImage(picture, picture_link)}


                </Label>



                <input onChange={onChangePicture} id="logo-file" type="file" accept="image/*" />
              </div>
            </Col>

            <Col md="9">
              <Row>
                <Col md="4"><div className="mb-3">
                  <Label className="frm-label">Matricule</Label>
                  {is_profile ?
                    <Input defaultValue={ref} readOnly className="form-control" placeholder="Matricul" /> :
                    <Input defaultValue={ref} onChange={(e) => setRef(e.target.value)} className="form-control" placeholder="Matricul" />
                  }
                </div>
                </Col>

                <Col md="4"><div className="mb-3">
                  <Label className="frm-label">Nom</Label>
                  <Input defaultValue={firstName} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Nom de Staff" />
                </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Prénom</Label>
                    <Input defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} className="form-control" placeholder="Prénom de Staff" />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Date naissance </Label>
                    <input className="form-control" value={moment(birthDate).format("YYYY-MM-DD")} type="date" placeholder="Date 3" onChange={(e) => setBirthDate(e.target.value)} />
                  </div>
                </Col>



                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">E-mail</Label>
                    <Input defaultValue={personalEmail} onChange={(e) => setPersonalEmail(e.target.value)} className="form-control" placeholder="E-mail de personel" />
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-3">
                    <Label className="frm-label">Télèphone Personnel</Label>

                    <PhoneInput className="form-control"
                      country={'fr'}
                      value={personalNumber}
                      onChange={personalNumber => setPersonalNumber(personalNumber)}
                    />
                  </div>
                </Col>


              </Row>
            </Col>









            {/* INFOS PRO */}
            <Col md="12" className="hr-divider mb-2 mt-4">
              <h4 className="divider-name">Informations professionnelles</h4>
              <hr />
            </Col>
            {!is_profile ? <React.Fragment>
              <Col md="6">
                <div className="mb-3">
                  <Label className="frm-label">Métier</Label>
                  <Select
                    isMulti
                    name="roles"
                    onChange={(e) => {
                      setRole(e?.map(v => v?.value) || [])
                    }}
                    value={roles_options?.filter(option => role?.includes(option.value))}
                    options={roles_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sélectionner métier"
                  />

                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="frm-label">Responsable</Label>
                  {/* <select onChange={(e) => {
                    setResponsable(e?.target?.value || "")
                  }} value={responsable} className="form-control frm-select">
                    <option disabled value="" >Sélectioner</option>
                    {staffs?.map((item, i) => <option key={"item_" + i} value={item._id}>{item.profile.firstName || ""}
                      {" "}
                      {item.profile.lastName || ""}</option>)}
                  </select> */}
                     <Select
                    isMulti
                    name="responsables"
                    onChange={(e) => {
                      setResponsable(e?.map(v => v?.value) || [])
                    }}
                    value={staff_options?.filter(option => responsable?.includes(option.value))}
                    options={staff_options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sélectionner responsable"
                  />

                </div>
              </Col>
            </React.Fragment> : null}




            <Col md="6">
              <div className="mb-3">
                <Label className="frm-label">Télèphone Professionnel</Label>

                <PhoneInput className="form-control"
                  country={'fr'}
                  value={professionalNumber}
                  onChange={professionalNumber => setProfessionalNumber(professionalNumber)}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="frm-label">E-mail Professionnel</Label>
                <Input defaultValue={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="E-mail Professionnel" />
              </div>
            </Col>
            {!is_profile ? <Col md="4">
              <div className="mb-3">
                <Label className="frm-label">Collège de rattachement</Label>
                <select onChange={(e) => {
                  setMiddleSchool(e?.target?.value)
                }} value={middleSchool} className="form-control frm-select">
                  <option disabled value="" >Sélectioner</option>
                  {middle_schools?.map((item, i) => <option key={"item_" + i} value={item._id}>{item.name}</option>)}
                </select>
              </div>
            </Col> : null}


            {!is_profile ? <Col md="12" className="hr-divider mb-2 mt-2">
              <h4 className="divider-name">Adresse</h4>
              <hr />
            </Col> : null}


            <Col md="12">
              {!is_profile ? <hr /> : null}

              <Nav tabs className="nav-tabs-custom">

                <NavItem style={{ padding: 5 }}>
                  <NavLinkTab
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: active_tab === "1",
                    })}
                    onClick={() => {
                      setActiveTab("1")
                    }}
                  >
                    <span className="d-none d-sm-block"><i className="mdi mdi-map-marker" /> Adresse personnel
                    {(!latadress || !lngadress) ?
                      <i className="mdi mdi-alert-circle-outline hilighted" style={{ fontSize: 17, marginLeft: 10 }} title="Adresse incomplète" /> : null
                    }
                    </span>
                    
                  </NavLinkTab>
                </NavItem>
                {!is_profile ? <NavItem style={{ padding: 5 }}>
                  <NavLinkTab
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: active_tab === "2",
                    })}
                    onClick={() => {
                      setActiveTab("2")
                    }}
                  >
                    <span className="d-none d-sm-block"><i className="mdi mdi-map-outline" /> Adresse Rattachement 
                    {(!latHome || !lngHome) ?
                      <i className="mdi mdi-alert-circle-outline hilighted" style={{ fontSize: 17, marginLeft: 10 }} title="Adresse rattachement incomplète" /> : null
                    }
                    </span>
                  </NavLinkTab>
                </NavItem> : null}
              </Nav>

              <TabContent activeTab={active_tab} className="p-3 text-muted">


                {/* PERSONAL ADDRESS */}
                <TabPane tabId="1">
                  <Row>
                    <Col md="8">
                      <Row>
                        {/* ADDRESS */}
                        <Col md="8">
                          <div className="mb-3">
                            <Label className="frm-label">Adresse</Label>

                            <PlacesAutocomplete searchOptions={{
                              componentRestrictions: { country: ['FR'] },
                              bounds: defaultBounds,
                              stricBounds: false,
                              fields: ["address_components", "geometry", "icon", "name"],

                              types: ['address'],
                            }}
                              value={fullAddress}
                              onChange={(rslt) => {
                                try {
                                  setAddress(rslt)
                                } catch (error) { }

                                setCloseSuggessions(false);
                              }}
                              onSelect={(address, placeId, suggestion) => {
                                if (suggestion && suggestion.description) {
                                  setAddress(suggestion.description)
                                  handleSelectAddress(suggestion.description, suggestion.placeId)
                                }

                              }}

                              autocomplete="off"

                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                return (
                                  <div style={{ width: "100%" }}>
                                    <input
                                      {...getInputProps({
                                        placeholder: 'Adress...',
                                        className: 'form-control form-control',
                                      })}
                                    />
                                    {!close_suggessions && <div className="autocomplete-dropdown-container" style={{}}>
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, i) => {

                                        return (
                                          <div key={"ADRS_SUGG_start_indx_" + i}
                                            {...getSuggestionItemProps(suggestion, {
                                              className: suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item', style: {}
                                            })}

                                            onClick={() => {
                                              if (suggestion && suggestion.description) {
                                                setAddress(suggestion.description)
                                                handleSelectAddress(suggestion.description, suggestion.placeId)
                                              }

                                            }}

                                          >
                                            <span>{suggestion?.description || ""}</span>
                                          </div>
                                        );
                                      })}
                                    </div>}
                                  </div>
                                )
                              }
                              }
                            </PlacesAutocomplete>
                          </div>
                        </Col>

                        {/* ZIP CODE */}
                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Code postal</Label>

                            <PlacesAutocomplete searchOptions={{
                              componentRestrictions: { country: ['FR'] },
                              types: ['geocode']
                            }}
                              value={postalCode}
                              onChange={(rslt) => {
                                try {
                                  setPostalCode(rslt.split(" ")[0])
                                } catch (error) { }

                                setCloseSuggessionsPost(false);
                              }}
                              onSelect={(rslt) => {
                                try {
                                  setPostalCode(rslt.split(" ")[0])
                                } catch (error) { }
                              }}

                              autocomplete="off"
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{ width: "100%" }}>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Code postal...',
                                      className: 'form-control form-control'
                                    })}
                                  />
                                  {!close_suggessions_post && <div className="autocomplete-dropdown-container" style={{}}>
                                    {loading && <div>Chargement...</div>}
                                    {suggestions.map((suggestion, i) => {

                                      return (
                                        <div key={"ZIP_SUGG_start_indx_" + i}
                                          {...getSuggestionItemProps(suggestion, {
                                            className: suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item', style: {}
                                          })}

                                          onClick={() => {
                                            setPostalCode(suggestion?.terms[0]?.value)
                                            setAddress(suggestion?.description || "")

                                            geocodeByAddress(suggestion.description)
                                              .then(results => {

                                                try {
                                                  const adrs_cmp = results[0].address_components

                                                  setCity(adrs_cmp[1].long_name)
                                                  setDepartment(adrs_cmp[2].long_name)
                                                  setRegion(adrs_cmp[3].long_name)

                                                } catch (error) { }

                                                setCloseSuggessionsPost(true)
                                              })


                                              .catch(error => {

                                              });

                                            geocodeByAddress(suggestion?.description)
                                              .then(results => getLatLng(results[0]))
                                              .then((result) => {

                                                try {
                                                  setLatAdress(result.lat)
                                                  setLngAdress(result.lng)
                                                } catch (error) { }
                                              })
                                          }}
                                        >
                                          <span>{suggestion?.terms[0]?.value + " " + (suggestion?.terms[1]?.value) || ""}</span>
                                        </div>
                                      );
                                    })}
                                  </div>}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>

                        </Col>


                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Département</Label>
                            <Input value={department} onChange={(e) => setDepartment(e.target.value)} className="form-control" placeholder="Département" />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Région</Label>
                            <Input value={region} onChange={(e) => setRegion(e.target.value)} className="form-control" placeholder="Région" />
                          </div>
                        </Col>


                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Ville</Label>
                            <Input value={city} onChange={(e) => setCity(e.target.value)} className="form-control" placeholder="Ville" />
                          </div>
                        </Col>

                        <Col md="12">
                          <div className="mb-3">
                            <Label className="frm-label">Complément d'adresse</Label>
                            <Input value={additionAddress} onChange={(e) => setAdditionAddress(e.target.value)} className="form-control" placeholder="Complément d'adresse" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" className="mb-3">
                      {(latadress && lngadress) ? <div style={{ height: '30vh', width: '100%' }}>

                        <GoogleMapReact
                          bootstrapURLKeys={{ key: endpoints.gmap_key }}
                          center={{ lat: latadress, lng: lngadress }}
                          defaultZoom={11}

                          yesIWantToUseGoogleMapApiInternals={true}

                        >
                          <img src={marker_red} style={{
                            width: 44,
                            marginTop: -58,
                            marginLeft: -22
                          }}
                            lat={latadress} lng={lngadress}
                            alt="#"
                          />
                        </GoogleMapReact>
                      </div> : null}
                    </Col>
                  </Row>
                </TabPane>













                {/* ATTACHMENT ADDRESS */}
                <TabPane tabId="2">
                  <Row>
                    <Col md="8">
                      <Row>
                        {/* ADDRESS */}
                        <Col md="8">
                          <div className="mb-3">
                            <Label className="frm-label">Adresse</Label>

                            <PlacesAutocomplete searchOptions={{
                              componentRestrictions: { country: ['FR'] },
                              bounds: defaultBounds,
                              stricBounds: false,
                              fields: ["address_components", "geometry", "icon", "name"],

                              types: ['address'],
                            }}
                              value={fullAddressHome}
                              onChange={(rslt) => {
                                try {
                                  setFullAddressHome(rslt)
                                } catch (error) { }

                                setCloseSuggessions(false);
                              }}
                              onSelect={(address, placeId, suggestion) => {
                            if (suggestion && suggestion.description) {
                              setFullAddressHome(suggestion.description)
                              handleSelectAddress(suggestion.description, suggestion.placeId)
                            }
                          }}

                              autocomplete="off"

                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{ width: "100%" }}>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Adress...',
                                      className: 'form-control form-control',
                                    })}
                                  />
                                  {!close_suggessions && <div className="autocomplete-dropdown-container" style={{}}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, i) => {

                                      return (
                                        <div key={"ATTACH_ADRS_SUGG_start_indx_" + i}
                                          {...getSuggestionItemProps(suggestion, {
                                            className: suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item', style: {}
                                          })}

                                          onClick={() => {
                                            if (suggestion && suggestion.description) {
                                              setFullAddressHome(suggestion.description)
                                              handleSelectAddress(suggestion.description, suggestion.placeId, true)
                                            }

                                          }}

                                        >
                                          <span>{suggestion?.description || ""}</span>
                                        </div>
                                      );
                                    })}
                                  </div>}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>

                        {/* ZIP CODE ATTACH */}
                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Code postal</Label>

                            <PlacesAutocomplete searchOptions={{
                              componentRestrictions: { country: ['FR'] },
                              types: ['geocode']
                            }}
                              value={postalCodeHome}
                              onChange={(rslt) => {
                                try {
                                  setPostalCodeHome(rslt.split(" ")[0])
                                } catch (error) { }

                                setCloseSuggessionsPost(false);
                              }}
                              onSelect={(rslt) => {
                                try {
                                  setPostalCodeHome(rslt.split(" ")[0])
                                } catch (error) { }
                              }}

                              autocomplete="off"
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div style={{ width: "100%" }}>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Code postal...',
                                      className: 'form-control form-control'
                                    })}
                                  />
                                  {!close_suggessions_post && <div className="autocomplete-dropdown-container" style={{}}>
                                    {loading && <div>Chargement...</div>}
                                    {suggestions.map((suggestion, i) => {

                                      return (
                                        <div key={"ZIP_SUGG_start_indx_" + i}
                                          {...getSuggestionItemProps(suggestion, {
                                            className: suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item', style: {}
                                          })}

                                          onClick={() => {
                                            setPostalCodeHome(suggestion?.terms[0]?.value)
                                            setFullAddressHome(suggestion?.description || "")

                                            geocodeByAddress(suggestion.description)
                                              .then(results => {

                                                try {
                                                  const adrs_cmp = results[0].address_components

                                                  setCityHome(adrs_cmp[1].long_name)
                                                  setDepartmentHome(adrs_cmp[2].long_name)
                                                  setRegionHome(adrs_cmp[3].long_name)

                                                } catch (error) { }

                                                setCloseSuggessionsPost(true)
                                              })


                                              .catch(error => {

                                              });

                                            geocodeByAddress(suggestion?.description)
                                              .then(results => getLatLng(results[0]))
                                              .then((result) => {

                                                try {
                                                  setLatHome(result.lat)
                                                  setLngHome(result.lng)
                                                } catch (error) { }
                                              })
                                          }}
                                        >
                                          <span>{suggestion?.terms[0]?.value + " " + (suggestion?.terms[1]?.value) || ""}</span>
                                        </div>
                                      );
                                    })}
                                  </div>}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>

                        </Col>


                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Département</Label>
                            <Input value={departmentHome} onChange={(e) => setDepartmentHome(e.target.value)} className="form-control" placeholder="Département" />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Région</Label>
                            <Input value={regionHome} onChange={(e) => setRegionHome(e.target.value)} className="form-control" placeholder="Région" />
                          </div>
                        </Col>


                        <Col md="4">
                          <div className="mb-3">
                            <Label className="frm-label">Ville</Label>
                            <Input value={cityHome} onChange={(e) => setCityHome(e.target.value)} className="form-control" placeholder="Ville" />
                          </div>
                        </Col>


                        <Col md="12">
                          <div className="mb-3">
                            <Label className="frm-label">Complément d'adresse</Label>
                            <Input value={additionAddressHome} onChange={(e) => setAdditionAddressHome(e.target.value)} className="form-control" placeholder="Complément d'adresse" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4" className="mb-3">
                      {(latadress && lngadress) ? <div style={{ height: '30vh', width: '100%' }}>

                        <GoogleMapReact
                          bootstrapURLKeys={{ key: endpoints.gmap_key }}
                          center={{ lat: latHome, lng: lngHome }}
                          defaultZoom={11}

                          yesIWantToUseGoogleMapApiInternals={true}

                        >
                          <img src={marker_red} style={{
                            width: 44,
                            marginTop: -58,
                            marginLeft: -22
                          }}
                            lat={latHome} lng={lngHome}
                            alt="#"
                          />
                        </GoogleMapReact>
                      </div> : null}
                    </Col>
                  </Row>
                </TabPane>


              </TabContent>
            </Col>













            {/* ********************************************** LICENSES ********************************************** */}
            {!is_profile ? <React.Fragment>
              <Col md="12" className="hr-divider mb-2 mt-2">
                <h4 className="divider-name">Permis</h4>
                <hr />
              </Col>


              <Col md="6">
                <div className="mb-3">
                  <Label className="frm-label">Permis</Label>
                  <SelectReact styles={{ menu: provided => ({ ...provided, zIndex: 999 }) }}
                    // defaultValue={type_machine_options.length > 0 ? type_machine_options[0] : null}
                    closeMenuOnSelect={false}
                    onChange={(list_licenses) => {
                      try {
                        const new_licences = []
                        if (list_licenses) for (const lc of list_licenses) {

                          let licenseLimitDate = null;
                          let licenseControlDate = null;
                          let licenceGettingDate = null;
                          for (const old_lc of licences) {
                            if (old_lc.value === lc.value) {
                              licenseLimitDate = old_lc.licenseLimitDate || null;
                              licenseControlDate = old_lc.licenseControlDate || null;
                              licenceGettingDate = old_lc.licenceGettingDate || null;
                              break;
                            }
                          }

                          new_licences.push({
                            ...lc,
                            licenseLimitDate,
                            licenseControlDate,
                            licenceGettingDate,

                            licence: {
                              _id: lc.value,
                              name: lc.label
                            }
                          })
                        }

                        setLicences([])
                        setTimeout(() => {
                          setLicences(new_licences || [])
                        }, 100);
                      } catch (error) { }
                    }}
                    placeholder="Sélectioner"
                    value={licences_list?.filter(option => licences.map((l) => l.value).includes(option.value))}
                    // defaultValue={licences_list&&licences_list.filter(option => option.value = permis)}
                    isMulti
                    options={licences_list}

                  />
                </div>

                {Array.isArray(licences) ? licences.map((item, indx) => {
                  if (!item || !item.licence) return null;

                  return (<Row key={"licence_" + indx} >
                    <Col md="12">
                      <p>Permis :  {item.licence.name || ""}</p>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="frm-label">Date d'obtention</Label>
                        <input className="form-control" style={{ padding: 7 }} type="date" value={item.licenceGettingDate} placeholder="Optension" onChange={(e) => {
                          changeLicenceAttr(indx, "licenceGettingDate", e.target.value)
                        }} />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="frm-label">Date de contrôle</Label>
                        <input className="form-control" style={{ padding: 7 }} type="date" value={item.licenseControlDate} placeholder="Contrôle" onChange={(e) => {
                          changeLicenceAttr(indx, "licenseControlDate", e.target.value)
                        }} />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label className="frm-label">Date de validité</Label>
                        <input className="form-control" style={{ padding: 7 }} type="date" value={item.licenseLimitDate} placeholder="Validité" onChange={(e) => {
                          changeLicenceAttr(indx, "licenseLimitDate", e.target.value)
                        }} />
                      </div>
                    </Col>
                  </Row>)
                }) : null}
              </Col>

              <Col md="3">
                <div className="attach-wrapper">
                  <Label className="attach-label" htmlFor="front-license">

                    {frontLicensePicture_link === "" ? <div className="center_both">
                      <i className="mdi mdi-camera icon-cam"></i>
                      <span className="attach-holder">Recto </span>
                    </div> :
                      renderPic(frontLicensePicture, frontLicensePicture_link, "frontLicensePicture")
                    }


                  </Label>



                  <input onChange={(e) => onChangePicture(e, "front")} id="front-license" type="file" accept="image/*" />
                </div>
              </Col>
              <Col md="3">
                <div className="attach-wrapper">
                  <Label className="attach-label" htmlFor="back-license">

                    {backLicensePictureLink === "" ? <div className="center_both">
                      <i className="mdi mdi-camera icon-cam"></i>
                      <span className="attach-holder">Verso </span>
                    </div> :
                      renderPic(backLicensePicture, backLicensePictureLink, "backLicensePicture")
                    }


                  </Label>



                  <input onChange={(e) => onChangePicture(e, "back")} id="back-license" type="file" accept="image/*" />
                </div>
              </Col>










              {/* ********************************************** MEDICAL ********************************************** */}
              <Col md="12" className="hr-divider mb-2 mt-5">
                <h4 className="divider-name">Médicale</h4>
                <hr />
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">Document
                    {(medicalDocument && medicalDocument.originalname) &&
                      <a href={`${endpoints.root}/${medicalDocument.path}`}
                        target="_blank" rel="noreferrer">
                        {" "} ({medicalDocument.originalname})
                      </a>
                    }
                  </Label>
                  <Input className="form-control" type="file" id="licenseLimitDate" onChange={(e) => onChangePicture(e, "medical_doc")} />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">Dernière Visite </Label>
                  <input className="form-control" type="date" value={moment(medicalDate).format("YYYY-MM-DD")} placeholder="Dernière Visite"
                    onChange={(e) => setMedicalDate(e.target.value)}
                  />

                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label className="frm-label">Prochaine Visite </Label>
                  <input className="form-control" type="date" value={moment(medicalLimitDate).format("YYYY-MM-DD")} placeholder="Prochaine Visite"
                    onChange={(e) => setMedicaLimitDate(e.target.value)}
                  />
                </div>
              </Col>

            </React.Fragment> : null}













            {/* ********************************************** CONTACT PERSON********************************************** */}
            <Col md="12" className="hr-divider mb-2 mt-5">
              <h4 className="divider-name">Personne à contacter</h4>
              <hr />
            </Col>

            {contacts.map((item, index) =>
              <Row key={"CONTACTS" + index} >
                <Col md="6">
                  <div className="mb-3">
                    <Label className="frm-label">Personne à contacter</Label>
                    <input className="form-control" placeholder="Nom et Prénom"

                      value={item.contactFullName} onChange={(e) => {
                        const new_contacts = [...contacts];
                        new_contacts[index].contactFullName = e.target.value
                        setContacts(new_contacts)
                      }} />

                  </div>
                </Col>
                <Col md="5">
                  <div className="mb-3">
                    <Label className="frm-label">Numéro de téléphone (Personne à contacter)</Label>
                    <PhoneInput className="form-control"
                      country={'fr'}
                      value={item.contactFullPhone}
                      onChange={(contactFullPhone) => {
                        const new_contacts = [...contacts];
                        new_contacts[index].contactFullPhone = contactFullPhone
                        setContacts(new_contacts)
                      }} />

                  </div>
                </Col>
                <Col md="1" className="my-4">
                  <div onClick={() => dropSteps(index)}>
                    <i
                      style={contacts?.length === 1 ? { cursor: "default", color: "#ddd", fontSize: 25, position: 'absolute' } :
                        { fontSize: 25, position: 'absolute', color: "#C95978" }} className="mdi mdi-close-circle-outline  float-right" />
                  </div>
                </Col>
              </Row>)}
            <Col md="12">
              <span className='burgundy-red' style={{ cursor: 'pointer' }} id="click"
                onClick={() => setContacts([...contacts, { contactFullName: "", contactFullPhone: "" }])}> + Ajouter personne à contacter</span>
            </Col>
          </Row>

          <div className="mt-5">
            <Button onClick={() => {
              if (!loading_edit_staff) submitEdit()
            }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
              Enregistrer {(loading_edit_staff || loading_edit_profile) && <Spinner className="ms-2 small-spinner" color="light" />}
            </Button>

            {(!is_profile && !is_return_mdl) ? <NavLink to="/staffs/list">
              <Button color="secondary" outline className="waves-effect" style={{ width: 127 }}>
                Annuler
              </Button>
            </NavLink> : null}


            {is_return_mdl ? <Button onClick={() => { if (isFunction(onCancel)) onCancel(); }} color="secondary" outline className="waves-effect" style={{ width: 127 }}>Annuler</Button> : null}
          </div>
        </CardBody>
      </Card>


      {is_profile ? <div>
        <hr />
        <Card>
          <CardHeader>
            <h3>Changer mot de passe</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Label>Ancien mot de passe</Label>
                <Input type="password" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
              </Col>
              <Col md="6">
                <Label>Nouveau mot de passe</Label>
                <Input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Col>
              <Col md="6" className="mt-3">
                <Label>Confirmation</Label>
                <Input type="password" className="form-control" value={confirm_pass} onChange={(e) => setConfirmPassword(e.target.value)} />
              </Col>

              <Col md="6" style={{ marginTop: 42 }}>
                <Button disabled={(confirm_pass !== password || !oldPassword || oldPassword === "")} onClick={() => {
                  if (!loading_edit_pass) submitChangePassword()
                }} color="primary" className="btn btn-primary waves-effect waves-light primary-frm" >
                  Changer {(loading_edit_pass) && <Spinner className="ms-2 small-spinner" color="light" />}
                </Button>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div> : null}

    </React.Fragment >
  )
}
EditStaffCmp.propTypes = {
  staff_id: PropTypes.string.isRequired,
  back_path: PropTypes.string,

  is_profile: PropTypes.bool,

  // FOR OPEN FROM MODAL
  is_return_mdl: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
}
export default EditStaffCmp