// import { call, put, takeEvery } from "redux-saga/effects"


// import {
//   GET_ONE_ACTIVITY, PATCH_ACTIVITY
// } from "./actionTypes"
// import {
//   getOneActivitySuccess, getOneActivityFail,
//   patchActivitySuccess, patchActivityFail,
//   // createActivitySuccess, createActivityFail,
// } from "./actions"
//   ;
// import axiosApi from "helpers/axiosConfig";
// import { endpoints } from "helpers/apiConfigs";


// =============== GET ONE
// function* fetchActivity({ payload }) {
//   try {
//     const response = yield call(fetchActivityReq, payload);
//     if (response && response?.data?.success) {
//       yield put(getOneActivitySuccess({
//         activity: response.activity
//       }))
//     }
//     else yield put(getOneActivityFail(response?.data?.error))
//   } catch (error) {
//     yield put(getOneActivityFail(error))
//   }
// }
// const fetchActivityReq = async (payload) => {
//   return await axiosApi.get(`${endpoints.activities}/${payload?.activity_id}`)
//     .then(resp => resp)
//     .catch(error => { return (error && error.response) ? { message: error.response } : {} })
// };



function* activitySaga() {
  // yield takeEvery(PATCH_ACTIVITY, editActivity)
}
export default activitySaga
