import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// const ListClearancesPage = React.lazy(() => import('./list-clearances.page'));
const AddClearancePage = React.lazy(() => import('./add-clearances-page'));
// const EditClearancePage = React.lazy(() => import('./edit-client.page'));
// const PreviewClearancePage = React.lazy(() => import('./preview-client.page'));

const Clearances = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      {/* <Route path={`${match.url}/list`}
        render={props => <ListClearancesPage {...props} />}
      /> */}

      <Route path={`${match.url}/add`}
        render={props => <AddClearancePage {...props} />}
      />

      {/* <Route path={`${match.url}/edit/:clientId`}
        render={props => <EditClearancePage {...props} />}
      />


      <Route path={`${match.url}/preview/:clientId`}
        render={props => <PreviewClearancePage {...props} />}
      /> */}

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Clearances;
