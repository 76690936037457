import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListTasksPage = React.lazy(() => import('./list-tasks.page'));
const MapTasksPage = React.lazy(() => import('./map-tasks.page'));
const PlanMachinesPage = React.lazy(() => import('./plan-machines.page'));
const PlanMaterialsPage = React.lazy(() => import('./plan-materials.page'));

const Clients = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/tasks`} />

      <Route path={`${match.url}/tasks`}
        render={props => <ListTasksPage {...props} />}
      />
      <Route path={`${match.url}/map`}
        render={props => <MapTasksPage {...props} />}
      />

      <Route path={`${match.url}/materials`}
        render={props => <PlanMaterialsPage {...props} />}
      />

      <Route path={`${match.url}/machines`}
        render={props => <PlanMachinesPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/tasks`} />
    </Switch>
  </Suspense>
);
export default Clients;