import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListOrdersPage = React.lazy(() => import('./list-orders.page'));
 const AddOrderPage = React.lazy(() => import('./add-order.page'));
const EditOrderPage = React.lazy(() => import('./edit-order.page'));
const PreviewOrderPage = React.lazy(() => import('./preview-order.page'));

const Orders = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListOrdersPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddOrderPage {...props} />}
      />

      <Route path={`${match.url}/edit/:orderId`}
        render={props => <EditOrderPage {...props} />}
      />


      <Route path={`${match.url}/preview/:orderId`}
        render={props => <PreviewOrderPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Orders;