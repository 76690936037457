import ClientSrv from "services/ClientSrv";

import {
  GET_CLIENTS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_SUCCESS,

  GET_ONE_CLIENT,
  GET_ONE_CLIENT_SUCCESS,
  GET_ONE_CLIENT_FAIL,

  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAIL,
 
  CREATE_CLIENT_USER,
  CREATE_CLIENT_USER_SUCCESS,
  CREATE_CLIENT_USER_FAIL,

  PATCH_CLIENT,
  PATCH_CLIENT_SUCCESS,
  PATCH_CLIENT_FAIL,

  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,

  IMPORT_CLIENTS,
  IMPORT_CLIENTS_SUCCESS,
  IMPORT_CLIENTS_FAIL,

  GET_CLIENT_USERS,
  GET_CLIENT_USERS_SUCCESS,
  GET_CLIENT_USERS_FAIL,

  GET_SUBSCRIBERS,
  GET_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS_FAIL,

  DELETE_CLIENT_INTERLOCUTOR,
  DELETE_CLIENT_INTERLOCUTOR_SUCCESS,
  DELETE_CLIENT_INTERLOCUTOR_FAIL,

  CREATE_INTERLOCUTOR,
  CREATE_INTERLOCUTOR_SUCCESS,
  CREATE_INTERLOCUTOR_FAIL,

  PATCH_SUBSCRIBER,
  PATCH_SUBSCRIBER_SUCCESS,
  PATCH_SUBSCRIBER_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListClients = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_CLIENTS,
    });

    ClientSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clients: response.data.data.clients,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_CLIENTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_CLIENTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createClient = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_CLIENT,
    });

    ClientSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            client: response.data.data.user,
          }

          dispatch({
            type: CREATE_CLIENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_CLIENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
// =============== ADD USER
export const addUser = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_CLIENT_USER,
    });

    ClientSrv.addUser(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            client: response.data.data.user,
          }

          dispatch({
            type: CREATE_CLIENT_USER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_CLIENT_USER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== GET ONE
export const getOneClient = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_CLIENT,
    });

    ClientSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            client: response.data.data.client	,
          }


          dispatch({
            type: GET_ONE_CLIENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_CLIENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editClient = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_CLIENT,
    });

    ClientSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            client: response.data.data.client	,
          }

          dispatch({
            type: PATCH_CLIENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_CLIENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteClient = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_CLIENT,
    });

    ClientSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_CLIENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_CLIENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE CLIENT INTERLOCUTOR
export const deleteClientInterlocutor = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_CLIENT_INTERLOCUTOR,
    });

    ClientSrv.deleteInterlocutor(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_CLIENT_INTERLOCUTOR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_CLIENT_INTERLOCUTOR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== IMPORT
export const importClients = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_CLIENTS,
    });

    ClientSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            clients: response.data.data.clients || [],
          }

          dispatch({
            type: IMPORT_CLIENTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_CLIENTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};




// =============== GET CLIENT USERS
export const getClientUsers = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_USERS,
    });

    ClientSrv.getClientUsers(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            users: response.data.data.allowedUsers || [],
          }


          dispatch({
            type: GET_CLIENT_USERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_CLIENT_USERS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== ADD INTERLOCUTOR
export const createInterlocutor = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_INTERLOCUTOR,
    });

    ClientSrv.createInterlocutor(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            interlocutor: response.data.data.interlocutor,
          }

          dispatch({
            type: CREATE_INTERLOCUTOR_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_INTERLOCUTOR_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};








// =============== GET SUBSCRIBERS (by super admin)
export const getListSubscribers = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_SUBSCRIBERS,
    });

    ClientSrv.getSubscribers(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            subscribers: response.data.data.users,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_SUBSCRIBERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_SUBSCRIBERS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH SUBSCRIBER
export const editSubscriber = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_SUBSCRIBER,
    });

    ClientSrv.patchUser(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            user: response.data.data.user	,
          }

          dispatch({
            type: PATCH_SUBSCRIBER_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_SUBSCRIBER_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
}