import {
  GET_MEAL_BONUSS,
  GET_MEAL_BONUSS_FAIL,
  GET_MEAL_BONUSS_SUCCESS,

  GET_ONE_MEAL_BONUS,
  GET_ONE_MEAL_BONUS_SUCCESS,
  GET_ONE_MEAL_BONUS_FAIL,

  CREATE_MEAL_BONUS,
  CREATE_MEAL_BONUS_SUCCESS,
  CREATE_MEAL_BONUS_FAIL,

  PATCH_MEAL_BONUS,
  PATCH_MEAL_BONUS_SUCCESS,
  PATCH_MEAL_BONUS_FAIL,

  DELETE_MEAL_BONUS,
  DELETE_MEAL_BONUS_SUCCESS,
  DELETE_MEAL_BONUS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  meal_bonus: null,
  meal_bonuses: [],
  error: null,

  loading_get_meal_bonuses: false,
  loading_get_meal_bonus: false,

  loading_add_meal_bonus: false,
  loading_edit_meal_bonus: false,

  count_all_meal_bonuses: 0,
}

const MealBonusState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MEAL_BONUSS:
      return {
        ...state, loading_get_meal_bonuss: true, error: null
      }
    case GET_MEAL_BONUSS_SUCCESS:
      return {
        ...state,
        meal_bonuses: action.payload.meal_bonuses,
        loading_get_meal_bonuses: false, error: null,
        count_all_meal_bonuses: action.payload.counts || 0
      }
    case GET_MEAL_BONUSS_FAIL:
      return {
        ...state,
        loading_get_meal_bonuss: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MEAL_BONUS:
      return {
        ...state, meal_bonus: null, loading_get_meal_bonus: true, error: null
      }
    case GET_ONE_MEAL_BONUS_SUCCESS:
      return {
        ...state,
        meal_bonus: action.payload.meal_bonus,
        loading_get_meal_bonus: false, error: null
      }
    case GET_ONE_MEAL_BONUS_FAIL:
      return {
        ...state,
        loading_get_meal_bonus: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MEAL_BONUS:
      return {
        ...state, meal_bonus: null, loading_add_meal_bonus: true, error: null
      }
    case CREATE_MEAL_BONUS_SUCCESS:
      return {
        ...state,
        meal_bonus: action.payload.meal_bonus,
        loading_add_meal_bonus: false, error: null
      }
    case CREATE_MEAL_BONUS_FAIL:
      return {
        ...state,
        loading_add_meal_bonus: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MEAL_BONUS:
      return {
        ...state, loading_edit_meal_bonus: true, error: null
      }
    case PATCH_MEAL_BONUS_SUCCESS:
      return {
        ...state,
        meal_bonus: action.payload.meal_bonus,
        loading_edit_meal_bonus: false, error: null
      }
    case PATCH_MEAL_BONUS_FAIL:
      return {
        ...state,
        loading_edit_meal_bonus: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MEAL_BONUS:
      return {
        ...state, meal_bonus: null, loading_delete_meal_bonus: true, error: null
      }
    case DELETE_MEAL_BONUS_SUCCESS:
      return {
        ...state,
        meal_bonus: action.payload.meal_bonus,
        loading_delete_meal_bonus: false, error: null
      }
    case DELETE_MEAL_BONUS_FAIL:
      return {
        ...state,
        loading_delete_meal_bonus: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MealBonusState
