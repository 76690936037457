import { endpoints } from "helpers/apiConfigs"
import axiosApi from "helpers/axiosConfig"
import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"

function PermissionsContext({ children }) {
  const [showPopUp, setShowPopUp] = useState(false)

  const [errMessage, setErrMessage] = useState("")

  // console.log({ connectionRestared });
  axiosApi.interceptors.response.use(
    function (response) {
      // console.log(response);
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // console.log(error?.response?.data?.error);

      // if (error?.message === "Network Error") {
      //   setErr(9999);
      //   setShowPopUp(true);
      // } else {
    //   console.log({ err: error?.response, m: error?.config?.method === "get" })

      if (String(error?.config?.method) !== "get" && error.response?.status === 403) {
        setShowPopUp(true)
        // switch (error.response?.status) {
        //   case 403:
        //     if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.projects
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Chantier"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(endpoints.stocks)
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Stock"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.vacations
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Congé"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.chekingInTask
        //       ) ||
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.clockingin
        //       ) ||
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.work_reports
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Pointage"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.mouvements
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Mouvement"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(endpoints.tasks)
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Ouvrage"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.document
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Document"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(
        //         endpoints.providers
        //       )
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Fournisseur"`
        //       )
        //     } else if (
        //       String(error?.response?.config?.url)?.startsWith(endpoints.staff)
        //     ) {
        //       setErrMessage(
        //         `${error?.response?.data?.error?.message} "Module : Personnel"`
        //       )
        //     } else {
        //       setErr(403)
        //       setErrMessage(error?.response?.data?.error?.message)
        //     }
        //     setShowPopUp(true)
        //     break
        //   default:
        //     break
        // }
      }
      // }

      return Promise.reject(error)
    }
  )
  return (
    <div style={{ flex: 1 }}>
      {children}
      {showPopUp && (
        <SweetAlert
          style={{ height: "auto" }}
          error
          title={"Permissions"}
          onConfirm={() => {
            // setSuccessDLG(false)
            setShowPopUp(false)
          }}
        >
          {"Vous n'êtes pas autorisé"}
        </SweetAlert>
      )}
    </div>
  )
}

export default PermissionsContext
