import {
  GET_MATERIALS,
  GET_MATERIALS_FAIL,
  GET_MATERIALS_SUCCESS,

  GET_ONE_MATERIAL,
  GET_ONE_MATERIAL_SUCCESS,
  GET_ONE_MATERIAL_FAIL,

  CREATE_MATERIAL,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAIL,

  PATCH_MATERIAL,
  PATCH_MATERIAL_SUCCESS,
  PATCH_MATERIAL_FAIL,

  DELETE_MATERIAL,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  material: null,
  materials: [],
  error: null,

  loading_get_materials: false,
  loading_get_material: false,

  loading_add_material: false,
  loading_edit_material: false,

  count_all_materials: 0,
}

const MaterialState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MATERIALS:
      return {
        ...state, loading_get_materials: true, error: null,
        materials: []
      }
    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        materials: action.payload.materials,
        loading_get_materials: false, error: null,
        count_all_materials: action.payload.counts || 0
      }
    case GET_MATERIALS_FAIL:
      return {
        ...state,
        loading_get_materials: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MATERIAL:
      return {
        ...state, material: null, loading_get_material: true, error: null
      }
    case GET_ONE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload.material,
        loading_get_material: false, error: null
      }
    case GET_ONE_MATERIAL_FAIL:
      return {
        ...state,
        loading_get_material: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_MATERIAL:
      return {
        ...state, material: null, loading_add_material: true, error: null
      }
    case CREATE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload.material,
        loading_add_material: false, error: null
      }
    case CREATE_MATERIAL_FAIL:
      return {
        ...state,
        loading_add_material: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_MATERIAL:
      return {
        ...state, loading_edit_material: true, error: null
      }
    case PATCH_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload.material,
        loading_edit_material: false, error: null
      }
    case PATCH_MATERIAL_FAIL:
      return {
        ...state,
        loading_edit_material: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_MATERIAL:
      return {
        ...state, material: null, loading_delete_material: true, error: null
      }
    case DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
        material: action.payload.material,
        loading_delete_material: false, error: null
      }
    case DELETE_MATERIAL_FAIL:
      return {
        ...state,
        loading_delete_material: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default MaterialState
