import {
  GET_STOCK_TYPES,
  GET_STOCK_TYPES_FAIL,
  GET_STOCK_TYPES_SUCCESS,

  GET_ONE_STOCK_TYPE,
  GET_ONE_STOCK_TYPE_SUCCESS,
  GET_ONE_STOCK_TYPE_FAIL,

  CREATE_STOCK_TYPE,
  CREATE_STOCK_TYPE_SUCCESS,
  CREATE_STOCK_TYPE_FAIL,

  PATCH_STOCK_TYPE,
  PATCH_STOCK_TYPE_SUCCESS,
  PATCH_STOCK_TYPE_FAIL,

  DELETE_STOCK_TYPE,
  DELETE_STOCK_TYPE_SUCCESS,
  DELETE_STOCK_TYPE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  stock_type: null,
  stock_types: [],
  error: null,

  loading_get_stock_types: false,
  loading_get_stock_type: false,

  loading_add_stock_type: false,
  loading_edit_stock_type: false,

  count_all_stock_types: 0,
}

const StockTypeState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_STOCK_TYPES:
      return {
        ...state, loading_get_stock_types: true, error: null
      }
    case GET_STOCK_TYPES_SUCCESS:
      return {
        ...state,
        stock_types: action.payload.stock_types,
        loading_get_stock_types: false, error: null,
        count_all_stock_types: action.payload.counts || 0
      }
    case GET_STOCK_TYPES_FAIL:
      return {
        ...state,
        loading_get_stock_types: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_STOCK_TYPE:
      return {
        ...state, stock_type: null, loading_get_stock_type: true, error: null
      }
    case GET_ONE_STOCK_TYPE_SUCCESS:
      return {
        ...state,
        stock_type: action.payload.stock_type,
        loading_get_stock_type: false, error: null
      }
    case GET_ONE_STOCK_TYPE_FAIL:
      return {
        ...state,
        loading_get_stock_type: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_STOCK_TYPE:
      return {
        ...state, stock_type: null, loading_add_stock_type: true, error: null
      }
    case CREATE_STOCK_TYPE_SUCCESS:
      return {
        ...state,
        stock_type: action.payload.stock_type,
        loading_add_stock_type: false, error: null
      }
    case CREATE_STOCK_TYPE_FAIL:
      return {
        ...state,
        loading_add_stock_type: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_STOCK_TYPE:
      return {
        ...state, loading_edit_stock_type: true, error: null
      }
    case PATCH_STOCK_TYPE_SUCCESS:
      return {
        ...state,
        stock_type: action.payload.stock_type,
        loading_edit_stock_type: false, error: null
      }
    case PATCH_STOCK_TYPE_FAIL:
      return {
        ...state,
        loading_edit_stock_type: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_STOCK_TYPE:
      return {
        ...state, stock_type: null, loading_delete_stock_type: true, error: null
      }
    case DELETE_STOCK_TYPE_SUCCESS:
      return {
        ...state,
        stock_type: action.payload.stock_type,
        loading_delete_stock_type: false, error: null
      }
    case DELETE_STOCK_TYPE_FAIL:
      return {
        ...state,
        loading_delete_stock_type: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default StockTypeState
