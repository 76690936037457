import {
  GET_STOCKS,
  GET_STOCKS_FAIL,
  GET_STOCKS_SUCCESS,

  GET_ONE_STOCK,
  GET_ONE_STOCK_SUCCESS,
  GET_ONE_STOCK_FAIL,

  CREATE_STOCK,
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_FAIL,

  PATCH_STOCK,
  PATCH_STOCK_SUCCESS,
  PATCH_STOCK_FAIL,

  DELETE_STOCK,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAIL
} from "./actionTypes"

const INIT_STATE = {
  stock: null,
  stocks: [],
  error: null,

  loading_get_stocks: false,
  loading_get_stock: false,

  loading_add_stock: false,
  loading_edit_stock: false,

  count_all_stocks: 0,
}

const StockState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_STOCKS:
      return {
        ...state, loading_get_stocks: true, error: null
      }
    case GET_STOCKS_SUCCESS:
      return {
        ...state,
        stocks: action.payload.stocks,
        loading_get_stocks: false, error: null,
        count_all_stocks: action.payload.counts || 0
      }
    case GET_STOCKS_FAIL:
      return {
        ...state,
        loading_get_stocks: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_STOCK:
      return {
        ...state, stock: null, loading_get_stock: true, error: null
      }
    case GET_ONE_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload.stock,
        loading_get_stock: false, error: null
      }
    case GET_ONE_STOCK_FAIL:
      return {
        ...state,
        loading_get_stock: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_STOCK:
      return {
        ...state, stock: null, loading_add_stock: true, error: null
      }
    case CREATE_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload.stock,
        loading_add_stock: false, error: null
      }
    case CREATE_STOCK_FAIL:
      return {
        ...state,
        loading_add_stock: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_STOCK:
      return {
        ...state, loading_edit_stock: true, error: null
      }
    case PATCH_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload.stock,
        loading_edit_stock: false, error: null
      }
    case PATCH_STOCK_FAIL:
      return {
        ...state,
        loading_edit_stock: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_STOCK:
      return {
        ...state, stock: null, loading_delete_stock: true, error: null
      }
    case DELETE_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload.stock,
        loading_delete_stock: false, error: null
      }
    case DELETE_STOCK_FAIL:
      return {
        ...state,
        loading_delete_stock: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default StockState
