import {
  GET_MODULES,
  GET_MODULES_FAIL,
  GET_MODULES_SUCCESS,

  GET_ONE_MODULE,
  GET_ONE_MODULE_SUCCESS,
  GET_ONE_MODULE_FAIL,

  // CREATE_MODULE,
  // CREATE_MODULE_SUCCESS,
  // CREATE_MODULE_FAIL,

  PATCH_MODULE,
  PATCH_MODULE_SUCCESS,
  PATCH_MODULE_FAIL,

  // DELETE_MODULE,
  // DELETE_MODULE_SUCCESS,
  // DELETE_MODULE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  module: null,
  modules: [],
  error: null,

  loading_get_modules: false,
  loading_get_module: false,

  loading_add_module: false,
  loading_edit_module: false,

  count_all_modules: 0,
}

const ModuleState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_MODULES:
      return {
        ...state, loading_get_modules: true, error: null,
        modules: []
      }
    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload.modules,
        loading_get_modules: false, error: null,
        count_all_modules: action.payload.counts || 0
      }
    case GET_MODULES_FAIL:
      return {
        ...state,
        loading_get_modules: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_MODULE:
      return {
        ...state, module: null, loading_get_module: true, error: null
      }
    case GET_ONE_MODULE_SUCCESS:
      return {
        ...state,
        module: action.payload.module,
        loading_get_module: false, error: null
      }
    case GET_ONE_MODULE_FAIL:
      return {
        ...state,
        loading_get_module: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    // case CREATE_MODULE:
    //   return {
    //     ...state, module: null, loading_add_module: true, error: null
    //   }
    // case CREATE_MODULE_SUCCESS:
    //   return {
    //     ...state,
    //     module: action.payload.module,
    //     loading_add_module: false, error: null
    //   }
    // case CREATE_MODULE_FAIL:
    //   return {
    //     ...state,
    //     loading_add_module: false,
    //     error: action.payload?.error || {}
    //   }




    // =============== PATCH
    case PATCH_MODULE:
      return {
        ...state, loading_edit_module: true, error: null
      }
    case PATCH_MODULE_SUCCESS:
      return {
        ...state,
        module: action.payload.module,
        loading_edit_module: false, error: null
      }
    case PATCH_MODULE_FAIL:
      return {
        ...state,
        loading_edit_module: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    // case DELETE_MODULE:
    //   return {
    //     ...state, module: null, loading_delete_module: true, error: null
    //   }
    // case DELETE_MODULE_SUCCESS:
    //   return {
    //     ...state,
    //     module: action.payload.module,
    //     loading_delete_module: false, error: null
    //   }
    // case DELETE_MODULE_FAIL:
    //   return {
    //     ...state,
    //     loading_delete_module: false,
    //     error: action.payload?.error || {}
    //   }





    default:
      return state
  }
}

export default ModuleState
