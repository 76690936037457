// const { default: axiosApi } = require("helpers/axiosConfig")

import axios from "axios"

const AdresseSrv = {
  // =============== GET LIST
  getReverse: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if ((payload.filters[key] || payload.filters[key] === false) && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axios.get(`https://api-adresse.data.gouv.fr/reverse/?${filters}`)
        .then(resp => {
          resolve(resp?.data);
        })
        .catch(e => reject(e))
    })
  },

}

export default AdresseSrv