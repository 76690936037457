/* MATERIALS */
export const GET_MATERIALS = "GET_MATERIALS"
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS"
export const GET_MATERIALS_FAIL = "GET_MATERIALS_FAIL"

export const GET_ONE_MATERIAL = "GET_ONE_MATERIAL"
export const GET_ONE_MATERIAL_SUCCESS = "GET_ONE_MATERIAL_SUCCESS"
export const GET_ONE_MATERIAL_FAIL = "GET_ONE_MATERIAL_FAIL"

export const CREATE_MATERIAL = "CREATE_MATERIAL"
export const CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS"
export const CREATE_MATERIAL_FAIL = "CREATE_MATERIAL_FAIL"

export const PATCH_MATERIAL = "PATCH_MATERIAL"
export const PATCH_MATERIAL_SUCCESS = "PATCH_MATERIAL_SUCCESS"
export const PATCH_MATERIAL_FAIL = "PATCH_MATERIAL_FAIL"

export const DELETE_MATERIAL = "DELETE_MATERIAL"
export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS"
export const DELETE_MATERIAL_FAIL = "DELETE_MATERIAL_FAIL"

export const IMPORT_MATERIALS = "IMPORT_MATERIALS"
export const IMPORT_MATERIALS_SUCCESS = "IMPORT_MATERIALS_SUCCESS"
export const IMPORT_MATERIALS_FAIL = "IMPORT_MATERIALS_FAIL"