import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListStaffsPage = React.lazy(() => import('./list-staffs.page'));
const ListStaffsAllPage = React.lazy(() => import('./list-staffs-all.page.js'));
const AddStaffPage = React.lazy(() => import('./add-staff.page'));
const EditStaffPage = React.lazy(() => import('./edit-staff.page'));
const PreviewStaffPage = React.lazy(() => import('./preview-staff.page'));

const ListVacationsPage = React.lazy(() => import('./list-vacations.page'));
const ListBonusPage = React.lazy(() => import('./list-bonus.page'));
const ListClockinginPage = React.lazy(() => import('./list-clockingin.page'));

const ReportValidationPage = React.lazy(() => import('./report-validation.page'));

const AddStaffReportPage = React.lazy(() => import('./add-staff-report.page'));

const Staffs = ({ match }) => (

  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/all`}
        render={props => <ListStaffsAllPage {...props} />}
      />

      <Route path={`${match.url}/list`}
        render={props => <ListStaffsPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddStaffPage {...props} />}
      />

      <Route path={`${match.url}/edit/:staffId`}
        render={props => <EditStaffPage {...props} />}
      />

      <Route path={`${match.url}/preview/:staffId`}
        render={props => <PreviewStaffPage {...props} />}
      />


      <Route path={`${match.url}/vacations`}
        render={props => <ListVacationsPage {...props} />}
      />
      <Route path={`${match.url}/bonus`}
        render={props => <ListBonusPage {...props} />}
      />
      <Route path={`${match.url}/clockingin`}
        render={props => <ListClockinginPage {...props} />}
      />
      <Route path={`${match.url}/add-clockingin`}
        render={props => <AddStaffReportPage {...props} />}
      />

      <Route path={`${match.url}/report-validation/:reportId`}
        render={props => <ReportValidationPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Staffs;