/* MEAL_BONUSS */
export const GET_MEAL_BONUSS = "GET_MEAL_BONUSS"
export const GET_MEAL_BONUSS_SUCCESS = "GET_MEAL_BONUSS_SUCCESS"
export const GET_MEAL_BONUSS_FAIL = "GET_MEAL_BONUSS_FAIL"

export const GET_ONE_MEAL_BONUS = "GET_ONE_MEAL_BONUS"
export const GET_ONE_MEAL_BONUS_SUCCESS = "GET_ONE_MEAL_BONUS_SUCCESS"
export const GET_ONE_MEAL_BONUS_FAIL = "GET_ONE_MEAL_BONUS_FAIL"

export const CREATE_MEAL_BONUS = "CREATE_MEAL_BONUS"
export const CREATE_MEAL_BONUS_SUCCESS = "CREATE_MEAL_BONUS_SUCCESS"
export const CREATE_MEAL_BONUS_FAIL = "CREATE_MEAL_BONUS_FAIL"

export const PATCH_MEAL_BONUS = "PATCH_MEAL_BONUS"
export const PATCH_MEAL_BONUS_SUCCESS = "PATCH_MEAL_BONUS_SUCCESS"
export const PATCH_MEAL_BONUS_FAIL = "PATCH_MEAL_BONUS_FAIL"

export const DELETE_MEAL_BONUS = "DELETE_MEAL_BONUS"
export const DELETE_MEAL_BONUS_SUCCESS = "DELETE_MEAL_BONUS_SUCCESS"
export const DELETE_MEAL_BONUS_FAIL = "DELETE_MEAL_BONUS_FAIL"