/* ClOTHES */
export const GET_ClOTHES = "GET_ClOTHES"
export const GET_ClOTHES_SUCCESS = "GET_ClOTHES_SUCCESS"
export const GET_ClOTHES_FAIL = "GET_ClOTHES_FAIL"

export const GET_ONE_ClOTHE = "GET_ONE_ClOTHE"
export const GET_ONE_ClOTHE_SUCCESS = "GET_ONE_ClOTHE_SUCCESS"
export const GET_ONE_ClOTHE_FAIL = "GET_ONE_ClOTHE_FAIL"

export const CREATE_ClOTHE = "CREATE_ClOTHE"
export const CREATE_ClOTHE_SUCCESS = "CREATE_ClOTHE_SUCCESS"
export const CREATE_ClOTHE_FAIL = "CREATE_ClOTHE_FAIL"

export const PATCH_ClOTHE = "PATCH_ClOTHE"
export const PATCH_ClOTHE_SUCCESS = "PATCH_ClOTHE_SUCCESS"
export const PATCH_ClOTHE_FAIL = "PATCH_ClOTHE_FAIL"

export const DELETE_ClOTHE = "DELETE_ClOTHE"
export const DELETE_ClOTHE_SUCCESS = "DELETE_ClOTHE_SUCCESS"
export const DELETE_ClOTHE_FAIL = "DELETE_ClOTHE_FAIL"

export const GET_STAFF_ClOTHES = "GET_STAFF_ClOTHES"
export const GET_STAFF_ClOTHES_SUCCESS = "GET_STAFF_ClOTHES_SUCCESS"
export const GET_STAFF_ClOTHES_FAIL = "GET_STAFF_ClOTHES_FAIL"


export const CREATE_STAFF_ClOTHE = "CREATE_STAFF_ClOTHE"
export const CREATE_STAFF_ClOTHE_SUCCESS = "CREATE_STAFF_ClOTHE_SUCCESS"
export const CREATE_STAFF_ClOTHE_FAIL = "CREATE_STAFF_ClOTHE_FAIL"

export const PATCH_STAFF_ClOTHE = "PATCH_STAFF_ClOTHE"
export const PATCH_STAFF_ClOTHE_SUCCESS = "PATCH_STAFF_ClOTHE_SUCCESS"
export const PATCH_STAFF_ClOTHE_FAIL = "PATCH_STAFF_ClOTHE_FAIL"

export const DELETE_STAFF_ClOTHE = "DELETE_STAFF_ClOTHE"
export const DELETE_STAFF_ClOTHE_SUCCESS = "DELETE_STAFF_ClOTHE_SUCCESS"
export const DELETE_STAFF_ClOTHE_FAIL = "DELETE_STAFF_ClOTHE_FAIL"