import { isFunction } from 'helpers/utils';
import PropTypes from 'prop-types'
import React from "react"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { NavLink } from "react-router-dom";

import {
  Input
} from 'reactstrap';

import no from "../../../assets/images/no.png";

const ListingTableTD = props => {
  const { data, children } = props

  const renderContent = () => {
    switch (data.type) {
      case "component":
        return (children)

      case "radio":
      case "checkbox": return (<Input {...data}
        type={data.type}
        className={data.inner_class || ""} style={data.inner_style || {}}

        value={data.value} name={data.name}

        onChange={(e) => {
          if (data.onChange && isFunction(data.onChange)) data.onChange(e.target.value)
        }}
      // checked={(data.checked)} defaultChecked={(data.defaultChecked)}
      />)

      case "image": return (<img className={data.inner_class || ""} style={data.inner_style || { borderRadius: 5 }} src={data.value || no} alt="#" />)

      case "image-card": return (<NavLink to={data.to}>
        <div className="user-img-wrap">
          <div className="user-img">
            <img src={data.value || no} alt="#" />
          </div>
          <p className="user-name">{data.name}</p>
        </div>
      </NavLink>)

      case "link": return (<NavLink to={data.value} target={data.target || ""} rel={data.rel || ""} >{data.name}</NavLink>)

      case "icon": return (<i className={data.name} style={data.inner_style || {}} ></i>)

      case "actions": return (<React.Fragment>
        {Array.isArray(data.value) && data.value.map((action, i) => (renderAction(action, i)))}
      </React.Fragment>)

      default:
        if (data.addition) return (<React.Fragment>
          {data.name} <span className={data.addition_class || ""} style={data.addition_style || {}} > {data.addition} </span>
        </React.Fragment>)
        return data.name;
    }
  }

  const renderAction = (action, i) => {
    switch (action.type) {
      case "link": return (<NavLink key={"action_" + i} to={action.to}><i className={action.icon}  style={action.style || {}}/></NavLink>)

      default: return (<i key={"action_" + i} onClick={() => {
        if (isFunction(action.onClick)) action.onClick()
      }} className={action.icon} style={action.style || {}} />)
    }
  }

  return (<td className={data.class || ""} style={data.style || {}} onClick={() => {
    if (isFunction(data.onClick)) data.onClick()
  }}>
    {renderContent()}
  </td>)
}
ListingTableTD.propTypes = {
  // data: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.any.isRequired,
    value: PropTypes.any,

    class: PropTypes.string,
    inner_class: PropTypes.string,

    style: PropTypes.object,
    inner_style: PropTypes.object,

    type: PropTypes.oneOf(['string', "number", 'link', "icon", "image", "image-card", 'checkbox', 'radio', "actions", "component", "date"]).isRequired,

    show_selection: false
  }).isRequired
}

const mapStatetoProps = state => {
  // const {  } = state.Profile
  return {}
}

export default withRouter(
  connect(mapStatetoProps, {

  })(ListingTableTD)
)
