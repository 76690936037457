import {
  GET_ARTICLES,
  GET_ARTICLES_FAIL,
  GET_ARTICLES_SUCCESS,

  GET_ONE_ARTICLE,
  GET_ONE_ARTICLE_SUCCESS,
  GET_ONE_ARTICLE_FAIL,

  CREATE_ARTICLE,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAIL,

  PATCH_ARTICLE,
  PATCH_ARTICLE_SUCCESS,
  PATCH_ARTICLE_FAIL,

  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  article: null,
  articles: [],
  error: null,

  loading_get_articles: false,
  loading_get_article: false,

  loading_add_article: false,
  loading_edit_article: false,

  count_all_articles: 0,
}

const ArticleState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_ARTICLES:
      return {
        ...state, loading_get_articles: true, error: null
      }
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        loading_get_articles: false, error: null,
        count_all_articles: action.payload.counts || 0
      }
    case GET_ARTICLES_FAIL:
      return {
        ...state, articles: [],
        loading_get_articles: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_ARTICLE:
      return {
        ...state, article: null, loading_get_article: true, error: null
      }
    case GET_ONE_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        loading_get_article: false, error: null
      }
    case GET_ONE_ARTICLE_FAIL:
      return {
        ...state,
        loading_get_article: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_ARTICLE:
      return {
        ...state, article: null, loading_add_article: true, error: null
      }
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        loading_add_article: false, error: null
      }
    case CREATE_ARTICLE_FAIL:
      return {
        ...state,
        loading_add_article: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_ARTICLE:
      return {
        ...state, loading_edit_article: true, error: null
      }
    case PATCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        loading_edit_article: false, error: null
      }
    case PATCH_ARTICLE_FAIL:
      return {
        ...state,
        loading_edit_article: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_ARTICLE:
      return {
        ...state, article: null, loading_delete_article: true, error: null
      }
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        loading_delete_article: false, error: null
      }
    case DELETE_ARTICLE_FAIL:
      return {
        ...state,
        loading_delete_article: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ArticleState
