import axios from "axios"
import { endpoints } from "./apiConfigs"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API,
})

// axios.defaults.withCredentials = true
// axiosApi.defaults.withCredentials = true


axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401){
      // localStorage.removeItem("authUser")
      // localStorage.removeItem("authToken")
       localStorage.clear()

      window.location.href = '/'
    }

    return Promise.reject(error)
  }
)

export function axiosGlobalConfigs(token) {
  axiosApi.defaults.headers.common['Authorization'] = (!token || token === "") ? localStorage.getItem("authToken") : token;
  axiosApi.defaults.headers.post['Content-Type'] = 'application/json';

  axiosApi.defaults.baseURL = endpoints.root
}

export default axiosApi;