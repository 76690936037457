import {
  CREATE_COMPANY,
  CREATE_COMPANY_FAIL,
  CREATE_COMPANY_SUCCESS,

  GET_COMPANY,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,

  PATCH_COMPANY,
  PATCH_COMPANY_SUCCESS,
  PATCH_COMPANY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  company: null,
  error: null,

  loading_get_company: false,

  loading_add_company: false,
  loading_edit_company: false,
}

const CompanyState = (state = INIT_STATE, action) => {
  switch (action.type) {

    // =============== GET ONE
    case GET_COMPANY:
      return {
        ...state, company: null, loading_get_company: true, error: null
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company,
        loading_get_company: false, error: null
      }
    case GET_COMPANY_FAIL:
      return {
        ...state,
        loading_get_company: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_COMPANY:
      return {
        ...state, company: null, loading_add_company: true, error: null
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company,
        loading_add_company: false, error: null
      }
    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        loading_add_company: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_COMPANY:
      return {
        ...state, loading_edit_company: true, error: null
      }
    case PATCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company,
        loading_edit_company: false, error: null
      }
    case PATCH_COMPANY_FAIL:
      return {
        ...state,
        loading_edit_company: false,
        error: action.payload?.error || {}
      }

    default:
      return state
  }
}

export default CompanyState
