import ProjectTypeSrv from "services/ProjectTypeSrv";

import {
  GET_PROJECT_TYPES,
  GET_PROJECT_TYPES_FAIL,
  GET_PROJECT_TYPES_SUCCESS,

  GET_ONE_PROJECT_TYPE,
  GET_ONE_PROJECT_TYPE_SUCCESS,
  GET_ONE_PROJECT_TYPE_FAIL,

  CREATE_PROJECT_TYPE,
  CREATE_PROJECT_TYPE_SUCCESS,
  CREATE_PROJECT_TYPE_FAIL,

  PATCH_PROJECT_TYPE,
  PATCH_PROJECT_TYPE_SUCCESS,
  PATCH_PROJECT_TYPE_FAIL,

  DELETE_PROJECT_TYPE,
  DELETE_PROJECT_TYPE_SUCCESS,
  DELETE_PROJECT_TYPE_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListProjectTypes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_TYPES,
    });

    ProjectTypeSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project_types: response.data.data.projectTypes,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PROJECT_TYPES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_TYPES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createProjectType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_PROJECT_TYPE,
    });

    ProjectTypeSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project_type: response.data.data.projectType,
          }

          dispatch({
            type: CREATE_PROJECT_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_PROJECT_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneProjectType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_PROJECT_TYPE,
    });

    ProjectTypeSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project_type: response.data.data.projectType
          }

          dispatch({
            type: GET_ONE_PROJECT_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_PROJECT_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editProjectType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_PROJECT_TYPE,
    });

    ProjectTypeSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project_type: response.data.data.projectType,
          }

          dispatch({
            type: PATCH_PROJECT_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_PROJECT_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteProjectType = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PROJECT_TYPE,
    });

    ProjectTypeSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PROJECT_TYPE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PROJECT_TYPE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};