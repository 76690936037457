import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import StripeCheckout from 'react-stripe-checkout';

import { Link, NavLink } from "react-router-dom"

import { Card, CardBody, Row, Col, Button } from "reactstrap"

// Redux
import { useDispatch, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

// import SweetAlert from "react-bootstrap-sweetalert"
import {
  getActiveSubscription,
  getListInscriptions, 
  //deleteInscription
  deleteInscription
} from "store/actions"
import PaymentMdl from 'components/Modals/Payment/PaymentMdl';

import { isFunction, getCountPages } from "helpers/utils"
import Pagination from "components/Common/Pagination/Pagination"
import DropStatuses from "components/Common/DropStatuses/DropStatuses"
import ListingTable from "components/Common/ListingTable/ListingTable"
import ListingTableTD from "components/Common/ListingTable/ListingTableTD"
import Limitation from "components/Common/Tools/Limitation/Limitation"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert";

// const inscriptions = [];

const ListInscriptionsGridCmp = props => {
  // HOOKS
  
  const dispatch = useDispatch()
  // const { inscriptions, count_all_inscriptions } = useSelector(({ InscriptionState }) => InscriptionState);
  const { user } = useSelector(({ Login }) => Login)

  const [selected_inscriptions, setSelectedInscriptions] = useState([])

  const [current_page, setCurrentPage] = useState(1)
  const [show_payment_mdl, setShowPaymentMdl] = useState(false)

  const [show_alert_delete, setShowAlertDelete] = useState(false)
  const [selected_inscription, setSelectedInscription] = useState(null)
  const [success_dlg, setSuccessDLG] = useState(false)

  const [inscriptions, setInscriptions] = useState([])
  const [count_all_inscriptions, setCountAll] = useState([])

  const {
    default_selected_inscriptions,
    //selection_multi
    //show_selection
  } = props
  const [status, setStatus] = useState(-1)
  const [open_limits, setOpenLimits] = useState(false)
  const [limit, setLimit] = useState(20)
  const [activeSubId, setActiveSubId] = useState(null)

  
  const [sort_by, setSortBy] = useState({
    name: "Date desc",
    value: "-_id",
  })
  useEffect(() => {
    getPage()
    dispatch(getActiveSubscription({}, (resp) => {
      setActiveSubId(resp.subscription?._id || null)
    }, (err) => { }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, limit, sort_by,current_page])


  const getPage = (page = 1) => {
    const offset = page === 1 || page === 0 ? 0 : limit * (page - 1)

    const filters = {
      sort: sort_by?.value,
    }
    if (status !== -1 && status !== "all") filters["status"] = status

    dispatch(
      getListInscriptions(
        {
          offset,
          limit,
          filters,
        },
        resp => {
          // console.log(resp?.inscriptions)
          setInscriptions(resp?.inscriptions || [])
          setCountAll(resp?.counts || 0)
        },
        err => { }
      )
    )
  }

  useEffect(() => {
    if (isFunction(props.changeSelection))
      props.changeSelection(selected_inscriptions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_inscriptions])

  useEffect(() => {
    setSelectedInscriptions(
      default_selected_inscriptions ? [...default_selected_inscriptions] : []
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_selected_inscriptions])

  const deleteSelectedCLient = () => {
    if (selected_inscription) {
      dispatch(deleteInscription({ _id: selected_inscription?._id }, (resp) => {
        setSuccessDLG(true)
        getPage(current_page)
        setSelectedInscription(null)
      }, (error) => {
        getPage(current_page)
        setSelectedInscription(null)
      }))
    }
  }

  const renderInscriptionStatus = status => {
    switch (status) {
      case 0:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#f1b44c",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Demande
          </span>
        )
      case 1:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#34c38f",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Validé
          </span>
        )
      case 2:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#adb5bd",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Expiré
          </span>
        )
      case 3:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#f46a6a",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Annulé
          </span>
        )
      case 4:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#50a5f1",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Renouvelé
          </span>
        )
        case 5:
          return (
            <span
              style={{
                borderRadius: 3,
                backgroundColor: "#34c38f",
                color: "#fff",
                padding: "5px 10px",
                width: 100,
                marginRight: 10,
              }}
            >
              Payé
            </span>
          )

      default:
        return (
          <span
            style={{
              borderRadius: 3,
              backgroundColor: "#f1b44c",
              color: "#fff",
              padding: "5px 10px",
              width: 100,
              marginRight: 10,
            }}
          >
            Demande
          </span>
        )
    }
  }
  const headers = [
    // { name: "#" },
    { name: "Date début" },
    { name: "Modules" },

    { name: "Prix HT(€)", sorting: true, sort_by: "price" },
    { name: "TTC(€)", sorting: true, sort_by: "price" },
    { name: "Stockage (Go)" },
    { name: "Stockage supplémentaire" },
    { name: "Statut", sorting: true, sort_by: "status", detailsSubscription: true },
    { name: "Action", sorting: true, sort_by: "status" },

  ]

  if (user?.userType === "SUPER_ADMIN")
    headers.push({ name: "Action", style: { width: 140 } })

  // console.log("/////ARTICLES", inscriptions);
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md="5">
          <DropStatuses
            statuses={[
              {
                name: "Demande",
                value: 0,
              },
              {
                name: "Validé",
                value: 1,
              },
              {
                name: "Expiré",
                value: 2,
              },
              {
                name: "Annulé",
                value: 3,
              },
              {
                name: "Renouvelé",
                value: 4,
              },
              {
                name: "Payé",
                value: 5,
              },
              {
                name: "Tous les inscriptions",
                value: "all",
                color: "#264B9A",
              },
            ]}
            onStatusChanged={new_status => {
              if (new_status) setStatus(new_status.value)
            }}
            default_status={{
              name: "Tous les inscriptions",
              value: "Tous les inscriptions",
              color: "#264B9A",
            }}
          />
        </Col>

        <Col md="1" />

        <Col md="6">
          <div className="float-right">
            <Limitation
              options={[5, 10, 20]}
              text="Inscriptions par page"
              limit={limit}
              setLimit={setLimit}
              setOpenLimits={setOpenLimits}
              open_limits={open_limits}
            />
          </div>
          <div className="float-right">
            {!inscriptions?.find(i =>( i?.status?.code === 0) || (i?.status?.code === 4 ) || (i?.status?.code ===  5) || (i?.status?.code ===  1)) ? <NavLink href={"/subscriptions"} to={"/subscriptions"} >
              <Button
                color="success"
                style={{ fontWeight: 500, borderRadius: 35 }}
              >
                Demande Inscription
              </Button>
            </NavLink> : null}
          </div>
        </Col>
      </Row>

      <Card>
        <CardBody className="p-0" style={{ minHeight: 100 }}>
          <ListingTable
            headers={headers}
            changeSortBy={(new_sort_by = "") => setSortBy(new_sort_by)}
          >
            {inscriptions.map((item, i) => (
              <tr key={"inscription_" + i}>
                {/* <ListingTableTD data={{
                name: item.subscription?.name || "", class: "", style: {}, type: "link", target: "_blank",
                value: "/subscribtions/preview/" + item.subscription?.subscriptionId
              }} /> */}
                <ListingTableTD
                  data={{
                    name: moment(item?.beginAt).format(
                      "DD-MM-YYYY"
                    ),
                    type: "string"
                  }}
                />

                {/* <ListingTableTD
                  data={{
                    name: item.modules
                      ? item.modules.map(mdl => mdl.name)?.join(", ")
                      : "",
                    type: "string",
                  }}
                /> */}
                <td style={{display:'inline-grid'}}>
                  {item.modules?.map((mdl, indx) => {
                    if (!mdl || !mdl.countUsers) return null
                    return (
                      <span
                        key={`mdl-${indx}`}
                        style={{
                          backgroundColor: "#50a5f1",
                          borderRadius: 5,
                          padding: 5,
                          color: "#fff",
                          margin: 2,
                        }}
                      >
                        {mdl.name}{" "}
                        <i className="mdi mdi-account-supervisor" /> *{" "}
                        {mdl.countUsers || 0}
                      </span>
                    )
                  })}
                </td>
        { (item?.status?.code === 0 ||  item?.status?.code === 1 )  
          ? 
        <><ListingTableTD
                  data={{
                    name: Number(item?.total || 0).toFixed(2),
                    type: "number",
                  }}
                />
             <ListingTableTD
                  data={{
                    name: Number(item?.totalTTC || 0).toFixed(2),
                    type: "number",
                  }}
                /> </>:<>
                <ListingTableTD
                  data={{
                    name: Number(item?.totalByMonth || 0).toFixed(2),
                    type: "number",
                  }}
                />
             <ListingTableTD
                  data={{
                    name: Number(item?.totalTTCByMonth || 0).toFixed(2),
                    type: "number",
                  }}
                /> 
                </>
                }
                <ListingTableTD
                  data={{
                    name: item?.storage || 0,
                    type: "string",
                  }}
                />

                <ListingTableTD
                  data={{
                    name: item.storageAdditions?.additionSize || 0,
                    type: "string",
                  }}
                />

                <ListingTableTD
                  data={{
                    name: renderInscriptionStatus(item.status?.code || 0),
                    type: "string",
                  }}
                />

                {/* ACTIONS */}
                {user?.userType === "SUPER_ADMIN" ? (
                  <ListingTableTD
                    data={{
                      name: "",
                      class: "actions",
                      type: "actions",
                      value: [
                        // {
                        //   type: "link", to: `/clients/preview/${item._id}`, icon: "mdi mdi-eye"
                        // }, {
                        //   type: "link", to: `/clients/edit/${item._id}`, icon: "mdi mdi-pencil-box-outline"
                        // },
                        // {
                        //   type: "button", onClick: () => {
                        //     setShowAlertDelete(true)
                        //     setSelectedClient(item)
                        //   }, icon: "mdi mdi-trash-can-outline"
                        // }
                      ],
                    }}
                  />
                ) : null}
                {(user?.userType === "ADMIN") ? (
                  // <ListingTableTD
                  //   data={{
                  //     name: "",
                  //     class: "actions",
                  //     type: "actions",
                  //     value: [
                  //       //   {
                  //       //   type: "link", to: `/clients/preview/${item._id}`, icon: "mdi mdi-eye"
                  //       // },
                  //        {
                  //         type: "link", to: `/subscriptions/preview/${item._id}`, icon: "mdi mdi-pencil-box-outline" , params:{ testvalue: "hello" }
                  //       },
                  //       {
                  //         type: "button", onClick: () => {
                  //           // setShowPaymentMdl(true)
                  //           // setSelectedInscriptions(item)
                  //         }, icon: "mdi mdi-credit-card"
                  //       }
                  //     ],
                  //   }}
                  // />
                  <td className="actions">
                    {!item.parent && !['0','2','3'].includes(String(item?.status?.code) )? <Link to={{ pathname: `/subscriptions/user/edit/${activeSubId}`, state: item }}
                    ><i className="mdi mdi-pencil-box-outline"></i></Link> : null}
                    
                    {item.parent ?  <Link to={{ pathname: `/subscriptions/preview-sub/${activeSubId}`, state: item }}
                    ><i className="mdi mdi-eye"></i></Link> : null 
                    }

                   { ['0','1','3'].includes(String(item?.status?.code) ) ? <i className="mdi mdi-trash-can-outline" onClick={(e)=>{
                    setSelectedInscription(item);setShowAlertDelete(true)} }>
                      </i> : null}
                  </td>
                ) : null}
                {show_payment_mdl && <PaymentMdl is_open={show_payment_mdl} StripeCheckout = {StripeCheckout} 
                  toggleOpen={() => setShowPaymentMdl(!show_payment_mdl)}
                  accept={() => { getPage(); setShowPaymentMdl(false) }}

                  user_subscription={selected_inscriptions}
                />}

              </tr>
            ))}
          </ListingTable>

          {/* <Pagination
            currentPage={current_page}
            totalPage={getCountPages(count_all_inscriptions, limit)}
            onChangePage={i => {
              setCurrentPage(i)
              getPage(i)
            }}
          /> */}
        </CardBody>
      </Card>

      <Pagination
        currentPage={current_page}
        totalPage={getCountPages(count_all_inscriptions, limit)}
        onChangePage={i => {
          setCurrentPage(i)
          getPage(i)
        }}
      />
      {show_alert_delete && <SweetAlert
        title="Êtes-vous sûr?"
        warning
        showCancel
        confirmButtonText="Oui, supprimez-le!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setShowAlertDelete(false)
          deleteSelectedCLient(true)
          // setdynamic_title("Deleted")
          // setdynamic_description("Your file has been deleted.")
        }}
        onCancel={() => setShowAlertDelete(false)}
      >
        Vous ne pourrez pas revenir en arrière!
      </SweetAlert>}


      {success_dlg ? (
        <SweetAlert 
          success
          title={"Supprimé"}
          onConfirm={() => {
            setSuccessDLG(false)
          }}
        >
          {"L'inscription a été supprimé.."}
        </SweetAlert>
      ) : null}
    </React.Fragment>

  )
}
ListInscriptionsGridCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_inscriptions: PropTypes.array,
}

export default ListInscriptionsGridCmp
