/* REPARATION_TYPES */
export const GET_REPARATION_TYPES = "GET_REPARATION_TYPES"
export const GET_REPARATION_TYPES_SUCCESS = "GET_REPARATION_TYPES_SUCCESS"
export const GET_REPARATION_TYPES_FAIL = "GET_REPARATION_TYPES_FAIL"

export const GET_ONE_REPARATION_TYPE = "GET_ONE_REPARATION_TYPE"
export const GET_ONE_REPARATION_TYPE_SUCCESS = "GET_ONE_REPARATION_TYPE_SUCCESS"
export const GET_ONE_REPARATION_TYPE_FAIL = "GET_ONE_REPARATION_TYPE_FAIL"

export const CREATE_REPARATION_TYPE = "CREATE_REPARATION_TYPE"
export const CREATE_REPARATION_TYPE_SUCCESS = "CREATE_REPARATION_TYPE_SUCCESS"
export const CREATE_REPARATION_TYPE_FAIL = "CREATE_REPARATION_TYPE_FAIL"

export const PATCH_REPARATION_TYPE = "PATCH_REPARATION_TYPE"
export const PATCH_REPARATION_TYPE_SUCCESS = "PATCH_REPARATION_TYPE_SUCCESS"
export const PATCH_REPARATION_TYPE_FAIL = "PATCH_REPARATION_TYPE_FAIL"

export const DELETE_REPARATION_TYPE = "DELETE_REPARATION_TYPE"
export const DELETE_REPARATION_TYPE_SUCCESS = "DELETE_REPARATION_TYPE_SUCCESS"
export const DELETE_REPARATION_TYPE_FAIL = "DELETE_REPARATION_TYPE_FAIL"