import ProjectSrv from "services/ProjectSrv";

import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,

  GET_ONE_PROJECT,
  GET_ONE_PROJECT_SUCCESS,
  GET_ONE_PROJECT_FAIL,

  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,

  PATCH_PROJECT,
  PATCH_PROJECT_SUCCESS,
  PATCH_PROJECT_FAIL,

  GET_PROJECT_PLANING,
  GET_PROJECT_PLANING_SUCCESS,
  GET_PROJECT_PLANING_FAIL,

  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,

  // INCOMES
  GET_PROJECT_INCOMES,
  GET_PROJECT_INCOMES_FAIL,
  GET_PROJECT_INCOMES_SUCCESS,

  CREATE_INCOME,
  CREATE_INCOME_SUCCESS,
  CREATE_INCOME_FAIL,

  PATCH_INCOME,
  PATCH_INCOME_SUCCESS,
  PATCH_INCOME_FAIL,

  DELETE_INCOME,
  DELETE_INCOME_SUCCESS,
  DELETE_INCOME_FAIL,


  GET_PROJECT_ADDITION_EXPENSES,
  GET_PROJECT_ADDITION_EXPENSES_SUCCESS,
  GET_PROJECT_ADDITION_EXPENSES_FAIL,

  CREATE_ADDITION_EXPENSE,
  CREATE_ADDITION_EXPENSE_SUCCESS,
  CREATE_ADDITION_EXPENSE_FAIL,

  PATCH_ADDITION_EXPENSE,
  PATCH_ADDITION_EXPENSE_SUCCESS,
  PATCH_ADDITION_EXPENSE_FAIL,

  DELETE_ADDITION_EXPENSE,
  DELETE_ADDITION_EXPENSE_SUCCESS,
  DELETE_ADDITION_EXPENSE_FAIL,



  // EXPENSES
  GET_PROJECT_EXPENSES,
  GET_PROJECT_EXPENSES_SUCCESS,
  GET_PROJECT_EXPENSES_FAIL,

  // PHASES
  GET_PROJECT_PHASES,
  GET_PROJECT_PHASES_SUCCESS,
  GET_PROJECT_PHASES_FAIL,




  // ARCHIVES
  GET_PROJECT_ARCHIVES,
  GET_PROJECT_ARCHIVES_FAIL,
  GET_PROJECT_ARCHIVES_SUCCESS,

  CREATE_ARCHIVE,
  CREATE_ARCHIVE_SUCCESS,
  CREATE_ARCHIVE_FAIL,

  PATCH_ARCHIVE,
  PATCH_ARCHIVE_SUCCESS,
  PATCH_ARCHIVE_FAIL,

  DELETE_ARCHIVE,
  DELETE_ARCHIVE_SUCCESS,
  DELETE_ARCHIVE_FAIL,

  GET_PROJECT_USERS,
  GET_PROJECT_USERS_SUCCESS,
  GET_PROJECT_USERS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListProjects = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECTS,
    });

    ProjectSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            projects: response.data.data.projects,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PROJECTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECTS_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createProject = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_PROJECT,
    });

    ProjectSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project: response.data.data.project,
          }

          dispatch({
            type: CREATE_PROJECT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_PROJECT_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneProject = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_PROJECT,
    });

    ProjectSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project: response.data.data.project
          }

          dispatch({
            type: GET_ONE_PROJECT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_PROJECT_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editProject = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_PROJECT,
    });

    ProjectSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            project: response.data.data.project,
          }

          dispatch({
            type: PATCH_PROJECT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_PROJECT_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};









// GET PROJECT PLANNING
export const getProjectPlaning = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_PLANING,
    });

    ProjectSrv.getProjectPlaning(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          // console.log(response);

          const resp = {
            project_planing: response.data.data.projectPlanning || []
          }

          dispatch({
            type: GET_PROJECT_PLANING_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_PLANING_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteProject = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_PROJECT,
    });

    ProjectSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_PROJECT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_PROJECT_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};









// =============== GET PROJECT INCOMES
export const getProjectIncomes = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_INCOMES,
    });

    ProjectSrv.getProjectIncomes(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            incomes: response.data.data.incomes,
            counts: response.data.data.counts,
            total: response.data.data.total || 0
          }

          dispatch({
            type: GET_PROJECT_INCOMES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_INCOMES_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== CREATE PROJECT INCOME
export const createProjectIncome = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_INCOME,
    });

    ProjectSrv.createIncome(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            income: response.data.data.income,
          }

          dispatch({
            type: CREATE_INCOME_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_INCOME_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH INCOME
export const editProjectIncome = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_INCOME,
    });

    ProjectSrv.patchIncome(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            income: response.data.data.income,
          }

          dispatch({
            type: PATCH_INCOME_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_INCOME_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== DELETE PROJECT INCOME
export const deleteProjectIncome = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_INCOME,
    });

    ProjectSrv.deleteProjectIncome(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_INCOME_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_INCOME_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};















// =============== GET PROJECT ADDITION ADDITION EXPENSE
export const getProjectAdditionExpenses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_ADDITION_EXPENSES,
    });

    ProjectSrv.getAdditionExpenses(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            expenses: response.data.data.expenses,
            counts: response.data.data.counts,
            total: response.data.data.total || 0
          }

          dispatch({
            type: GET_PROJECT_ADDITION_EXPENSES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_ADDITION_EXPENSES_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== CREATE PROJECT ADDITION EXPENSE
export const createProjectAdditionExpense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ADDITION_EXPENSE,
    });

    ProjectSrv.createAdditionExpense(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            expense: response.data.data.expense,
          }

          dispatch({
            type: CREATE_ADDITION_EXPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ADDITION_EXPENSE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ADDITION EXPENSE
export const editProjectAdditionExpense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ADDITION_EXPENSE,
    });

    ProjectSrv.patchAdditionExpense(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            income: response.data.data.expense,
          }

          dispatch({
            type: PATCH_ADDITION_EXPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ADDITION_EXPENSE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== DELETE PROJECT ADDITION EXPENSE
export const deleteProjectAdditionExpense = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ADDITION_EXPENSE,
    });

    ProjectSrv.deleteAdditionExpense(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ADDITION_EXPENSE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ADDITION_EXPENSE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};









// =============== GET PROJECT PHASES
export const getProjectPhases = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_PHASES,
    });

    ProjectSrv.getProjectPhases(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            phases: response.data.data.phases || [],
          }

          dispatch({
            type: GET_PROJECT_PHASES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_PHASES_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};














// =============== GET PROJECT ARCHIVE
export const getProjectArchives = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_ARCHIVES,
    });

    ProjectSrv.getProjectArchives(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            archives: response.data.data.archives,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_PROJECT_ARCHIVES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_ARCHIVES_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== CREATE PROJECT ARCHIVE
export const createProjectArchive = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ARCHIVE,
    });

    ProjectSrv.createArchive(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            income: response.data.data.income,
          }

          dispatch({
            type: CREATE_ARCHIVE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ARCHIVE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH ARCHIVE
export const editProjectArchive = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ARCHIVE,
    });

    ProjectSrv.patchArchive(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            income: response.data.data.income,
          }

          dispatch({
            type: PATCH_ARCHIVE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ARCHIVE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== DELETE PROJECT ARCHIVE
export const deleteProjectArchive = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ARCHIVE,
    });

    ProjectSrv.deleteProjectArchive(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ARCHIVE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ARCHIVE_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};











// =============== GET PROJECT EXPENSES
export const getProjectExpenses = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_EXPENSES,
    });

    ProjectSrv.getProjectExpenses(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            expenses: response.data.data.expenses,
            counts: response.data.data.counts,
            total: response.data.data.total
          }

          dispatch({
            type: GET_PROJECT_EXPENSES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_EXPENSES_FAIL,
          payload: { error: {} }
        });
        // if (callbackError) if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



// =============== GET PROJECT USERS
export const getProjectUsers = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_USERS,
    });

    ProjectSrv.getProjectUsers(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            users: response.data.data.allowedUsers || [],
          }


          dispatch({
            type: GET_PROJECT_USERS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else {
          if (callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_PROJECT_USERS_FAIL,
          payload: { error: {} }
        });
        if (callbackError) callbackError(e?.response?.data?.error);
      });
  };
};