// import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import {
  Row, Col
} from "reactstrap";

import logo_dark from "assets/images/logo-dark.png"
import logo_light from "assets/images/logo-light.png"
import { NavLink, useHistory } from "react-router-dom";
import RightMenu from "./RightMenu";
import { useSelector } from "react-redux";

const Header = props => {
  const history = useHistory();

  // useEffect(() => {
  //   window.location.href = "/login"
  // }, [])

  const [show_task_bar, setShowTaskBar] = useState(false)
  const [white_bg, setWhiteBg] = useState(true)

  // history.push('/supplies');
  const { user } = useSelector(({ Login }) => Login);

  useEffect(() => {
    switch (history?.location?.pathname) {
      case "/about-us":
      case "/contact-us":
      case "/prices":
        setWhiteBg(false)
        break;

      default: setWhiteBg(true); break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.pathname])

  return (
    <React.Fragment>
      <div className="menu-wraper" style={{ backgroundColor: white_bg ? "#fff" : "transparent" }}>
        <div className="container">
          <Row>
            <Col md="6">
              <div className="left-side">
                <NavLink to="/">
                  {white_bg ? <img className="logo-dark" src={logo_dark} alt="#" /> : <img className="logo-dark" src={logo_light} alt="#" />}
                </NavLink>
              </div>
            </Col>
            <Col md="6">
              {user?._id && localStorage.getItem("authUser") !== null ?
                <div className={`right-side ${white_bg ? "" : "white"}`}>
                  <NavLink to="/dashboard" className="menu-btn">Tableau de bord</NavLink>
                  <span onClick={() => setShowTaskBar(true)} className="menu-toggle">
                    <i className="mdi mdi-menu" />
                  </span>
                </div>
                :
                <div className={`right-side ${white_bg ? "" : "white"}`}>
                  <NavLink to="/login" className="menu-link">Connexion</NavLink>
                  <NavLink to="/register" className="menu-btn">S'inscrire</NavLink>
                  <span onClick={() => setShowTaskBar(true)} className="menu-toggle">
                    <i className="mdi mdi-menu" />
                  </span>
                </div>
              }
            </Col>
          </Row>
        </div>
      </div>




      {show_task_bar && <RightMenu
        toggleOpen={() => setShowTaskBar(!show_task_bar)}
        action={"ADD"}
      />}
    </React.Fragment>
  )
}

Header.propTypes = {
}
export default Header