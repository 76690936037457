import MachineSrv from "services/MachineSrv";

import {
  GET_MACHINES,
  GET_MACHINES_FAIL,
  GET_MACHINES_SUCCESS,

  GET_ONE_MACHINE,
  GET_ONE_MACHINE_SUCCESS,
  GET_ONE_MACHINE_FAIL,

  CREATE_MACHINE,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_FAIL,

  PATCH_MACHINE,
  PATCH_MACHINE_SUCCESS,
  PATCH_MACHINE_FAIL,

  DELETE_MACHINE,
  DELETE_MACHINE_SUCCESS,
  DELETE_MACHINE_FAIL,

  GET_MACHINE_MARKS,
  GET_MACHINE_MARKS_SUCCESS,
  GET_MACHINE_MARKS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListMachines = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MACHINES,
    });

    MachineSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            machines: response.data.data.machines,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_MACHINES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MACHINES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createMachine = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_MACHINE,
    });

    MachineSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            machine: response.data.data.machine,
          }

          dispatch({
            type: CREATE_MACHINE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_MACHINE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneMachine = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_MACHINE,
    });

    MachineSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            machine: response.data.data.machine
          }

          dispatch({
            type: GET_ONE_MACHINE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_MACHINE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editMachine = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_MACHINE,
    });

    MachineSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            machine: response.data.data.machines,
          }

          dispatch({
            type: PATCH_MACHINE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_MACHINE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteMachine = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_MACHINE,
    });

    MachineSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_MACHINE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_MACHINE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



export const getListMachineMarks = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MACHINE_MARKS,
    });

    MachineSrv.getMarks(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            machine_marks: response.data.data.mark || []
          }
          
          dispatch({
            type: GET_MACHINE_MARKS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MACHINE_MARKS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};