// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const StaffSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] === false || (payload.filters[key] && payload.filters[key] !== ""))
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.staffs}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.staffs}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.staffs, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== NOTIF
  sendNotif: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.notifs + "/firebase/push", payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== EMAIL NOTIF
  sendEmailNotif: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.notifs + "/email/send", payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.staffs}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== IMPORT
  import: (payload) => {
    return new Promise((resolve, reject) => {

      return axiosApi.post(`${endpoints.staffs}/import`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  printEmpowerment: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.staffs}/print-empowerment`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  printAnnualCalendar: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(`${endpoints.staffs}/print-calendar`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  editEmpowerment: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.staffs}/empowerments/${payload?.empowerment_id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
  deleteEmpowerment: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.staffs}/empowerments/${payload?.empowerment_id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.staffs}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET LIST VACATIONS BY STAFF
  getListStaffVacations: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      // const addition_link = (payload?.by_page) ? `/${payload?.offset || 0}/${payload?.limit || 10}` : ""

      return axiosApi.get(`${endpoints.vacations}/user/${payload?.staff_id || 0}${(payload?.by_page) ? `/${payload?.offset || 0}/${payload?.limit || 10}` : ""
        }/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET LIST PUBLIC VACATION
  getListPublicVacations: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      // const addition_link = (payload?.by_page) ? `/${payload?.offset || 0}/${payload?.limit || 10}` : ""

      return axiosApi.get(`${endpoints.vacations}/public-vacations?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },




  // =============== GET LIST AVAILABLES
  getListStaffsAvailable: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] === false || (payload.filters[key] && payload.filters[key] !== ""))
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      // ${payload?.offset || 0}/${payload?.limit || 10}/
      return axiosApi.get(`${endpoints.staffs}/availability/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
}

export default StaffSrv