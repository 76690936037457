import axiosApi from "./axiosConfig"

const API_URL = process.env.REACT_APP_API

export const user_prefix = 'authUser', token_prefix = 'authToken'

export const endpoints = {
  gmap_key: process.env.REACT_APP_GMAP_KEY,
  root: API_URL,
  companies: `/companies`,
  users: `/users`,
  staffs: `/staff`,
  clients: `/clients`,
  providers: `/providers`,
  affiliations: `/users/affiliations`,
  quote: `/quotes`,
  quote_steps: `/quote-steps`,
  quote_step_tasks: `/tasks/quote-step`,
  tasks: `/tasks`,
  taskDateRanges :"/tasks/date-range",
  notifs: `/notifications`,
  interlocutors: `/interlocutors`,
  contact: `/contact-us`,
  licences: `/licences`,

  articles: `/articles`,
  stocks: `/stocks`,
  materials: `/materials`,
  projects: `/projects`,
  supplies: `/supplies`,
  traceabilities: `/traceability`,
  machine: `/machines`,
  document: `/documents`,
  movements: `/movements`,
  orders: `/orders`,
  clearances: '/clearances',
  workingtime: `/working-times`,
  vacations: `/vacations`,
  bonuses: `/bonuses`,
  clockingins: `/bonuses/clocking-in`,
  reports: `/working-reports`,
  checking_tasks: `/checking-tasks`,

  modules: `/modules`,
  subscriptions: `/subscriptions`,
  inscriptions: `/user-subscriptions`,
  subscribers: `/subscribers`,

  roles: `/roles`,
  licenses: `/settings/driving-licenses`,
  units: `/settings/units`,
  activities: `/settings/activities`,
  article_types: `/settings/article-types`,
  material_types: `/settings/material-types`,
  stock_types: `/settings/stock-types`,
  project_types: `/settings/project-types`,
  machine_types: `/settings/machine-types`,
  repair_types: `settings/repair-types`,
  authorizations: `/settings/authorizations`,
  categories: `/settings/categories`,
  publications: `/settings/publications`,
  middleSchool: `/settings/colleges`,
  quote_phases: `/settings/quote-phases`,
  travel_bonus: `/settings/travel-bonus`,
  meal_bonus: `/settings/meal-bonus`,
  cable_types: `/settings/cable-types`,
  achievement_conditions: `/settings/achievement-conditions`,
  accessory_marks: `/settings/accessory-marks`,
  clothes: `/settings/clothing-types`,
  clothing_staff: `/settings/clothing-staff`,
  payments:'/payments',
  permissions: `/permissions`,
  counts : `/settings/counts`
}



export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
