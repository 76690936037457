/* PROVIDERS */
export const GET_PROVIDERS = "GET_PROVIDERS"
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS"
export const GET_PROVIDERS_FAIL = "GET_PROVIDERS_FAIL"

export const GET_ONE_PROVIDER = "GET_ONE_PROVIDER"
export const GET_ONE_PROVIDER_SUCCESS = "GET_ONE_PROVIDER_SUCCESS"
export const GET_ONE_PROVIDER_FAIL = "GET_ONE_PROVIDER_FAIL"

export const CREATE_PROVIDER = "CREATE_PROVIDER"
export const CREATE_PROVIDER_SUCCESS = "CREATE_PROVIDER_SUCCESS"
export const CREATE_PROVIDER_FAIL = "CREATE_PROVIDER_FAIL"

export const PATCH_PROVIDER = "PATCH_PROVIDER"
export const PATCH_PROVIDER_SUCCESS = "PATCH_PROVIDER_SUCCESS"
export const PATCH_PROVIDER_FAIL = "PATCH_PROVIDER_FAIL"

export const DELETE_PROVIDER = "DELETE_PROVIDER"
export const DELETE_PROVIDER_SUCCESS = "DELETE_PROVIDER_SUCCESS"
export const DELETE_PROVIDER_FAIL = "DELETE_PROVIDER_FAIL"

export const IMPORT_PROVIDERS = "IMPORT_PROVIDERS"
export const IMPORT_PROVIDERS_SUCCESS = "IMPORT_PROVIDERS_SUCCESS"
export const IMPORT_PROVIDERS_FAIL = "IMPORT_PROVIDERS_FAIL"