/* PUBLICATIONS */
export const GET_PUBLICATIONS = "GET_PUBLICATIONS"
export const GET_PUBLICATIONS_SUCCESS = "GET_PUBLICATIONS_SUCCESS"
export const GET_PUBLICATIONS_FAIL = "GET_PUBLICATIONS_FAIL"

export const GET_ONE_PUBLICATION = "GET_ONE_PUBLICATION"
export const GET_ONE_PUBLICATION_SUCCESS = "GET_ONE_PUBLICATION_SUCCESS"
export const GET_ONE_PUBLICATION_FAIL = "GET_ONE_PUBLICATION_FAIL"

export const CREATE_PUBLICATION = "CREATE_PUBLICATION"
export const CREATE_PUBLICATION_SUCCESS = "CREATE_PUBLICATION_SUCCESS"
export const CREATE_PUBLICATION_FAIL = "CREATE_PUBLICATION_FAIL"

export const PATCH_PUBLICATION = "PATCH_PUBLICATION"
export const PATCH_PUBLICATION_SUCCESS = "PATCH_PUBLICATION_SUCCESS"
export const PATCH_PUBLICATION_FAIL = "PATCH_PUBLICATION_FAIL"

export const DELETE_PUBLICATION = "DELETE_PUBLICATION"
export const DELETE_PUBLICATION_SUCCESS = "DELETE_PUBLICATION_SUCCESS"
export const DELETE_PUBLICATION_FAIL = "DELETE_PUBLICATION_FAIL"

export const GET_PUBLICATION_SUPPLIES = "GET_PUBLICATION_SUPPLIES"
export const GET_PUBLICATION_SUPPLIES_SUCCESS = "GET_PUBLICATION_SUPPLIES_SUCCESS"
export const GET_PUBLICATION_SUPPLIES_FAIL = "GET_PUBLICATION_SUPPLIES_FAIL"
