/* LICENSES */
export const GET_LICENSES = "GET_LICENSES"
export const GET_LICENSES_SUCCESS = "GET_LICENSES_SUCCESS"
export const GET_LICENSES_FAIL = "GET_LICENSES_FAIL"

export const GET_ONE_LICENSE = "GET_ONE_LICENSE"
export const GET_ONE_LICENSE_SUCCESS = "GET_ONE_LICENSE_SUCCESS"
export const GET_ONE_LICENSE_FAIL = "GET_ONE_LICENSE_FAIL"

export const CREATE_LICENSE = "CREATE_LICENSE"
export const CREATE_LICENSE_SUCCESS = "CREATE_LICENSE_SUCCESS"
export const CREATE_LICENSE_FAIL = "CREATE_LICENSE_FAIL"

export const PATCH_LICENSE = "PATCH_LICENSE"
export const PATCH_LICENSE_SUCCESS = "PATCH_LICENSE_SUCCESS"
export const PATCH_LICENSE_FAIL = "PATCH_LICENSE_FAIL"

export const DELETE_LICENSE = "DELETE_LICENSE"
export const DELETE_LICENSE_SUCCESS = "DELETE_LICENSE_SUCCESS"
export const DELETE_LICENSE_FAIL = "DELETE_LICENSE_FAIL"