import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,

  GET_ONE_SUBSCRIPTION,
  GET_ONE_SUBSCRIPTION_SUCCESS,
  GET_ONE_SUBSCRIPTION_FAIL,

  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,

  PATCH_SUBSCRIPTION,
  PATCH_SUBSCRIPTION_SUCCESS,
  PATCH_SUBSCRIPTION_FAIL,

  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  subscription: null,
  subscriptions: [],
  error: null,

  loading_get_subscriptions: false,
  loading_get_subscription: false,

  loading_add_subscription: false,
  loading_edit_subscription: false,

  count_all_subscriptions: 0,
}

const SubscriptionState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_SUBSCRIPTIONS:
      return {
        ...state, loading_get_subscriptions: true, error: null
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
        loading_get_subscriptions: false, error: null,
        count_all_subscriptions: action.payload.counts || 0
      }
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state, subscriptions: [],
        loading_get_subscriptions: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_SUBSCRIPTION:
      return {
        ...state, subscription: null, loading_get_subscription: true, error: null
      }
    case GET_ONE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        loading_get_subscription: false, error: null
      }
    case GET_ONE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading_get_subscription: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_SUBSCRIPTION:
      return {
        ...state, subscription: null, loading_add_subscription: true, error: null
      }
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        loading_add_subscription: false, error: null
      }
    case CREATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading_add_subscription: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_SUBSCRIPTION:
      return {
        ...state, loading_edit_subscription: true, error: null
      }
    case PATCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        loading_edit_subscription: false, error: null
      }
    case PATCH_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading_edit_subscription: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_SUBSCRIPTION:
      return {
        ...state, subscription: null, loading_delete_subscription: true, error: null
      }
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        loading_delete_subscription: false, error: null
      }
    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading_delete_subscription: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default SubscriptionState
