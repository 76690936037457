/* MACHINES */
export const GET_MACHINES = "GET_MACHINES"
export const GET_MACHINES_SUCCESS = "GET_MACHINES_SUCCESS"
export const GET_MACHINES_FAIL = "GET_MACHINES_FAIL"

export const GET_ONE_MACHINE = "GET_ONE_MACHINE"
export const GET_ONE_MACHINE_SUCCESS = "GET_ONE_MACHINE_SUCCESS"
export const GET_ONE_MACHINE_FAIL = "GET_ONE_MACHINE_FAIL"

export const CREATE_MACHINE = "CREATE_MACHINE"
export const CREATE_MACHINE_SUCCESS = "CREATE_MACHINE_SUCCESS"
export const CREATE_MACHINE_FAIL = "CREATE_MACHINE_FAIL"

export const PATCH_MACHINE = "PATCH_MACHINE"
export const PATCH_MACHINE_SUCCESS = "PATCH_MACHINE_SUCCESS"
export const PATCH_MACHINE_FAIL = "PATCH_MACHINE_FAIL"

export const DELETE_MACHINE = "DELETE_MACHINE"
export const DELETE_MACHINE_SUCCESS = "DELETE_MACHINE_SUCCESS"
export const DELETE_MACHINE_FAIL = "DELETE_MACHINE_FAIL"

export const GET_MACHINE_MARKS = "GET_MACHINE_MARKS"
export const GET_MACHINE_MARKS_SUCCESS = "GET_MACHINE_MARKS_SUCCESS"
export const GET_MACHINE_MARKS_FAIL = "GET_MACHINE_MARKS_FAIL"