import React from "react"
import PropTypes from 'prop-types'
import { //FormGroup 
  // Input, Label, Button
} from "reactstrap"

import { connect } from "react-redux"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link } from "react-router-dom"

import "../../components/CommonForBoth/rightbar.scss"

const RightMenu = props => {
  return (
    <React.Fragment>
      <div className="right-bar right-menu-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.toggleOpen(false)
                }}
                className="right-bar-toggle float-end"
                style={{ backgroundColor: "transparent", margin: 5 }}
              >
                <i className="mdi mdi-close close-icon" />
              </Link>
              {/* <h5 className="m-0 primed">Créer une intervention</h5> */}
            </div>

            {/* <hr className="my-0" /> */}

            <div className="p-2 full-height-scroll h-90vh">
              <ul className="right-menu-items">
                <li><Link to="/">Accueil<span className="sm-underline"></span></Link></li>
                <li><Link to="/prices">Tarifs<span className="sm-underline"></span></Link></li>
                <li><Link to="/about-us">Qui Sommes-Nous?<span className="sm-underline"></span></Link></li>
                <li><Link to="/contact-us">Contactez-nous<span className="sm-underline"></span></Link></li>
              </ul>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  )
}

RightMenu.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  // return { ...state.Layout }
  return {}
}

export default connect(mapStateToProps, {
})(RightMenu)
