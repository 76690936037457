import {
  GET_VACATIONS,
  GET_VACATIONS_FAIL,
  GET_VACATIONS_SUCCESS,

  GET_ONE_VACATION,
  GET_ONE_VACATION_SUCCESS,
  GET_ONE_VACATION_FAIL,

  CREATE_VACATION,
  CREATE_VACATION_SUCCESS,
  CREATE_VACATION_FAIL,

  PATCH_VACATION,
  PATCH_VACATION_SUCCESS,
  PATCH_VACATION_FAIL,

  DELETE_VACATION,
  DELETE_VACATION_SUCCESS,
  DELETE_VACATION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  vacation: null,
  vacations: [],
  error: null,

  loading_get_vacations: false,
  loading_get_vacation: false,

  loading_add_vacation: false,
  loading_edit_vacation: false,

  count_all_vacations: 0,
}

const VacationState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_VACATIONS:
      return {
        ...state, loading_get_vacations: true, error: null,
        vacations: []
      }
    case GET_VACATIONS_SUCCESS:
      return {
        ...state,
        vacations: action.payload.vacations,
        loading_get_vacations: false, error: null,
        count_all_vacations: action.payload.counts || 0
      }
    case GET_VACATIONS_FAIL:
      return {
        ...state,
        loading_get_vacations: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_VACATION:
      return {
        ...state, vacation: null, loading_get_vacation: true, error: null
      }
    case GET_ONE_VACATION_SUCCESS:
      return {
        ...state,
        vacation: action.payload.vacation,
        loading_get_vacation: false, error: null
      }
    case GET_ONE_VACATION_FAIL:
      return {
        ...state,
        loading_get_vacation: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_VACATION:
      return {
        ...state, vacation: null, loading_add_vacation: true, error: null
      }
    case CREATE_VACATION_SUCCESS:
      return {
        ...state,
        vacation: action.payload.vacation,
        loading_add_vacation: false, error: null
      }
    case CREATE_VACATION_FAIL:
      return {
        ...state,
        loading_add_vacation: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_VACATION:
      return {
        ...state, loading_edit_vacation: true, error: null
      }
    case PATCH_VACATION_SUCCESS:
      return {
        ...state,
        vacation: action.payload.vacation,
        loading_edit_vacation: false, error: null
      }
    case PATCH_VACATION_FAIL:
      return {
        ...state,
        loading_edit_vacation: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_VACATION:
      return {
        ...state, vacation: null, loading_delete_vacation: true, error: null
      }
    case DELETE_VACATION_SUCCESS:
      return {
        ...state,
        vacation: action.payload.vacation,
        loading_delete_vacation: false, error: null
      }
    case DELETE_VACATION_FAIL:
      return {
        ...state,
        loading_delete_vacation: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default VacationState
