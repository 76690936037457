// const { default: axiosApi } = require("helpers/axiosConfig")
import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";

const ClotheSrv = {
  // =============== GET LIST
  getList: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.clothes}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== GET ONE
  getOne: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.clothes}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== CREATE
  create: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.clothes, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH
  patch: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.clothes}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE
  delete: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.clothes}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },







  // ********************** STAFF CLOTHES ******************
  getStaffClothes: (payload) => {
    let filters = ""
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "")
            filters += `&${key}=${payload.filters[key]}`
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi.get(`${endpoints.clothing_staff}/${payload?.offset || 0}/${payload?.limit || 10}/?${filters}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },

  // =============== CREATE STAFF CLOTHES
  addStaffClothe: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.post(endpoints.clothing_staff, payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== PATCH STAFF CLOTHES
  patchStaffClothe: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.patch(`${endpoints.clothing_staff}/${payload?._id}`, payload.data)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },


  // =============== DELETE STAFF CLOTHES
  removeStaffClothe: (payload) => {
    return new Promise((resolve, reject) => {
      return axiosApi.delete(`${endpoints.clothing_staff}/${payload?._id}`)
        .then(resp => {
          resolve(resp);
        })
        .catch(e => reject(e))
    })
  },
}

export default ClotheSrv