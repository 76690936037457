/* CLEARANCES */
export const GET_CLEARANCES = "GET_CLEARANCES"
export const GET_CLEARANCES_SUCCESS = "GET_CLEARANCES_SUCCESS"
export const GET_CLEARANCES_FAIL = "GET_CLEARANCES_FAIL"

export const GET_ONE_CLEARANCE = "GET_ONE_CLEARANCE"
export const GET_ONE_CLEARANCE_SUCCESS = "GET_ONE_CLEARANCE_SUCCESS"
export const GET_ONE_CLEARANCE_FAIL = "GET_ONE_CLEARANCE_FAIL"

export const CREATE_CLEARANCE = "CREATE_CLEARANCE"
export const CREATE_CLEARANCE_SUCCESS = "CREATE_CLEARANCE_SUCCESS"
export const CREATE_CLEARANCE_FAIL = "CREATE_CLEARANCE_FAIL"

export const PATCH_CLEARANCE = "PATCH_CLEARANCE"
export const PATCH_CLEARANCE_SUCCESS = "PATCH_CLEARANCE_SUCCESS"
export const PATCH_CLEARANCE_FAIL = "PATCH_CLEARANCE_FAIL"

export const DELETE_CLEARANCE = "DELETE_CLEARANCE"
export const DELETE_CLEARANCE_SUCCESS = "DELETE_CLEARANCE_SUCCESS"
export const DELETE_CLEARANCE_FAIL = "DELETE_CLEARANCE_FAIL"


