import StockSrv from "services/StockSrv";

import {
  GET_STOCKS,
  GET_STOCKS_FAIL,
  GET_STOCKS_SUCCESS,

  GET_ONE_STOCK,
  GET_ONE_STOCK_SUCCESS,
  GET_ONE_STOCK_FAIL,

  CREATE_STOCK,
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_FAIL,

  PATCH_STOCK,
  PATCH_STOCK_SUCCESS,
  PATCH_STOCK_FAIL,

  DELETE_STOCK,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListStocks = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_STOCKS,
    });

    StockSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            stocks: response.data.data.stocks,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_STOCKS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_STOCKS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};
export const initStock= (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_STOCK,
    });

    StockSrv.initStock(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            stock: response.data.data.stock,
          }

          dispatch({
            type: CREATE_STOCK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_STOCK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createStock = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_STOCK,
    });

    StockSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            stock: response.data.data.stock,
          }

          dispatch({
            type: CREATE_STOCK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_STOCK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneStock = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_STOCK,
    });

    StockSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            stock: response.data.data.stock
          }

          dispatch({
            type: GET_ONE_STOCK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_STOCK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editStock = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_STOCK,
    });

    StockSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            stock: response.data.data.stock,
          }

          dispatch({
            type: PATCH_STOCK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_STOCK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteStock = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_STOCK,
    });

    StockSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_STOCK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_STOCK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};