import UserSrv from "services/UserSrv"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,

  GET_USER_PERMISSIONS,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAIL,

  GET_CONNECTED_PROFILE,
  GET_CONNECTED_PROFILE_SUCCESS,
  GET_CONNECTED_PROFILE_FAIL,

  EDIT_CONNECTED_PROFILE,
  EDIT_CONNECTED_PROFILE_SUCCESS,
  EDIT_CONNECTED_PROFILE_FAIL,

  EDIT_PASSWORD,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PASSWORD_FAIL,

  SEND_CONTACT_MSG,
  SEND_CONTACT_MSG_SUCCESS,
  SEND_CONTACT_MSG_FAIL,

  CHECK_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_SUCCESS,
  CHECK_SUBSCRIPTION_FAIL
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}






export const getPermissions = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_USER_PERMISSIONS,
    });

    UserSrv.getPermissions(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            permissions: response.data.data.accessRights || []
          }

          dispatch({
            type: GET_USER_PERMISSIONS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_USER_PERMISSIONS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

export const getConnectedProfile = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_CONNECTED_PROFILE,
    });

    UserSrv.getConnectedProfile(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            profile: response.data.data?.user || {}
          }

          dispatch({
            type: GET_CONNECTED_PROFILE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_CONNECTED_PROFILE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



export const editConnectedProfile = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDIT_CONNECTED_PROFILE,
    });

    UserSrv.editConnectedProfile(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            profile: response.data.data?.user || {}
          }

          dispatch({
            type: EDIT_CONNECTED_PROFILE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EDIT_CONNECTED_PROFILE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



export const editPassword = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: EDIT_PASSWORD,
    });

    UserSrv.editPassword(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data
          }

          dispatch({
            type: EDIT_PASSWORD_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: EDIT_PASSWORD_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};



export const sendContactMsg = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: SEND_CONTACT_MSG,
    });

    UserSrv.sendContactMsg(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data
          }

          dispatch({
            type: SEND_CONTACT_MSG_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: SEND_CONTACT_MSG_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

export const checkUserSubscription = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CHECK_SUBSCRIPTION,
    });

    UserSrv.checkSubscription(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data
          }

          dispatch({
            type: CHECK_SUBSCRIPTION_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CHECK_SUBSCRIPTION_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
