import PropTypes from "prop-types"
import React from "react"

// import { NavLink } from "react-router-dom";

import { Card, CardBody, Row, Col, Spinner } from "reactstrap"

const DashCardCmp = props => {
  // HOOKS

  const {
    name,
    icon,
    value, //, addition
    loading,
  } = props

  return (
    <React.Fragment>
      <Card>
        <CardBody style={{ minHeight: 100, padding: 10 }}>
          <Row>
            <Col md="10">
              <h4 className="primed"> {name || ""} </h4>
              {loading ? (
                <Spinner animation="grow" variant="primary" size="sm" />
              ) : (
                <p
                  className=""
                  style={{ color: "#333", fontSize: 16, fontWeight: 500 }}
                >
                  {" "}
                  {value}{" "}
                </p>
              )}

              {/* <p className="successed" style={{ margin: 0 }}>{addition}</p> */}
            </Col>
            <Col md="2" className="p-0">
              <i
                className={icon + " primed"}
                style={{
                  fontSize: 40,
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
DashCardCmp.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,

  value: PropTypes.string.isRequired,
  // addition: PropTypes.string.isRequired,
}

export default DashCardCmp
