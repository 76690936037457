import {
  GET_ClOTHES,
  GET_ClOTHES_FAIL,
  GET_ClOTHES_SUCCESS,

  GET_ONE_ClOTHE,
  GET_ONE_ClOTHE_SUCCESS,
  GET_ONE_ClOTHE_FAIL,

  CREATE_ClOTHE,
  CREATE_ClOTHE_SUCCESS,
  CREATE_ClOTHE_FAIL,

  PATCH_ClOTHE,
  PATCH_ClOTHE_SUCCESS,
  PATCH_ClOTHE_FAIL,

  DELETE_ClOTHE,
  DELETE_ClOTHE_SUCCESS,
  DELETE_ClOTHE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  clothe: null,
  clothes: [],
  error: null,

  loading_get_clothes: false,
  loading_get_clothe: false,

  loading_add_clothe: false,
  loading_edit_clothe: false,

  count_all_clothes: 0,
}

const ClotheState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_ClOTHES:
      return {
        ...state, loading_get_clothes: true, error: null
      }
    case GET_ClOTHES_SUCCESS:
      return {
        ...state,
        clothes: action.payload.clothes,
        loading_get_clothes: false, error: null,
        count_all_clothes: action.payload.counts || 0
      }
    case GET_ClOTHES_FAIL:
      return {
        ...state,
        loading_get_clothes: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_ClOTHE:
      return {
        ...state, clothe: null, loading_get_clothe: true, error: null
      }
    case GET_ONE_ClOTHE_SUCCESS:
      return {
        ...state,
        clothe: action.payload.clothe,
        loading_get_clothe: false, error: null
      }
    case GET_ONE_ClOTHE_FAIL:
      return {
        ...state,
        loading_get_clothe: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_ClOTHE:
      return {
        ...state, clothe: null, loading_add_clothe: true, error: null
      }
    case CREATE_ClOTHE_SUCCESS:
      return {
        ...state,
        clothe: action.payload.clothe,
        loading_add_clothe: false, error: null
      }
    case CREATE_ClOTHE_FAIL:
      return {
        ...state,
        loading_add_clothe: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_ClOTHE:
      return {
        ...state, loading_edit_clothe: true, error: null
      }
    case PATCH_ClOTHE_SUCCESS:
      return {
        ...state,
        clothe: action.payload.clothe,
        loading_edit_clothe: false, error: null
      }
    case PATCH_ClOTHE_FAIL:
      return {
        ...state,
        loading_edit_clothe: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_ClOTHE:
      return {
        ...state, clothe: null, loading_delete_clothe: true, error: null
      }
    case DELETE_ClOTHE_SUCCESS:
      return {
        ...state,
        clothe: action.payload.clothe,
        loading_delete_clothe: false, error: null
      }
    case DELETE_ClOTHE_FAIL:
      return {
        ...state,
        loading_delete_clothe: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ClotheState
