/* SUPPLYS */
export const GET_SUPPLYS = "GET_SUPPLYS"
export const GET_SUPPLYS_SUCCESS = "GET_SUPPLYS_SUCCESS"
export const GET_SUPPLYS_FAIL = "GET_SUPPLYS_FAIL"

export const GET_ONE_SUPPLY = "GET_ONE_SUPPLY"
export const GET_ONE_SUPPLY_SUCCESS = "GET_ONE_SUPPLY_SUCCESS"
export const GET_ONE_SUPPLY_FAIL = "GET_ONE_SUPPLY_FAIL"

export const CREATE_SUPPLY = "CREATE_SUPPLY"
export const CREATE_SUPPLY_SUCCESS = "CREATE_SUPPLY_SUCCESS"
export const CREATE_SUPPLY_FAIL = "CREATE_SUPPLY_FAIL"

export const PATCH_SUPPLY = "PATCH_SUPPLY"
export const PATCH_SUPPLY_SUCCESS = "PATCH_SUPPLY_SUCCESS"
export const PATCH_SUPPLY_FAIL = "PATCH_SUPPLY_FAIL"

export const DELETE_SUPPLY = "DELETE_SUPPLY"
export const DELETE_SUPPLY_SUCCESS = "DELETE_SUPPLY_SUCCESS"
export const DELETE_SUPPLY_FAIL = "DELETE_SUPPLY_FAIL"

export const IMPORT_SUPPLIES = "IMPORT_SUPPLIES"
export const IMPORT_SUPPLIES_SUCCESS = "IMPORT_SUPPLIES_SUCCESS"
export const IMPORT_SUPPLIES_FAIL = "IMPORT_SUPPLIES_FAIL"

export const GET_TRACABILITIES = "GET_TRACABILITIES"
export const GET_TRACABILITIES_SUCCESS = "GET_TRACABILITIES_SUCCESS"
export const GET_TRACABILITIES_FAIL = "GET_TRACABILITIES_FAIL"

export const PRINT_TRACEABILITY = "PRINT_TRACEABILITY"
export const PRINT_TRACEABILITY_SUCCESS = "PRINT_TRACEABILITY_SUCCESS"
export const PRINT_TRACEABILITY_FAIL = "PRINT_TRACEABILITY_FAIL"

export const GET_ONE_TRACEBILITY = "GET_ONE_TRACEBILITY"
export const GET_ONE_TRACEBILITY_SUCCESS = "GET_ONE_TRACEBILITY_SUCCESS"
export const GET_ONE_TRACEBILITY_FAIL = "GET_ONE_TRACEBILITY_FAIL"

export const PATCH_TRACEBILITY = "PATCH_TRACEBILITY"
export const PATCH_TRACEBILITY_SUCCESS = "PATCH_TRACEBILITY_SUCCESS"
export const PATCH_TRACEBILITY_FAIL = "PATCH_TRACEBILITY_FAIL"
  
export const DELETE_TRACEABILITY = "DELETE_TRACEABILITY"
export const DELETE_TRACEABILITY_SUCCESS = "DELETE_TRACEABILITY_SUCCESS"
export const DELETE_TRACEABILITY_FAIL = "DELETE_TRACEABILITY_FAIL"

