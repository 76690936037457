/* MACHINE_TYPES */
export const GET_MACHINE_TYPES = "GET_MACHINE_TYPES"
export const GET_MACHINE_TYPES_SUCCESS = "GET_MACHINE_TYPES_SUCCESS"
export const GET_MACHINE_TYPES_FAIL = "GET_MACHINE_TYPES_FAIL"

export const GET_ONE_MACHINE_TYPE = "GET_ONE_MACHINE_TYPE"
export const GET_ONE_MACHINE_TYPE_SUCCESS = "GET_ONE_MACHINE_TYPE_SUCCESS"
export const GET_ONE_MACHINE_TYPE_FAIL = "GET_ONE_MACHINE_TYPE_FAIL"

export const CREATE_MACHINE_TYPE = "CREATE_MACHINE_TYPE"
export const CREATE_MACHINE_TYPE_SUCCESS = "CREATE_MACHINE_TYPE_SUCCESS"
export const CREATE_MACHINE_TYPE_FAIL = "CREATE_MACHINE_TYPE_FAIL"

export const PATCH_MACHINE_TYPE = "PATCH_MACHINE_TYPE"
export const PATCH_MACHINE_TYPE_SUCCESS = "PATCH_MACHINE_TYPE_SUCCESS"
export const PATCH_MACHINE_TYPE_FAIL = "PATCH_MACHINE_TYPE_FAIL"

export const DELETE_MACHINE_TYPE = "DELETE_MACHINE_TYPE"
export const DELETE_MACHINE_TYPE_SUCCESS = "DELETE_MACHINE_TYPE_SUCCESS"
export const DELETE_MACHINE_TYPE_FAIL = "DELETE_MACHINE_TYPE_FAIL"