import React from "react"
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"

const Limitation = props => {
  const { text, limit, options, setLimit, setOpenLimits, open_limits } = props

  return (<Dropdown
    isOpen={open_limits}
    toggle={() => setOpenLimits(!open_limits)}
  >
    <DropdownToggle className="btn custom-drop" caret style={{ fontSize: 14 }}>
      <span className="hilighted">{limit + " "}</span>{text}
      <i className="mdi mdi-chevron-down" />
    </DropdownToggle>
    <DropdownMenu>
      {options.map((opt, i) => <DropdownItem key={"opt_" + i} onClick={() => setLimit(opt)} >{opt}</DropdownItem>)}
    </DropdownMenu>
  </Dropdown>)
}

Limitation.propTypes = {
  text: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  setLimit: PropTypes.func.isRequired,

  setOpenLimits: PropTypes.func.isRequired,
  open_limits: PropTypes.bool
}

export default Limitation
