import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,

  GET_ONE_ROLE,
  GET_ONE_ROLE_SUCCESS,
  GET_ONE_ROLE_FAIL,

  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,

  PATCH_ROLE,
  PATCH_ROLE_SUCCESS,
  PATCH_ROLE_FAIL,

  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  role: null,
  roles: [],
  error: null,

  loading_get_roles: false,
  loading_get_role: false,

  loading_add_role: false,
  loading_edit_role: false,

  count_all_roles: 0,
}

const RoleState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_ROLES:
      return {
        ...state, loading_get_roles: true, error: null
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        loading_get_roles: false, error: null,
        count_all_roles: action.payload.counts || 0
      }
    case GET_ROLES_FAIL:
      return {
        ...state,
        loading_get_roles: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_ROLE:
      return {
        ...state, role: null, loading_get_role: true, error: null
      }
    case GET_ONE_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.role,
        loading_get_role: false, error: null
      }
    case GET_ONE_ROLE_FAIL:
      return {
        ...state,
        loading_get_role: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_ROLE:
      return {
        ...state, role: null, loading_add_role: true, error: null
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.role,
        loading_add_role: false, error: null
      }
    case CREATE_ROLE_FAIL:
      return {
        ...state,
        loading_add_role: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_ROLE:
      return {
        ...state, loading_edit_role: true, error: null
      }
    case PATCH_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.role,
        loading_edit_role: false, error: null
      }
    case PATCH_ROLE_FAIL:
      return {
        ...state,
        loading_edit_role: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_ROLE:
      return {
        ...state, role: null, loading_delete_role: true, error: null
      }
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.role,
        loading_delete_role: false, error: null
      }
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading_delete_role: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default RoleState
