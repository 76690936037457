import ReportSrv from "services/ReportSrv";

import {
  GET_REPORTS,
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,

  GET_ONE_REPORT,
  GET_ONE_REPORT_SUCCESS,
  GET_ONE_REPORT_FAIL,

  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAIL,

  PATCH_REPORT,
  PATCH_REPORT_SUCCESS,
  PATCH_REPORT_FAIL,

  DELETE_REPORT,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_FAIL,

  PATCH_REPORT_CHECKING,
  PATCH_REPORT_CHECKING_SUCCESS,
  PATCH_REPORT_CHECKING_FAIL,

  CREATE_REPORT_CHECKING,
  CREATE_REPORT_CHECKING_SUCCESS,
  CREATE_REPORT_CHECKING_FAIL,

  DELETE_REPORT_CHECKING,
  DELETE_REPORT_CHECKING_SUCCESS,
  DELETE_REPORT_CHECKING_FAIL,

  DELETE_REPORT_PROJECT,
  DELETE_REPORT_PROJECT_SUCCESS,
  DELETE_REPORT_PROJECT_FAIL,

  CHECK_WORKING_STAFF_DATE,
  CHECK_WORKING_STAFF_DATE_SUCCESS,
  CHECK_WORKING_STAFF_DATE_FAIL,

  GET_REPORTED_TASKS,
  GET_REPORTED_TASKS_SUCCESS,
  GET_REPORTED_TASKS_FAIL,

  CREATE_CHECKING_TASK,
  CREATE_CHECKING_TASK_SUCCESS,
  CREATE_CHECKING_TASK_FAIL,

  DELETE_CHECKING_TASK,
  DELETE_CHECKING_TASK_SUCCESS,
  DELETE_CHECKING_TASK_FAIL,

  PATCH_CHECKING_TASK,
  PATCH_CHECKING_TASK_SUCCESS,
  PATCH_CHECKING_TASK_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListReports = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_REPORTS,
    });

    ReportSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            reports: response.data.data.workReports || [],
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_REPORTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_REPORTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createReport = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_REPORT,
    });

    ReportSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            report: response.data.data.report,
          }

          dispatch({
            type: CREATE_REPORT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_REPORT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneReport = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_REPORT,
    });

    ReportSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            report: response.data.data.workReport
          }

          dispatch({
            type: GET_ONE_REPORT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_REPORT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editReport = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_REPORT,
    });

    ReportSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            report: response.data.data.report,
          }

          dispatch({
            type: PATCH_REPORT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_REPORT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteReport = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_REPORT,
    });

    ReportSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_REPORT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_REPORT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== CHECK WORKING
export const checkWorkingStaffDate = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_WORKING_STAFF_DATE,
    });
    ReportSrv.checkWorkingStaff(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data,
          }

          dispatch({
            type: CHECK_WORKING_STAFF_DATE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CHECK_WORKING_STAFF_DATE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET REPORTED TASKs
export const getReportedTasks = (payload, callback, callbackError) => {
  return (dispatch) => {
    dispatch({
      type: GET_REPORTED_TASKS,
    });
    ReportSrv.reportedTasks(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            data: response.data.data,
          }

          dispatch({
            type: GET_REPORTED_TASKS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_REPORTED_TASKS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== EDIT REPORT CHECKING TASK
export const editReportCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_REPORT_CHECKING,
    });

    ReportSrv.patchReportCheckingTask(payload)
      .then((response) => {
        if (response) { //&& response?.data?.success
          const resp = response

          dispatch({
            type: PATCH_REPORT_CHECKING_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_REPORT_CHECKING_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== ADD REPORT CHECKING TASK
export const addReportCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_REPORT_CHECKING,
    });
    
    ReportSrv.addReportCheckingTask(payload)
      .then((response) => {
        if (response) { //&& response?.data?.success
          const resp = response

          dispatch({
            type: CREATE_REPORT_CHECKING_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_REPORT_CHECKING_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== DELETE CHECKING TASK FROM REPORT
export const deleteReportCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_REPORT_CHECKING,
    });
    
    ReportSrv.deleteReportCheckingTask(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_REPORT_CHECKING_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_REPORT_CHECKING_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE PROJECT FROM REPORT
export const deleteReportProject = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_REPORT_PROJECT,
    });
    
    ReportSrv.deleteReportProject(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_REPORT_PROJECT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_REPORT_PROJECT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};














// =============== ADD CHECKING TASK
export const addCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_CHECKING_TASK,
    });
    
    ReportSrv.addCheckingTask(payload)
      .then((response) => {
        if (response) { //&& response?.data?.success
          const resp = response

          dispatch({
            type: CREATE_CHECKING_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_CHECKING_TASK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== EDIT CHECKING TASK
export const editCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_CHECKING_TASK,
    });
    
    ReportSrv.patchCheckingTask(payload)
      .then((response) => {
        if (response) { //&& response?.data?.success
          const resp = response

          dispatch({
            type: PATCH_CHECKING_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_CHECKING_TASK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== ADD CHECKING TASK
export const deleteCheckingTask = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_CHECKING_TASK,
    });
    
    ReportSrv.deleteCheckingTask(payload)
      .then((response) => {
        if (response) { //&& response?.data?.success
          const resp = response

          dispatch({
            type: DELETE_CHECKING_TASK_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_CHECKING_TASK_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};