/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

export const GET_ONE_ORDER = "GET_ONE_ORDER"
export const GET_ONE_ORDER_SUCCESS = "GET_ONE_ORDER_SUCCESS"
export const GET_ONE_ORDER_FAIL = "GET_ONE_ORDER_FAIL"

export const CREATE_ORDER = "CREATE_ORDER"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"

export const PATCH_ORDER = "PATCH_ORDER"
export const PATCH_ORDER_SUCCESS = "PATCH_ORDER_SUCCESS"
export const PATCH_ORDER_FAIL = "PATCH_ORDER_FAIL"

export const PRINT_ORDER = "PRINT_ORDER"
export const PRINT_ORDER_SUCCESS = "PRINT_ORDER_SUCCESS"
export const PRINT_ORDER_FAIL = "PRINT_ORDER_FAIL"

export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"