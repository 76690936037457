/* AUTHORIZATIONS */
export const GET_AUTHORIZATIONS = "GET_AUTHORIZATIONS"
export const GET_AUTHORIZATIONS_SUCCESS = "GET_AUTHORIZATIONS_SUCCESS"
export const GET_AUTHORIZATIONS_FAIL = "GET_AUTHORIZATIONS_FAIL"

export const GET_ONE_AUTHORIZATION = "GET_ONE_AUTHORIZATION"
export const GET_ONE_AUTHORIZATION_SUCCESS = "GET_ONE_AUTHORIZATION_SUCCESS"
export const GET_ONE_AUTHORIZATION_FAIL = "GET_ONE_AUTHORIZATION_FAIL"

export const CREATE_AUTHORIZATION = "CREATE_AUTHORIZATION"
export const CREATE_AUTHORIZATION_SUCCESS = "CREATE_AUTHORIZATION_SUCCESS"
export const CREATE_AUTHORIZATION_FAIL = "CREATE_AUTHORIZATION_FAIL"

export const PATCH_AUTHORIZATION = "PATCH_AUTHORIZATION"
export const PATCH_AUTHORIZATION_SUCCESS = "PATCH_AUTHORIZATION_SUCCESS"
export const PATCH_AUTHORIZATION_FAIL = "PATCH_AUTHORIZATION_FAIL"

export const DELETE_AUTHORIZATION = "DELETE_AUTHORIZATION"
export const DELETE_AUTHORIZATION_SUCCESS = "DELETE_AUTHORIZATION_SUCCESS"
export const DELETE_AUTHORIZATION_FAIL = "DELETE_AUTHORIZATION_FAIL"

export const GET_AUTHORIZATION_SUPPLIES = "GET_AUTHORIZATION_SUPPLIES"
export const GET_AUTHORIZATION_SUPPLIES_SUCCESS = "GET_AUTHORIZATION_SUPPLIES_SUCCESS"
export const GET_AUTHORIZATION_SUPPLIES_FAIL = "GET_AUTHORIZATION_SUPPLIES_FAIL"

export const GET_EMPOWERMENTS = "GET_EMPOWERMENTS"
export const GET_EMPOWERMENTS_SUCCESS = "GET_EMPOWERMENTS_SUCCESS"
export const GET_EMPOWERMENTS_FAIL = "GET_EMPOWERMENTS_FAIL"
