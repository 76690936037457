import {
  GET_ACTIVITYS,
  GET_ACTIVITYS_FAIL,
  GET_ACTIVITYS_SUCCESS,

  GET_ONE_ACTIVITY,
  GET_ONE_ACTIVITY_SUCCESS,
  GET_ONE_ACTIVITY_FAIL,

  CREATE_ACTIVITY,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAIL,

  PATCH_ACTIVITY,
  PATCH_ACTIVITY_SUCCESS,
  PATCH_ACTIVITY_FAIL,

  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL
} from "./actionTypes"

const INIT_STATE = {
  activity: null,
  activities: [],
  error: null,

  loading_get_activities: false,
  loading_get_activity: false,

  loading_add_activity: false,
  loading_edit_activity: false,

  count_all_activities: 0,
}

const ActivityState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_ACTIVITYS:
      return {
        ...state, loading_get_activities: true, error: null
      }
    case GET_ACTIVITYS_SUCCESS:
      return {
        ...state,
        activities: action.payload.activities,
        loading_get_activities: false, error: null,
        count_all_activities: action.payload.counts || 0
      }
    case GET_ACTIVITYS_FAIL:
      return {
        ...state,
        loading_get_activities: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_ACTIVITY:
      return {
        ...state, activity: null, loading_get_activity: true, error: null
      }
    case GET_ONE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload.activity,
        loading_get_activity: false, error: null
      }
    case GET_ONE_ACTIVITY_FAIL:
      return {
        ...state,
        loading_get_activity: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_ACTIVITY:
      return {
        ...state, activity: null, loading_add_activity: true, error: null
      }
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload.activity,
        loading_add_activity: false, error: null
      }
    case CREATE_ACTIVITY_FAIL:
      return {
        ...state,
        loading_add_activity: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_ACTIVITY:
      return {
        ...state, loading_edit_activity: true, error: null
      }
    case PATCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload.activity,
        loading_edit_activity: false, error: null
      }
    case PATCH_ACTIVITY_FAIL:
      return {
        ...state,
        loading_edit_activity: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_ACTIVITY:
      return {
        ...state, activity: null, loading_delete_activity: true, error: null
      }
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.payload.activity,
        loading_delete_activity: false, error: null
      }
    case DELETE_ACTIVITY_FAIL:
      return {
        ...state,
        loading_delete_activity: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default ActivityState
