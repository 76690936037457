import DocumentSrv from "services/DocumentSrv";

import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAIL,
  GET_DOCUMENTS_SUCCESS,

  GET_ONE_DOCUMENT,
  GET_ONE_DOCUMENT_SUCCESS,
  GET_ONE_DOCUMENT_FAIL,

  CREATE_DOCUMENT,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAIL,

  PATCH_DOCUMENT,
  PATCH_DOCUMENT_SUCCESS,
  PATCH_DOCUMENT_FAIL,

  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,

} from "./actionTypes"

// =============== GET LIST
export const getListDocuments = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_DOCUMENTS,
    });

    DocumentSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            documents: response.data.data.documents,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_DOCUMENTS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_DOCUMENTS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createDocument = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_DOCUMENT,
    });

    DocumentSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            document: response.data.data.expense
          }

          dispatch({
            type: CREATE_DOCUMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_DOCUMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneDocument = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_DOCUMENT,
    });

    DocumentSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            document: response.data.data.documents
          }

          dispatch({
            type: GET_ONE_DOCUMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_DOCUMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editDocument = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_DOCUMENT,
    });

    DocumentSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            document: response.data.data.document,
          }

          dispatch({
            type: PATCH_DOCUMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_DOCUMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteDocument = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_DOCUMENT,
    });

    DocumentSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_DOCUMENT_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_DOCUMENT_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};