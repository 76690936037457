/* MIDDLE_SCHOOLS */
export const GET_MIDDLE_SCHOOLS = "GET_MIDDLE_SCHOOLS"
export const GET_MIDDLE_SCHOOLS_SUCCESS = "GET_MIDDLE_SCHOOLS_SUCCESS"
export const GET_MIDDLE_SCHOOLS_FAIL = "GET_MIDDLE_SCHOOLS_FAIL"

export const GET_ONE_MIDDLE_SCHOOL = "GET_ONE_MIDDLE_SCHOOL"
export const GET_ONE_MIDDLE_SCHOOL_SUCCESS = "GET_ONE_MIDDLE_SCHOOL_SUCCESS"
export const GET_ONE_MIDDLE_SCHOOL_FAIL = "GET_ONE_MIDDLE_SCHOOL_FAIL"
 
export const CREATE_MIDDLE_SCHOOL= "CREATE_MIDDLE_SCHOOL"
export const CREATE_MIDDLE_SCHOOL_SUCCESS = "CREATE_MIDDLE_SCHOOL_SUCCESS"
export const CREATE_MIDDLE_SCHOOL_FAIL = "CREATE_MIDDLE_SCHOOL_FAIL"

export const PATCH_MIDDLE_SCHOOL = "PATCH_MIDDLE_SCHOOL"
export const PATCH_MIDDLE_SCHOOL_SUCCESS = "PATCH_MIDDLE_SCHOOL_SUCCESS"
export const PATCH_MIDDLE_SCHOOL_FAIL = "PATCH_MIDDLE_SCHOOL_FAIL"

export const DELETE_MIDDLE_SCHOOL = "DELETE_MIDDLE_SCHOOL"
export const DELETE_MIDDLE_SCHOOL_SUCCESS = "DELETE_MIDDLE_SCHOOL_SUCCESS"
export const DELETE_MIDDLE_SCHOOL_FAIL = "DELETE_MIDDLE_SCHOOL_FAIL"