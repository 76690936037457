import {
  GET_QUOTES,
  GET_QUOTES_FAIL,
  GET_QUOTES_SUCCESS,

  GET_ONE_QUOTE,
  GET_ONE_QUOTE_SUCCESS,
  GET_ONE_QUOTE_FAIL,

  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAIL,

  PATCH_QUOTE,
  PATCH_QUOTE_SUCCESS,
  PATCH_QUOTE_FAIL,

  DELETE_QUOTE,
  DELETE_QUOTE_SUCCESS,
  DELETE_QUOTE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  quote: null,
  quotes: [],
  error: null,

  loading_get_quotes: false,
  loading_get_quote: false,

  loading_add_quote: false,
  loading_edit_quote: false,

  count_all_quotes: 0,
}

const QuoteState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET LIST
    case GET_QUOTES:
      return {
        ...state, loading_get_quotes: true, error: null
      }
    case GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload.quotes,
        loading_get_quotes: false, error: null,
        count_all_quotes: action.payload.counts || 0
      }
    case GET_QUOTES_FAIL:
      return {
        ...state,
        loading_get_quotes: false,
        error: action.payload?.error || {}
      }


    // =============== GET ONE
    case GET_ONE_QUOTE:
      return {
        ...state, quote: null, loading_get_quote: true, error: null
      }
    case GET_ONE_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        loading_get_quote: false, error: null
      }
    case GET_ONE_QUOTE_FAIL:
      return {
        ...state,
        loading_get_quote: false,
        error: action.payload?.error || {}
      }



    // =============== CREATE
    case CREATE_QUOTE:
      return {
        ...state, quote: null, loading_add_quote: true, error: null
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        loading_add_quote: false, error: null
      }
    case CREATE_QUOTE_FAIL:
      return {
        ...state,
        loading_add_quote: false,
        error: action.payload?.error || {}
      }




    // =============== PATCH
    case PATCH_QUOTE:
      return {
        ...state, loading_edit_quote: true, error: null
      }
    case PATCH_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        loading_edit_quote: false, error: null
      }
    case PATCH_QUOTE_FAIL:
      return {
        ...state,
        loading_edit_quote: false,
        error: action.payload?.error || {}
      }



    // =============== DELETE
    case DELETE_QUOTE:
      return {
        ...state, quote: null, loading_delete_quote: true, error: null
      }
    case DELETE_QUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        loading_delete_quote: false, error: null
      }
    case DELETE_QUOTE_FAIL:
      return {
        ...state,
        loading_delete_quote: false,
        error: action.payload?.error || {}
      }





    default:
      return state
  }
}

export default QuoteState
