import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListUserSubscriptionsPage = React.lazy(() => import('./list-user-subscription'));
const EditUserSubscription = React.lazy(() => import('./edit-user-subscription'));

const UserSubscriptions = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListUserSubscriptionsPage {...props} />}
      />

       <Route path={`${match.url}/preview/:subscriptionId`}
        render={props => <EditUserSubscription {...props} />}
      />

      {/*<Route path={`${match.url}/edit/:subscriptionId`}
        render={props => <EditSubscriptionPage {...props} />}
      /> */}

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default UserSubscriptions;