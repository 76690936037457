import React from "react"
import PropTypes from 'prop-types'
// import {  } from "reactstrap"
// import { NavLink, Router } from "react-router-dom";

const AddBtn = props => {
  return (
    <div className="add-btn" {...props}>
      <span className="add-icon"><i className="mdi mdi-plus" /></span>
      <span data-testid="link_text" >{props.text}</span>
    </div>
  )
}

AddBtn.propTypes = {
  text: PropTypes.string.isRequired
}

export default AddBtn
