import { endpoints } from "helpers/apiConfigs";
import axiosApi from "../helpers/axiosConfig";
const CountsSrv = {
  // =============== GET LIST
  getCount: (payload) => {
    // console.log(payload);

    let filters = "";
    if (payload.filters) {
      for (const key in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, key)) {
          if (payload.filters[key] && payload.filters[key] !== "") {
            filters += `&${key}=${payload.filters[key]}`;
          }
        }
      }
    }

    return new Promise((resolve, reject) => {
      return axiosApi
        .post(`${endpoints.counts}`, payload)
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((e) => reject(e));
    });
  },
};

export default CountsSrv;