import PropTypes from 'prop-types'
import React, { useEffect, useState,useMemo } from "react"

import { NavLink } from "react-router-dom";

import {
  Card, CardBody, Row, Col
} from "reactstrap"

// Redux
import { useDispatch,useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

import SweetAlert from "react-bootstrap-sweetalert"
import {
  getListProjects, deleteProject, getListStaffs
} from 'store/actions'

import no from "../../../assets/images/no.png";
import Limitation from 'components/Common/Tools/Limitation/Limitation';
import AddBtn from 'components/Common/Tools/AddBtn/AddBtn';
// import SortBy from 'components/Common/Tools/SortBy/SortBy';
import ListingTable from 'components/Common/ListingTable/ListingTable';

import SearchInput from 'components/Common/Tools/SearchInput/Search';
import { isFunction, getCountPages } from 'helpers/utils';
import Pagination from 'components/Common/Pagination/Pagination';
// import DropStatuses from 'components/Common/DropStatuses/DropStatuses';
import moment from 'moment';
import { endpoints } from 'helpers/apiConfigs';

import ReactTooltip from 'react-tooltip'

import AsyncSelect from 'react-select/async';
import SelectReact from 'react-select';

const statuses = [{
  label: "Créé", value: 1,
}, {
  label: "Programmé", value: 6,
}, {
  label: "En Cours", value: 2,
}, {
  label: "Suspendu", value: 5,
}, {
  label: "Terminé", value: 4,
}, {
  label: "Arrêté", value: 3,
}, {
  label: "Archivé", value: 7,
}, {
  label: "Tous les chantiers", value: -1, color: "#264B9A"
}]

const ListProjectsGridCmp = props => {
  // HOOKS
  const dispatch = useDispatch();
  // const { count_all_projects } = useSelector(({ ProjectState }) => ProjectState);
  const user = useSelector(state => state.Login.user);
  const memoizedUser = useMemo(() => user, [user]);
  const [projects, setProjects] = useState([])
  const [count_all_projects, setCountAll] = useState(0)

  const [selected_projects, setSelectedProjects] = useState([])

  // const [open_sorts, setOpenSorts] = useState(false)
  const [sort_by, setSortBy] = useState({
    name: "Date desc", value: "-_id"
  })
  const [query_name, setQueryName] = useState("")
  // const [status, setStatus] = useState(-1)
  const [filter_statuses, setFilterStatuses] = useState([{ label: "Tous les chantiers", value: -1, color: "#264B9A" }]);

  const [open_limits, setOpenLimits] = useState(false)
  const [limit, setLimit] = useState(20)
  const [current_page, setCurrentPage] = useState(1)

  const [show_alert_delete, setShowAlertDelete] = useState(false)
  const [selected_project, setSelectedProject] = useState(null)
  const [success_dlg, setSuccessDLG] = useState(false)


  const [staff_options, setStaffOptions] = useState([{ value: null, label: "Tous" }])
  const [filter_staff_id, setFilterStaffId] = useState(null)

  const {
    show_selection, default_selected_projects, parent_filters, //display_mode
    hide_image, hide_client, hide_header, hide_actions, force_limitation
    //selection_multi
  } = props

  useEffect(() => {
    getStaffsPromise()

    setSelectedProject(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query_name, limit, sort_by, parent_filters, filter_statuses, filter_staff_id])

  const [mode_view, setModeView] = useState("table");

  const getPage = (page = 1) => {
    const offset = (page === 1 || page === 0) ? 0 : limit * (page - 1);
    const filters = {
      name: query_name,
      status: "1,2,3,4,5,6,7"
    }
    if (sort_by?.value !== "undefined") filters["sort"] = sort_by.value;

    if (!filter_statuses?.filter((e) => e.value === -1).length) filters.status = filter_statuses.map(item => item.value).join(",")

    if (filter_staff_id) filters['staffID'] = filter_staff_id

    if (parent_filters && Array.isArray(parent_filters)) {
      for (let i = 0; parent_filters.length > i; i++) {
        filters[parent_filters[i].key] = parent_filters[i].value
      }
    }



    // if(status != -1)
    dispatch(getListProjects({
      offset, limit: force_limitation ? force_limitation : limit, filters
    }, (resp) => {
      setProjects(resp?.projects || [])
      setCountAll(resp?.counts || 0)
    }, (err) => { }))
  }

  const getStaffsPromise = (query_search = "") => {
    return new Promise((resolve) => {
      dispatch(getListStaffs({
        offset: 0, limit: 500, filters: {
          sort: "firstName", firstName: query_search,
        }
      }, (resp) => {
        const data_staff = resp.staffs || []
        const staff_options = [{ value: null, label: "Tous" }];

        for (let i = 0; i < data_staff.length; i++) {
          staff_options.push({
            value: data_staff[i]?._id,
            label: data_staff[i]?.profile?.firstName + " " + data_staff[i]?.profile?.lastName
          })
        }

        // if (query_search === "") setStaffOptions(staff_options)
        setStaffOptions(staff_options)
        resolve(staff_options)
      }, (err) => { resolve([{ value: null, label: "Tous" }]) }))
    });
  }

  useEffect(() => {
    if (isFunction(props.changeSelection)) props.changeSelection(selected_projects)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_projects])

  useEffect(() => {
    setSelectedProjects(default_selected_projects ? [...default_selected_projects] : [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_selected_projects])


  const deleteSelectedCLient = () => {
    if (selected_project) {
      dispatch(deleteProject({ _id: selected_project._id }, (resp) => {
        // setSuccessDLG(true)
        getPage(current_page)
      }, (error) => {
        getPage(current_page)
        // alert(error.message || "Veuillez vérifier vos données et réessayer")
      }))
    }
  }


  // const isDefaultChecked = (project_id) => {
  //   if (Array.isArray(default_selected_projects))
  //     for (const cl of default_selected_projects) {
  //       if (cl?.name === project_id) return true
  //     }

  //   return false
  // }

  // const changeSelectionList = (project) => {
  //   if (!props.selection_multi) setSelectedProjects([project])

  //   else {
  //     let exist_index = -1
  //     for (let i = 0; i < selected_projects.length; i++) {
  //       if (selected_projects[i].name === project.name) {
  //         exist_index = i; break;
  //       }
  //     }

  //     const new_selected_projects = selected_projects;

  //     if (exist_index > -1) new_selected_projects.splice(exist_index, 1)
  //     else new_selected_projects.push(project)

  //     setSelectedProjects([...new_selected_projects])
  //   }
  // }

  const headers = [
    { name: "#" },
    { name: "Nom", sorting: true, sort_by: "name" },
    { name: "Client" },
    { name: "Types" },
    { name: "Mise en service", sorting: true, sort_by: "beginDate" },
    { name: "Statut", style: { width: 140 } },
    // , sorting: true, sort_by: "status"

    {
      name: "Action", style: { width: 100 }
    }
  ]
  if (show_selection) headers.unshift({ name: "" })
  if (hide_actions) headers.splice(headers.length - 1, 1);

  const renderProjectStatus = (status) => {
    if (!status) return null;

    switch (status.code) {
      case 0: return (<span style={{ borderRadius: 3, backgroundColor: "#f1b44c", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Brouillon
      </span>)
      case 1: return (<span style={{ borderRadius: 3, backgroundColor: "#264b9a", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Créé
      </span>)
      case 2: return (<span style={{ borderRadius: 3, backgroundColor: "#34c38f", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        En cours
      </span>)

      case 3: return (<span style={{ borderRadius: 3, backgroundColor: "#f46a6a", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Arrêté
      </span>)

      case 4: return (<span style={{ borderRadius: 3, backgroundColor: "#adb5bd", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Terminé
      </span>)

      case 5: return (<span style={{ borderRadius: 3, backgroundColor: "#adb5bd", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Suspendu
      </span>)

      case 6: return (<span style={{ borderRadius: 3, backgroundColor: "#f1b44c", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Programmée
      </span>)

      case 7: return (<span style={{ borderRadius: 3, backgroundColor: "#adb5bd", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        Archivé
      </span>)


      default: return (<span style={{ borderRadius: 3, backgroundColor: "#34c38f", color: "#fff", padding: "5px 10px", width: 120, marginRight: 10 }}>
        En cours
      </span>)
    }
  }

  const renderProjectImage = (item) => {
    if (!item) return null;
    if (item.logo) return <img src={`${endpoints.root}/${item.logo.path}`} style={{ width: 40, float: "left", marginRight: 5 }} alt={item.name || ""} />

    if (item.client?.profile?.picture) return <img src={`${endpoints.root}/${item.client?.profile?.picture?.path}`} style={{ width: 40, float: "left", marginRight: 5 }} alt={item.name || ""} />

    return <img src={no} style={{ width: 40, float: "left", marginRight: 5 }} alt={item.name || ""} />
  }
  return (
    <React.Fragment >
      {!hide_header ? <Row className="mb-2">
        <Col md="3">
          <div style={{ maxWidth: 287 }}>
            <SearchInput onChange={(e) => setQueryName(e.target.value)}
              icon_name="mdi mdi-handshake" onIconClick={() => getPage()}
            />
          </div>
        </Col>
        {/* <Col md="2">
          <DropStatuses
            statuses={[{
              name: "Créé", value: 1,
            }, {
              name: "Programmée", value: 6,
            }, {
              name: "En Cours", value: 2,
            }, {
              name: "Suspendu", value: 5,
            }, {
              name: "Terminé", value: 4,
            }, {
              name: "Arrêté", value: 3,
            }, {
              name: "Archivé", value: 7,
            }, {
              name: "Tous les chantiers", value: -1, color: "#264B9A"
            }]}

            onStatusChanged={(new_status) => {
              if (new_status) setStatus(new_status.value)
            }}

            default_status={{
              name: "Tous les chantiers", value: "Tous les chantiers", color: "#264B9A",
            }}
          />
        </Col> */}

        <Col md="3">
         { memoizedUser?.userType !== 'CLIENT' &&  <Row>
            <Col md="4">
              <span className="frm-label" style={{ float: 'right', marginTop: 9 }}>Personnel</span>
            </Col>
            <Col md="8">
              <AsyncSelect
                value={staff_options.filter(option => option.value === filter_staff_id)}
                defaultOptions={staff_options}
                options={staff_options}
                loadOptions={(inputValue) => getStaffsPromise(inputValue)}
                onChange={(e) => setFilterStaffId(e?.value)}
              />
            </Col>
          </Row>
}
        </Col>

          <Col md="6">
            <div className="float-right">
            <Limitation
              options={mode_view === "table" ? [5, 10, 20] : [6, 12, 24]} text="par page"
              limit={limit} setLimit={setLimit}
              setOpenLimits={setOpenLimits} open_limits={open_limits}
            />
          </div>


          <div className="float-right" style={{ fontSize: 18, marginTop: 5 }}>
            <i className="mdi mdi-format-list-text"
              style={{ cursor: "pointer", color: mode_view === "table" ? "#264b9a" : "#777" }}
              onClick={() => setModeView("table")}
            />{"  "}
            <i className="mdi mdi-view-grid-outline"
              style={{ cursor: "pointer", color: mode_view === "grid" ? "#264b9a" : "#777" }}
              onClick={() => setModeView("grid")}
            />
          </div>


          {/* <div className="float-right mr-2 ml-2">
            <SortBy
              sort_by={sort_by.name} setSortBy={setSortBy}
              open_sorts={open_sorts} setOpenSorts={setOpenSorts}
              options={[{
                name: "Nom", value: "firstName"
              }, {
                name: "Nom desc", value: "-firstName"
              }, {
                name: "Date", value: "_id"
              }, {
                name: "Date desc", value: "-_id"
              },]}
            />
          </div> */}
  
         { memoizedUser?.userType !== 'CLIENT' && <div className="float-right" style={{ marginRight: 5 }}>
            <NavLink href={"/projects/add"} to={"/projects/add"}>
              <AddBtn text="Ajouter" />
            </NavLink>
          </div>}
        </Col>

        <Col md="6" className='mt-2'>
          <SelectReact
            onChange={(new_statuses) => {
              if (!new_statuses?.length) setFilterStatuses([{
                label: "Tous les chantiers", value: -1, color: "#264B9A"
              }])
              else setFilterStatuses(new_statuses.filter(item => item.value !== -1) || []);
            }}

            isMulti closeMenuOnSelect={true}
            value={statuses?.filter(option => filter_statuses.map((filter_status) => filter_status.value).includes(option.value))}

            options={statuses}
            isOptionDisabled={(option) => option.value === -1}
          />
        </Col>
        <Col md="6" />

      </Row> : null}





      <ReactTooltip
        // backgroundColor="#fff" textColor="#333" borderColor="#444"
        arrowColor="#264b9a" type="info" multiline
      />


      <div style={{ minHeight: 500 }}>

        {(mode_view === "table" && Array.isArray(projects)) ?
          <Card>
            <CardBody className="p-0" style={{ minHeight: 100 }}>
              <ListingTable headers={headers}
                changeSortBy={(new_sort_by) => setSortBy(new_sort_by || "")}
              >
                {projects?.map((item, i) => <tr key={"prj_" + item?._id}
                  onMouseEnter={() => ReactTooltip.rebuild()}
                  data-tip={`
                  #${item.ref}<br />
                  ${(item.address?.fullAddress && item.address?.fullAddress !== "") ? item.address?.fullAddress : (item.address?.street || "")}<br />
                  ${([1, 2, 6].includes(item.status.code) && item.countFinishedTasks) ? "Interventions Terminées : " + (item.countFinishedTasks || 0) + "<br />" : ""}
                  ${([1, 2, 6].includes(item.status.code) && item.countInprogressTasks) ? "Interventions En cours : " + (item.countInprogressTasks || 0) + "<br />" : ""}
                  ${([1, 2, 6].includes(item.status.code) && item.countPendingTasks) ? "Interventions En attente : " + (item.countPendingTasks || 0) + "<br />" : ""}
                `}>

                  <td>{hide_image ? null : renderProjectImage(item)}</td>

                  <td>
                    <NavLink to={"/projects/preview/" + item._id}>
                      {item.name}
                      {/* <span className="grey" style={{ display: "block" }}>#{item.ref}</span>
                      <span className="grey" style={{ display: "block" }}>
                        <i className="dripicons dripicons-location" style={{ fontSize: 10 }} /> {(item.address?.fullAddress && item.address?.fullAddress !== "") ? item.address?.fullAddress : (item.address?.street || "")}
                      </span> */}
                    </NavLink>
                  </td>

                  <td onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``} >
                    {hide_client ? null : <NavLink to={"/clients/preview/" + item._id}>
                      {item.client?.profile.fullName}
                      {item.client ? <span className="grey" style={{ display: "block" }}>#{item.client?.ref}</span> : null}
                    </NavLink>}
                  </td>

                  <td><span className="hilighted">{
                    item.projectTypes?.map(project_type => ((project_type?.name && project_type?.name !== "") ? project_type?.name : "")).join(', ')
                    // item.projectType?.name
                  }</span></td>


                  <td onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``}><span className="grey">{item.beginDate ? moment(item.beginDate).format("DD-MM-YYYY") : null}</span></td>


                  <td onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``}>{
                    <span style={{ textAlign: "center", display: "block", width: 120 }}>{renderProjectStatus(item.status)}</span>
                  }</td>



                  {!hide_actions ? <td className="actions" onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``} >
                    <NavLink to={"/projects/preview/" + item._id}><i className="mdi mdi-eye" /></NavLink>
                   {  memoizedUser?.userType !== 'CLIENT' && <NavLink to={"/projects/edit/" + item._id}><i className="mdi mdi-pencil-box-outline" /></NavLink>}
                    {/* <i onClick={() => {
                      setShowAlertDelete(true)
                      setSelectedProject(item)
                    }
                    } className="mdi mdi-trash-can-outline" /> */}
                  </td> : null}

                </tr>)}
              </ListingTable>
            </CardBody>
          </Card> :
          <Row>
            {projects?.map((item, i) => <Col key={"project_" + i} md="4" className="mt-1">
              <Card onMouseEnter={() => ReactTooltip.rebuild()}
                data-tip={`
              ${item.name} #${item.ref}<br />
              ${(item.address?.fullAddress && item.address?.fullAddress !== "") ? item.address?.fullAddress : (item.address?.street || "")}<br />
              ${([1, 2, 6].includes(item.status.code) && item.countFinishedTasks) ? "Interventions Terminées : " + (item.countFinishedTasks || 0) + "<br />" : ""}
              ${([1, 2, 6].includes(item.status.code) && item.countInprogressTasks) ? "Interventions En cours : " + (item.countInprogressTasks || 0) + "<br />" : ""}
              ${([1, 2, 6].includes(item.status.code) && item.countPendingTasks) ? "Interventions En attente : " + (item.countPendingTasks || 0) + "<br />" : ""}
            `}>
                <CardBody className="p-0 card-project">
                  <Row>
                    <Col md="2">
                      <div className="project-logo">{renderProjectImage(item)}</div>
                    </Col>
                    <Col md="8">
                      <div className="project-infos">
                        <p className="name"><NavLink to={"/projects/preview/" + item._id}>{item.name}</NavLink></p>
                        <p className="sub-name grey">{item.client?.profile.fullName}</p>

                        <p className="grey">{
                          item.projectTypes?.map(project_type => ((project_type?.name && project_type?.name !== "") ? project_type?.name : "")).join(', ')
                        }</p>
                      </div>
                    </Col>
                    <Col md="2" onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``}>
                      <div className="project-actions">
                        <NavLink to={"/projects/preview/" + item._id}><i className="mdi mdi-eye" /></NavLink>
                        <NavLink to={"/projects/edit/" + item._id}><i className="mdi mdi-pencil-box-outline" /></NavLink>
                        {/* <i onClick={() => {
                          setShowAlertDelete(true)
                          setSelectedProject(item)
                        }
                        } className="mdi mdi-trash-can-outline" /> */}
                      </div>
                    </Col>

                  </Row>
                  <div className="project-footer" onMouseEnter={() => ReactTooltip.rebuild()} data-tip={``}>
                    {/* <span style={{ borderRadius: 3, backgroundColor: "#cdf4e0", color: "#00a20c", padding: 10, width: 100, marginRight: 10 }}>
                    
                  </span> */}
                    {renderProjectStatus(item.status)}
                    <span className="grey"><i className="mdi mdi-calendar"></i> {item.beginDate ? moment(item.beginDate).format("DD MMMM YYYY") : null}</span>
                  </div>
                </CardBody>
              </Card>
            </Col>)}
          </Row>}

        {force_limitation ? null : <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_projects, limit)}
          onChangePage={i => {
            setCurrentPage(i)
            getPage(i)
          }}
        />}


      </div>



      {show_alert_delete && <SweetAlert
              style={{ height: "auto" }}
        title="Êtes-vous sûr?"
        warning
        showCancel
        confirmButtonText="Oui, supprimez-le!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setShowAlertDelete(false)
          deleteSelectedCLient(true)
          // setdynamic_title("Deleted")
          // setdynamic_description("Your file has been deleted.")
        }}
        onCancel={() => setShowAlertDelete(false)}
      >
        Vous ne pourrez pas revenir en arrière!
      </SweetAlert>}


      {success_dlg ? (
        <SweetAlert
        style={{ height: "auto" }}
          success
          title={"Supprimé"}
          onConfirm={() => {
            setSuccessDLG(false)
          }}
        >
          {"Le project a été supprimé.."}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}
ListProjectsGridCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,
  parent_filters: PropTypes.array,

  display_mode: PropTypes.oneOf(['table', "boxes"]),

  hide_client: PropTypes.bool,
  hide_image: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_projects: PropTypes.array,

  hide_actions: PropTypes.bool,
  hide_header: PropTypes.bool,
  force_limitation: PropTypes.number
}

export default ListProjectsGridCmp