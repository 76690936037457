import {
    GET_ORDERS,
    GET_ORDERS_FAIL,
    GET_ORDERS_SUCCESS,
  
    GET_ONE_ORDER,
    GET_ONE_ORDER_SUCCESS,
    GET_ONE_ORDER_FAIL,
  
    CREATE_ORDER,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
  
    PATCH_ORDER,
    PATCH_ORDER_SUCCESS,
    PATCH_ORDER_FAIL,
  
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,

  } from "./actionTypes"
  
  const INIT_STATE = {
    order: null,
    orders: [],
   
    error: null,
  
    loading_get_orders: false,
    loading_get_order: false,
  
    loading_add_order: false,
    loading_edit_order: false,
  
    count_all_orders: 0,
  
 
  }
  
  const OrderState = (state = INIT_STATE, action) => {
    switch (action.type) {
      // =============== GET LIST
      case GET_ORDERS:
        return {
          ...state, loading_get_orders: true, error: null
        }
      case GET_ORDERS_SUCCESS:
        return {
          ...state,
          orders: action.payload.orders,
          loading_get_orders: false, error: null,
          count_all_orders: action.payload.counts || 0
        }
      case GET_ORDERS_FAIL:
        return {
          ...state,
          loading_get_orders: false,
          error: action.payload?.error || {}
        }
  
  
  
     
  
  
      // =============== GET ONE
      case GET_ONE_ORDER:
        return {
          ...state, order: null, loading_get_order: true, error: null
        }
      case GET_ONE_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload.order,
          loading_get_order: false, error: null
        }
      case GET_ONE_ORDER_FAIL:
        return {
          ...state,
          loading_get_order: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== CREATE
      case CREATE_ORDER:
        return {
          ...state, order: null, loading_add_order: true, error: null
        }
      case CREATE_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload.order,
          loading_add_order: false, error: null
        }
      case CREATE_ORDER_FAIL:
        return {
          ...state,
          loading_add_order: false,
          error: action.payload?.error || {}
        }
  
  
  
  
      // =============== PATCH
      case PATCH_ORDER:
        return {
          ...state, loading_edit_order: true, error: null
        }
      case PATCH_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload.order,
          loading_edit_order: false, error: null
        }
      case PATCH_ORDER_FAIL:
        return {
          ...state,
          loading_edit_order: false,
          error: action.payload?.error || {}
        }
  
  
  
      // =============== DELETE
      case DELETE_ORDER:
        return {
          ...state, order: null, loading_delete_order: true, error: null
        }
      case DELETE_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload.order,
          loading_delete_order: false, error: null
        }
      case DELETE_ORDER_FAIL:
        return {
          ...state,
          loading_delete_order: false,
          error: action.payload?.error || {}
        }
  
  
  
  
  
      default:
        return state
    }
  }
  
  export default OrderState
  