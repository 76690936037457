/* UNITS */
export const GET_UNITS = "GET_UNITS"
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS"
export const GET_UNITS_FAIL = "GET_UNITS_FAIL"

export const GET_ONE_UNIT = "GET_ONE_UNIT"
export const GET_ONE_UNIT_SUCCESS = "GET_ONE_UNIT_SUCCESS"
export const GET_ONE_UNIT_FAIL = "GET_ONE_UNIT_FAIL"

export const CREATE_UNIT = "CREATE_UNIT"
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS"
export const CREATE_UNIT_FAIL = "CREATE_UNIT_FAIL"

export const PATCH_UNIT = "PATCH_UNIT"
export const PATCH_UNIT_SUCCESS = "PATCH_UNIT_SUCCESS"
export const PATCH_UNIT_FAIL = "PATCH_UNIT_FAIL"

export const DELETE_UNIT = "DELETE_UNIT"
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS"
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL"