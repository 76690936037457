import {
  GET_TASKS_PLANNING,
  GET_TASKS_PLANNING_SUCCESS,
  GET_TASKS_PLANNING_FAIL,
  UPDATE_TASK_PLANNING,
  UPDATE_TASK_PLANNING_SUCCESS,
  UPDATE_TASK_PLANNING_FAIL,
  UPDATE_TASK_PLANNING_ADDRESS,
  UPDATE_TASK_PLANNING_ADDRESS_SUCCESS,
  UPDATE_TASK_PLANNING_ADDRESS_FAIL,
  CUT_TASK,
  CUT_TASK_FAIL,
  CUT_TASK_SUCCESS,
  GET_ONE_TASK,
  GET_ONE_TASK_FAIL,
  GET_ONE_TASK_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  tasks: [],
  steps: [],
  loadingUpdateTask: false,
  loadingUpdateTaskAddress: false,
  loadingCutTask: false,
  loading_get_tasks: false,
  loading_get_task:false
}

const TaskState = (state = INIT_STATE, action) => {
  switch (action.type) {
    // =============== GET PLANNING
    case GET_TASKS_PLANNING:
      return {
        ...state,
        loading_get_tasks: true,
        error: null,
      }
    case GET_TASKS_PLANNING_SUCCESS:
      return {
        ...state,
        tasks: action.payload.tasks || [],
        steps: action.payload.steps || [],
        loading_get_tasks: false,
        error: null,
      }
    case GET_TASKS_PLANNING_FAIL:
      return {
        ...state,
        loading_get_tasks: false,
        error: action.payload?.error || {},
      }
    case UPDATE_TASK_PLANNING:
      return {
        ...state,
        loadingUpdateTask: true,
      }
    case UPDATE_TASK_PLANNING_SUCCESS:
      return {
        ...state,
        loadingUpdateTask: false,
      }
    case UPDATE_TASK_PLANNING_FAIL:
      return {
        ...state,
        loadingUpdateTask: false,
      }

      case UPDATE_TASK_PLANNING_ADDRESS:
      return {
        ...state,
        loadingUpdateTaskAddress: true,
      }
    case UPDATE_TASK_PLANNING_ADDRESS_SUCCESS:
      return {
        ...state,
        loadingUpdateTaskAddress: false,
      }
    case UPDATE_TASK_PLANNING_ADDRESS_FAIL:
      return {
        ...state,
        loadingUpdateTaskAddress: false,
      }
      case CUT_TASK:
        return {
          ...state,
          loadingCutTask: true,
        }
      case CUT_TASK_SUCCESS:
        return {
          ...state,
          task: action.payload.task,
          loadingCutTask: true,
        }
      case CUT_TASK_FAIL:
      return {
        ...state,
        loadingCutTask: false,
      }


    case GET_ONE_TASK:
      return {
        ...state, task: null, loading_get_task: true, error: null
      }
    case GET_ONE_TASK_SUCCESS:
      return {
        ...state,
        task: action.payload.task,
        loading_get_task: false, error: null
      }
    case GET_ONE_TASK_FAIL:
      return {
        ...state,
        loading_get_task: false,
        error: action.payload?.error || {}
      }

    default:
      return state
  }
}

export default TaskState
