import RoleSrv from "services/RoleSrv";

import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,

  GET_ONE_ROLE,
  GET_ONE_ROLE_SUCCESS,
  GET_ONE_ROLE_FAIL,

  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,

  PATCH_ROLE,
  PATCH_ROLE_SUCCESS,
  PATCH_ROLE_FAIL,

  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListRoles = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ROLES,
    });

    RoleSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            roles: response.data.data.roles,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_ROLES_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ROLES_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createRole = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_ROLE,
    });

    RoleSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            role: response.data.data.role,
          }

          dispatch({
            type: CREATE_ROLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_ROLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneRole = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_ROLE,
    });

    RoleSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            role: response.data.data.role
          }

          dispatch({
            type: GET_ONE_ROLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_ROLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editRole = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_ROLE,
    });

    RoleSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            role: response.data.data.role,
          }

          dispatch({
            type: PATCH_ROLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_ROLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteRole = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_ROLE,
    });

    RoleSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_ROLE_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_ROLE_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};