/* CLIENTS */
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

export const GET_ONE_CLIENT = "GET_ONE_CLIENT"
export const GET_ONE_CLIENT_SUCCESS = "GET_ONE_CLIENT_SUCCESS"
export const GET_ONE_CLIENT_FAIL = "GET_ONE_CLIENT_FAIL"

export const CREATE_CLIENT = "CREATE_CLIENT"
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS"
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL"

export const PATCH_CLIENT = "PATCH_CLIENT"
export const PATCH_CLIENT_SUCCESS = "PATCH_CLIENT_SUCCESS"
export const PATCH_CLIENT_FAIL = "PATCH_CLIENT_FAIL"

export const DELETE_CLIENT = "DELETE_CLIENT"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL"

export const IMPORT_CLIENTS = "IMPORT_CLIENTS"
export const IMPORT_CLIENTS_SUCCESS = "IMPORT_CLIENTS_SUCCESS"
export const IMPORT_CLIENTS_FAIL = "IMPORT_CLIENTS_FAIL"


export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS"
export const GET_SUBSCRIBERS_SUCCESS = "GET_SUBSCRIBERS_SUCCESS"
export const GET_SUBSCRIBERS_FAIL = "GET_SUBSCRIBERS_FAIL"

export const GET_CLIENT_USERS = "GET_CLIENT_USERS"
export const GET_CLIENT_USERS_SUCCESS = "GET_CLIENT_USERS_SUCCESS"
export const GET_CLIENT_USERS_FAIL = "GET_CLIENT_USERS_FAIL"


export const DELETE_CLIENT_INTERLOCUTOR = "DELETE_CLIENT_INTERLOCUTOR"
export const DELETE_CLIENT_INTERLOCUTOR_SUCCESS = "DELETE_CLIENT_INTERLOCUTOR_SUCCESS"
export const DELETE_CLIENT_INTERLOCUTOR_FAIL = "DELETE_CLIENT_INTERLOCUTOR_FAIL"

export const CREATE_INTERLOCUTOR = "CREATE_INTERLOCUTOR"
export const CREATE_INTERLOCUTOR_SUCCESS = "CREATE_INTERLOCUTOR_SUCCESS"
export const CREATE_INTERLOCUTOR_FAIL = "CREATE_INTERLOCUTOR_FAIL"


export const PATCH_SUBSCRIBER = "PATCH_SUBSCRIBER"
export const PATCH_SUBSCRIBER_SUCCESS = "PATCH_SUBSCRIBER_SUCCESS"
export const PATCH_SUBSCRIBER_FAIL = "PATCH_SUBSCRIBER_FAIL"

export const CREATE_CLIENT_USER = "CREATE_CLIENT_USER"
export const CREATE_CLIENT_USER_SUCCESS = "CREATE_CLIENT_USER_SUCCESS"
export const CREATE_CLIENT_USER_FAIL = "CREATE_CLIENT_USER_FAIL"

