import CountsSrv from "services/CountsSrv";
import { GET_COUNTS, GET_COUNTS_FAIL, GET_COUNTS_SUCCESS } from "./actionTypes";

export const getCounts = (payload, callback, callbackError) => {

    return (dispatch) => {
      dispatch({
        type: GET_COUNTS,
      });

      CountsSrv.getCount(payload)
      .then((res)=>{
        dispatch({
            type: GET_COUNTS_SUCCESS,
            payload : res
          }); 
          callback(res)
      })
      .catch((e)=>{
        dispatch({
            type: GET_COUNTS_FAIL,
          });
          callbackError(e)
      })
    };
  };