import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { NavLink } from "react-router-dom";

import {
  Card, CardBody,
  Row, Col
} from "reactstrap"

// Redux
import { useDispatch } from "react-redux"

//ACTIONS
import {
  getListClients, getListProjects, getListProviders,
} from 'store/actions'
// COMPONENTS
// import orange from "../../assets/images/orange.png";

import DashCardCmp from 'components/Dashboard/DashCardCmp';
import ListProjectsGridCmp from 'components/Modules/Projects/ListProjectsGridCmp';
import TasksByDuration from 'components/Dashboard/TasksByDuration';

// import { endpoints } from 'helpers/apiConfigs';
// https://fullcalendar.io/docs
import FullCalendar from '@fullcalendar/react' // must go before plugins

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';
import ListInscriptionsGridCmp from 'components/Modules/Inscription/ListInscriptionsGridCmp';




const AdminDashCmp = props => {
  // HOOKS
  const dispatch = useDispatch();
  const [count_all_clients, setCountAllClients] = useState(0)
  const [count_all_projects, setCountAllProjects] = useState(0)
  const [count_all_providers, setCountAllProviders] = useState(0)

  const [selected_day, setSelectedDay] = useState(moment().format("YYYY-MM-DD"))

  useEffect(() => {
    getCountClients()
    getCountProjects()
    getCountProviders()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getCountClients = () => {
    dispatch(getListClients({
      offset: 0, limit: 1, filters: {}
    }, (resp) => {
      setCountAllClients(resp?.counts || 0)
    }, (err) => { }))
  }

  const getCountProjects = () => {
    dispatch(getListProjects({
      offset: 0, limit: 1, filters: {
        status: "1,2,3,4,5,6,7"
      }
    }, (resp) => {
      setCountAllProjects(resp?.counts || 0)
    }, (err) => { }))
  }
  const getCountProviders = () => {
    dispatch(getListProviders({
      offset: 0, limit: 1, filters: {}
    }, (resp) => {
      setCountAllProviders(resp?.counts || 0)
    }, (err) => { }))
  }

  return (<React.Fragment>
    <Row className="mb-3">
      <Col md="4">
        <DashCardCmp
          name="Clients" icon="mdi mdi-account-group-outline"
          value={count_all_clients + " Clients"} addition="+2 ce mois"
        />
      </Col>
      <Col md="4">
        <DashCardCmp
          name="Chantiers" icon="mdi mdi-handshake"
          value={count_all_projects + " Chantiers"} addition="+3 ce mois"
        />
      </Col>
      <Col md="4">
        <DashCardCmp
          name="Les Fournisseurs" icon="mdi mdi-share-variant"
          value={count_all_providers + " Fournisseurs"} addition="+2 ce mois"
        />
      </Col>



      <Col md="4" className="mt-3"><p style={{ color: "#6D7995", fontSize: 16, fontWeight: 500, marginBottom: 0 }} >Les Chantiers</p></Col>
      <Col md="4" className="mt-3" style={{ textAlign: "right", marginBottom: 0 }}><p ><NavLink to="/projects" className={"hilighted"}>
        Voir tout <i className="mdi mdi-chevron-right" />
      </NavLink></p>
      </Col>
      <Col md="4" className="mt-3"><p style={{ color: "#6D7995", fontSize: 16, fontWeight: 500, marginBottom: 0 }} >Calendrier</p></Col>



      <Col md="8">
        <ListProjectsGridCmp force_limitation={5}
          hide_header={true} hide_actions={true}
        />
      </Col>
      <Col md="4">
        <Card>
          <CardBody className="p-2">
            <FullCalendar locale="fr"
              plugins={[
                dayGridPlugin,
                // timeGridPlugin, 
                interactionPlugin
              ]}

              headerToolbar={{
                left: 'title',
                center: '',
                right: '' //prev,next
              }}

              dateClick={(info) => {
                try {
                  setSelectedDay(info.dateStr)
                } catch (error) { }
              }}

              selectable={true}


              initialView="dayGridMonth"
              //resourceTimeGridDay ???
              // height={"auto"}
              // contentHeight="auto"
              // contentHeight={800}
              events={[]}
              // eventColor='#378006'

              eventMouseEnter={(info) => {
                // console.log(info.event);
                // info.el.setAttribute("data-tip", info.event?._def?.extendedProps?.description);
                // ReactTooltip.rebuild();
              }}
            />


            <TasksByDuration start_date={selected_day} end_date={selected_day} />
          </CardBody>
        </Card>
      </Col>


      <Col md="12">
        {/* <Card>
          <CardBody> */}
        
        <h4>Inscriptions</h4>
        <hr />
        <ListInscriptionsGridCmp />
        {/* </CardBody>
        </Card> */}
      </Col>
    </Row>



  </React.Fragment>)
}
AdminDashCmp.propTypes = {
  show_selection: PropTypes.bool,
  selection_multi: PropTypes.bool,

  changeSelection: PropTypes.func,
  default_selected_modules: PropTypes.array
}

export default AdminDashCmp