/* MODULES */
export const GET_MODULES = "GET_MODULES"
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS"
export const GET_MODULES_FAIL = "GET_MODULES_FAIL"

export const GET_ONE_MODULE = "GET_ONE_MODULE"
export const GET_ONE_MODULE_SUCCESS = "GET_ONE_MODULE_SUCCESS"
export const GET_ONE_MODULE_FAIL = "GET_ONE_MODULE_FAIL"

// export const CREATE_MODULE = "CREATE_MODULE"
// export const CREATE_MODULE_SUCCESS = "CREATE_MODULE_SUCCESS"
// export const CREATE_MODULE_FAIL = "CREATE_MODULE_FAIL"

export const PATCH_MODULE = "PATCH_MODULE"
export const PATCH_MODULE_SUCCESS = "PATCH_MODULE_SUCCESS"
export const PATCH_MODULE_FAIL = "PATCH_MODULE_FAIL"

// export const DELETE_MODULE = "DELETE_MODULE"
// export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS"
// export const DELETE_MODULE_FAIL = "DELETE_MODULE_FAIL"