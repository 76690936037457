import MaterialSrv from "services/MaterialSrv";

import {
  GET_MATERIALS,
  GET_MATERIALS_FAIL,
  GET_MATERIALS_SUCCESS,

  GET_ONE_MATERIAL,
  GET_ONE_MATERIAL_SUCCESS,
  GET_ONE_MATERIAL_FAIL,

  CREATE_MATERIAL,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAIL,

  PATCH_MATERIAL,
  PATCH_MATERIAL_SUCCESS,
  PATCH_MATERIAL_FAIL,

  DELETE_MATERIAL,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL,

  IMPORT_MATERIALS,
  IMPORT_MATERIALS_SUCCESS,
  IMPORT_MATERIALS_FAIL
} from "./actionTypes"

// =============== GET LIST
export const getListMaterials = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_MATERIALS,
    });

    MaterialSrv.getList(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            materials: response.data.data.materials,
            counts: response.data.data.counts
          }

          dispatch({
            type: GET_MATERIALS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_MATERIALS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};

// =============== CREATE
export const createMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: CREATE_MATERIAL,
    });

    MaterialSrv.create(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            material: response.data.data.material,
          }

          dispatch({
            type: CREATE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: CREATE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== GET ONE
export const getOneMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: GET_ONE_MATERIAL,
    });

    MaterialSrv.getOne(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            material: response.data.data.material
          }

          dispatch({
            type: GET_ONE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: GET_ONE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== PATCH
export const editMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: PATCH_MATERIAL,
    });

    MaterialSrv.patch(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            material: response.data.data.material,
          }

          dispatch({
            type: PATCH_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: PATCH_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};


// =============== DELETE
export const deleteMaterial = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: DELETE_MATERIAL,
    });

    MaterialSrv.delete(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = response.data.data

          dispatch({
            type: DELETE_MATERIAL_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: DELETE_MATERIAL_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};













// =============== IMPORT
export const importMaterials = (payload, callback, callbackError) => {

  return (dispatch) => {
    dispatch({
      type: IMPORT_MATERIALS,
    });

    MaterialSrv.import(payload)
      .then((response) => {
        if (response && response?.data?.success) {
          const resp = {
            materials: response.data.data.materials || [],
          }

          dispatch({
            type: IMPORT_MATERIALS_SUCCESS,
            payload: resp,
          });

          callback(resp);
        }
        else{
          if(callbackError) callbackError({ error: response?.data.error });
        }
      })
      .catch(e => {
        dispatch({
          type: IMPORT_MATERIALS_FAIL,
          payload: { error: {} }
        });
        if(callbackError) callbackError(e?.response?.data?.error);
      });
  };
};