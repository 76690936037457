/* PERMISSIONS */
export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS"
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL"

export const GET_ONE_PERMISSION = "GET_ONE_PERMISSION"
export const GET_ONE_PERMISSION_SUCCESS = "GET_ONE_PERMISSION_SUCCESS"
export const GET_ONE_PERMISSION_FAIL = "GET_ONE_PERMISSION_FAIL"

export const CREATE_PERMISSION = "CREATE_PERMISSION"
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS"
export const CREATE_PERMISSION_FAIL = "CREATE_PERMISSION_FAIL"

export const PATCH_PERMISSION = "PATCH_PERMISSION"
export const PATCH_PERMISSION_SUCCESS = "PATCH_PERMISSION_SUCCESS"
export const PATCH_PERMISSION_FAIL = "PATCH_PERMISSION_FAIL"

export const DELETE_PERMISSION = "DELETE_PERMISSION"
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS"
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL"

export const GET_PERMISSION_SUPPLIES = "GET_PERMISSION_SUPPLIES"
export const GET_PERMISSION_SUPPLIES_SUCCESS = "GET_PERMISSION_SUPPLIES_SUCCESS"
export const GET_PERMISSION_SUPPLIES_FAIL = "GET_PERMISSION_SUPPLIES_FAIL"


export const GET_USERS_PERMISSIONS = "GET_USERS_PERMISSIONS"
export const GET_USERS_PERMISSIONS_SUCCESS = "GET_USERS_PERMISSIONS_SUCCESS"
export const GET_USERS_PERMISSIONS_FAIL = "GET_USERS_PERMISSIONS_FAIL"

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS"
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS"
export const GET_USER_PERMISSIONS_FAIL = "GET_USER_PERMISSIONS_FAIL"

export const GET_MODELS = "GET_GET_MODELS"
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS"
export const GET_MODELS_FAIL = "GET_MODELS_FAIL"

export const GET_MODEL_PERMISSIONS = "GET_MODEL_PERMISSIONS"
export const GET_MODEL_PERMISSIONS_SUCCESS = "GET_MODEL_PERMISSIONS_SUCCESS"
export const GET_MODEL_PERMISSIONS_FAIL = "GET_MODEL_PERMISSIONS_FAIL"

export const CREATE_LIST_PERMISSIONS = "CREATE_LIST_PERMISSIONS"
export const CREATE_LIST_PERMISSIONS_SUCCESS = "CREATE_LIST_PERMISSIONS_SUCCESS"
export const CREATE_LIST_PERMISSIONS_FAIL = "CREATE_LIST_PERMISSIONS_FAIL"