import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ListClientsPage = React.lazy(() => import('./list-clients.page'));
const AddClientPage = React.lazy(() => import('./add-client.page'));
const EditClientPage = React.lazy(() => import('./edit-client.page'));
const PreviewClientPage = React.lazy(() => import('./preview-client.page'));

const Clients = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListClientsPage {...props} />}
      />

      <Route path={`${match.url}/add`}
        render={props => <AddClientPage {...props} />}
      />

      <Route path={`${match.url}/edit/:clientId`}
        render={props => <EditClientPage {...props} />}
      />


      <Route path={`${match.url}/preview/:clientId`}
        render={props => <PreviewClientPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    </Switch>
  </Suspense>
);
export default Clients;